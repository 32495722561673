import { ReactElement, useEffect, useState, useCallback } from 'react';

import { Timestamp } from 'firebase/firestore';

import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import FileUploadIcon from '@mui/icons-material/FileUpload';

import Papa from 'papaparse';

import { useData } from '../../contextProviders/DataProvider';
import DefaultObjects from '../../types/DefaultObjects';
import Invitation from '../../types/Invitation';
import HiddenInput from '../common/HiddenInput';

interface CsvInvitationRecord {
  email: string;
  name: string;
  ghin?: string;
  'appDetails.gender'?: string;
  'appDetails.mailingAddress'?: string;
  'appDetails.externalAppId'?: string;
  'appDetails.externalAppSource'?: string;
  'appDetails.clubId'?: string;
  'appDetails.clubSource'?: string;
  isMember?: string;
  isPro?: string;
  isAdmin?: string;
}

type ParseFailure = {
  row: number;
  data: Partial<CsvInvitationRecord>;
  error: string;
};

interface InvitationCsvUploadProps {
  orgId: string;
  orgName: string;
  mapping: Array<{ field: string; dbname: keyof Invitation }>;
  onInvitationsLoaded: (invitations: Invitation[], failures?: ParseFailure[]) => void;
  hasImportAction?: boolean;
  resetKey?: number;
}

export default function InvitationCsvUpload(props: InvitationCsvUploadProps): ReactElement {
  const { orgId, orgName, mapping, onInvitationsLoaded, hasImportAction = true, resetKey = 0 } = props;
  const { userProfile } = useData();
  const [csvFile, setCsvFile] = useState<string>('');
  const [parseProgress, setParseProgress] = useState<{ current: number; total: number }>({ current: 0, total: 0 });
  const [isProcessing, setIsProcessing] = useState(false);
  const [failures, setFailures] = useState<ParseFailure[]>([]);


  useEffect(() => {
    setCsvFile('');
    setParseProgress({ current: 0, total: 0 });
    setIsProcessing(false);
    setFailures([]);
  }, [resetKey]);

  const parseCsvContents = useCallback(
    (contents: string) => {
      const parsedInvitations: Invitation[] = [];

      if (!orgId) {
        onInvitationsLoaded([], [{ row: 0, data: {}, error: 'Organization not found' }]);
        console.error('Organization not found');
        return;
      }

      setIsProcessing(true);

      Papa.parse<CsvInvitationRecord>(contents, {
        header: true,
        skipEmptyLines: true,
        delimiter: ',',
        transformHeader: (header) => {
          const normalizeField = (field: string) =>
            field
              .trim()
              .toLowerCase()
              .replace(/[^a-z0-9]/g, '');

          const trimmedHeader = header.trim();
          const mappedField = mapping.find((m) => normalizeField(m.field) === normalizeField(trimmedHeader));

          console.log('Header transformation:', {
            original: header,
            trimmed: trimmedHeader,
            normalized: normalizeField(trimmedHeader),
            mapped: mappedField ? String(mappedField.dbname) : trimmedHeader,
          });

          return mappedField ? String(mappedField.dbname) : trimmedHeader;
        },
        step: (results) => {
          const row = results.data as CsvInvitationRecord;
          console.log('Raw CSV row:', row);

          // Initialize invitation with basic fields
          const invitation: Invitation = {
            ...DefaultObjects.Invitation,
            email: row.email,
            name: row.name,
            ghin: row.ghin,
            appDetails: {
              ...DefaultObjects.PersonDetails,
              gender: row['appDetails.gender'],
              mailingAddress: row['appDetails.mailingAddress'],
              externalAppId: row['appDetails.externalAppId'],
              externalAppSource: row['appDetails.externalAppSource'],
              clubId: row['appDetails.clubId'],
              clubSource: row['appDetails.clubSource'],
            },
            isMember: row.isMember?.toLowerCase() === 'true',
            isPro: row.isPro?.toLowerCase() === 'true',
            isAdmin: row.isAdmin?.toLowerCase() === 'true',
            createTime: Timestamp.now(),
            creatorId: userProfile?.itemId || 'system',
            orgId: orgId,
            orgName: orgName,
          };

          console.log('Created invitation:', invitation);

          setParseProgress((prev) => ({
            current: prev.current + 1,
            total: prev.total + 1,
          }));

          parsedInvitations.push(invitation);

          return invitation;
        },
        complete: () => {
          setIsProcessing(false);
          setParseProgress({ current: 0, total: 0 });
          console.log('CSV parsing complete');
          onInvitationsLoaded(parsedInvitations, failures);
        },
        error: (error: Error) => {
          console.error('Error parsing CSV:', error);
          setIsProcessing(false);
          setParseProgress({ current: 0, total: 0 });
          onInvitationsLoaded([], [{ row: 0, data: {}, error: error.message }]);
        },
      });
    },
    [orgId, orgName, userProfile, mapping, onInvitationsLoaded, failures]
  );

  useEffect(() => {
    if (csvFile && orgId) {
      console.log('Processing CSV file');
      parseCsvContents(csvFile);
    }
  }, [csvFile, orgId, parseCsvContents]);

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const contents = e.target?.result;
        if (typeof contents === 'string') {
          setCsvFile(contents);
        }
        reader.onload = null; // Remove listener
      };

      reader.onerror = () => {
        console.error('Error reading file');
        reader.abort(); // Abort on error
        reader.onload = null;
        reader.onerror = null;
      };

      reader.readAsText(file);
    }
    // Clear the input value so the same file can be uploaded again
    event.target.value = '';
  };

  return (
    <Stack direction="row" spacing={2} display="flex" justifyContent="center">
      {hasImportAction && (
        <Button
          component="label"
          role={undefined}
          size="small"
          variant="contained"
          tabIndex={-1}
          startIcon={<FileUploadIcon />}
          disabled={isProcessing || !orgId}
        >
          Import CSV File
          <HiddenInput type="file" accept=".csv" onChange={handleFileUpload} />
        </Button>
      )}

      {isProcessing && (
        <>
          <Typography variant="body2" align="center">
            Processing row {parseProgress.current} of {parseProgress.total}
          </Typography>
          <LinearProgress variant="determinate" value={(parseProgress.current / parseProgress.total) * 100} />
        </>
      )}
    </Stack>
  );
}
