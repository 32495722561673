import { memo, ReactElement, useState, useEffect } from 'react';

import { Timestamp } from 'firebase/firestore';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';

import dayjs, { Dayjs } from 'dayjs';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import Event from '../../types/Event';

type EventGolfSettingsEditorProps = {
  event: Event;
  onUpdate: (updates: Partial<Event>) => void;
};

const EventGolfSettingsEditor = memo(function EventGolfSettingsEditor({
  event,
  onUpdate,
}: EventGolfSettingsEditorProps): ReactElement {
  // Start in state 2 if ghinIndexDate is present, otherwise state 1
  const [isCustomDate, setIsCustomDate] = useState(!!event.ghinIndexDate);
  const ghinIndexDate = event.ghinIndexDate instanceof Timestamp ? event.ghinIndexDate : null;
  const defaultDate = event.days[0]?.datetime instanceof Timestamp ? event.days[0].datetime : null;

  // Watch for changes to days[0].date and adjust ghinIndexDate if needed
  useEffect(() => {
    if (ghinIndexDate && defaultDate && ghinIndexDate.toDate() > defaultDate.toDate()) {
      onUpdate({
        ghinIndexDate: defaultDate,
      });
    }
  }, [defaultDate, ghinIndexDate, onUpdate]);

  const handleClearDate = () => {
    onUpdate({
      ghinIndexDate: undefined,
    });
    setIsCustomDate(false);
  };

  const handleChangeDate = () => {
    // When switching to custom date, initialize with the default date if no custom date is set
    if (!ghinIndexDate && defaultDate) {
      onUpdate({
        ghinIndexDate: defaultDate,
      });
    }
    setIsCustomDate(true);
  };

  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={2} sx={{ display: 'flex', alignItems: 'center' }}>
        <TextField
          id="numteetimes"
          label="Tee Times"
          slotProps={{
            input: {
              inputMode: 'numeric',
              type: 'number',
            },
          }}
          value={event.numTeeTimes}
          onChange={({ target }) => {
            const numTeeTimes = parseInt(target.value || '0', 10);
            onUpdate({
              numTeeTimes,
              maxPlayers: numTeeTimes * 4,
            });
          }}
        />
        <TextField
          id="maxPlayers"
          label="# of Golfers"
          slotProps={{
            input: {
              inputMode: 'numeric',
              type: 'number',
            },
          }}
          value={event.maxPlayers}
          onChange={({ target }) =>
            onUpdate({
              maxPlayers: parseInt(target.value || '0', 10),
            })
          }
        />
      </Stack>
      <Stack direction="row" spacing={2} sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="body2">Signup Priority:</Typography>
        <ToggleButtonGroup
          color="primary"
          value={event.participantOrder === 'ghinIndex' ? 'index' : 'time'}
          exclusive
          onChange={(_, value) => {
            if (value) {
              onUpdate({
                participantOrder: value === 'time' ? 'signupTime' : 'ghinIndex',
              });
            }
          }}
          size="small"
          aria-label="Order"
        >
          <ToggleButton value="time">Time</ToggleButton>
          <ToggleButton value="index">Index</ToggleButton>
        </ToggleButtonGroup>
      </Stack>
      <Stack direction="row" spacing={2} sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="body2">GHIN Index Date:</Typography>
        {!isCustomDate ? (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <TextField
              size="small"
              disabled
              value={defaultDate ? dayjs(defaultDate.toDate()).format('MMM D, YYYY') : 'No date set'}
            />
            <Button size="small" onClick={handleChangeDate}>
              Change
            </Button>
          </Box>
        ) : (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={ghinIndexDate ? dayjs(ghinIndexDate.toDate()) : defaultDate ? dayjs(defaultDate.toDate()) : null}
                onChange={(date: Dayjs | null) => {
                  if (date && defaultDate && date.toDate() > defaultDate.toDate()) {
                    // If selected date is after days[0].date, use days[0].date instead
                    onUpdate({
                      ghinIndexDate: defaultDate,
                    });
                  } else {
                    onUpdate({
                      ghinIndexDate: date ? Timestamp.fromDate(date.toDate()) : undefined,
                    });
                  }
                }}
                maxDate={defaultDate ? dayjs(defaultDate.toDate()) : undefined}
                slotProps={{
                  textField: {
                    size: 'small',
                  },
                }}
              />
            </LocalizationProvider>
            <Button size="small" onClick={handleClearDate}>
              Clear
            </Button>
          </Box>
        )}
      </Stack>
    </Stack>
  );
});

export default EventGolfSettingsEditor;
