import { ReactElement } from 'react';

import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useData } from '../../contextProviders/DataProvider';
import Org from '../../types/Org';
import InvitationList from '../invitation/InvitationList';

interface OrgInvitationsProps {
  org: Org;
}

export default function OrgInvitations(props: OrgInvitationsProps): ReactElement {
  const org = props.org;
  const { isOrgAdmin, isOrgPro } = useData();

  if (isOrgAdmin(org.itemId) || isOrgPro(org.itemId)) {
    return (
      <Stack direction="column" spacing={1}>
        <InvitationList org={org} status="pending" showCopyEmailList={true} />
        <InvitationList org={org} status="accepted" showCopyEmailList={true} />
        <Typography align="left" gutterBottom variant="headline">
          Instructions
        </Typography>
        <Typography align="left" variant="body1" gutterBottom>
          Information about the actions that can be taken on each application.
        </Typography>
      </Stack>
    );
  } else {
    return <Container />;
  }
}
