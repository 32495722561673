import { useMemo } from 'react';

import { and, collection, Query, query, where, orderBy, collectionGroup, or } from 'firebase/firestore';

import { useCollectionData } from 'react-firebase-hooks/firestore';

import { useAuth } from '../contextProviders/AuthProvider';
import { useData } from '../contextProviders/DataProvider';
import { firestore } from '../firebase';
import Handbook from '../types/Handbook';

interface UseHandbooksProps {
  orgId?: string;
}

export default function useHandbooks(props: UseHandbooksProps) {
  const { orgId } = props;
  const { isGod } = useAuth();
  const { userProfile, isUserLoading, isOrgActive, getAdminOrgs, getMemberOrgs, getProOrgs } = useData();

  const adminOrgs = useMemo(() => getAdminOrgs(), [getAdminOrgs]);
  const memberOrgs = useMemo(() => getMemberOrgs(), [getMemberOrgs]);
  const proOrgs = useMemo(() => getProOrgs(), [getProOrgs]);
  const activeOrgs = useMemo(() => [...adminOrgs, ...memberOrgs, ...proOrgs], [adminOrgs, memberOrgs, proOrgs]);

  const handbooksQuery = useMemo(() => {
    if (!userProfile || activeOrgs.length === 0) return null;

    if (orgId || activeOrgs.length === 1) {
      const queryOrgId = orgId || activeOrgs[0];
      const deletedConditions = [];
      const testConditions = [];
      const publishedConditions = [];
      const orgConditions = [where('orgId', '==', queryOrgId)];

      if (isGod) {
        deletedConditions.push(where('isDeleted', 'in', [true, false]));
      } else {
        deletedConditions.push(where('isDeleted', '==', false));
        testConditions.push(where('isTest', '==', false));
        if (adminOrgs.includes(queryOrgId)) {
          publishedConditions.push(where('isPublished', 'in', [true, false]));
        } else {
          publishedConditions.push(where('isPublished', '==', true));
        }
      }

      return query(
        collection(firestore, 'org', queryOrgId, 'handbook') as Query<Handbook>,
          ...orgConditions,
          ...publishedConditions, 
          ...deletedConditions, 
          ...testConditions,
        orderBy('name')
      );
    } else {
      const deletedConditions = [];
      const orgConditions = [];
      const testConditions = [];

      if (isGod) {
        testConditions.push(where('isTest', 'in', [true, false]));
        deletedConditions.push(where('isDeleted', 'in', [true, false]));
      } else {
        testConditions.push(where('isTest', '==', false));
        deletedConditions.push(where('isDeleted', '==', false));
      }

      if (adminOrgs.length > 0) {
        const nonAdminOrgs = activeOrgs?.filter((org) => !adminOrgs.includes(org));
        if (nonAdminOrgs?.length > 0) {
          orgConditions.push(
            or(
              and(
                where('orgId', 'in', nonAdminOrgs),
                where('isPublished', '==', true)
              ),
              where('orgId', 'in', adminOrgs),
            )
          )
        } else {
          orgConditions.push(
            where('orgId', 'in', adminOrgs)
          )
        }
      } else {
        if (activeOrgs?.length > 0) {
          orgConditions.push(
            and(
              where('orgId', 'in', activeOrgs),
              where('isPublished', '==', true)
            )
          )
        }
      }

      return query(
        collectionGroup(firestore, 'handbook') as Query<Handbook>,
        and(...deletedConditions, ...testConditions,...orgConditions),
        orderBy('name')
      );
    }
  }, [userProfile, activeOrgs, adminOrgs, orgId, isGod]);

  const [handbooks, isHandbooksLoading, handbooksError] = useCollectionData<Handbook>(handbooksQuery);

  if (!isUserLoading && !userProfile && !isHandbooksLoading && !handbooksError) {
    return {
      handbooks: null,
      isHandbooksLoading: false,
      handbooksError: new Error('User must be logged in to view handbooks'),
    };
  }

  if (!isUserLoading && !isHandbooksLoading && !handbooksError && !isGod && orgId && !isOrgActive(orgId)) {
    return {
      handbooks: null,
      isHandbooksLoading: false,
      handbooksError: new Error('Not authorized to view these handbooks'),
    };
  }

  return {
    handbooks: handbooks || null,
    isHandbooksLoading: isHandbooksLoading || isUserLoading,
    handbooksError,
  };
}
