import { useMemo } from 'react';

import { User } from 'firebase/auth';
import { collection, doc, DocumentReference } from 'firebase/firestore';

import { useDocumentData } from 'react-firebase-hooks/firestore';

import { firestore } from '../firebase';
import Person from '../types/Person';

export interface UseUserProfileResult {
  userProfile: Person | null | undefined;
  isUserProfileLoading?: boolean;
  userProfileError?: Error | null;
}

export interface UseUserProfileProps {
  user: User | null;
}

export default function useUserProfile(props: UseUserProfileProps): UseUserProfileResult {
  const { user } = props;

  const userProfileRef = useMemo(() => {
    if (!user) return null;

    const profileCollection = collection(firestore, 'profile');
    const docRef = doc(profileCollection, user.uid) as DocumentReference<Person>;
    return docRef;
  }, [user]);

  const [userProfile, isUserProfileLoading, userProfileError] = useDocumentData<Person>(userProfileRef);

  const memoizedResult = useMemo(() => {
    if (!user) {
      return {
        userProfile: null,
        isUserProfileLoading: false,
        userProfileError: null,
      };
    }

    return {
      userProfile: userProfile || null,
      isUserProfileLoading,
      userProfileError,
    };
  }, [user, userProfile, isUserProfileLoading, userProfileError]);

  return memoizedResult;
}
