import { ReactElement, useEffect, useState, useCallback } from 'react';

import { Timestamp } from 'firebase/firestore';

import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import FileUploadIcon from '@mui/icons-material/FileUpload';

import Papa from 'papaparse';

import { useData } from '../../contextProviders/DataProvider';
import { CoursePair } from '../../types/Course';
// import { CompHandicap, CompScoring } from '../../types/Competition';
import DefaultObjects from '../../types/DefaultObjects';
import Event from '../../types/Event';
import EventDay from '../../types/EventDay';
import FirestoreDataTypes from '../../types/FirestoreDataTypes';
import League from '../../types/League';
import Season from '../../types/Season';
import HiddenInput from '../common/HiddenInput';
// import Score from '../../types/Score';
import { convertStringToType } from '../../utils/utils';

interface CsvEventRecord {
  courseId?: string;
  courseName?: string;
  datetime: string;
  description: string;
  isGolf: string;
  isShotgun: string;
  isTest: string;
  leagueId: string;
  leagueName: string;
  maxPlayers: string;
  numTeeTimes: string;
  orgId: string;
  orgName: string;
  seasonId: string;
  seasonName: string;
  signupOpensAt: string;
  signupClosesAt: string;
  timezone: string;
}

type ParseFailure = {
  row: number;
  data: Partial<CsvEventRecord>;
  error: string;
};

interface EventCsvUploadProps {
  league: League;
  season: Season;
  mapping: Array<{ field: string; dbname: keyof Event }>;
  onEventsLoaded: (events: Event[], failures?: ParseFailure[]) => void;
  hasImportAction?: boolean;
  resetKey?: number;
}

interface EventDayWithCourseDetails extends EventDay {
  courseName?: string;
  courseShortName?: string;
}

export default function EventCsvUpload(props: EventCsvUploadProps): ReactElement {
  const { league, season, mapping, onEventsLoaded, hasImportAction = true, resetKey = 0 } = props;
  const { userProfile } = useData();
  const [csvFile, setCsvFile] = useState<string>('');
  const [parseProgress, setParseProgress] = useState<{ current: number; total: number }>({ current: 0, total: 0 });
  const [isProcessing, setIsProcessing] = useState(false);
  // const [events, setEvents] = useState<Event[]>([]);
  const [failures, setFailures] = useState<ParseFailure[]>([]);

  useEffect(() => {
    if (csvFile && league) {
      console.log('Processing CSV file');
      parseCsvContents(csvFile);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [csvFile, league]);

  useEffect(() => {
    setCsvFile('');
    setParseProgress({ current: 0, total: 0 });
    setIsProcessing(false);
    setFailures([]);
  }, [resetKey]);

  type ParseFailure = {
    row: number;
    data: Partial<CsvEventRecord>;
    error: string;
  };

  const parseCsvContents = useCallback(
    (contents: string) => {
      const parsedEvents: Event[] = [];

      if (!league) {
        onEventsLoaded([], [{ row: 0, data: {}, error: 'League not found' }]);
        console.error('League not found');
        return;
      }

      setIsProcessing(true);

      Papa.parse<CsvEventRecord>(contents, {
        header: true,
        skipEmptyLines: true,
        delimiter: ',',
        transformHeader: (header) => {
          const normalizeField = (field: string) =>
            field
              .trim()
              .toLowerCase()
              .replace(/[^a-z0-9]/g, '');

          const trimmedHeader = header.trim();
          const mappedField = mapping.find((m) => normalizeField(m.field) === normalizeField(trimmedHeader));

          return mappedField ? String(mappedField.dbname) : trimmedHeader;
        },
        step: (results) => {
          const row = results.data as CsvEventRecord;

          // Initialize event with basic fields
          const event = {
            ...DefaultObjects.Event,
            creatorId: userProfile?.itemId || 'system',
            createTime: Timestamp.now(),
            orgId: league.orgId,
            orgName: league.orgName,
            seasonId: season.itemId,
            seasonName: season.name,
            leagueId: league.itemId,
            leagueName: league.name,
          } as Event;

          // Track which day indices actually have data
          const daysWithData = new Set<number>();

          // First pass: identify which days have data
          Object.keys(row).forEach((key) => {
            const value = row[key as keyof CsvEventRecord];
            if (value === undefined || value === '') return;

            const dayFieldMatch = key.match(/^days\[(\d+)\]\./);
            if (dayFieldMatch) {
              const dayIndex = parseInt(dayFieldMatch[1]);
              daysWithData.add(dayIndex);
            }
          });

          // Initialize days array only for days that have data
          if (daysWithData.size > 0) {
            event.days = [];
            // Sort the day indices to ensure they're in order
            const sortedDayIndices = Array.from(daysWithData).sort((a, b) => a - b);

            // Create entries only for days with data
            sortedDayIndices.forEach((dayIndex) => {
              // Ensure we have entries for all days up to this index
              while (event.days.length <= dayIndex) {
                event.days.push({ ...DefaultObjects.Event.days[0] });
              }
            });
          } else {
            // If no day data found, initialize with a single default day
            event.days = [{ ...DefaultObjects.Event.days[0] }];
          }

          // Only process fields that are in our mapping
          const mappedFields = new Set(mapping.map((m) => String(m.dbname)));

          (Object.keys(row) as Array<keyof CsvEventRecord>).forEach((key) => {
            const value = row[key];
            if (value === undefined || value === '') return;

            const dayFieldMatch = key.match(/^days\[(\d+)\]\./);
            if (dayFieldMatch) {
              const dayIndex = parseInt(dayFieldMatch[1]);
              const fieldName = key.split('.')[1] as keyof EventDay;

              // Only process if this day has data (should always be true here)
              if (dayIndex < event.days.length) {
                (event.days[dayIndex][fieldName] as FirestoreDataTypes) = convertStringToType(
                  value,
                  fieldName,
                  event.days[dayIndex]
                );
              }
            } else if (mappedFields.has(String(key))) {
              (event[key as keyof Event] as FirestoreDataTypes) = convertStringToType(value, key, event);
            }
          });

          // Fix up course information
          fixupEventCoursePairs(event);

          setParseProgress((prev) => ({
            current: prev.current + 1,
            total: prev.total + 1,
          }));

          parsedEvents.push(event);

          return event;
        },
        complete: () => {
          setIsProcessing(false);
          setParseProgress({ current: 0, total: 0 });
          console.log('CSV parsing complete');
          onEventsLoaded(parsedEvents, failures);
        },
        error: (error: Error) => {
          console.error('Error parsing CSV:', error);
          setIsProcessing(false);
          setParseProgress({ current: 0, total: 0 });
          onEventsLoaded([], [{ row: 0, data: {}, error: error.message }]);
        },
      });
    },
    [league, userProfile, mapping, onEventsLoaded, failures, season.itemId, season.name]
  );

  // Update the fixup function to use the new interface
  const fixupEventCoursePairs = (event: Event) => {
    // Ensure coursePairs array exists
    if (!event.coursePairs) {
      event.coursePairs = [];
    }

    // Process each day to collect unique courses
    const courseMap = new Map<string, CoursePair>();

    // First collect existing coursePairs
    event.coursePairs.forEach((course) => {
      if (course.id) {
        courseMap.set(course.id, course);
      }
    });

    // Then process days to find courses that need to be added
    event.days.forEach((day) => {
      if (day.courseId) {
        // Skip if this course is already in the map
        if (!courseMap.has(day.courseId)) {
          const dayWithDetails = day as EventDayWithCourseDetails;

          // Create a new CoursePair from the day's course information
          const coursePair: CoursePair = {
            id: day.courseId,
            name: dayWithDetails.courseName || 'Unknown Course',
            shortName: dayWithDetails.courseShortName || 'Unknown',
            timezone: event.timezone || 'America/Los_Angeles', // Default to event timezone
          };

          courseMap.set(day.courseId, coursePair);
        }
      }
    });

    // Update the coursePairs array with all courses
    event.coursePairs = Array.from(courseMap.values());

    // Calculate start and end datetimes
    if (event.days.length > 0) {
      // Find earliest start time for startDatetime
      let earliestTime: Timestamp | null = null;

      for (const day of event.days) {
        if (day.datetime instanceof Timestamp) {
          if (earliestTime === null || day.datetime.toMillis() < earliestTime.toMillis()) {
            earliestTime = day.datetime;
          }
        }
      }

      if (earliestTime) {
        event.startDatetime = earliestTime;
      }
    }
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const contents = e.target?.result;
        if (typeof contents === 'string') {
          setCsvFile(contents);
        }
        reader.onload = null; // Remove listener
      };

      reader.onerror = () => {
        console.error('Error reading file');
        reader.abort(); // Abort on error
        reader.onload = null;
        reader.onerror = null;
      };

      reader.readAsText(file);
    }
    // Clear the input value so the same file can be uploaded again
    event.target.value = '';
  };

  return (
    <Stack direction="row" spacing={2} display="flex" justifyContent="center">
      {hasImportAction && (
        <Button
          component="label"
          role={undefined}
          size="small"
          variant="contained"
          tabIndex={-1}
          startIcon={<FileUploadIcon />}
          disabled={isProcessing || !league}
        >
          Import CSV File
          <HiddenInput type="file" accept=".csv" onChange={handleFileUpload} />
        </Button>
      )}

      {isProcessing && (
        <>
          <Typography variant="body2" align="center">
            Processing row {parseProgress.current} of {parseProgress.total}
          </Typography>
          <LinearProgress variant="determinate" value={(parseProgress.current / parseProgress.total) * 100} />
        </>
      )}
    </Stack>
  );
}
