import { ReactElement, useEffect, useState } from 'react';

import { ref } from 'firebase/storage';

import { Link } from 'react-router-dom';

import { useDownloadURL } from 'react-firebase-hooks/storage';

import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import PersonOffIcon from "@mui/icons-material/PersonOff";
import ShieldIcon from '@mui/icons-material/Shield';

import { useAuth } from '../../contextProviders/AuthProvider';
import Person from '../../types/Person';
import PersonDetails from '../../types/PersonDetails';
import { FormatPhoneNumber, GetFirstAndLastName } from '../../utils/utils';
import PgaIcon from '../common/PgaIcon';

interface PersonListItemProps {
  person: Person;
  personDetails?: PersonDetails;
  checkAuthEmail?: boolean;
  isActive?: boolean;
  isAdminHighlighted?: boolean;
  isClickable?: boolean;
  isDividerDisplayed?: boolean;
  isOrgAdmin?: boolean;
  isOrgPro?: boolean;
  renderActions?: ReactElement;
  isActionsOpen?: boolean;
}

export default function PersonListItem(props: PersonListItemProps): ReactElement {
  const { checkUserExists, imagesRef } = useAuth();
  const {
    person,
    personDetails,
    checkAuthEmail,
    isActive = true,
    isActionsOpen = false,
    isClickable,
    isDividerDisplayed,
    isOrgAdmin,
    isOrgPro,
    isAdminHighlighted = true,
    renderActions,
  } = props;
  
  const [imageUrl, isImageLoading] = useDownloadURL(
    person?.picture ? ref(imagesRef, person.picture) : null
  );
  const [isEmailAvailable, setIsEmailAvailable] = useState(true);

  useEffect(() => {
    if (person.email === '' || !checkAuthEmail) return;
    
    const checkEmailExists = async () => {
      const isExists = await checkUserExists(person.email);
      setIsEmailAvailable(!isExists);
    };
    checkEmailExists();
  }, [person.email, checkAuthEmail, checkUserExists]);

  const StyledAvatar = (): ReactElement => {
    return (
      <Box sx={{ position: 'relative' }}>
        <Avatar alt={person.name} src={!isImageLoading && imageUrl ? imageUrl : ''} />
        {!isActive && (
          <Tooltip title="Inactive" arrow placement="right">
            <PersonOffIcon
              sx={{
                position: 'absolute',
                bottom: -4,
                right: -4,
                fontSize: '1.2rem',
            color: 'primary.main',
            backgroundColor: 'background.paper',
            borderRadius: '50%'
              }}
            />
          </Tooltip>
        )}
        {isActive && isOrgPro && (
          <Tooltip title="Pro" arrow placement="right">
            <PgaIcon
              sx={{
                position: 'absolute',
                bottom: -4,
                right: -4,
                fontSize: '1.2rem',
                color: 'primary.main',
                backgroundColor: 'background.paper',
                borderRadius: '50%'
              }}
            />
          </Tooltip>
        )}
        {isActive && isOrgAdmin && !isOrgPro && (
          <Tooltip title="Admin" arrow placement="right">
            <ShieldIcon
              sx={{
                position: 'absolute',
                bottom: -4,
                right: -4,
                fontSize: '1.2rem',
                color: 'primary.main',
                backgroundColor: 'background.paper',
                borderRadius: '50%'
              }}
            />
          </Tooltip>
        )}
      </Box>
    );
  };

  const StyledName = (): ReactElement => {
    const [firstName, lastName] = GetFirstAndLastName(person.name);

    return (
      <Stack
        direction="column"
        spacing={0}
        sx={{ alignItems: 'flex-start', justifyContent: 'space-around', width: { xs: '6rem', sm: '8rem', md: '12rem' } }}
      >
        <Typography variant="h6" sx={{ display: { xs: 'block', md: 'none' } }}>
          {firstName}
        </Typography>
        <Typography variant="overline" sx={{ display: { xs: 'block', md: 'none' } }}>
          {lastName}
        </Typography>
        <Typography variant="h6" sx={{ display: { xs: 'none', md: 'block' } }}>
          {`${firstName} ${lastName}`}
        </Typography>
      </Stack>
    );
  };

  const StyledBasicInfo = (): ReactElement => {
    return (
      <Stack direction="column" spacing={0} sx={{ alignItems: 'flex-start' }}>
        <Typography variant="body1" sx={{ fontFamily: { xs: 'Roboto Condensed', sm: 'roboto' } }}>
          {person.email}
        </Typography>
        {checkAuthEmail && isEmailAvailable === false && (
          <Alert severity="error" sx={{ py: 0, px: 0, my: 0, lineHeight: 1 }}>
            This email address is in use.
          </Alert>
        )}
        {personDetails?.phone && (
          <Typography variant="body1" sx={{ fontFamily: { xs: 'Roboto Condensed', sm: 'roboto' } }}>
            {FormatPhoneNumber(personDetails.phone)}
          </Typography>
        )}
        {(person.ghin && person.ghin !== '' && person.ghin !== '0') ? (
          <Typography variant="body1" sx={{ fontFamily: { xs: 'Roboto Condensed', sm: 'roboto' } }}>
            {'GHIN: ' + person.ghin + ', Index: ' + person.index}
          </Typography>
        ) : (
          <Typography variant="body1" sx={{ fontFamily: { xs: 'Roboto Condensed', sm: 'roboto' } }}>
            {'GHIN: unknown'}
          </Typography>
        )}
      </Stack>
    );
  };

  return (
    <ListItem
      divider={isDividerDisplayed}
      className={`${person.isTest ? 'test' : ''} ${!isActive ? 'inactive' : ''} ${isAdminHighlighted && isOrgAdmin ? 'admin' : ''}`}
      sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
    >
      <Box
        component={isClickable ? Link : 'div'}
        to={isClickable ? `/profile/${person.itemId}` : undefined}
        sx={{
          display: 'flex',
          flex: '1 1 auto',
          minWidth: 0,
          alignItems: 'center',
          textDecoration: 'none',
          color: 'inherit',
        }}
      >
        <ListItemAvatar sx={{ display: { xs: 'none', sm: 'flex' } }}>
          <StyledAvatar />
        </ListItemAvatar>
        <ListItemAvatar sx={{ color: 'surface.contrastText' }}>
          <StyledName />
        </ListItemAvatar>
        <ListItemText 
          sx={{ 
            display: { 
              xs: isActionsOpen ? 'none' : 'block', 
              sm: 'block' 
            } 
          }}
        >
          <StyledBasicInfo />
        </ListItemText>
      </Box>
      {renderActions && (
        <ListItemText>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            {renderActions}
          </Box>
        </ListItemText>
      )}
    </ListItem>
  );
}
