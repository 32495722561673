import { ReactElement, useEffect, useState } from 'react';

import { Link, useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Fab from '@mui/material/Fab';
import Tab from '@mui/material/Tab';

import AddIcon from '@mui/icons-material/Add';

import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import GratiPageWrapper from '../components/common/GratiPageWrapper';
import EventSchedule from '../components/event/EventSchedule';
import { useApp } from '../contextProviders/AppProvider';
import { useData } from '../contextProviders/DataProvider';
import { EventQueryFilter } from '../enums/EventQueryFilter';
import Event from '../types/Event';
import { IsEventOver, IsEventUpcoming } from '../utils/utils';

interface EventsPageProps {
  initialTab?: string;
}

export default function EventsPage(props: EventsPageProps): ReactElement {
  const { canCreateEvents } = useData();
  const navigate = useNavigate();
  const { setPageName, previousLocation } = useApp();
  const [value, setValue] = useState(props.initialTab ? props.initialTab : '1');
  // If we are navigating back from an event page, then we can get the eventId and use it to set the selected event
  const selectedEventId =
    previousLocation?.startsWith('/event/') || previousLocation?.startsWith('/event-edit/')
      ? previousLocation.substring(previousLocation.lastIndexOf('/') + 1)
      : undefined;

  useEffect(() => {
    setPageName('Events');
  }, [setPageName]);

  const handleChange = (event: React.SyntheticEvent, newTabSelected: string) => {
    setValue(newTabSelected);
    switch (newTabSelected) {
      case '1':
        navigate('/upcoming-events', {
          replace: true,
          state: { preventReload: true },
        });
        break;
      case '2':
        navigate('/past-events', {
          replace: true,
          state: { preventReload: true },
        });
        break;
      case '3':
        navigate('/now-events', {
          replace: true,
          state: { preventReload: true },
        });
        break;
    }
  };

  const handleSelectedEventSet = (event: Event) => {
    const isEventPast = IsEventOver(event);
    const isEventUpcoming = IsEventUpcoming(event);
    setValue(isEventPast ? '2' : isEventUpcoming ? '1' : '3');
  };

  return (
    <GratiPageWrapper>
      <Box sx={{ width: '100%' }}>
        <TabContext value={value}>
          <Box>
            <TabList
              onChange={handleChange}
              variant="fullWidth"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              <Tab value="3" label="Now" wrapped />
              <Tab value="1" label="Upcoming" wrapped />
              <Tab value="2" label="Past" wrapped />
            </TabList>
          </Box>
          <TabPanel value="3">
            <Container disableGutters className="SectionContainer">
              <EventSchedule
                filter={EventQueryFilter.Now}
                isSortedbyLeague={false}
                selectedEventId={selectedEventId || undefined}
                onSelectedEventSet={handleSelectedEventSet}
              />
            </Container>
          </TabPanel>
          <TabPanel value="1">
            <>
              <Container disableGutters className="SectionContainer">
                <EventSchedule
                  filter={EventQueryFilter.Upcoming}
                  isSortedbyLeague={false}
                  selectedEventId={selectedEventId || undefined}
                  onSelectedEventSet={handleSelectedEventSet}
                />
              </Container>
              {canCreateEvents() && (
                <Fab variant="extended" color="primary" aria-label="add event" component={Link} to="/event-create">
                  <AddIcon />
                  Add event
                </Fab>
              )}
            </>
          </TabPanel>
          {value === '2' && (
            <TabPanel value="2">
              <Container disableGutters className="SectionContainer">
                <EventSchedule
                  filter={EventQueryFilter.Past}
                  isSortedbyLeague={true}
                  selectedEventId={selectedEventId || undefined}
                  onSelectedEventSet={handleSelectedEventSet}
                />
              </Container>
            </TabPanel>
          )}
        </TabContext>
      </Box>
    </GratiPageWrapper>
  );
}
