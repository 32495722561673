import { doc, DocumentReference } from "firebase/firestore";

import { useDocumentData } from "react-firebase-hooks/firestore";

import { firestore } from "../firebase";
import Event from "../types/Event";

export const useEvent = (orgId: string, eventId: string): [Event | undefined, boolean, Error | undefined] => {

  const [event, isEventLoading, isEventError] = useDocumentData<Event>(
    doc(firestore, 'org/' + orgId + '/event/' + eventId) as DocumentReference<Event>
  );

  if (!orgId || !eventId) {
    console.error('Missing required fields:', { orgId, eventId });
    return [
      undefined,
      false,
      new Error('Missing required fields') as Error,
    ];
  }

  return [event, isEventLoading, isEventError];
};