import { ReactElement } from 'react';

import { useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';

import { useAuth } from '../../contextProviders/AuthProvider';
import { useData } from '../../contextProviders/DataProvider';

interface GratiGodActionsProps {
  pageGodActions?: ReactElement | null;
}

export default function GratiGodActions({ pageGodActions }: GratiGodActionsProps) {
  const navigate = useNavigate();
  const { isGod, isGodCapable, disableGod } = useAuth();
  const { isAdminDisabled, adminDisabled } = useData();

  if (!isGod && !isGodCapable) {
    return <></>;
  }

  return (
    <Stack
      sx={{
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'flex-end',
        spacing: 1,
        mt: 1,
        ml: -1,
        mr: -1,
        p: 1,
        backgroundColor: 'tertiary.container',
        color: 'tertiary.onContainer',
      }}
    >
      {isGod && pageGodActions}
      {isGod &&
        <Stack direction="row" spacing={2}>
          <Button 
            variant="outlined"
            size="small" 
            type="button" 
            onClick={() => navigate('/firestore-utility')}
          >
            Firestore Editor
          </Button>
          <Button 
            variant="outlined"
            size="small" 
            type="button" 
            onClick={() => navigate('/event-uploader')}
          >
            Event Uploader
          </Button>
          <Button 
            variant="outlined"
            size="small" 
            type="button" 
            onClick={() => navigate('/invitation-uploader')}
          >
            Invitation Uploader
          </Button>
        </Stack>
      }
      {isGod &&
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                id={'disable_admin'}
                checked={isAdminDisabled}
                onChange={(e) => {
                  adminDisabled(e.target.checked);
                }}
              />
            }
            label={'Disable Admin'}
          />
        </FormGroup>
      }                                        
      {isGodCapable &&                      
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                id={'disable_god'}
                checked={!isGod}
                onChange={(e) => {
                  disableGod(!e.target.checked);
                }}
              />
            }
            label={isGod ? "Disable God" : "Enable God"}
          />
        </FormGroup>
      }
    </Stack>
  );
}