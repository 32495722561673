import { useMemo } from 'react';

import { collection, query, where, orderBy, Query, documentId } from 'firebase/firestore';

import { useCollectionData } from 'react-firebase-hooks/firestore';

import { useAuth } from '../contextProviders/AuthProvider';
import { useData } from '../contextProviders/DataProvider';
import { firestore } from '../firebase';
import Org from '../types/Org';

interface OrgsWithAdminOrProRightsProps {
  includeProRights: boolean;
  includeAdminRights: boolean;
}

export default function useOrgsWithAdminOrProRights(props: OrgsWithAdminOrProRightsProps) {
  const { includeProRights, includeAdminRights } = props;
  const { isGod } = useAuth();
  const { getAdminOrgs, getProOrgs } = useData();

  const adminOrgs = includeAdminRights ? getAdminOrgs() : [];
  const proOrgs = includeProRights ? getProOrgs() : [];

  // Combine orgs without duplicates using Set
  const allOrgs = Array.from(new Set([...adminOrgs, ...proOrgs]));

  const orgsQuery = useMemo(() => {
    if (!allOrgs.length) return null;

    return query(
      collection(firestore, 'org'),
      where(documentId(), 'in', allOrgs),
      where('isTest', 'in', [isGod, false, null]),
      orderBy('name')
    );
  }, [allOrgs, isGod]);

  const [orgs, isOrgsLoading, orgsError] = useCollectionData<Org>(orgsQuery as Query<Org>);

  return {
    orgs,
    isOrgsLoading,
    orgsError,
  };
}
