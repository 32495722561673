import { useEffect } from 'react';

import { Link, useLocation } from 'react-router';

import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { SxProps } from '@mui/material/styles';
import { Theme } from '@mui/material/styles';
import SvgIcon from '@mui/material/SvgIcon';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import { useApp } from '../../contextProviders/AppProvider';
import { useData } from '../../contextProviders/DataProvider';

interface GratiNavRailProps {
  sx?: SxProps<Theme>;
}

export default function GratiNavRail(props: GratiNavRailProps) {
  const sx = props.sx;
  const location = useLocation();
  const { pageContexts } = useApp();
  const { getAdminOrgs, getAllActiveOrgs, userProfile } = useData();
 
  useEffect(() => {
    const urlSegments = location.pathname.split('/');
    const urlPath = urlSegments[0] + '/' + urlSegments[1];
    console.log('GratiNavRail location: ', urlPath);
  }, [location]);

  return (
    <Drawer variant="permanent" sx={[...(Array.isArray(sx) ? sx : [sx])]}>
      <Toolbar sx={{ py: 0, my: 0, height: '0px' }} />
      <List sx={{ width: '100%', pt: '0px', display: 'block' }}>
        {pageContexts.map(
          (page) =>
            page.showNavRail &&
            ((page.showLogin &&
              userProfile &&
              (!page.showOnlyAdmin || getAdminOrgs().length > 0) &&
              (page.showIfOneOrg || getAllActiveOrgs().length > 1)) ||
            (page.showLogout && !userProfile) ? (
              <ListItem key={page.name} alignItems="center" disablePadding sx={{ width: '100%' }}>
                <IconButton key={page.name} component={Link} to={page.to}>
                  {page.icon ? <SvgIcon component={page.icon} /> : null}
                  <Typography variant="iconbutton" textAlign="center">
                    {page.name}
                  </Typography>
                </IconButton>
              </ListItem>
            ) : null)
        )}
      </List>
    </Drawer>
  );
}
