import { ReactElement } from 'react';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import Org from '../../types/Org';
import GratiCard from '../common/GratiCard';

interface OrgCardProps {
  org: Org;
  variant?: 'elevation' | 'outlined' | 'none';
  isClickable?: boolean;
}

export default function OrgCard(props: OrgCardProps): ReactElement {
  const { org, isClickable } = props;
  const variant = props.variant ?? 'outlined';

  return (
    <GratiCard
      to={isClickable ? `/org/${org.itemId}` : undefined}
      key={org.itemId}
      variant={variant}
      overline={''}
      avatar={org.logo}
      title={org.name}
      subtitle={org.shortName}
      description={org.description}
      actionbar={
        org.homeUrl ? (
          <Stack direction="row" spacing={1}>
            <Button
              variant="text"
              color="primary"
              href={org.homeUrl}
              target="_blank"
              rel="noopener noreferrer"
              endIcon={<OpenInNewIcon />}
            >
              Club Website
            </Button>
          </Stack>
        ) : undefined
      } 
    />
  );
}
