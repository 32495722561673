import { useEffect } from 'react';

import { useNavigate, useSearchParams } from 'react-router-dom';

export default function AuthActionHandler() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const mode = searchParams.get('mode');
  const oobCode = searchParams.get('oobCode');
  const continueUrl = searchParams.get('continueUrl');
  console.log('authActionHandler');

  useEffect(() => {
    const handleAction = async () => {
      if (!oobCode) {
        console.error('No action code provided');
        navigate('/login');
        return;
      }

      try {
        switch (mode) {
          case 'verifyEmail': {
            console.log('continueUrl', continueUrl);
            navigate(`/account-verify-email?oobCode=${oobCode}&continueUrl=${continueUrl}`);
            break;
          }
          case 'verifyAndChangeEmail': {
            if (continueUrl) {
              const url = new URL(continueUrl);
              url.searchParams.set('oobCode', oobCode);
              navigate(url.pathname + url.search);
            } else {
              console.error('Continue URL not found');
              navigate('/login');
            }
            break;
          }
          case 'recoverEmail': {
            navigate(`/account-recover-email?oobCode=${oobCode}`);
            break;
          }
          case 'resetPassword': {
            navigate(`/account-reset-password?oobCode=${oobCode}`);
            break;
          }
          // Add other action types here as needed
          default:
            console.error('Unknown action mode:', mode);
            navigate('/login');
        }
      } catch (error) {
        console.error('Error handling auth action:', error);
        navigate('/login');
      }
    };

    handleAction();
  }, [mode, oobCode, continueUrl, navigate]);

  return null; // This component doesn't render anything
}
