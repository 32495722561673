import { ReactElement, useEffect, useRef } from 'react';

import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';

import { Dayjs } from 'dayjs';

import { DatePickerToolbar, DatePickerToolbarProps, PickersDay } from '@mui/x-date-pickers';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';

export interface EventDateCalendarProps {
  isMultiDay?: boolean;
  currentDate?: Dayjs;
  value?: Dayjs[];
  defaultValue?: Dayjs[];
  onChange?: (newDates: Dayjs[] | null) => void;
  onMultiDayChange?: (isMultiDay: boolean) => void;
  orientation?: 'portrait' | 'landscape';
}

export default function EventDateCalendar(props: EventDateCalendarProps): ReactElement {
  const { isMultiDay, value, onChange, onMultiDayChange } = props;
  const refDates = useRef<Dayjs[]>(value ?? []);

  useEffect(() => {
    refDates.current = value ?? [];
  }, [value]);

  useEffect(() => {
    if (!isMultiDay && refDates.current.length > 1) {
      const firstDate = refDates.current.reduce(
        (acc, current) => (current.valueOf() < acc.valueOf() ? current : acc),
        refDates.current[0]
      );
      refDates.current = [firstDate];
      onChange?.(refDates.current);
    }
  }, [isMultiDay, onChange]);

  function isSameDay(a: Dayjs, b: Dayjs) {
    return a.year() === b.year() && a.month() === b.month() && a.date() === b.date();
  }

  const handleDayClick = (day: Dayjs) => {
    if (isMultiDay) {
      if (refDates.current && refDates.current.some((d) => isSameDay(d, day))) {
        if (refDates.current.length > 1) {
          refDates.current = refDates.current.filter((d) => !isSameDay(d, day));
        }
      } else {
        refDates.current = day ? [...refDates.current, day] : [];
      }
    } else {
      refDates.current = [day];
    }

    onChange?.(refDates.current);
  };

  function MultiDayToolbar(props: DatePickerToolbarProps<Dayjs>) {
    return (
      <Box
        className={props.className}
        sx={{display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start'}}
      >
        <DatePickerToolbar {...props} sx={{ display: 'none' }} />
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={isMultiDay ?? false}
                name="multiday"
                onChange={({ target }) => {
                  if (onMultiDayChange) {
                    onMultiDayChange(target.checked);
                  }
                }}
              />
            }
            label={isMultiDay ? 'Multi-day' : 'Single-day'}
          />
        </FormGroup>
      </Box>
    );
  }

  return (
    <StaticDatePicker
      value={props.currentDate}
      orientation={props.orientation}
      defaultValue={refDates.current[0]}
      slots={{
        toolbar: MultiDayToolbar,
        day: (props) => {
          const isSelected = refDates.current.some((day) => isSameDay(day, props.day));
          return <PickersDay {...props} selected={isSelected} onClick={() => handleDayClick(props.day)} />;
        },
      }}
      slotProps={{
        actionBar: {
          actions: [],
        },
      }}
    />
  );
}
