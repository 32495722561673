import { ReactElement, useEffect, useMemo, useState, useCallback } from 'react';

import { useNavigate } from 'react-router';
import { Link, useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import Typography from '@mui/material/Typography';
import { debounce } from '@mui/material/utils';

import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';

import GratiPageWrapper from '../components/common/GratiPageWrapper';
import LeagueEditor from '../components/league/LeagueEditor';
import LeagueView from '../components/league/LeagueView';
import { useApp } from '../contextProviders/AppProvider';
import { useData } from '../contextProviders/DataProvider';
import useLeague from '../dataHooks/useLeague';
import useSeasons from '../dataHooks/useSeasons';
import { useLeagueMutators } from '../dataMutators/useLeagueMutators';
import { useMembershipMutators } from '../dataMutators/useMembershipMutators';
import { useSeasonMutators } from '../dataMutators/useSeasonMutators';
import League from '../types/League';
import Membership from '../types/Membership';
import Season from '../types/Season';

interface LeaguePageProps {
  isEditMode?: boolean;
}

export default function LeaguePage(props: LeaguePageProps): ReactElement {
  const { isEditMode } = props;

  const { updateActions, setPageName } = useApp();
  const { isLeagueAdmin, isOrgActive, userProfile } = useData();
  const { orgId, leagueId } = useParams();
  const navigate = useNavigate();
  const { deleteLeague, updateLeague } = useLeagueMutators();
  const { updateMemberships } = useMembershipMutators();
  const { addSeason, updateSeason, deleteSeason } = useSeasonMutators();

  const { league, isLeagueLoading, leagueError } = useLeague(userProfile ? { orgId, leagueId } : {});
  const { seasons, isSeasonsLoading, seasonsError } = useSeasons(userProfile ? { orgId, leagueId } : {});

  const [pendingSeasons, setPendingSeasons] = useState<Record<string, Season>>({});

  // Combine original seasons with any pending changes
  const activeSeasons = useMemo(
    () =>
      seasons?.map((season) => {
        if (season.itemId && pendingSeasons[season.itemId]) {
          return { ...season, ...pendingSeasons[season.itemId] };
        }
        return season;
      }) || [], // Return empty array if seasons is undefined
    [seasons, pendingSeasons]
  );

  const debouncedUpdate = useMemo(
    () =>
      debounce((season: Season) => {
        console.log('Saving season to Firestore:', season);
        updateSeason(season);
      }, 500),
    [updateSeason]
  );

  const handleSeasonChange = useCallback(
    (season: Partial<Season>) => {
      if (!season.itemId) return;
      const id = season.itemId;

      setPendingSeasons((prev) => {
        const updatedSeason = {
          ...(prev[id] || seasons?.find((s) => s.itemId === id)),
          ...season,
        } as Season;

        // Pass complete season object to debouncedUpdate
        debouncedUpdate(updatedSeason);

        return {
          ...prev,
          [id]: updatedSeason,
        };
      });
    },
    [seasons, debouncedUpdate]
  );

  useEffect(() => {
    if (league) {
      setPageName(league.name);
      if (isLeagueAdmin(league.itemId, league.orgId)) {
        updateActions([
          {
            name: 'Edit',
            handleClick: () => navigate('/league-edit/' + league.orgId + '/' + league.itemId, { replace: true }),
            icon: EditIcon,
          },
        ]);
      }
    } else if (leagueError) {
      setPageName(leagueError.message);
    }
  }, [league, leagueError, isLeagueAdmin, updateActions, setPageName, navigate]);

  const handleCancel = () => {
    navigate(-1);
  };

  const handleSubmit = async (league: League, updatedMemberships: Partial<Membership>[]) => {
    if (league) {
      await updateLeague(league);
      // If there are membership updates and includeAllOrgMembers is false, update them
      if (updatedMemberships.length > 0 && !league.includeAllOrgMembers) {
        await updateMemberships(league.orgId, updatedMemberships);
      }
    }
    navigate('/league/' + league.orgId + '/' + league.itemId, { replace: true });
  };

  const handleDelete = (league: League) => {
    console.log(`Deleting league ${league.itemId}`);
    deleteLeague(league);
    navigate(-1);
  };

  const handleSeasonAdded = (season: Season) => {
    addSeason(season);
  };

  const handleSeasonDelete = useCallback(
    (season: Season) => {
      if (!season.itemId) return;

      console.log('Deleting season:', season.itemId); // Debug log

      try {
        // Remove from local state immediately
        setPendingSeasons((prev) => {
          const newState = { ...prev };
          delete newState[season.itemId];
          return newState;
        });

        // Delete from Firestore
        deleteSeason(season);
      } catch (error) {
        console.error('Error deleting season: ', error);
      }
    },
    [deleteSeason]
  );

  const validateUser = () => {
    if (!orgId || !leagueId) {
      return false;
    }
    if (isEditMode) {
      return isLeagueAdmin(leagueId, orgId) ?? false;
    } else {
      return isOrgActive(orgId) ?? false;
    }
  };

  return (
    <GratiPageWrapper isContentLoading={isLeagueLoading || isSeasonsLoading} validateUser={validateUser}>
      <>
        {league && seasons != null && (
          <>
            {isLeagueAdmin(league.itemId, league.orgId) ? (
              <Box>
                {isEditMode ? (
                  <LeagueEditor
                    league={league}
                    seasons={activeSeasons}
                    isNew={false}
                    onCancel={handleCancel}
                    onSubmit={handleSubmit}
                    onDelete={handleDelete}
                    onSeasonAdd={handleSeasonAdded}
                    onSeasonChange={handleSeasonChange}
                    onSeasonDelete={handleSeasonDelete}
                  />
                ) : (
                  <LeagueView league={league} seasons={activeSeasons} />
                )}
                <Fab
                  variant="extended"
                  color="primary"
                  aria-label="add event"
                  component={Link}
                  to={`/event-create/${league.orgId}/${league.itemId}`}
                >
                  <AddIcon />
                  Add event
                </Fab>
              </Box>
            ) : (
              <Box>
                <LeagueView league={league} seasons={activeSeasons} />
              </Box>
            )}
          </>
        )}
        {seasonsError && <Typography>Error loading seasons: {seasonsError.message}</Typography>}
      </>
    </GratiPageWrapper>
  );
}
