import React, { ReactElement, ReactNode, useCallback, useContext, useEffect, useState } from 'react';

import {
  Auth,
  EmailAuthProvider,
  reauthenticateWithCredential,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  updateEmail,
  updatePassword,
  User,
  ActionCodeSettings,
} from 'firebase/auth';
import { disableNetwork, doc, enableNetwork, setDoc } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { StorageReference } from 'firebase/storage';

import { Navigate } from 'react-router-dom';

import { useAuthState } from 'react-firebase-hooks/auth';

import { auth, cloudFunctions, firestore, imagesRef } from '../firebase';

export interface AuthProviderProps {
  children?: ReactNode;
}

export interface AuthContextModel {
  auth: Auth;
  authUrl: string;
  checkUserExists: (email: string) => Promise<boolean>;
  disableGod: (disable: boolean) => void;
  imagesRef: StorageReference;
  isEmailVerified: boolean;
  isGod: boolean;
  isGodCapable: boolean;
  isLoggedIn: boolean;
  isLoggingIn: boolean;
  resetPassword: (email: string) => Promise<void>;
  signIn: (email: string, password: string) => Promise<void>;
  signOutUser: () => Promise<void>;
  user: User | null;
  verifyEmailNow: (uid: string) => Promise<void>;
  updateUserEmail: (newEmail: string) => Promise<void>;
  updateUserPassword: (currentPassword: string, newPassword: string) => Promise<void>;
  reauthenticateUser: (password: string) => Promise<void>;
  sendVerifyEmailChange: (continueUrl: string) => Promise<void>;
}

export const AuthContext = React.createContext<AuthContextModel>({} as AuthContextModel);

export function useAuth(): AuthContextModel {
  return useContext(AuthContext);
}

export const AuthProvider = ({ children }: AuthProviderProps): ReactElement => {
  // TODO: Handle user auth error
  const [user, isLoggingIn, isLoginError] = useAuthState(auth);

  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [isGod, setIsGod] = useState<boolean>(false);
  const [isGodCapable, setIsGodCapable] = useState<boolean>(false);
  const authUrl = 'https://gratigolf.com'; // Used for the auth action handlers.

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setIsLoggedIn(true);
        console.log('***user: ', user);
        user.getIdTokenResult().then((tokenResult) => {
          if (tokenResult?.claims?.god) {
            console.log('setting God to true');
            setIsGod(true);
            setIsGodCapable(true);
            console.log('God is here');
          }
        });
      } else if (isLoginError) {
        setIsLoggedIn(false);
        console.log(isLoginError);
      } else {
        setIsLoggedIn(false);
      }
    });
    return unsubscribe;
  }, [user, isLoginError]);

  async function clearFirestoreCache() {
    try {
      await disableNetwork(firestore);
      await enableNetwork(firestore);
      console.log('Firestore cache cleared successfully.');
    } catch (error) {
      console.error('Error clearing Firestore cache:', error);
    }
  }

  function disableGod(disable: boolean) {
    if (user && isGodCapable) {
      setIsGod(disable);
      console.log(disable ? 'God is dead' : 'God is here');
    }
  }

  interface ReqCheckEmailInterface {
    email: string;
  }

  interface ResCheckEmailInterface {
    emailExists: boolean;
  }

  const checkUserExists = async (email: string): Promise<boolean> => {
    const checkAuthEmailExists = httpsCallable<ReqCheckEmailInterface, ResCheckEmailInterface>(
      cloudFunctions,
      'checkUserExists'
    );

    return checkAuthEmailExists({ email: email })
      .then((result) => {
        return result.data.emailExists;
      })
      .catch((error) => {
        console.log(error);
        return false;
      });
  };

  const signIn = async (email: string, password: string) => {
    signInWithEmailAndPassword(auth, email, password)
      .then((/* userCredential */) => {
        // ********* setUser(userCredential.user);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  async function signOutUser(): Promise<void> {
    localStorage.clear();
    sessionStorage.clear();
    await signOut(auth);

    // Clear react-firebase-hooks cache
    await clearFirestoreCache();

    <Navigate replace={true} to="/login" />;
  }

  function resetPassword(email: string): Promise<void> {
    return sendPasswordResetEmail(auth, email);
  }

  function verifyEmailNow(uid: string): Promise<void> {
    const verifyEmailRef = doc(firestore, 'verifyEmailNow/' + uid);
    return setDoc(verifyEmailRef, { itemId: uid });
  }

  function sendVerifyEmailChange(continueUrl: string): Promise<void> {
    if (!user) {
      throw new Error('No user logged in');
    }
    const actionCodeSettings: ActionCodeSettings = {
      url: continueUrl,
    };
    return sendEmailVerification(user, actionCodeSettings);
  }

  const reauthenticateUser = useCallback(
    async (password: string) => {
      if (!user?.email) {
        throw new Error('No user email found');
      }
      console.log('reauthenticating user: ', user.email);
      const credential = EmailAuthProvider.credential(user.email, password);
      console.log('credential: ', credential);
      await reauthenticateWithCredential(user, credential);
    },
    [user]
  );

  const updateUserEmail = useCallback(
    async (newEmail: string) => {
      if (!user) {
        throw new Error('No user logged in');
      }
      updateEmail(user, newEmail).then(() => {
        console.log('Email updated');
      }).catch((error) => {
        console.error('Error updating email:', error);
      });
    },
    [user]
  );

  const updateUserPassword = useCallback(
    async (currentPassword: string, newPassword: string) => {
      if (!user) {
        throw new Error('No user logged in');
      }
      await reauthenticateUser(currentPassword);
      await updatePassword(user, newPassword);
    },
    [user, reauthenticateUser]
  );

  const values = {
    user: user ?? null,
    auth,
    authUrl,
    disableGod,
    imagesRef,
    isEmailVerified: user ? user.emailVerified : false,
    isGodCapable,
    isLoggingIn,
    isLoggedIn,
    isGod,
    checkUserExists,
    signIn,
    signOutUser,
    resetPassword,
    verifyEmailNow,
    updateUserEmail,
    updateUserPassword,
    reauthenticateUser,
    sendVerifyEmailChange,
  };
  return (
    <AuthContext.Provider value={values}>
      {isLoggingIn && <div>Loading...</div>}
      {children}
    </AuthContext.Provider>
  );
};

//export const useUserContext = (): UserContextState => {
//  return useContext(UserStateContext)
//}
