import { ReactElement, useState } from 'react';

import { UploadResult } from 'firebase/storage';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';

import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';

import { useAuth } from '../../contextProviders/AuthProvider';
import { useData } from '../../contextProviders/DataProvider';
import DefaultObjects from '../../types/DefaultObjects';
import Org from '../../types/Org';
import GratiImageUpload from '../common/GratiImageUpload';

interface OrgEditorProps {
  org: Org | undefined;
  handleCancel: () => void;
  handleSubmit: (org: Org, isAddedToUserProfile?: boolean) => void;
  handleDelete?: () => void;
  isNew?: boolean;
}

export default function OrgEditor(props: OrgEditorProps): ReactElement {
  const { handleCancel, handleSubmit, handleDelete, isNew } = props;
  const { isGod } = useAuth();
  const { isOrgAdmin, isOrgPro, userProfile } = useData();

  console.log('isNew', isNew);
  const [org, setOrg] = useState<Org>(props.org ?? {
    ...DefaultObjects.Org,
    creatorId: userProfile?.itemId || '',
  });

  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [isAddedToUserProfile, setIsAddedToUserProfile] = useState(true);
  const isInitialized = (org.logo && org.logo.length > 0) ? true : false;

  const handleImageUpdated = (snapshot: UploadResult | null) => {
    if (org) {
      setOrg({ ...org, logo: snapshot ? snapshot.ref.name : "" });
    }
    console.log('Uploaded a blob or file!');
  };

  if (org) {
    if (isOrgAdmin(org.itemId) || isOrgPro(org.itemId) || (isNew && isGod)) {
      return ( 
        <Box
          component="form"
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(org, isAddedToUserProfile);
          }}
        >
          <Stack spacing={2}>
            <TextField
              id="name"
              label="Org Name"
              aria-label="Org Name"
              aria-required="true"
              defaultValue={org.name}
              onChange={({ target }) => setOrg({ ...org, name: target.value })}
            />
            <TextField
              id="shortName"
              label="Short Name"
              aria-label="Short Name"
              aria-required="true"
              defaultValue={org.shortName}
              onChange={({ target }) => setOrg({ ...org, shortName: target.value })}
            />
            <TextField
              id="homeUrl"
              label="Club Website"
              aria-label="Club Website"
              aria-required="true"
              defaultValue={org.homeUrl}
              onChange={({ target }) => setOrg({ ...org, homeUrl: target.value })}
            />
            <GratiImageUpload
              onImageUpdated={handleImageUpdated}
              isInitialized={isInitialized}
              storageImageFileName={org.itemId + '-logo'}
              label="Logo"
            />
          </Stack>
          <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2} mt={8}>
            <Button size="medium" variant="contained" color="primary" startIcon={<SaveIcon />} type="submit">
              Save
            </Button>
            <Button
              size="medium"
              variant="outlined"
              color="secondary"
              startIcon={<CancelIcon />}
              onClick={() => handleCancel()}
            >
              Cancel
            </Button>
            {(isOrgAdmin(org.itemId) || isOrgPro(org.itemId)) && (
              <>
                {handleDelete && 
                  <Button
                    size="medium"
                    variant="text"
                    color="secondary"
                    startIcon={<DeleteIcon />}
                    onClick={() => setDeleteConfirm(true)}
                  >
                    Delete
                  </Button>
                }
                {deleteConfirm && (
                  <span>
                    <Button size="large" onClick={() => handleDelete && handleDelete()}>
                      Confirm
                    </Button>
                    <Button size="large" onClick={() => setDeleteConfirm(false)}>
                      Cancel
                    </Button>
                  </span>
                )}
              </>
            )}
          </Stack>
          {isGod && org &&
            <Stack 
              direction="row" 
              justifyContent="flex-start" 
              alignItems="center" spacing={2} 
              sx={{ backgroundColor: "tertiary.container", color: "tertiary.onContainer", mt: 2, p: 1 }}
            >
              <FormGroup>
                <FormControlLabel
                  id="isTest"
                  value="top"
                  aria-label="Is a test org"
                  aria-required="false"
                  label="Test?"
                  control={
                    <Switch
                      id={'isTest' + org.isTest}
                      checked={org.isTest}
                      color="secondary"
                      onChange={(e) => setOrg({ ...org, isTest: e.target.checked })}
                    />
                  }
                />
              </FormGroup>
              {!props.org &&
                <FormGroup>
                  <FormControlLabel
                    id="isAddedToUserProfile"
                    value="top"
                    aria-label="God can create orgs and not be a part of them. By default, they are added to the user profile."
                    aria-required="false"
                    label="Add to User Profile?"
                    control={
                      <Switch
                        id={'isAddedToUserProfile'}
                        checked={isAddedToUserProfile}
                        color="secondary"
                        onChange={(e) => setIsAddedToUserProfile(e.target.checked)}
                      />
                    }
                  />
                </FormGroup>
              }
            </Stack>  
          }
        </Box>
      );
    } else {
      return <> </>;
    }
  } else {
    return <></>;
  }
}
