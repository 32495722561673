import { ReactElement } from 'react';

import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

interface OrgMembershipSelectorProps {
  isMember: boolean;
  isPro: boolean;
  isAdmin: boolean;
  onMemberChange: (isMember: boolean) => void;
  onProChange: (isPro: boolean) => void;
  onAdminChange: (isAdmin: boolean) => void;
}

export default function OrgMembershipSelector({
  isMember,
  isPro,
  isAdmin,
  onMemberChange,
  onProChange,
  onAdminChange,
}: OrgMembershipSelectorProps): ReactElement {
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Tooltip title="Views and participates in events, leagues, and seasons.">
        <Stack alignItems="center">
          <Typography variant="caption">Member</Typography>
          <Checkbox checked={isMember} onChange={(e) => onMemberChange(e.target.checked)} />
        </Stack>
      </Tooltip>
      <Tooltip title="Manages events, leagues, seasons and courses.">
        <Stack alignItems="center">
          <Typography variant="caption">Pro</Typography>
          <Checkbox checked={isPro} onChange={(e) => onProChange(e.target.checked)} />
        </Stack>
      </Tooltip>
      <Tooltip title="Manages events, leagues, and seasons.">
        <Stack alignItems="center">
          <Typography variant="caption">Admin</Typography>
          <Checkbox checked={isAdmin} onChange={(e) => onAdminChange(e.target.checked)} />
        </Stack>
      </Tooltip>
    </Stack>
  );
}
