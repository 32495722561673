import { ReactElement, useEffect } from 'react';

import AccountEditor from '../components/account/AccountEditor';
import GratiPageWrapper from '../components/common/GratiPageWrapper';
import { useApp } from '../contextProviders/AppProvider';

export default function AccountPage(): ReactElement {
  const { setPageName } = useApp();

  useEffect(() => {
    setPageName('Account');
  }, [setPageName]);

  return (
    <GratiPageWrapper>
      <AccountEditor />
    </GratiPageWrapper>
  );
}
