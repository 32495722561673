import { ReactElement, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import Fab from '@mui/material/Fab';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Popover from '@mui/material/Popover';

import AddIcon from '@mui/icons-material/Add';

import GratiPageWrapper from '../components/common/GratiPageWrapper';
import SeasonDirectory from '../components/season/SeasonDirectory';
import { useAuth } from '../contextProviders/AuthProvider';
import { useData } from '../contextProviders/DataProvider';
import useSeasons from '../dataHooks/useSeasons';

export default function SeasonsPage(): ReactElement {
  const navigate = useNavigate();
  const { isGod } = useAuth();
  const { getAdminLeagues } = useData();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const adminLeagues = getAdminLeagues();
  const hasMultipleAdminLeagues = adminLeagues.length > 1;
  const hasSingleAdminLeague = adminLeagues.length === 1;
  const { seasons, isSeasonsLoading, seasonsError } = useSeasons({ fetchAll: true, includeCatchAll: isGod });

  const handleFabClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (hasMultipleAdminLeagues) {
      setAnchorEl(event.currentTarget);
    } else if (hasSingleAdminLeague) {
      navigate(`/season-create/${adminLeagues[0]}`);
    }
  };

  const handleLeagueSelect = (leagueId: string) => {
    setAnchorEl(null);
    navigate(`/season-create/${leagueId}`);
  };

  return (
    <GratiPageWrapper isContentLoading={isSeasonsLoading && !seasonsError}>
      <SeasonDirectory seasons={seasons} />
      {(hasSingleAdminLeague || hasMultipleAdminLeagues) && (
        <>
          <Fab
            color="primary"
            aria-label="add"
            onClick={handleFabClick}
            sx={{
              position: 'fixed',
              bottom: 16,
              right: 16,
            }}
          >
            <AddIcon />
          </Fab>
          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
          >
            <List>
              {adminLeagues.map((leagueId) => (
                <ListItem key={leagueId} disablePadding>
                  <ListItemButton onClick={() => handleLeagueSelect(leagueId)}>
                    <ListItemText primary={leagueId} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Popover>
        </>
      )}
    </GratiPageWrapper>
  );
}
