import { ReactElement, useMemo } from 'react';

import Alert from '@mui/material/Alert';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListSubheader from '@mui/material/ListSubheader';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useAuth } from '../../contextProviders/AuthProvider';
import { useData } from '../../contextProviders/DataProvider';
import useLeagues from '../../dataHooks/useLeagues';
import League from '../../types/League';
import Hourglass from '../common/Hourglass';

import { LeagueListItem } from './LeagueListItem';

interface LeaguesByOrg {
  [orgId: string]: {
    name: string;
    leagues: League[];
  };
}

export default function LeagueDirectory(): ReactElement {
  const { isGod } = useAuth();
  const { userProfile, getAllActiveOrgs } = useData();
  const { leagues, isLeaguesLoading, leaguesError } = useLeagues({
    person: userProfile,
    includeCatchAll: isGod,
    includeOrgAdmined: true,
    includeOrgPro: true,
    includeLeagueAdmined: true,
    includeLeagueMember: true,
  });

  const areLeaguesGroupedByOrg = getAllActiveOrgs().length > 1;

  // Group leagues by organization
  const leaguesByOrg = useMemo(() => {
    if (!leagues) return {};

    const grouped: LeaguesByOrg = {};

    for (const league of leagues) {
      if (!grouped[league.orgId]) {
        grouped[league.orgId] = { name: league.orgName || '', leagues: [] };
      }
      grouped[league.orgId].leagues.push(league);
    }

    return grouped;
  }, [leagues]);

  if (isLeaguesLoading) {
    return <Hourglass />;
  } else if (leaguesError) {
    return <Alert severity="error">Error loading leagues {leaguesError.message}</Alert>;
  } else if (!leagues || leagues.length === 0) {
    return <Alert severity="error">No leagues</Alert>;
  }

  const listContent = areLeaguesGroupedByOrg
    ? Object.entries(leaguesByOrg).map(([orgId, org]) => (
        <ListItem disableGutters key={`section-${orgId}`} sx={{ pl: 0, width: '100%' }}>
          <List
            subheader={
              <ListSubheader>
                <Typography variant="h6" color="primary.main">
                  {org.name}
                </Typography>
              </ListSubheader>
            }
            sx={{ pl: 0, width: '100%' }}
          >
            {org.leagues.map((league) => (
              <LeagueListItem
                key={league.itemId}
                league={league}
                isDividerDisplayed={true}
              />
            ))}
          </List>
        </ListItem>
      ))
    : leagues.map((league) => (
        <LeagueListItem key={league.itemId} league={league} isDividerDisplayed={true}/>
      ));

  return (
    <Stack>
      <List
        sx={{
          width: '100%',
          height: '100%',
          position: 'relative',
          overflow: 'auto',
          p: 0,
          '& ul': { padding: 0 },
          '& li': { padding: 0 },
        }}
        subheader={<li />}
      >
        {listContent}
      </List>
    </Stack>
  );
}
