import { useMemo } from 'react';

import { collection, orderBy, Query, query, where } from 'firebase/firestore';

import { useCollectionData } from 'react-firebase-hooks/firestore';

import { useAuth } from '../contextProviders/AuthProvider';
import { useData } from '../contextProviders/DataProvider';
import { firestore } from '../firebase';
import Membership from '../types/Membership';
import { MembershipStatus } from '../types/Membership';

interface UseMembershipsForOrgProps {
  orgId?: string;
}

export default function useMembershipsForOrg(props: UseMembershipsForOrgProps) {
  const { orgId } = props;
  const { isGod } = useAuth();
  const { userProfile, isUserLoading, isOrgActive } = useData();

  const membershipsQuery = useMemo(() => {
    if (!orgId) return null;
    if (!isGod && !isOrgActive(orgId)) return null;

    return query(
      collection(firestore, 'org', orgId, 'membership'),
      where('isMember', '==', true),
      where('status', '==', 'active' as MembershipStatus),
      orderBy('name')
    ) as Query<Membership>;
  }, [orgId, isGod, isOrgActive]);

  const [memberships, isMembershipsLoading, isMembershipsError] = useCollectionData<Membership>(membershipsQuery);

  if (!isUserLoading && !userProfile && !isMembershipsLoading && !isMembershipsError) {
    return {
      memberships: null,
      isMembershipsLoading: false,
      isMembershipsError: new Error('User must be logged in to view org memberships') as Error | null,
    };
  }

  if (!isUserLoading && !isMembershipsLoading && !isMembershipsError && !isGod && orgId && !isOrgActive(orgId)) {
    return {
      memberships: null,
      isMembershipsLoading: false,
      isMembershipsError: new Error('Not authorized to view these memberships') as Error | null,
    };
  }

  return {
    memberships: memberships ?? null,
    isMembershipsLoading: isMembershipsLoading || isUserLoading,
    isMembershipsError: isMembershipsError as Error | null,
  };
}
