import { useMemo } from 'react';

import { collection, collectionGroup, Query, query, where } from 'firebase/firestore';

import { useCollectionData } from 'react-firebase-hooks/firestore';

import { useAuth } from '../contextProviders/AuthProvider';
import { firestore } from '../firebase';
import Membership, { MembershipStatus } from '../types/Membership';
import Person from '../types/Person';

interface UseMembershipsOfPersonProps {
  userProfile?: Person | null;
  personId?: string | null;
  orgId?: string | null;
  status?: MembershipStatus;
}

export default function useMembershipsOfPerson(props: UseMembershipsOfPersonProps) {
  const { userProfile, personId, orgId, status = 'active' } = props;
  const { isGod } = useAuth();


  const getOrgIdsByStatus = (status: MembershipStatus): string[] => {
    if (!userProfile) return [];
    
    switch (status) {
      case 'active':
        return Array.from(new Set([
          ...(userProfile.orgIds || []),
          ...(userProfile.adminOrgIds || []),
          ...(userProfile.proOrgIds || [])
        ]));
        
      case 'pending':
        return Array.from(new Set([
          ...(userProfile.pendingOrgIds || []),
          ...(userProfile.pendingAdminOrgIds || []),
          ...(userProfile.pendingProOrgIds || [])
        ]));
        
      case 'inactive':
        return Array.from(new Set([
          ...(userProfile.inactiveOrgIds || []),
          ...(userProfile.inactiveProOrgIds || []),
          ...(userProfile.inactiveAdminOrgIds || [])
        ]));
        
      case 'all':
        return Array.from(new Set([
          ...(userProfile.orgIds || []),
          ...(userProfile.adminOrgIds || []),
          ...(userProfile.proOrgIds || []),
          ...(userProfile.pendingOrgIds || []),
          ...(userProfile.pendingAdminOrgIds || []),
          ...(userProfile.pendingProOrgIds || []),
          ...(userProfile.inactiveOrgIds || []),
          ...(userProfile.inactiveProOrgIds || []),
          ...(userProfile.inactiveAdminOrgIds || [])
        ]));
        
      default:
        return [];
    }
  };

  const membershipQuery = useMemo(() => {
    if (!personId) return null;
    if (!userProfile) return null;

    const validOrgIds = getOrgIdsByStatus(status);

    if (validOrgIds.length === 0) {
      return null;
    }

    if (orgId) {
      if (isGod || validOrgIds.includes(orgId)) {
        return query(
          collection(firestore, 'org', orgId, 'membership') as Query<Membership>,
          where('itemId', '==', personId),
          where('status', '==', status)
        );
      } else {
        return null;
      }
    }

    // If god or querying own memberships, can see all memberships
    if (isGod || personId === userProfile.itemId) {
      return query(
        collectionGroup(firestore, 'membership') as Query<Membership>,
        where('itemId', '==', personId),
        where('orgId', 'in', validOrgIds),
        where('status', '==', status)
      );
    }

    // Otherwise, can only see active memberships in shared orgs
    return query(
      collectionGroup(firestore, 'membership') as Query<Membership>,
      where('itemId', '==', personId),
      where('orgId', 'in', validOrgIds),
      where('status', '==', status)
    );
  }, [personId, userProfile, isGod, orgId]);

  const [memberships, isMembershipsLoading, membershipsError] = useCollectionData<Membership>(membershipQuery);

  const memoizedReturn = useMemo(() => {
    if (!isMembershipsLoading && !membershipsError && userProfile === null) {
      return {
        memberships: [],
        isMembershipsLoading: false,
        membershipsError: new Error('User must be logged in to view memberships'),
      };
    }

    // if the query qualifiers are passed in as null, then don't return anything. Not an error either.
    // It just means that the calling component wanted nothing fetched.
    if (personId === null) {
      return {
        memberships: [],
        isMembershipsLoading: false,
      };
    }

    if (orgId && userProfile && !isGod && !getOrgIdsByStatus(status).includes(orgId)) {
      return {
        memberships: [],
        isMembershipsLoading: false,
        membershipsError: new Error('User not authorized to get these memberships'),
      };
    }

    return {
      memberships: memberships || [],
      isMembershipsLoading: isMembershipsLoading || !userProfile,
      membershipsError,
    };
  }, [
    memberships, 
    isMembershipsLoading, 
    membershipsError, 
    userProfile, 
    personId, 
    orgId, 
    isGod]);

  return memoizedReturn;
}

