import { 
  ReactElement, 
  useEffect 
} from 'react';

import { useNavigate } from 'react-router-dom';

import GratiPageWrapper from '../components/common/GratiPageWrapper';
import { useAuth } from "../contextProviders/AuthProvider";

export default function LogoutPage(): ReactElement {
  const navigate = useNavigate();
  const { signOutUser } = useAuth();
  
  useEffect(() => {
    const signOut = async () => {
      try {
        signOutUser().then(() => {
          navigate('/welcome');
        });
      } catch (error) {
        console.log(error);
      }
    };
    signOut();
  }, [signOutUser, navigate]);

  return (
    <GratiPageWrapper isNoAuthRequired={true}>
      <> </>
    </GratiPageWrapper>
  );
};