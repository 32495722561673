import React, { useState, useCallback, ReactElement, useEffect } from 'react';

import { getDownloadURL } from 'firebase/storage';
import { ref } from 'firebase/storage';

import { useParams } from 'react-router-dom';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import GratiPageWrapper from '../components/common/GratiPageWrapper';
import { useApp } from '../contextProviders/AppProvider';
import { useApplicationMutators } from '../dataMutators/useApplicationMutators';
import { imagesRef } from '../firebase';
import { DefaultObjects } from '../types/DefaultObjects';
import { validateAddress, validateEmail, validateGHIN, validatePhone } from '../utils/validators';

interface FormData {
  name: string;
  email: string;
  phone: string;
  mailingAddress: string;
  ghin: string;
}

interface FormErrors {
  name?: string;
  email?: string;
  phone?: string;
  mailingAddress?: string;
  ghin?: string;
}

export default function ApplyPage(): ReactElement {
  const { orgId = "unknown", orgName = "GratiGolf" } = useParams();
  const { setPageName } = useApp();
  const [isApplicationAccepted, setIsApplicationAccepted] = useState(false);
  const welcomeImageFileName = 'welcome.png';
  const [welcomeImageUrl, setWelcomeImageUrl] = useState('');
  const { addApplication } = useApplicationMutators();
  const [formData, setFormData] = useState<FormData>({
    name: '',
    email: '',
    phone: '',
    mailingAddress: '',
    ghin: '',
  });

  useEffect(() => {
    getDownloadURL(ref(imagesRef, welcomeImageFileName))
      .then((url) => {
        setWelcomeImageUrl(url);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    setPageName(`Apply to ${orgName}`);
  }, [setPageName, orgName]);

  const ApplicationStatus = (): ReactElement => {
    return (
      <Container>
       <Card sx={{ maxWidth: 640 }}>
         <CardMedia component="img" image={welcomeImageUrl} alt="Welcome" />
         <CardContent>
           <Typography gutterBottom variant="h5" component="div">
             Your application has been received!
           </Typography>
           <Typography variant="body2" color="text.secondary">
             Thank you for applying. We will review your application and get back to you shortly.
           </Typography>
         </CardContent>
       </Card>
     </Container>
    );
  };

  const [errors, setErrors] = useState<FormErrors>({});
  const [touched, setTouched] = useState<Record<keyof FormData, boolean>>({
    name: false,
    email: false,
    phone: false,
    mailingAddress: false,
    ghin: false,
  });

  const validateField = useCallback((field: keyof FormData, value: string): string | undefined => {
    switch (field) {
      case 'name':
        return value.trim() === '' ? 'Name is required' : undefined;
      case 'email':
        return !validateEmail(value) ? 'Invalid email address' : undefined;
      case 'phone':
        return !validatePhone(value) ? 'Invalid phone number' : undefined;
      case 'mailingAddress':
        return !validateAddress(value) ? 'Invalid address' : undefined;
      case 'ghin':
        return !validateGHIN(value) && value.trim() !== ''  ? 'Invalid GHIN' : undefined;
      default:
        return undefined;
    }
  }, []);

  const handleChange = useCallback(
    (field: keyof FormData) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setFormData((prevFormData) => ({
        ...prevFormData,
        [field]: value,
      }));
      // Only validate if the field has been touched before
      if (touched[field]) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: validateField(field, value),
        }));
      }
    },
    [touched, validateField]
  );

  const handleBlur = useCallback(
    (field: keyof FormData) => () => {
      setTouched((prevTouched) => ({
        ...prevTouched,
        [field]: true,
      }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: validateField(field, formData[field]),
      }));
    },
    [formData, validateField]
  );

  const handleSubmit = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault();

      // Mark all fields as touched
      setTouched({
        name: true,
        email: true,
        phone: true,
        mailingAddress: true,
        ghin: true,
      });

      // Validate all fields
      const newErrors: FormErrors = {};
      for (const field of Object.keys(formData) as (keyof FormData)[]) {
        const error = validateField(field, formData[field]);
        if (error) {
          newErrors[field] = error;
        }
      }
      setErrors(newErrors);

      // Submit if no errors
      if (Object.keys(newErrors).length === 0) {
        const application = {
          ...DefaultObjects.Application,
          orgId: orgId,
          orgName: orgName,
          name: formData.name,
          email: formData.email,
          phone: formData.phone,
          ghin: formData.ghin,
          details: {
            itemId: "",
            name: formData.name,
            mailingAddress: formData.mailingAddress,
          },
        };
        addApplication(application).then(() => {
          setIsApplicationAccepted(true);
        });
        console.log('Submitting:', application); // Replace with actual submission logic
      }
    },
    [formData, validateField, addApplication, orgId, orgName]
  );

  return (
    <GratiPageWrapper isNoAuthRequired={true}>
      {isApplicationAccepted ? 
        (
          <ApplicationStatus />
        ) : (
          <Stack component="form" onSubmit={handleSubmit} spacing={2} sx={{ maxWidth: 'sm', margin: 'auto', padding: 2 }}>
            <Typography variant="h5">{`${orgName} application`}</Typography>
            <Typography variant="body2">{`Please fill out the form below to apply to ${orgName}.`}</Typography>
            <Typography variant="body2">{`We will review your application and get back to you shortly.`}</Typography>

            <TextField
              required
              fullWidth
              autoFocus
              id="name"
              label="Name"
              value={formData.name}
              onChange={handleChange('name')}
              onBlur={handleBlur('name')}
              error={touched.name && !!errors.name}
              helperText={touched.name ? errors.name : undefined}
            />

            <TextField
              required
              fullWidth
              id="email"
              label="Email"
              type="email"
              value={formData.email}
              onChange={handleChange('email')}
              onBlur={handleBlur('email')}
              error={touched.email && !!errors.email}
              helperText={touched.email ? errors.email : undefined}
            />

            <TextField
              required
              fullWidth
              id="phone"
              label="Phone"
              type="tel"
              value={formData.phone}
              onChange={handleChange('phone')}
              onBlur={handleBlur('phone')}
              error={touched.phone && !!errors.phone}
              helperText={touched.phone ? errors.phone : undefined}
            />

            <TextField
              required
              fullWidth
              id="mailingAddress"
              label="Mailing Address"
              value={formData.mailingAddress}
              onChange={handleChange('mailingAddress')}
              onBlur={handleBlur('mailingAddress')}
              error={touched.mailingAddress && !!errors.mailingAddress}
              helperText={touched.mailingAddress ? errors.mailingAddress : undefined}
            />

            <TextField
              fullWidth
              id="ghin"
              label="GHIN"
              value={formData.ghin}
              onChange={handleChange('ghin')}
              onBlur={handleBlur('ghin')}
              error={touched.ghin && !!errors.ghin}
              helperText={touched.ghin ? errors.ghin : undefined}
            />

            <Button type="submit" variant="contained">
              Submit
            </Button>
          </Stack>
        )
      }
    </GratiPageWrapper>
  );
}
