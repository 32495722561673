import { ReactElement, useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import Papa from 'papaparse';

import { useAuth } from '../../contextProviders/AuthProvider';
import { useSeasonMutators } from '../../dataMutators/useSeasonMutators';
import { CompHandicap, CompScoring } from '../../types/Competition';
import Competition from '../../types/Competition';
import Result from '../../types/Result';
import Score from '../../types/Score';
import Season from '../../types/Season';
import { generateFirestoreId } from '../../utils/utils';
import HiddenInput from '../common/HiddenInput';

type Scores = {
  data: Score[];
};

export type SeasonStandingCsvUploadProps = {
  season: Season;
};

export default function SeasonStandingCsvUpload(props: SeasonStandingCsvUploadProps): ReactElement {
  const { season } = props;
  const { isGod } = useAuth();
  const { addStandingToSeason } = useSeasonMutators();

  const [csvFile, setCsvFile] = useState<string>('');
  const [scores, setScores] = useState<Scores>({ data: [] });

  useEffect(() => {
    if (csvFile !== '') {
      Papa.parse(csvFile, {
        header: true,
        skipEmptyLines: true,
        delimiter: ',',
        complete: (results: Scores) => {
          setScores(results);
        },
      });
    }
  }, [csvFile]);

  useEffect(() => {
    if (scores.data.length > 0) {
      const standing: Result = {
        competition: {
          name: 'Meadows Eclectic - Net',
          scoring: "eclectic" as CompScoring,
          handicap: "net" as CompHandicap,
          itemId: generateFirestoreId(),
        } as Competition,
        courseId: '1yeuzwBqMruA0QK4aDLc',
        scores: scores.data,
      } as Result;
      addStandingToSeason(season, standing).catch((error) => {
        console.log(error, 'comp name: ' + standing.competition.name);
      });
    }
  }, [season, scores, addStandingToSeason]);

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const contents = e.target?.result;
        if (typeof contents === 'string') {
          setCsvFile(contents);
        }
      };
      reader.readAsText(file);
    }
  };

  if (isGod) {
    return (
      <Grid container justifyContent="center">
        <Grid size={4}>
          <Button component="label" role={undefined} variant="contained" tabIndex={-1} startIcon={<CloudUploadIcon />}>
            Upload Standings for a Season
            <HiddenInput type="file" accept=".csv" onChange={handleFileUpload} />
          </Button>
        </Grid>
      </Grid>
    );
  } else {
    return <></>;
  }
}
