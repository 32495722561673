import { ReactElement, useState } from 'react';

import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListSubheader from '@mui/material/ListSubheader';
import Typography from '@mui/material/Typography';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import Event from '../../types/Event';

import EventListItem from './EventListItem';

export interface EventScheduleSectionProps {
  sectionId: string;
  title: string;
  events: Event[];
  isLeagueDisplayed: boolean;
  //isUpcoming: boolean;
  selectedEventId?: string;
  defaultExpanded?: boolean;
}

export default function EventScheduleSection(props: EventScheduleSectionProps): ReactElement {
  const { sectionId, title, events, isLeagueDisplayed, /*isUpcoming,*/ selectedEventId, defaultExpanded = false } = props;

  const [isCollapsed, setIsCollapsed] = useState(!defaultExpanded);

  const handleCollapseClick = () => {
    setIsCollapsed(!isCollapsed);
  };

  if (events.length > 0) {
    return (
      <>
        <ListItemButton key={sectionId} onClick={handleCollapseClick} sx={{ width: '100%', px: 0 }}>
          <ListSubheader disableGutters>
            <Typography variant="h6" color="primary.secondary">
              {title}
            </Typography>
          </ListSubheader>
          <span style={{ display: 'flex', justifyContent: 'right', width: '100%' }}>
            {isCollapsed ? <ExpandMore /> : <ExpandLess />}
          </span>
        </ListItemButton>
        <Collapse in={!isCollapsed} timeout="auto" unmountOnExit sx={{ p: 0 }}>
          <List disablePadding>
            {events.map((event: Event) => {
              //if (IsEventOver(event) !== isUpcoming) {
                return (
                  <EventListItem
                    key={event.itemId}
                    event={event}
                    isLeagueDisplayed={isLeagueDisplayed}
                    isSelected={event.itemId === selectedEventId}
                  />
                );
              //} else {
              //  return null;
              //}
            })}
          </List>
        </Collapse>
      </>
    );
  } else {
    return <></>;
  }
}
