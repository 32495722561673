import { collection, CollectionReference, orderBy, Query } from "firebase/firestore";
import { query, where } from "firebase/firestore";

import { useCollectionData } from "react-firebase-hooks/firestore";

import { useAuth } from "../contextProviders/AuthProvider";
import { useData } from "../contextProviders/DataProvider";
import { firestore } from "../firebase";
import { MembershipStatus } from "../types/Membership";
import Org from "../types/Org";

interface UseOrgsProps {
  membershipStatus?: MembershipStatus[];
}

export default function useOrgs(props: UseOrgsProps) {
  const { isGod } = useAuth();
  const { userProfile, getAllActiveOrgs, getAllPendingOrgs, getAllInactiveOrgs } = useData();
  const membershipStatus = props.membershipStatus ?? ['all' as MembershipStatus];

  const getOrgsQuery = () => {
    const targetIds: string[] = [];
    
    if (membershipStatus?.includes('all' as MembershipStatus)) {
      // Combine all org IDs for ALL status
      targetIds.push(...getAllActiveOrgs());
      targetIds.push(...(userProfile?.pendingOrgIds ?? []));
      if (isGod) {
        targetIds.push(...(userProfile?.inactiveOrgIds ?? []));
      }
    } else {
      // Handle individual statuses
      if (membershipStatus?.includes('active' as MembershipStatus)) {
        targetIds.push(...getAllActiveOrgs());
      }
      if (membershipStatus?.includes('pending' as MembershipStatus)) {
        targetIds.push(...(getAllPendingOrgs()));
      }
      if (isGod && membershipStatus?.includes('inactive' as MembershipStatus)) {
        targetIds.push(...(getAllInactiveOrgs()));
      }
    }
  
    // If no IDs found, return a query that will yield no results
    if (targetIds.length === 0) {
      return null;
    }

    const activeConditions = [];
    const testConditions = [];
    const orgIdsConditions = [where('itemId', 'in', targetIds)];

    if (!isGod) {
      activeConditions.push(where('isActive', '==', true));
      testConditions.push(where('isTest', '==', false));
    }
  
    return query(
      collection(firestore, 'org') as CollectionReference<Org>,
      ...activeConditions,
      ...orgIdsConditions,
      ...testConditions,
      orderBy('name')
    ) as Query<Org>;
  }

  // *************** Org loaders ****************
  const qOrgs = userProfile && getAllActiveOrgs().length > 0 ? 
    getOrgsQuery() : null;

  const [orgs, isOrgsLoading, orgsError] = useCollectionData(qOrgs);

  return { orgs: orgs ?? [], isOrgsLoading, orgsError };   
}
