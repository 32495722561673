import { useMemo } from 'react';

import { collection, doc, DocumentReference } from 'firebase/firestore';

import { useDocumentData } from 'react-firebase-hooks/firestore';

import { useAuth } from '../contextProviders/AuthProvider';
import { useData } from '../contextProviders/DataProvider';
import { firestore } from '../firebase';
import Event from '../types/Event';

interface UseEventProps {
  orgId?: string;
  eventId?: string;
}

export default function useEvent(props: UseEventProps) {
  const { orgId, eventId } = props;

  const { isGod } = useAuth();
  const { userProfile, isUserLoading, isOrgActive } = useData();

  // Only create document reference if we have all required data and user is authorized
  const eventRef = useMemo(() => {
    if (!orgId || !eventId) return null;

    return doc(collection(firestore, 'org', orgId, 'event'), eventId) as DocumentReference<Event>;
  }, [orgId, eventId]);

  const [event, isEventLoading, eventError] = useDocumentData<Event>(eventRef);

  if (!isUserLoading && !userProfile && !isEventLoading && !eventError) {
    return {
      event: null,
      isEventLoading: false,
      eventError: new Error('User must be logged in to view events'),
    };
  }

  if (userProfile && !isEventLoading && !eventError && !isGod && orgId && !isOrgActive(orgId)) {
    return {
      event: null,
      isEventLoading: false,
      eventError: new Error('Not authorized to view this event'),
    };
  }

  return {
    event: event || null,
    isEventLoading: isEventLoading || isUserLoading,
    eventError,
  };
}
