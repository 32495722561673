import { useEffect, ReactElement, useState } from 'react';

import { AuthError } from 'firebase/auth';

import { useNavigate, useSearchParams } from 'react-router';
import { Link } from 'react-router-dom';

import { useSignInWithEmailAndPassword } from 'react-firebase-hooks/auth';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import LoginIcon from '@mui/icons-material/Login';
import PasswordIcon from '@mui/icons-material/Password';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import GratiPageWrapper from '../components/common/GratiPageWrapper';
import { useApp } from '../contextProviders/AppProvider';
import { useAuth } from '../contextProviders/AuthProvider';
import { validateEmail } from '../utils/validators';

export default function LoginPage(): ReactElement {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { setPageName } = useApp();
  const { auth, isLoggedIn } = useAuth();

  const [formData, setFormData] = useState({
    email: '',
    password: '',
    isEmailEdited: false,
    isEmailValid: false,
  });
  const [localError, setLocalError] = useState<AuthError | null>(null);
  const [showPassword, setShowPassword] = useState(false);

  const [signInWithEmailAndPassword, loggedInUser, isLoginLoading, loginError] = useSignInWithEmailAndPassword(auth);

  useEffect(() => {
    setPageName('Login to GratiGolf');
  }, [setPageName]);

  useEffect(() => {
    if (!isLoginLoading && loggedInUser) {
      navigate('/');
    }
  }, [isLoginLoading, loggedInUser, navigate]);

  useEffect(() => {
    setLocalError(loginError || null);
  }, [loginError]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setLocalError(null);

    setFormData((prev) => ({
      ...prev,
      [name]: value,
      ...(name === 'email' && {
        isEmailEdited: value !== '',
        isEmailValid: value !== '' && validateEmail(value),
      }),
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await signInWithEmailAndPassword(formData.email, formData.password);
  };

  const GenerateErrorMessage = () => {
    if (localError) {
      switch (localError.code) {
        case 'auth/invalid-email':
        case 'auth/wrong-password':
        case 'auth/invalid-credential':
        case 'auth/user-not-found':
          return (
            <Alert variant="filled" severity="error">
              Your email address or password is incorrect. Please try again.
            </Alert>
          );
        case 'auth/insufficient-permission':
        case 'auth/disabled-account':
          return (
            <Alert variant="filled" severity="error">
              Your account is inactive. Please contact us!
            </Alert>
          );
        case 'auth/invalid-claims':
        case 'auth/invalid-action-code':
        case 'auth/internal-error':
          return (
            <Alert variant="filled" severity="error">
              Something went wrong. It&apos;s us, not you. Please try again later.
            </Alert>
          );
        default:
          return (
            <Alert variant="filled" severity="error">
              {localError.message}
            </Alert>
          );
      }
    }
  };

  return (
    <GratiPageWrapper isNoAuthRequired={true}>
      <Stack
        spacing={2}
        component="form"
        onSubmit={handleSubmit}
        sx={{
          display: 'flex',
          direction: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {searchParams.get('isEmailChange') === 'true' && (
          <Alert severity="success" sx={{ maxWidth: 'md', width: '100%' }}>
            Your email address has been successfully changed. Please log in with your new email address.
          </Alert>
        )}
        <TextField
          fullWidth
          required
          name="email"
          error={formData.isEmailEdited && !formData.isEmailValid}
          label={formData.isEmailEdited && !formData.isEmailValid ? 'Error' : 'Email'}
          helperText={
            formData.isEmailEdited && !formData.isEmailValid ? 'Invalid email address' : 'Valid email address'
          }
          value={formData.email}
          onChange={handleInputChange}
          sx={{ maxWidth: 'md' }}
          slotProps={{
            input: {
              autoComplete: 'username',
            },
          }}
        />
        <TextField
          fullWidth
          required
          name="password"
          type={showPassword ? 'text' : 'password'}
          label="Password"
          autoComplete="current-password"
          helperText="Enter your password..."
          value={formData.password}
          onChange={handleInputChange}
          slotProps={{
            input: {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            },
          }}
          sx={{ maxWidth: 'md' }}
        />
        <Button type="submit" variant="contained" startIcon={<LoginIcon />}>
          Login
        </Button>
        {localError && <GenerateErrorMessage />}
        {isLoginLoading && (
          <Alert variant="filled" severity="info">
            Logging in...
          </Alert>
        )}
        <Box textAlign="center">
          <Button
            component={Link}
            to="/account-forgot-password"
            variant="text"
            color="secondary"
            startIcon={<PasswordIcon />}
          >
            Forgot Password?
          </Button>
        </Box>
        {!isLoggedIn && (
          <Stack spacing={2} sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="body2" color="surface.contrastText">
              Don&apos;t have an account?{' '}
              <Link to="/apply" style={{ color: 'inherit', textDecoration: 'underline' }}>
                Apply
              </Link>{' '}
              today!
            </Typography>
            <Typography variant="body2" color="surface.contrastText">
              Need help? Contact us at admin@gratisports.com
            </Typography>
          </Stack>
        )}
      </Stack>
    </GratiPageWrapper>
  );
}
