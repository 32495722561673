import { useMemo } from 'react';

import {
  and,
  collection,
  CollectionReference,
  or,
  orderBy,
  Query,
  QueryCompositeFilterConstraint,
  QueryFieldFilterConstraint,
} from 'firebase/firestore';
import { query, where } from 'firebase/firestore';

import { useCollectionData } from 'react-firebase-hooks/firestore';

import { useAuth } from '../contextProviders/AuthProvider';
import { useData } from '../contextProviders/DataProvider';
import { OrgMemberType } from '../enums/OrgMemberType';
import { firestore } from '../firebase';
import { MembershipStatus } from '../types/Membership';
import Person from '../types/Person';

interface UsePersonsProps {
  isActiveUser?: boolean;
  membershipStatus?: MembershipStatus;
  orgMemberType: OrgMemberType;
  orgId?: string;
}

export default function usePersons(props: UsePersonsProps) {
  const { isActiveUser, membershipStatus, orgMemberType, orgId } = props;
  const { isGod } = useAuth();
  const { isOrgMemberProOrAdmin, userProfile, getAllActiveOrgs, getAdminOrgs, isUserLoading } = useData();

  // Memoize these values
  const adminOrgs = useMemo(() => getAdminOrgs(), [getAdminOrgs]);
  const activeOrgs = useMemo(() => getAllActiveOrgs(), [getAllActiveOrgs]);

  const getProfilesQuery = () => {
    const activeConditions: QueryFieldFilterConstraint[] = [];
    const orgConditions: (QueryFieldFilterConstraint | QueryCompositeFilterConstraint)[] = [];
    const testConditions: QueryFieldFilterConstraint[] = [];
    const orgIds: string[] = [];
    // We are getting profiles for a specific org.

    if (orgId) {
      (isGod || isOrgMemberProOrAdmin(orgId)) && orgIds.push(orgId);
    } else {
      activeOrgs?.length > 0 && orgIds.push(...activeOrgs);
    }

    if (isGod && isActiveUser === false) {
      activeConditions.push(where('isActive', '==', false));
    } else {
      activeConditions.push(where('isActive', '==', true));
    }

    if (!isGod) {
      testConditions.push(where('isTest', '==', false));
    }

    if (orgMemberType) {
      switch (orgMemberType) {
        case OrgMemberType.ADMIN:
          switch (membershipStatus) {
            case 'inactive' as MembershipStatus:
              (isGod || orgIds.every((org) => adminOrgs.includes(org))) &&
                orgConditions.push(where('inactiveAdminOrgIds', 'array-contains-any', orgIds));
              break;
            case 'all' as MembershipStatus:
              isGod || orgIds.every((org) => adminOrgs.includes(org))
                ? orgConditions.push(
                    or(
                      where('adminOrgIds', 'array-contains-any', orgIds),
                      where('inactiveAdminOrgIds', 'array-contains-any', orgIds)
                    )
                  )
                : orgConditions.push(where('adminOrgIds', 'array-contains-any', orgIds));
              break;
            default:
              orgConditions.push(where('adminOrgIds', 'array-contains-any', orgIds));
              break;
          }
          break;
        case OrgMemberType.PRO:
          switch (membershipStatus) {
            case 'inactive' as MembershipStatus:
              (isGod || orgIds.every((org) => adminOrgs.includes(org))) &&
                orgConditions.push(where('inactiveProOrgIds', 'array-contains-any', orgIds));
              break;
            case 'all' as MembershipStatus:
              isGod || orgIds.every((org) => adminOrgs.includes(org))
                ? orgConditions.push(
                    or(
                      where('proOrgIds', 'array-contains-any', orgIds),
                      where('inactiveProOrgIds', 'array-contains-any', orgIds)
                    )
                  )
                : orgConditions.push(where('proOrgIds', 'array-contains-any', orgIds));
              break;
            default:
              orgConditions.push(where('proOrgIds', 'array-contains-any', orgIds));
              break;
          }
          break;
        default:
          switch (membershipStatus) {
            case 'inactive' as MembershipStatus:
              (isGod || orgIds.every((org) => adminOrgs.includes(org))) &&
                orgConditions.push(where('inactiveOrgIds', 'array-contains-any', orgIds));
              break;
            case 'all' as MembershipStatus:
              isGod || orgIds.every((org) => adminOrgs.includes(org))
                ? orgConditions.push(
                    or(
                      where('orgIds', 'array-contains-any', orgIds),
                      where('inactiveOrgIds', 'array-contains-any', orgIds)
                    )
                  )
                : orgConditions.push(where('orgIds', 'array-contains-any', orgIds));
              break;
            default:
              orgConditions.push(where('orgIds', 'array-contains-any', orgIds));
              break;
          }
          break;
      }
    }

    return query(
      collection(firestore, 'profile') as CollectionReference<Person>,
      and(...activeConditions, ...orgConditions, ...testConditions),
      orderBy('name')
    ) as Query<Person>;
  };

  // Memoize the query
  const qProfiles = useMemo(() => {
    if (isUserLoading && !userProfile) {
      return null;
    }

    return getProfilesQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActiveUser, membershipStatus, orgId, isGod, adminOrgs, activeOrgs]);

  const [persons, isPersonsLoading, personsError] = useCollectionData<Person>(qProfiles);

  if (!isUserLoading && !userProfile && !isPersonsLoading && !personsError) {
    return {
      persons: null,
      isPersonsLoading: false,
      personsError: new Error('User must be logged in to view persons'),
    };
  }

  return {
    persons: persons || null,
    isPersonsLoading: isPersonsLoading || isUserLoading,
    personsError,
  };
}
