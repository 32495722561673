import {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
} from 'react';

import useMembershipsOfPerson from '../dataHooks/useMembershipsOfPerson';
import useUserProfile from '../dataHooks/useUserProfile';
import { MembershipStatus } from '../types/Membership';
import Membership from '../types/Membership';
import Person from '../types/Person';

import { useAuth } from './AuthProvider';

export interface DataProviderProps {
  children?: ReactNode;
}

export interface DataContextModel {
  // User State
  userProfile: Person | null | undefined;
  isUserLoading: boolean;

  // God mode settings
  isAdminDisabled: boolean;
  adminDisabled: (disable: boolean) => void;

  // Getters
  getAllActiveOrgs: (person?: Person) => string[];
  getAllPendingOrgs: (person?: Person) => string[];
  getAllInactiveOrgs: (person?: Person) => string[];
  getAdminOrgs: (person?: Person) => string[];
  getProOrgs: (person?: Person) => string[];
  getAdminLeagues: () => string[];
  getMemberOrgs: (person?: Person) => string[];
  getAllActiveLeagues: () => string[];
  getMemberLeagues: () => [string, string][];

  // Membership checks
  isSelf: (person: Person) => boolean;
  isOrgActive: (orgId: string, person?: Person) => boolean | undefined;
  isOrgMember: (orgId: string, person?: Person) => boolean | undefined;
  isOrgMemberProOrAdminOfAny: () => boolean | undefined;
  isOrgMemberOfAny: () => boolean | undefined;
  isOrgMemberProOrAdmin: (orgId: string, person?: Person) => boolean | undefined;
  isLeagueMember: (leagueId: string) => Promise<boolean>;
  areInASharedOrg: (resourceProfile: Person, requesterProfile?: Person) => boolean;

  // Admin checks
  isOrgAdmin: (orgId: string, person?: Person) => boolean | undefined;
  isLeagueAdmin: (leagueId: string, orgId: string) => boolean | undefined;
  isEventAdmin: (orgId: string, leagueId: string, eventId: string) => boolean | undefined;
  hasAdminRightsOverPerson: (person: Person) => boolean;

  // Pro checks
  isOrgPro: (orgId: string, person?: Person) => boolean | undefined;

  // Capability checks
  canEditPerson: (person: Person) => boolean;
  canEditPersonDetails: (person: Person) => boolean;
  canCreateEvents: () => boolean;
  canCreateHandbooks: (orgId?: string) => boolean | undefined;
  canCreateLeagues: (orgId?: string) => boolean | undefined;
  canCreateSeasons: (orgId?: string, leagueId?: string) => boolean | undefined;

  // Membership Management
  activeMemberships: Membership[] | undefined;
  memberships: Membership[] | undefined;
  getOrgMembership: (orgId: string, personId?: string) => Promise<Membership | null>;
  getLeagueMembership: (leagueId: string, personId?: string) => Promise<Membership | null>;
}

export const DataContext = createContext<DataContextModel>({} as DataContextModel);

export function useData(): DataContextModel {
  return useContext(DataContext);
}

export const DataProvider = ({ children }: DataProviderProps): ReactElement => {
  const { isGod, isLoggedIn, isLoggingIn, user } = useAuth();
  const [isUserLoading, setIsUserLoading] = useState<boolean>(false);

  const [userProfile, setUserProfile] = useState<Person | null>(null);
  const [memberships, setMemberships] = useState<Membership[]>([]);

  const {
    userProfile: fetchedUserProfile,
    isUserProfileLoading,
    userProfileError,
  } = useUserProfile({ user: user ?? null });
  const {
    memberships: fetchedMemberships,
    isMembershipsLoading,
    membershipsError,
  } = useMembershipsOfPerson(userProfile ? { userProfile, personId: userProfile?.itemId, status: 'active' as MembershipStatus } : { });

  const [activeMemberships, setActiveMemberships] = useState<Membership[] | undefined>(undefined);
  const [isAdminDisabled, setIsAdminDisabled] = useState<boolean>(false);
  const [adminLeagues, setAdminLeagues] = useState<string[]>([]);
  const [memberLeagues, setMemberLeagues] = useState<[string, string][]>([]);

  const userProfileRef = useRef<Person | null>(userProfile);
  const membershipsRef = useRef<Membership[]>(memberships);

  useEffect(() => {
    setUserProfile(fetchedUserProfile ?? null);
  }, [fetchedUserProfile]);

  useEffect(() => {
    setMemberships(fetchedMemberships);
  }, [fetchedMemberships]);

  useEffect(() => {
    userProfileRef.current = userProfile;
  }, [userProfile]);

  useEffect(() => {
    membershipsRef.current = memberships;
  }, [memberships]);

  useEffect(() => {
    // Reset userProfile and memberships when the user logs out
    if (!isLoggedIn && !isLoggingIn) {
      setUserProfile(null);
      setMemberships([]);
      userProfileRef.current = null;
      membershipsRef.current = [];
    }
  }, [isLoggedIn, isLoggingIn]);

  useEffect(() => {
    if (isLoggingIn || isUserProfileLoading) {
      setIsUserLoading(true);
    } else {
      setIsUserLoading(false);
    }
  }, [isLoggingIn, isUserProfileLoading]);

  useEffect(() => {
    const setMembershipSubgroups = () => {
      // Get active memberships
      const activeMemberships = membershipsRef.current.filter((m) => m.status === ('active' as MembershipStatus));
      setActiveMemberships(activeMemberships);

      // Set admin leagues
      setAdminLeagues(activeMemberships.filter((m) => m.leagueAdmins).flatMap((m) => m.leagueAdmins || []));
      setMemberLeagues(
        activeMemberships
          .filter((m) => m.leagues)
          .flatMap((m) => (m.leagues || []).map((leagueId) => [m.orgId, leagueId] as [string, string]))
      );
    };

    if (!isMembershipsLoading && !membershipsError && membershipsRef.current && membershipsRef.current.length > 0) {
      setMembershipSubgroups();
    } else if (membershipsError) {
      console.log('Error loading memberships:', membershipsError);
    } else if (isMembershipsLoading) {
      console.log('Loading memberships');
    } else if (!membershipsRef.current || membershipsRef.current.length === 0) {
      console.log('No memberships found');
    }
  }, [isMembershipsLoading, membershipsError]);

  // *************** Getter functions ****************
  const getAllActiveOrgs = useCallback((person?: Person): string[] => {
    const profile = person ?? userProfileRef.current;
    if (!profile) return [];

    const allOrgs = new Set([...(profile.orgIds || []), ...(profile.proOrgIds || []), ...(profile.adminOrgIds || [])]);

    return Array.from(allOrgs);
  }, []);

  const getAllPendingOrgs = useCallback((person?: Person): string[] => {
    const profile = person ?? userProfileRef.current;
    if (!profile) return [];

    const allOrgs = new Set(
      [...(profile.pendingOrgIds || []), 
      ...(profile.pendingProOrgIds || []), 
      ...(profile.pendingAdminOrgIds || [])]
    );

    return Array.from(allOrgs);
  }, []);
    
  const getAllInactiveOrgs = useCallback((person?: Person): string[] => {
    const profile = person ?? userProfileRef.current;
    if (!profile) return [];

    const allOrgs = new Set(
      [...(profile.inactiveOrgIds || []), 
      ...(profile.inactiveProOrgIds || []), 
      ...(profile.inactiveAdminOrgIds || [])]
    );

    return Array.from(allOrgs);
  }, []);

  const getAdminOrgs = useCallback((person?: Person): string[] => {
    const profile = person ?? userProfileRef.current;
    if (!profile) return [];

    return profile.adminOrgIds || [];
  }, []);

  const getAdminLeagues = useCallback((): string[] => {
    return adminLeagues;
  }, [adminLeagues]);

  const getProOrgs = useCallback((person?: Person): string[] => {
    const profile = person ?? userProfileRef.current;
    if (!profile) return [];

    return profile.proOrgIds || [];
  }, []);

  const getMemberOrgs = useCallback((person?: Person): string[] => {
    const profile = person ?? userProfileRef.current;
    if (!profile) return [];

    return profile.orgIds || [];
  }, []);

  const getAllActiveLeagues = useCallback((): string[] => {
    return activeMemberships?.flatMap((m) => m.leagues || []) || [];
  }, [activeMemberships]);

  const getMemberLeagues = useCallback((): [string, string][] => {
    return memberLeagues;
  }, [memberLeagues]);


  // *************** Membership checks ****************
  const isSelf = useCallback((profile: Person) => {
    return userProfileRef.current?.itemId === profile.itemId;
  }, []);

  const isOrgActive = useCallback((orgId: string, person?: Person): boolean | undefined => {
    const profile = person ?? userProfileRef.current;
    
    if (!profile) {
      return undefined;
    }

    return (
      profile?.orgIds?.includes(orgId) || profile?.adminOrgIds?.includes(orgId) || profile?.proOrgIds?.includes(orgId)
    );
  }, []);

  const isOrgMember = useCallback((orgId: string, person?: Person): boolean => {
    if (!person && !userProfileRef.current) {
      return false;
    }

    if (person) {
      return person.orgIds?.find((org) => org === orgId) ? true : false;
    }

    if (userProfileRef.current?.orgIds?.includes(orgId)) {
      return true;
    }

    return false;
  }, []);

  const isOrgMemberProOrAdmin = useCallback(
    (orgId: string, person?: Person): boolean => {
      const profile = person ?? userProfileRef.current;
      if (!profile) {
        return false;
      }

      return getAllActiveOrgs(profile).includes(orgId) ? true : false;
    },
    [getAllActiveOrgs]
  );

  const isLeagueMember = useCallback(
    async (leagueId: string): Promise<boolean> => {
      if (!userProfileRef.current || isAdminDisabled) {
        return false;
      }

      if (isGod) {
        return true;
      }

      if (isMembershipsLoading) {
        return new Promise((resolve) => {
          const checkMemberships = setInterval(() => {
            if (!isMembershipsLoading && membershipsRef.current) {
              clearInterval(checkMemberships);
              const isMember = memberLeagues.some(([, lid]) => lid === leagueId);
              resolve(isMember);
            }
          }, 100);
        });
      }

      if (!membershipsRef.current) {
        return false;
      }

      return isGod || memberLeagues.some(([, lid]) => lid === leagueId);
    },
    [isAdminDisabled, isGod, isMembershipsLoading, memberLeagues]
  );

  const areInASharedOrg = useCallback(
    (resourceProfile: Person, requesterProfile: Person | null | undefined = userProfileRef.current): boolean => {
      if (isGod || isSelf(resourceProfile)) {
        return true;
      }

      if (!resourceProfile || !requesterProfile) {
        return false;
      }

      if (resourceProfile.orgIds && requesterProfile.orgIds) {
        if (resourceProfile.orgIds?.some((id) => requesterProfile.orgIds?.includes(id))) {
          return true;
        }
      }

      if (resourceProfile.inactiveOrgIds && requesterProfile.adminOrgIds) {
        if (resourceProfile.inactiveOrgIds?.some((id) => requesterProfile.adminOrgIds?.includes(id))) {
          return true;
        }
      }

      return false;
    },
    [isGod, isSelf]
  );

  const isOrgMemberProOrAdminOfAny = useCallback((): boolean | undefined => {
    if (!userProfileRef.current) {
      return undefined;
    }

    const orgIds = [
      ...(userProfileRef.current.proOrgIds || []),
      ...(userProfileRef.current.adminOrgIds || []),
      ...(userProfileRef.current.orgIds || []),
    ];

    if (orgIds.length > 0) {
      return true;
    } else {
      return false;
    }
  }, []);

  const isOrgMemberOfAny = useCallback((): boolean | undefined => {
    if (!userProfileRef.current) {
      return undefined;
    }

    if (userProfileRef.current.orgIds && userProfileRef.current.orgIds.length > 0) {
      return true;
    } else {
      return false;
    }
  }, []);

  // *************** Admin ****************
  const isOrgAdminOfAny = useCallback((): boolean | undefined => {
    if (!userProfileRef.current) {
      return undefined;
    } else if (userProfileRef.current.adminOrgIds && userProfileRef.current.adminOrgIds.length > 0) {
      return true;
    } else {
      return false;
    }
  }, []);

  const isOrgProOfAny = useCallback((): boolean | undefined => {
    if (!userProfileRef.current) {
      return undefined;
    } else if (userProfileRef.current.proOrgIds && userProfileRef.current.proOrgIds.length > 0) {
      return true;
    } else {
      return false;
    }
  }, []);

  const isOrgAdmin = useCallback(
    (orgId: string, person?: Person): boolean => {
      if (!person && (!userProfileRef.current || isAdminDisabled)) {
        return false;
      }

      if (person && person !== userProfileRef.current) {
        return person.adminOrgIds?.find((org) => org === orgId) ? true : false;
      }

      if (isGod || userProfileRef.current?.adminOrgIds?.find((org) => org === orgId)) {
        return true;
      }

      return false;
    },
    [isAdminDisabled, isGod]
  );

  // *************** Pro checks ****************
  const isOrgPro = useCallback((orgId: string, person?: Person): boolean | undefined => {
    const profile = person ?? userProfileRef.current;
    if (!profile) {
      return false;
    }

    if (profile.proOrgIds?.find((org) => org === orgId)) {
      return true;
    }

    return false;
  }, []);

  // *************** League admin checks ****************
  const isLeagueAdminOfAny = useCallback((): boolean | undefined => {
    if (!userProfileRef.current || membershipsRef.current === undefined) {
      return undefined;
    } else if (isOrgAdminOfAny() === true) {
      return true;
    } else if (adminLeagues.length > 0) {
      return true;
    } else {
      return false;
    }
  }, [adminLeagues, isOrgAdminOfAny]);

  const isLeagueAdmin = useCallback(
    (leagueId: string, orgId: string): boolean | undefined => {
      if (!userProfileRef.current) {
        return undefined;
      } else if (!membershipsRef.current || isAdminDisabled) {
        return false;
      }

      return isGod || isOrgAdmin(orgId) || isOrgPro(orgId) || getAdminLeagues().includes(leagueId);
    },
    [isAdminDisabled, isGod, isOrgAdmin, isOrgPro, getAdminLeagues]
  );

  const isEventAdmin = useCallback(
    (orgId: string, leagueId: string, eventId: string): boolean | undefined => {
      // placeholder. will add fancier logic for who can edit an event later.
      return isLeagueAdmin(leagueId, orgId);
    },
    [isLeagueAdmin]
  );

  const hasAdminRightsOverPerson = useCallback(
    (person: Person) => {
      return (
        isGod ||
        isSelf(person) ||
        getAdminOrgs().some((orgId) => person?.orgIds?.includes(orgId) || person?.inactiveOrgIds?.includes(orgId))
      );
    },
    [isGod, isSelf, getAdminOrgs]
  );


  // *************** Capability checks ****************
  const canEditPerson = useCallback(
    (profile: Person): boolean => {
      return isGod || isSelf(profile) || hasAdminRightsOverPerson(profile);
    },
    [isGod, isSelf, hasAdminRightsOverPerson]
  );

  const canEditPersonDetails = useCallback(
    (profile: Person): boolean => {
      return isGod || isSelf(profile);
    },
    [isGod, isSelf]
  );

  const canCreateEvents = useCallback((): boolean => {
    return Boolean(
      isGod ||
        (userProfileRef.current?.adminOrgIds &&
          (userProfileRef.current?.adminOrgIds?.length > 0 || getAdminLeagues().length > 0))
    );
  }, [isGod, getAdminLeagues]);

  const canCreateHandbooks = useCallback(
    (orgId?: string): boolean | undefined => {
      if (!userProfileRef.current) {
        return undefined;
      } else if (orgId) {
        return isOrgAdmin(orgId) || isOrgPro(orgId);
      } else {
        return isOrgAdminOfAny() || isOrgProOfAny();
      }
    },
    [isOrgAdmin, isOrgPro, isOrgAdminOfAny, isOrgProOfAny]
  );

  const canCreateLeagues = useCallback(
    (orgId?: string): boolean | undefined => {
      if (!userProfileRef.current) {
        return undefined;
      } else if (orgId) {
        return isOrgAdmin(orgId) || isOrgPro(orgId);
      } else {
        return isOrgAdminOfAny() || isOrgProOfAny();
      }
    },
    [isOrgAdmin, isOrgPro, isOrgAdminOfAny, isOrgProOfAny]
  );

  const canCreateSeasons = useCallback(
    (orgId?: string, leagueId?: string): boolean | undefined => {
      if (!userProfileRef.current) {
        return undefined;
      } else if (leagueId && orgId) {
        return isLeagueAdmin(leagueId, orgId) || isOrgAdmin(orgId) || isOrgPro(orgId);
      } else if (orgId) {
        return isOrgAdmin(orgId) || isOrgPro(orgId);
      } else {
        return isLeagueAdminOfAny() || isOrgAdminOfAny() || isOrgProOfAny();
      }
    },
    [isLeagueAdmin, isOrgAdmin, isOrgPro, isLeagueAdminOfAny, isOrgAdminOfAny, isOrgProOfAny]
  );

  // *************** Membership management ****************
  const adminDisabled = useCallback((disable: boolean) => {
    setIsAdminDisabled(disable);
  }, []);

  const getLeagueMembership = useCallback(async (leagueId: string, personId?: string): Promise<Membership | null> => {
    const membership = membershipsRef.current.find(
      (m) => m.leagues?.includes(leagueId) && m.itemId === (personId || userProfileRef.current?.itemId)
    );
    return membership || null;
  }, []);

  const getOrgMembership = useCallback(async (orgId: string, personId?: string): Promise<Membership | null> => {
    const membership = membershipsRef.current.find(
      (m) => m.orgId === orgId && m.itemId === (personId || userProfileRef.current?.itemId)
    );
    return membership || null;
  }, []);

  const values = useMemo(() => {
    return {
      // User state
      userProfile: userProfileRef.current,
      isUserLoading,
      // God mode settings
      isAdminDisabled,
      adminDisabled,
      // Getters
      getAllActiveOrgs,
      getAllPendingOrgs,
      getAllInactiveOrgs,
      getAdminOrgs,
      getProOrgs,
      getAdminLeagues,
      getAllActiveLeagues,
      getMemberLeagues,
      getMemberOrgs,
      // Membership checks
      isSelf,
      isOrgActive,
      isOrgMember,
      isOrgMemberProOrAdminOfAny,
      isOrgMemberOfAny,
      isOrgMemberProOrAdmin,
      isLeagueMember,
      areInASharedOrg,
      // Admin checks
      isOrgAdmin,
      isLeagueAdmin,
      isEventAdmin,
      hasAdminRightsOverPerson,
      // Pro checks
      isOrgPro,
      // Capability checks
      canEditPerson,
      canEditPersonDetails,
      canCreateEvents,
      canCreateHandbooks,
      canCreateLeagues,
      canCreateSeasons,
      // Membership management
      activeMemberships,
      memberships,
      getLeagueMembership,
      getOrgMembership,
    };
  }, [
    isUserLoading,
    isAdminDisabled,
    activeMemberships,
    memberships,
    adminDisabled,
    getLeagueMembership,
    getOrgMembership,
    getAllActiveOrgs,
    getAllPendingOrgs,
    getAllInactiveOrgs,
    isEventAdmin,
    isLeagueAdmin,
    isOrgAdmin,
    isOrgPro,
    isLeagueMember,
    isOrgMember,
    isOrgMemberProOrAdminOfAny,
    isOrgMemberOfAny,
    canCreateEvents,
    canCreateHandbooks,
    canCreateLeagues,
    canCreateSeasons,
    canEditPerson,
    canEditPersonDetails,
    areInASharedOrg,
    hasAdminRightsOverPerson,
    isSelf,
    isOrgActive,
    getAdminLeagues,
    getAdminOrgs,
    getAllActiveLeagues,
    getMemberLeagues,
    getMemberOrgs,
    getProOrgs,
    isOrgMemberProOrAdmin,
  ]);

  return <DataContext.Provider value={values}>{children}</DataContext.Provider>;
};
