import { 
  Navigate, 
  useLocation 
} from 'react-router-dom';

import { useAuth } from "../../contextProviders/AuthProvider";

import Hourglass from './Hourglass';

export default function GodRoute(props: { children: React.ReactNode }) {
  const { children } = props
  const { isLoggedIn, isLoggingIn, isGod } = useAuth();

  const location = useLocation();

  return (
    isLoggingIn ? (
      <Hourglass />
    ) : (
      isLoggedIn ? (
        isGod ? (
          <>{children}</>
        ) : (
          <Navigate
            replace={true}
            to="/loadingerror"
            state={{ from: `${location.pathname}${location.search}` }}
          />
        )
      ) : (
        <Navigate
          replace={true}
          to="/login"
          state={{ from: `${location.pathname}${location.search}` }}
        />
      )
    )
  );
}