import { ReactElement, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import GratiPageWrapper from '../components/common/GratiPageWrapper';
import OrgEditor from '../components/org/OrgEditor';
import { useApp } from '../contextProviders/AppProvider';
import { useAuth } from '../contextProviders/AuthProvider';
import { useData } from '../contextProviders/DataProvider';
import { useOrgMutators } from '../dataMutators/useOrgMutators';
import DefaultObjects from '../types/DefaultObjects';
import Org from '../types/Org';

export default function OrgCreatePage(): ReactElement {
  const { setPageName } = useApp();
  const { isGod } = useAuth();
  const { userProfile } = useData();
  const { addOrg } = useOrgMutators();
  const navigate = useNavigate();

  const createNewOrg = (): Org => {
    return {
      ...DefaultObjects.Org,
      creatorId: userProfile?.itemId || '',
    };
  };

  useEffect(() => {
    setPageName('Create Org');
  }, [setPageName]);


  const handleSubmit = (org: Org) => {
    if (org) {
      addOrg(org, true, org.isTest, true);
    }
    navigate(-1);
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const validateUser = (): boolean => {
    return isGod;
  };

  return (
    <GratiPageWrapper validateUser={validateUser}>
      <OrgEditor org={createNewOrg()} isNew={true} handleSubmit={handleSubmit} handleCancel={handleCancel} />
    </GratiPageWrapper>
  );
}
