import { useEffect, useMemo, useState } from 'react';

import { collectionGroup, orderBy, Query, query, where, QueryFieldFilterConstraint } from 'firebase/firestore';

import { useCollectionData } from 'react-firebase-hooks/firestore';

import { useAuth } from '../contextProviders/AuthProvider';
import { useData } from '../contextProviders/DataProvider';
import { EventQueryFilter } from '../enums/EventQueryFilter';
import { firestore } from '../firebase';
import Event from '../types/Event';

interface UseEventsProps {
  filter?: EventQueryFilter | null;
}

export default function useEvents(props: UseEventsProps) {
  const { filter } = props;
  const { isGod } = useAuth();
  const { userProfile, isUserLoading, isOrgMemberProOrAdminOfAny, getAllActiveOrgs } = useData();
  const [hasMemberships, setHasMemberships] = useState(isOrgMemberProOrAdminOfAny());

  // Add state to track the current time period
  const [timeCounter, setTimeCounter] = useState(0);

  useEffect(() => {
    setHasMemberships(isOrgMemberProOrAdminOfAny());
  }, [isOrgMemberProOrAdminOfAny]);

  // Create a single memoized time value that updates with timeCounter
  const currentDatetime = useMemo(() => new Date(Date.now() + timeCounter * 0), [timeCounter]);

  const next24Hours = useMemo(() => {
    const date = new Date(currentDatetime);
    date.setHours(date.getHours() + 24);
    return date;
  }, [currentDatetime]); // Use currentDatetime as the dependency

  // Add effect to update timeCounter every 24 hours
  useEffect(() => {
    const interval = setInterval(() => {
      setTimeCounter((prev) => prev + 1);
    }, 24 * 60 * 60 * 1000); // 24 hours in milliseconds

    return () => clearInterval(interval);
  }, []);

  // *************** Event loaders ***************

  const eventsQuery = useMemo(() => {
    // if user isn't logged in or filter is undefined, wait
    if (!userProfile || filter === undefined || hasMemberships === undefined) return null;

    // if filter is null, caller doesn't want to fetch anything
    if (filter === null) return null;

    // Combine all org IDs into a single array
    const activeOrgIds = getAllActiveOrgs();

    if (activeOrgIds.length === 0) {
      return null;
    }

    const eventConditions: QueryFieldFilterConstraint[] = [];
    const orgConditions: QueryFieldFilterConstraint[] = [];
    const testConditions: QueryFieldFilterConstraint[] = [];

    if (!isGod) {
      testConditions.push(where('isTest', '==', false));
    }

    // If we have any org IDs, add a single where clause
    if (activeOrgIds.length > 0) {
      orgConditions.push(where('orgId', 'in', activeOrgIds));
    }

    const now = new Date(); // Get current date/time *once*
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0); // Start of today
    const tomorrow = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 0, 0, 0, 0); // Start of tomorrow

    switch (filter) {
      case EventQueryFilter.OpenForSignup:
        eventConditions.push(where('signupClosesAt', '>', currentDatetime));
        eventConditions.push(where('signupOpensAt', '<', next24Hours));
        break;
      case EventQueryFilter.Past:
        eventConditions.push(where('endDatetime', '<', today));
        break;
      case EventQueryFilter.Upcoming:
        eventConditions.push(where('startDatetime', '>', today));
        break;
      case EventQueryFilter.Now:
        eventConditions.push(where('endDatetime', '>=', today), where('startDatetime', '<', tomorrow));
        break;
    }

    return query(
      collectionGroup(firestore, 'event') as Query<Event>,
      ...orgConditions,
      ...eventConditions,
      ...testConditions,
      orderBy('startDatetime', 'asc')
    );
  }, [userProfile, filter, isGod, currentDatetime, next24Hours, hasMemberships, getAllActiveOrgs]);

  const [events, isEventsLoading, eventsError] = useCollectionData<Event>(eventsQuery);

  if (!isUserLoading && !userProfile && !isEventsLoading && !eventsError && filter !== undefined) {
    return {
      events: [],
      isEventsLoading: false,
      eventsError: new Error('User must be logged in to view events'),
    };
  }

  if (userProfile && !isEventsLoading && !eventsError && hasMemberships === false && filter !== undefined) {
    return {
      events: [],
      isEventsLoading: false,
      eventsError: new Error('Not authorized to view these events'),
    };
  }

  // if filter is null, return null without error
  if (filter === null) {
    return {
      events: [],
      isEventsLoading: false,
      eventsError: null,
    };
  }

  return {
    events: events || [],
    isEventsLoading: isEventsLoading || isUserLoading,
    eventsError,
  };
}
