import { memo, ReactElement } from 'react';

import Stack from '@mui/material/Stack';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import Event from '../../types/Event';

import EventGolfSettingsEditor from './EventGolfSettingsEditor';

type EventTypeEditorProps = {
  event: Event;
  isGolf: boolean;
  onGolfChange: (isGolf: boolean) => void;
  onUpdate: (updates: Partial<Event>) => void;
  onParticipantOrderChange?: (order: 'time' | 'index') => void;
};

const EventTypeEditor = memo(function EventTypeEditor(props: EventTypeEditorProps): ReactElement {
  const { event, isGolf, onGolfChange, onUpdate } = props;

  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={2} sx={{ display: 'flex', alignItems: 'center' }}>
        <ToggleButtonGroup
          color="primary"
          value={isGolf ? 'golf' : 'social'}
          exclusive
          onChange={(_, value) => {
            if (value) {
              onGolfChange(value === 'golf');
              onUpdate({
                isGolf: value === 'golf',
              });
            }
          }}
          size="small"
          aria-label="Event Type"
        >
          <ToggleButton value="golf">Golf</ToggleButton>
          <ToggleButton value="social">Social</ToggleButton>
        </ToggleButtonGroup>
      </Stack>

      {isGolf && (
        <EventGolfSettingsEditor
          event={event}
          onUpdate={onUpdate}
        />
      )}
    </Stack>
  );
});

export default EventTypeEditor;
