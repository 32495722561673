import { useMemo } from 'react';

import { collection, orderBy, Query, query, where } from 'firebase/firestore';

import { useCollectionData } from 'react-firebase-hooks/firestore';

import { useAuth } from '../contextProviders/AuthProvider';
import { useData } from '../contextProviders/DataProvider';
import { firestore } from '../firebase';
import Registration from '../types/Registration';

interface UseEventPartnerRegsProps {
  orgId?: string;
  eventId?: string;
  partnerId?: string;
}

export default function useEventPartnerRegs(props: UseEventPartnerRegsProps) {
  const { orgId, eventId, partnerId } = props;
  const { isGod } = useAuth();
  const { userProfile, isUserLoading, isOrgActive } = useData();

  const registrationsQuery = useMemo(() => {
    if (!orgId || !eventId || !partnerId) return null;
    if (!isGod && !isOrgActive(orgId)) return null;

    return query(
      collection(firestore, 'org/' + orgId + '/event/' + eventId + '/reg'),
      where('partnerId', '==', partnerId),
      orderBy('datetime')
    ) as Query<Registration>;

  }, [orgId, eventId, partnerId, isGod, isOrgActive]);

  const [regs, isRegsLoading, isRegsError] = useCollectionData<Registration>(
    registrationsQuery
  );

  if (!isUserLoading && !userProfile && !isRegsLoading && !isRegsError) {
    return {
      regs: null,
      isRegsLoading: false,
      isRegsError: new Error('User must be logged in to view event registrations') as Error | null,
    };
  }

  if (!isUserLoading && !isRegsLoading && !isRegsError && !isGod && orgId && !isOrgActive(orgId)) {
    return {
      regs: null,
      isRegsLoading: false,
      isRegsError: new Error('Not authorized to view these registrations') as Error | null,
    };
  }

  return {
    regs: regs ?? [],
    isRegsLoading: isRegsLoading || isUserLoading,
    isRegsError: isRegsError as Error | null,
  };
}
