import { useEffect, useState } from 'react';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import { SxProps, Theme } from '@mui/material/styles';

import AcUnitIcon from '@mui/icons-material/AcUnit';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';

import { useData } from '../../contextProviders/DataProvider';
import useNotices from '../../dataHooks/useNotices';

interface GratiNoticeProps {
  sx?: SxProps<Theme>;
}

export default function GratiNoticeBanner(props: GratiNoticeProps) {
  const { userProfile } = useData();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isSliding, setIsSliding] = useState(false);
  const [slideDirection, setSlideDirection] = useState<'left' | 'right'>('left'); 
  const [noticeVisible, setNoticeVisible] = useState<('none' | 'sliding' | 'block')[]>([]);

  const { notices, isNoticesLoading, noticesError } = useNotices(
    userProfile ? {contextPairs: null, filter: 'upcoming'} : {}
  );

  useEffect(() => {
    if (notices && notices.length > 0) {
      setNoticeVisible(['block', ...Array(notices.length - 1).fill('none')]);
    }
  }, [notices]);

  useEffect(() => {
    if (isSliding === false) {
      setSlideDirection((prev) => (prev === 'left' ? 'right' : 'left'));
      setNoticeVisible((prev) => {
        const newNoticeVisible = [...prev];
        newNoticeVisible[currentIndex] = 'block';
        return newNoticeVisible;
      });
    }
  }, [isSliding, currentIndex]);

  const handleNext = () => {
    // First slide current alert out to the left
    setSlideDirection('left');
    setIsSliding(true);
    setCurrentIndex((prev) => {
      const newIndex = (prev + 1) % notices.length;
      setNoticeVisible((prevNotices) => {
        const newNoticeVisible = [...prevNotices];
        newNoticeVisible[prev] = 'sliding';
        return newNoticeVisible;
      });
      return newIndex;
    });
  };

  const handlePrev = () => {
    // First slide current alert out to the right
    setSlideDirection('right');
    setIsSliding(true);
    setCurrentIndex((prev) => {
      const newIndex = (prev - 1 + notices.length) % notices.length;
      setNoticeVisible((prevNotices) => {
        const newNoticeVisible = [...prevNotices];
        newNoticeVisible[prev] = 'sliding';
        return newNoticeVisible;
      });
      return newIndex;
    });
  };

  const handleExited = (index: number) => {
    setIsSliding(false);
    setNoticeVisible((prevNotices) => {
      const newNoticeVisible = [...prevNotices];
      newNoticeVisible[index] = 'none';
      return newNoticeVisible;
    });
  };

  const getAlertIcon = (noticeType: string) => {
    switch (noticeType) {
      case 'announcement':
        return <InfoIcon />;
      case 'alert':
        return <WarningIcon />;
      case 'calendar':
        return <CalendarMonthIcon />;
      case 'error':
        return <ErrorIcon />;
      case 'frostdelay':
        return <AcUnitIcon />;
      default:
        return <InfoIcon />;
    }
  };
  

  if (!isNoticesLoading && !noticesError) return null;

  if (!notices || notices.length === 0) return <></>;

  return (
    <Box sx={{ ...props.sx }}>
      {notices.length > 1 && (
        <IconButton onClick={handlePrev} sx={{ color: 'primary.onContainer' }}>
          <ChevronLeftIcon />
        </IconButton>
      )}
      {notices.map((notice, index) => (
        <Box
          key={index}
          sx={{
            display: noticeVisible[index] === 'none' ? 'none' : 'block',
            width: '100%',
            height: '100%',
          }}
        >
          <Slide
            direction={slideDirection}
            in={noticeVisible[index] === 'block'}
            timeout={500}
            onExited={() => handleExited(index)}
          >
            <Alert
              icon={getAlertIcon(notice.noticeType)}
              severity="info"
              sx={{
                display: 'flex',
                flexGrow: 1,
                pt: 0,
                pb: 0,
                width: '100%',
                backgroundColor: 'transparent',
                color: 'primary.onContainer',
                '& .MuiAlert-icon': { color: 'primary.onContainer' },
                position: 'relative',
              }}
            >
              <AlertTitle>{notice.title}</AlertTitle>
              {notice.text}
            </Alert>
          </Slide>
        </Box>
      ))}

      {notices.length > 1 && (
        <IconButton onClick={handleNext} sx={{ color: 'primary.onContainer' }}>
          <ChevronRightIcon />
        </IconButton>
      )}
    </Box>
  );
}
