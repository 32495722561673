import { ReactElement, useState } from 'react';

import { updatePassword } from 'firebase/auth';

import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { useAuth } from '../../contextProviders/AuthProvider';
import { validatePassword } from '../../utils/validators';

interface AccountPasswordEditorProps {
  isEditing: boolean;
  onStartEditing: () => void;
  onCancelEditing: () => void;
}

export default function AccountPasswordEditor({
  isEditing,
  onStartEditing,
  onCancelEditing,
}: AccountPasswordEditorProps): ReactElement {
  const { user, reauthenticateUser } = useAuth();
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errorField, setErrorField] = useState<'current' | 'new' | 'confirm' | 'general' | null>(null);
  const [isSuccess, setIsSuccess] = useState(false);

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setPasswordError(null);
    setErrorField(null);

    switch (id) {
      case 'currentPassword':
        setCurrentPassword(value);
        break;
      case 'newPassword':
        setNewPassword(value);
        if (confirmPassword && value !== confirmPassword) {
          setPasswordError('New passwords do not match');
          setErrorField('new');
        } else if (value && !validatePassword(value)) {
          setPasswordError('New password must be at least 8 characters long');
          setErrorField('new');
        }
        break;
      case 'confirmPassword':
        setConfirmPassword(value);
        if (value !== newPassword) {
          setPasswordError('New passwords do not match');
          setErrorField('confirm');
        } else if (value && !validatePassword(value)) {
          setPasswordError('New password must be at least 8 characters long');
          setErrorField('confirm');
        }
        break;
    }
  };

  const handleCancel = () => {
    setCurrentPassword('');
    setNewPassword('');
    setConfirmPassword('');
    setPasswordError(null);
    setErrorField(null);
    setIsUpdating(false);
    setShowCurrentPassword(false);
    setShowNewPassword(false);
    setShowConfirmPassword(false);
    setIsSuccess(false);
    onCancelEditing();
  };

  const handlePasswordSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user) {
      setPasswordError('User is not logged in');
      setErrorField('general');
      return;
    }

    if (newPassword !== confirmPassword) {
      setPasswordError('New passwords do not match');
      setErrorField('confirm');
      return;
    }

    if (!validatePassword(newPassword)) {
      setPasswordError('New password must be at least 8 characters long');
      setErrorField('new');
      return;
    }

    setIsUpdating(true);
    setPasswordError(null);
    setErrorField(null);

    try {
      // First reauthenticate the user
      await reauthenticateUser(currentPassword);

      // Then update the password
      await updatePassword(user, newPassword);

      // If we get here, the password was updated successfully
      setIsSuccess(true);
    } catch (error) {
      console.error('Error updating password:', error);
      if (error instanceof Error) {
        const errorMessage = error.message.toLowerCase();
        console.log('Error message:', errorMessage);

        if (errorMessage.includes('wrong-password') || errorMessage.includes('invalid-credential')) {
          setPasswordError('Current password is incorrect.');
          setErrorField('current');
        } else if (errorMessage.includes('requires-recent-login')) {
          setPasswordError('For security reasons, please log in again to change your password.');
          setErrorField('general');
        } else if (errorMessage.includes('weak-password')) {
          setPasswordError('New password is too weak. Please choose a stronger password.');
          setErrorField('new');
        } else if (errorMessage.includes('network-request-failed')) {
          setPasswordError('Network error. Please check your internet connection and try again.');
          setErrorField('general');
        } else if (errorMessage.includes('too-many-requests')) {
          setPasswordError('Too many attempts. Please try again later.');
          setErrorField('general');
        } else {
          setPasswordError('An unexpected error occurred. Please try again.');
          setErrorField('general');
        }
      } else {
        setPasswordError('An unexpected error occurred. Please try again.');
        setErrorField('general');
      }
    } finally {
      setIsUpdating(false);
    }
  };

  if (!isEditing) {
    return (
      <Container maxWidth="xs">
        <Stack spacing={2}>
          <Typography variant="title" gutterBottom>
            Password Settings
          </Typography>
          <Stack direction="row" spacing={2} alignItems="center">
            <TextField
              fullWidth
              type="password"
              label="Password"
              value="********"
              disabled
              slotProps={{
                input: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end" disabled>
                        <VisibilityOffIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                },
              }}
            />
            <Button variant="outlined" onClick={onStartEditing}>
              Change
            </Button>
          </Stack>
        </Stack>
      </Container>
    );
  }

  if (isSuccess) {
    return (
      <Container maxWidth="xs">
        <Stack spacing={2}>
          <Typography variant="title" gutterBottom>
            Password Settings
          </Typography>
          <Paper sx={{ p: 3, textAlign: 'center' }}>
            <Stack spacing={2}>
              <Typography color="success.main" variant="h6">
                Password Updated Successfully
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Your password has been changed. You can now use your new password to sign in.
              </Typography>
              <Button variant="contained" onClick={handleCancel}>
                Done
              </Button>
            </Stack>
          </Paper>
        </Stack>
      </Container>
    );
  }

  return (
    <Container maxWidth="xs">
      <Stack spacing={2}>
        <Typography variant="title" gutterBottom>
          Password Settings
        </Typography>
        <Typography variant="body2" color="text.secondary" paragraph>
          To change your password, please enter your current password and your new password below.
        </Typography>
        <form onSubmit={handlePasswordSubmit}>
          <Stack spacing={2}>
            <TextField
              fullWidth
              label="Username"
              value={user?.email || ''}
              disabled
              sx={{ display: 'none' }}
              slotProps={{
                input: {
                  autoComplete: 'username',
                },
              }}
            />
            <TextField
              id="currentPassword"
              type={showCurrentPassword ? 'text' : 'password'}
              label="Current Password"
              value={currentPassword}
              onChange={handlePasswordChange}
              fullWidth
              required
              error={errorField === 'current'}
              helperText={errorField === 'current' ? passwordError : ''}
              disabled={isUpdating}
              slotProps={{
                input: {
                  autoComplete: 'current-password',
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle current password visibility"
                        onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                        edge="end"
                        tabIndex={-1}
                      >
                        {showCurrentPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                },
              }}
            />
            <TextField
              id="newPassword"
              type={showNewPassword ? 'text' : 'password'}
              label="New Password"
              value={newPassword}
              onChange={handlePasswordChange}
              fullWidth
              required
              error={errorField === 'new'}
              helperText={errorField === 'new' ? passwordError : ''}
              disabled={isUpdating}
              slotProps={{
                input: {
                  autoComplete: 'new-password',
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle new password visibility"
                        onClick={() => setShowNewPassword(!showNewPassword)}
                        edge="end"
                        tabIndex={-1}
                      >
                        {showNewPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                },
              }}
            />
            <TextField
              id="confirmPassword"
              type={showConfirmPassword ? 'text' : 'password'}
              label="Confirm New Password"
              value={confirmPassword}
              onChange={handlePasswordChange}
              fullWidth
              required
              error={errorField === 'confirm'}
              helperText={errorField === 'confirm' ? passwordError : ''}
              disabled={isUpdating}
              slotProps={{
                input: {
                  autoComplete: 'new-password',
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle confirm password visibility"
                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                        edge="end"
                        tabIndex={-1}
                      >
                        {showConfirmPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                },
              }}
            />
            {errorField === 'general' && (
              <Typography color="error" variant="body2">
                {passwordError}
              </Typography>
            )}
            <Stack direction="row" spacing={2}>
              <Button
                type="submit"
                variant="contained"
                disabled={
                  !currentPassword ||
                  !newPassword ||
                  !confirmPassword ||
                  !!passwordError ||
                  isUpdating ||
                  !validatePassword(newPassword)
                }
              >
                Update Password
              </Button>
              <Button variant="outlined" color="secondary" onClick={handleCancel} disabled={isUpdating}>
                Cancel
              </Button>
            </Stack>
          </Stack>
        </form>
      </Stack>
    </Container>
  );
}
