import { ReactElement, useEffect, useState } from 'react';

import { applyActionCode } from 'firebase/auth';

import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import GratiPageWrapper from '../components/common/GratiPageWrapper';
import Hourglass from '../components/common/Hourglass';
import { useAuth } from '../contextProviders/AuthProvider';
import { useData } from '../contextProviders/DataProvider';
import { auth } from '../firebase';

export default function AccountVerifyEmailChangePage(): ReactElement {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { newEmail } = useParams();
  const oobCode = searchParams.get('oobCode');
  const continueUrl = searchParams.get('continueUrl');
  const { user, updateUserEmail } = useAuth();
  const { userProfile } = useData();
  const [updateError, setUpdateError] = useState<string | null>(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isUpdateSuccessful, setIsUpdateSuccessful] = useState(false);

  useEffect(() => {
    const verifyAndUpdateEmail = async () => {
      console.log('verifyAndUpdateEmail');
      if (!oobCode) {
        setUpdateError('Invalid action code');
        return;
      }

      if (!continueUrl) {
        setUpdateError('Continue URL not found');
        return;
      }

      if (!newEmail) {
        setUpdateError('New email not found');
        return;
      }

      setIsUpdating(true);
      console.log('oobCode: ', oobCode);
      console.log('newEmail: ', newEmail);
      console.log('continueUrl: ', continueUrl);

      try {
        // First verify the email change
        await applyActionCode(auth, oobCode);

        // If we get here, the action code was valid
        if (!user || !userProfile) {
          // User is not logged in, but the action code is valid
          setIsUpdateSuccessful(true);
          return;
        }

        // User is logged in, update their email
        await updateUserEmail(newEmail);
        console.log('updateUserEmail success');
        setIsUpdateSuccessful(true);
      } catch (error) {
        console.error('Error during email verification:', error);
        setUpdateError(error instanceof Error ? error.message : 'Failed to verify email change');
      } finally {
        setIsUpdating(false);
      }
    };

    verifyAndUpdateEmail();
  }, [user, userProfile, updateUserEmail, newEmail, oobCode, continueUrl]);

  return (
    <GratiPageWrapper>
      <Stack spacing={2} alignItems="center">
        {isUpdating ? (
          <>
            <Hourglass />
            <Typography>Updating your email address...</Typography>
          </>
        ) : updateError ? (
          <>
            <Typography color="error">{updateError}</Typography>
            <Typography>
              Your email change was not successful. You can continue using your old email address. Or try again from the{' '}
              <a href="/account" style={{ color: 'inherit' }}>
                account page
              </a>
              .
            </Typography>
          </>
        ) : isUpdateSuccessful ? (
          <>
            <Typography>Your email address has been successfully changed to {newEmail}</Typography>
            <Typography>You need to re-login to resume using Gratigolf!</Typography>
            <Button
              variant="contained"
              onClick={() => {
                if (continueUrl) {
                  const url = new URL(continueUrl);
                  url.searchParams.set('isEmailChange', 'true');
                  navigate(url.pathname + url.search);
                } else {
                  navigate('/login?isEmailChange=true');
                }
              }}
              sx={{ mt: 2 }}
            >
              Login
            </Button>
          </>
        ) : (
          <Hourglass />
        )}
      </Stack>
    </GratiPageWrapper>
  );
}
