import { useMemo } from 'react';

import { and, collection, orderBy, Query, query, QueryFieldFilterConstraint, where } from 'firebase/firestore';

import { useCollectionData } from 'react-firebase-hooks/firestore';

import { useAuth } from '../contextProviders/AuthProvider';
import { useData } from '../contextProviders/DataProvider';
import { firestore } from '../firebase';
import Membership from '../types/Membership';
import { MembershipStatus } from '../types/Membership';
interface UseMembershipsForLeagueProps {
  orgId?: string;
  leagueId?: string;
}

export default function useMembershipsForLeague(props: UseMembershipsForLeagueProps) {
  const { orgId, leagueId } = props;
  const { isGod } = useAuth();
  const { userProfile, isUserLoading, isOrgActive, getAllActiveOrgs } = useData();

  const membershipsQuery = useMemo(() => {
    if (!orgId || !leagueId) return null;
    if (!isGod && !isOrgActive(orgId)) return null;

    const activeOrgIds = getAllActiveOrgs();
    const orgConditions: QueryFieldFilterConstraint[] = activeOrgIds.length > 0 ? [where('orgId', 'in', activeOrgIds)] : [];

    return query(
      collection(firestore, 'org', orgId, 'membership'),
      and(
        where('status', '==', 'active' as MembershipStatus),
        where('leagues', 'array-contains', leagueId),
        ...orgConditions,
      ),
      orderBy('name')
    ) as Query<Membership>;
  }, [orgId, leagueId, isGod, isOrgActive, getAllActiveOrgs]);

  const [memberships, isMembershipsLoading, isMembershipsError] = useCollectionData<Membership>(membershipsQuery);

  if (!isUserLoading && !userProfile && !isMembershipsLoading && !isMembershipsError) {
    return {
      memberships: null,
      isMembershipsLoading: false,
      isMembershipsError: new Error('User must be logged in to view league memberships') as Error | null,
    };
  }

  if (!isUserLoading && !isMembershipsLoading && !isMembershipsError && !isGod && orgId && !isOrgActive(orgId)) {
    return {
      memberships: null,
      isMembershipsLoading: false,
      isMembershipsError: new Error('Not authorized to view these memberships') as Error | null,
    };
  }

  return {
    memberships: memberships ?? null,
    isMembershipsLoading: isMembershipsLoading || isUserLoading,
    isMembershipsError: isMembershipsError as Error | null,
  };
}
