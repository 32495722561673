import { ReactElement } from 'react';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { SxProps, Theme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

import InvitationEntry from '../../types/InvitationEntry';

import OrgMembershipSelector from './OrgMembershipSelector';

interface OrgUnknownInviteEditorProps {
  currentEntry: Omit<InvitationEntry, 'id'>;
  isFirstEntry: boolean;
  isEditing: boolean;
  emailState: {
    isChecking: boolean;
    isInvalid: boolean;
  };
  onNameChange: (name: string) => void;
  onEmailChange: (email: string) => void;
  onEmailValidation: (email: string) => void;
  onMemberChange: (isMember: boolean) => void;
  onProChange: (isPro: boolean) => void;
  onAdminChange: (isAdmin: boolean) => void;
  onAddOrUpdate: () => void;
  onCancel?: () => void;
  isDisabled: boolean;
  sx?: SxProps<Theme>;
}

export default function OrgUnknownInviteEditor(props: OrgUnknownInviteEditorProps): ReactElement {
  const {
    currentEntry,
    isEditing,
    emailState,
    onNameChange,
    onEmailChange,
    onEmailValidation,
    onMemberChange,
    onProChange,
    onAdminChange,
    onAddOrUpdate,
    onCancel,
    isDisabled,
    sx,
  } = props;

  return (
    <Grid container spacing={2} alignItems="center" sx={sx}>
      <Grid container size={{ xs: 9 }}>
        <Grid size={{ xs: 12, sm: 4, md: 4 }} display="flex" alignItems="center">
          <TextField
            id="name"
            label="Name"
            value={currentEntry.name}
            onChange={(e) => onNameChange(e.target.value)}
            size="small"
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 4, md: 4 }} display="flex" alignItems="center">
          <TextField
            id="email"
            label="Email"
            value={currentEntry.email}
            onChange={(e) => onEmailChange(e.target.value)}
            onBlur={(e) => onEmailValidation(e.target.value)}
            error={emailState.isInvalid}
            size="small"
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 2, md: 1 }}>
          <OrgMembershipSelector
            isMember={currentEntry.isMember}
            isPro={currentEntry.isPro}
            isAdmin={currentEntry.isAdmin}
            onMemberChange={onMemberChange}
            onProChange={onProChange}
            onAdminChange={onAdminChange}
          />
        </Grid>
      </Grid>
      <Grid size={{ xs: 3, sm: 2, md: 1 }}>
        <Stack direction="row" spacing={1} justifyContent="flex-start">
          {isEditing && onCancel && (
            <Button
              id="cancel-invitation"
              aria-label="cancel invitation"
              variant="text"
              onClick={onCancel}
              sx={{ minWidth: 'auto' }}
            >
              Cancel
            </Button>
          )}
          <Button
            id="add-invitation"
            aria-label="add invitation"
            variant="outlined"
            onClick={onAddOrUpdate}
            disabled={isDisabled}
            sx={{ minWidth: 'auto' }}
          >
            {isEditing ? 'Update' : 'Add'}
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
}
