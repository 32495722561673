import { ReactElement, useEffect, useState, useCallback, useMemo } from 'react';

import { useParams, useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import GratiCsvTable, { GratiTableColumn, GratiTableRow } from '../components/common/GratiCsvTable';
import GratiPageWrapper from '../components/common/GratiPageWrapper';
import InvitationCsvUpload from '../components/invitation/InvitationCsvUpload';
import OrgSelector from '../components/org/OrgSelector';
import { OrgIdNamePair } from '../components/org/OrgSelector';
import { useApp } from '../contextProviders/AppProvider';
import useOrgsWithAdminOrProRights from '../dataHooks/useOrgsWithAdminOrProRights';
import { useInvitationMutators } from '../dataMutators/useInvitationMutators';
import Invitation from '../types/Invitation';
import Org from '../types/Org';

const CSV_COLUMNS: Array<GratiTableRow<Invitation>> = [
  { field: 'Email', required: true, dbname: 'email', example: 'john.doe@example.com' },
  { field: 'Name', required: true, dbname: 'name', example: 'John Doe' },
  { field: 'GHIN', required: false, dbname: 'ghin', example: '1234567' },
  { field: 'Gender', required: false, dbname: 'appDetails.gender' as keyof Invitation, example: 'male' },
  {
    field: 'Mailing Address',
    required: false,
    dbname: 'appDetails.mailingAddress' as keyof Invitation,
    example: '123 Main St',
  },
  {
    field: 'External App ID',
    required: false,
    dbname: 'appDetails.externalAppId' as keyof Invitation,
    example: '12345',
  },
  {
    field: 'External App Source',
    required: false,
    dbname: 'appDetails.externalAppSource' as keyof Invitation,
    example: 'GG',
  },
  { field: 'Club ID', required: false, dbname: 'appDetails.clubId' as keyof Invitation, example: '789' },
  { field: 'Club Source', required: false, dbname: 'appDetails.clubSource' as keyof Invitation, example: 'Sunriver' },
  { field: 'Member', required: false, dbname: 'isMember' as keyof Invitation, example: 'TRUE/FALSE' },
  { field: 'Pro', required: false, dbname: 'isPro' as keyof Invitation, example: 'TRUE/FALSE' },
  { field: 'Admin', required: false, dbname: 'isAdmin' as keyof Invitation, example: 'TRUE/FALSE' },
];

interface GuideRow {
  property: string;
  [key: string]: string; // Index signature for dynamic fields
}

// For the Guide view
const GUIDE_COLUMNS: Array<GratiTableColumn<GuideRow>> = [
  { field: 'Property', getValue: (row: GuideRow) => row.property },
  ...CSV_COLUMNS.map((col) => ({
    field: col.field,
    getValue: (row: GuideRow) => row[col.field],
  })),
];

// Create transposed rows for the guide
const GUIDE_ROWS: GuideRow[] = [
  { property: 'Required', ...CSV_COLUMNS.reduce((acc, col) => ({ ...acc, [col.field]: col.required }), {}) },
  { property: 'Database Field', ...CSV_COLUMNS.reduce((acc, col) => ({ ...acc, [col.field]: col.dbname }), {}) },
  { property: 'Example', ...CSV_COLUMNS.reduce((acc, col) => ({ ...acc, [col.field]: col.example }), {}) },
];

export default function InvitationUploadPage(): ReactElement {
  const { orgId } = useParams<{ orgId?: string }>();
  const { setPageName } = useApp();
  const [activeStep, setStep] = useState<'upload' | 'review' | 'saving'>('upload');
  const [invitations, setInvitations] = useState<Invitation[]>([]);
  const [selectedOrgIdNamePair, setSelectedOrgIdNamePair] = useState<OrgIdNamePair | undefined>(undefined);
  const [orgName] = useState<string>('');
  const { addInvitations } = useInvitationMutators();
  const [isUploading, setIsUploading] = useState(false);
  const navigate = useNavigate();
  const [resetCounter, setResetCounter] = useState(0);
  const { orgs } = useOrgsWithAdminOrProRights({
    includeProRights: true,
    includeAdminRights: true,
  });

  const orgPairs = useMemo(() => {
    return (
      orgs?.map((org: Org) => ({
        orgId: org.itemId,
        orgName: org.name,
      })) ?? []
    );
  }, [orgs]);

  const steps = [
    { key: 'upload', label: 'Upload CSV' },
    { key: 'review', label: 'Review Invitations' },
    { key: 'save', label: 'Save Invitations' },
  ] as const;

  useEffect(() => {
    setPageName('Invitation Uploader');
  }, [setPageName]);

  useEffect(() => {
    if (orgPairs && orgPairs.length > 0) {
      if (orgId) {
        setSelectedOrgIdNamePair(orgPairs.find((pair) => pair.orgId === orgId));
      } else {
        setSelectedOrgIdNamePair(orgPairs[0]);
      }
    }
  }, [orgPairs, orgId]);

  const handleOrgChange = (orgPair: OrgIdNamePair) => {
    setSelectedOrgIdNamePair(orgPair);
  };

  const handleInvitationsLoaded = useCallback((parsedInvitations: Invitation[]) => {
    console.log('Invitations loaded, count:', parsedInvitations.length);
    setInvitations(parsedInvitations);
    setStep('review');
  }, []);

  const handleUpload = async () => {
    setIsUploading(true);
    if (selectedOrgIdNamePair) {
      try {
        await addInvitations(invitations);
        // Success - redirect or show message
        navigate(`/org-invitations/${selectedOrgIdNamePair.orgId}`);
      } catch (error) {
        console.error('Error uploading invitations:', error);
        // Show error message
      } finally {
        setIsUploading(false);
      }
    } else {
      console.error('Error uploading invitations: No organization selected');
      // Show error message
    }
  };

  const handleClear = () => {
    setInvitations([]);
    setStep('upload');
    setResetCounter((prev) => prev + 1); // Force reset of upload component
  };

  const getNestedValue = (obj: Invitation, path: string): string | number | boolean | undefined => {
    const parts = path.split('.');
    let current: unknown = obj;

    for (const part of parts) {
      if (current === undefined || current === null) {
        return undefined;
      }
      current = (current as { [key: string]: unknown })[part];
    }

    return current as string | number | boolean | undefined;
  };
  
  return (
    <GratiPageWrapper>
      <Stack spacing={2}>
        <Stepper activeStep={steps.findIndex((s) => s.key === activeStep)} sx={{ mb: 4 }}>
          {steps.map((step) => (
            <Step key={step.key}>
              <StepLabel>{step.label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <OrgSelector value={selectedOrgIdNamePair?.orgId ?? null} onChange={handleOrgChange} orgPairs={orgPairs} />

        {activeStep === 'upload' && (
          <GratiCsvTable<GuideRow>
            columns={GUIDE_COLUMNS}
            rows={GUIDE_ROWS}
            description="Your CSV file should have the following columns:"
            hasCsvDownloadAction={true}
          />
        )}

        {activeStep === 'review' && (
          <>
            <GratiCsvTable
              columns={CSV_COLUMNS.map((guide) => ({
                field: guide.field,
                getValue: (invitation: Invitation) => {
                  const value = guide.dbname.toString().includes('.')
                    ? getNestedValue(invitation, guide.dbname.toString())
                    : invitation[guide.dbname as keyof Invitation];
                  return String(value ?? '');
                },
              }))}
              rows={invitations}
              isEditable={true}
              onRowChange={(rowIndex, updatedInvitation) => {
                setInvitations((prev) =>
                  prev.map((invitation, i) => (i === rowIndex ? updatedInvitation : invitation))
                );
              }}
            />

            <Stack alignItems="center" spacing={2}>
              <Typography component="div" variant="body1" sx={{ mt: 2 }}>
                You are uploading {invitations.length} invitations to the <strong>{orgName}</strong> organization.
              </Typography>
              <Stack direction="row" justifyContent="center" spacing={2}>
                <Button
                  loading={isUploading}
                  loadingPosition="start"
                  size="small"
                  startIcon={<CloudUploadIcon />}
                  variant="contained"
                  onClick={handleUpload}
                  sx={{ mt: 2 }}
                >
                  Upload {invitations.length} Invitations
                </Button>
                {invitations?.length > 0 && (
                  <Button variant="outlined" size="small" onClick={handleClear}>
                    Clear Invitations
                  </Button>
                )}
              </Stack>
            </Stack>
          </>
        )}

        {selectedOrgIdNamePair && activeStep === 'upload' && (
          <InvitationCsvUpload
            orgId={selectedOrgIdNamePair.orgId}
            orgName={selectedOrgIdNamePair.orgName}
            mapping={CSV_COLUMNS}
            hasImportAction={true}
            onInvitationsLoaded={handleInvitationsLoaded}
            resetKey={resetCounter}
          />
        )}
      </Stack>
    </GratiPageWrapper>
  );
}
