import { ReactElement } from 'react';

import { Link } from 'react-router-dom';

import Fab from '@mui/material/Fab';

import AddIcon from '@mui/icons-material/Add';

import GratiPageWrapper from '../components/common/GratiPageWrapper';
import { OrgDirectory } from '../components/org/OrgDirectory';
import { useAuth } from '../contextProviders/AuthProvider';

export default function OrgsPage(): ReactElement {
  const { isGod } = useAuth();

  return (
    <GratiPageWrapper>
      <OrgDirectory />
      { isGod &&
        <Fab variant="extended" color="primary" aria-label="add org" component={Link} to="/org-create">
          <AddIcon />
          Add org
        </Fab>}
    </GratiPageWrapper>
  );  
}
