import { ReactElement, useEffect, useState } from 'react';

import { doc, DocumentReference, Timestamp } from 'firebase/firestore';

import { Link } from 'react-router-dom';

import { useDocumentData } from 'react-firebase-hooks/firestore';

import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';

import CancelIcon from '@mui/icons-material/Cancel';

import { useData } from '../../contextProviders/DataProvider';
import { useEventRegistrationMutators } from '../../dataMutators/useEventRegistrationMutators';
import { firestore } from '../../firebase';
import Event from '../../types/Event';
import Registration from '../../types/Registration';
import { DisplayDate, DisplayDates, IsEventOver, IsEventUpcoming, CurrentDateTime } from '../../utils/utils';

import PartnerSelector from './EventPartnerSelector';
interface ConfirmRegisterDialogProps {
  isOpen: boolean;
  isFull: boolean;
  eventTitle: string;
  onCancel: () => void;
  onConfirm: () => void;
}

function ConfirmRegisterDialog(props: ConfirmRegisterDialogProps): ReactElement {
  const { eventTitle, isOpen, isFull, onConfirm, onCancel, ...other } = props;
  return (
    <Dialog open={isOpen} {...other}>
      <DialogTitle>{eventTitle}</DialogTitle>
      <DialogContent>{isFull ? 'Be added to the waitlist?' : 'Confirm you are attending.'}</DialogContent>
      <DialogActions>
        <Button variant="contained" size="small" color="primary" onClick={onConfirm}>
          {isFull ? 'Confirm Waitlist' : 'Confirm Signup'}
        </Button>
        <Button variant="text" size="small" color="secondary" onClick={onCancel}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

interface EventRegBarProps {
  event: Event;
  isFull: boolean;
}

export default function EventRegBar(props: EventRegBarProps): ReactElement {
  const event = props.event;
  const { userProfile } = useData();
  const { addEventRegistration } = useEventRegistrationMutators();
  const [isRegistering, setIsRegistering] = useState(false);
  const [cancelConfirm, setCancelConfirm] = useState(false);
  const [isSignupOpen, setIsSignupOpen] = useState(() => {
    const now = CurrentDateTime();
    const isOpen = now >= event.signupOpensAt && now < event.signupClosesAt;
    console.log('Initial signup window state:', isOpen);
    return isOpen;
  });

  const [registration, isRegistrationLoading, isRegistrationLoadingError] = useDocumentData<Registration>(
    doc(
      firestore,
      'org/' + event.orgId + '/event/' + event.itemId + '/reg/' + userProfile?.itemId
    ) as DocumentReference<Registration>
  );

  useEffect(() => {
    if (event.isMemberMember && registration) {
      console.log('registration: ' + registration.isRegistered);
    }
    if (isRegistrationLoadingError) {
      console.log('Error loading registration');
    }
  }, [event.isMemberMember, registration, isRegistrationLoadingError]);

  // Add effect to check signup window status
  useEffect(() => {
    const now = CurrentDateTime();
    let timeoutId: NodeJS.Timeout;

    console.log('Checking signup window:', {
      now: now.toDate(),
      opensAt: event.signupOpensAt instanceof Timestamp ? event.signupOpensAt.toDate() : null,
      closesAt: event.signupClosesAt instanceof Timestamp ? event.signupClosesAt.toDate() : null,
    });

    if (now < event.signupOpensAt) {
      // Not open yet - set timeout for when it opens
      const msUntilOpen = (event.signupOpensAt as Timestamp).toMillis() - now.toMillis();
      console.log('Setting timer to open signup in', msUntilOpen, 'ms');
      timeoutId = setTimeout(() => {
        console.log('Opening signup window');
        setIsSignupOpen(true);
      }, msUntilOpen);
    } else if (now < event.signupClosesAt) {
      // Currently open - set timeout for when it closes
      const msUntilClose = (event.signupClosesAt as Timestamp).toMillis() - now.toMillis();
      console.log('Setting timer to close signup in', msUntilClose, 'ms');
      timeoutId = setTimeout(() => {
        console.log('Closing signup window');
        setIsSignupOpen(false);
      }, msUntilClose);
    } else {
      console.log('Signup window is closed');
      setIsSignupOpen(false);
    }

    return () => {
      if (timeoutId) {
        console.log('Clearing previous timer');
        clearTimeout(timeoutId);
      }
    };
  }, [event.signupOpensAt, event.signupClosesAt]);

  const handleCancelRegistration = () => {
    if (registration?.playerId) {
      try {
        addEventRegistration(event, false);
        setCancelConfirm(false);
      } catch (error) {
        alert(error);
      }
    }
  };

  const handleRegistration = () => {
    try {
      addEventRegistration(event, true);
    } catch (error) {
      alert(error);
    }
    setIsRegistering(false);
  };

  const styledDate = (): string => {
    if (event) {
      return event.days && event.days.length > 1
        ? DisplayDates(
            event.days.map((day) => (day.datetime as Timestamp).toDate()),
            event.timezone
          )
        : DisplayDate((event.days[0].datetime as Timestamp).toDate(), event.timezone);
    } else {
      return '';
    }
  };

  const styledTitle = (): string => {
    const name = event.name.length > 0 ? ' - ' + event.name : '';
    return styledDate() + ' ' + name;
  };

  if (!userProfile || isRegistrationLoading || isRegistrationLoadingError) {
    return <> </>;
  } else {
    if (IsEventUpcoming(event)) {
      if (registration && registration.isRegistered) {
        return (
          <Stack spacing={1} sx={{ display: 'flex', flexGrow: 1 }}>
            {event.isMemberMember && (
              <PartnerSelector isMemberMember={true} event={event} registration={registration} />
            )}
            {event.isMemberGuest && <PartnerSelector isMemberGuest={true} event={event} registration={registration} />}
            <Stack direction="row" spacing={1} sx={{ display: 'flex', alignItems: 'center' }}>
              {!cancelConfirm && (
                <Button
                  variant="outlined"
                  startIcon={<CancelIcon />}
                  color="secondary"
                  size="small"
                  onClick={() => setCancelConfirm(true)}
                >
                  Cancel Signup
                </Button>
              )}
              {!cancelConfirm && (
                <Alert severity="success" sx={{ flexGrow: 1 }}>
                  You are signed up.
                </Alert>
              )}
              {cancelConfirm && (
                <Stack direction="row" spacing={1}>
                  <Button variant="text" size="small" color="secondary" onClick={() => handleCancelRegistration()}>
                    Confirm Cancel
                  </Button>
                  <Button variant="text" size="small" color="secondary" onClick={() => setCancelConfirm(false)}>
                    Undo Cancel
                  </Button>
                </Stack>
              )}
            </Stack>
          </Stack>
        );
      } else if (isRegistering) {
        return (
          <ConfirmRegisterDialog
            eventTitle={styledTitle()}
            isFull={props.isFull}
            isOpen={isRegistering}
            onCancel={() => setIsRegistering(false)}
            onConfirm={() => handleRegistration()}
          />
        );
      } else if (isSignupOpen) {
        return (
          <Stack spacing={1} sx={{ display: 'flex', flexGrow: 1 }}>
            <Stack direction="row" spacing={1} sx={{ display: 'flex', alignItems: 'center' }}>
              <Button variant="contained" size="small" onClick={() => setIsRegistering(true)}>
                {props.isFull ? 'Join Waitlist' : 'Sign up'}
              </Button>
              <Alert severity="warning" sx={{ flexGrow: 1 }}>
                {props.isFull ? 'Event is full.' : 'You are not signed up.'}
              </Alert>
            </Stack>
          </Stack>
        );
      } else {
        return <></>;
      }
    } else if (event.isGolf) {
      if (IsEventOver(event)) {
        return (
          <Button
            variant="outlined"
            size="small"
            component={Link}
            to="/event"
            state={{ eventId: event.itemId, isNewEvent: false }}
            aria-label="view event results"
          >
            View Results
          </Button>
        );
      } else {
        return (
          <Button
            variant="outlined"
            size="small"
            component={Link}
            to="/event"
            state={{ eventId: event.itemId, isNewEvent: false }}
            aria-label="view event tee timess"
          >
            View Tee Times
          </Button>
        );
      }
    } else {
      return <></>;
    }
  }
}
