import { DocumentReference, DocumentSnapshot, FieldValue } from "firebase/firestore";
import { deleteField, Timestamp, writeBatch } from "firebase/firestore";
import { collection, doc, getDocs } from "firebase/firestore";

import { useAuth } from "../contextProviders/AuthProvider";
import { useData } from "../contextProviders/DataProvider";
import { firestore } from "../firebase";

export const useFirestoreCollectionMutators = () => {
  const { isGod } = useAuth();
  const { userProfile } = useData();
  const getDefaultValue = <T,>(value: T[keyof T], key: keyof T): T[keyof T] => {
    if (value instanceof Timestamp) {
      return Timestamp.now() as T[keyof T];
    } else if (typeof value === 'string') {
      return '' as T[keyof T];
    } else if (typeof value === 'number') {
      return 0 as T[keyof T];
    } else if (typeof value === 'boolean') {
      return false as T[keyof T];
    }
    return value;
  };

  const convertValue = <T,>(key: keyof T, value: T[keyof T], parentDoc: DocumentSnapshot): T[keyof T] => {
    const parentData = parentDoc.data();

    // Common fields first
    if (key === 'itemId') {
      return parentDoc.id as T[keyof T];
    } else if (key === 'creatorId') {
      return userProfile?.itemId as T[keyof T];
    } else if (key === 'createTime') {
      return Timestamp.now() as T[keyof T];
    }
    // Copy matching parent fields with type checking
    if (parentData && key in parentData) {
      const parentValue = parentData[key as string];
      if (typeof parentValue === typeof value) {
        return parentValue as T[keyof T];
      }
    }
    // Default values for remaining fields
    return getDefaultValue(value, key);
  };

  const createSubcollectionForAll = async <T extends { itemId?: string }>(
    collectionPath: string,
    emptyDoc: T
  ): Promise<number> => {
    const [parentCollection, , subcollectionName] = collectionPath.split('/');

    if (!parentCollection || !subcollectionName) {
      throw new Error('Invalid collection path. Format should be: collection/{doc}/subcollection');
    }

    const parentCollectionRef = collection(firestore, parentCollection);
    const querySnapshot = await getDocs(parentCollectionRef);

    const batch = writeBatch(firestore);

    querySnapshot.forEach((parentDoc) => {
      const subcollectionRef = collection(firestore, parentCollection, parentDoc.id, subcollectionName);
      const newDocRef = doc(subcollectionRef, parentDoc.id);

      const newDoc = { ...emptyDoc } as { [K in keyof T]: T[K] };

      (Object.keys(newDoc) as Array<keyof T>).forEach((key) => {
        newDoc[key] = convertValue(key, newDoc[key], parentDoc);
      });
      batch.set(newDocRef as DocumentReference<T>, newDoc);
    });

    await batch.commit();
    return querySnapshot.size;
  };

  const removeSubcollectionForAll = async (collectionPath: string): Promise<number> => {
    // Parse the collection path
    const [parentCollection, , subcollectionName] = collectionPath.split('/');

    if (!parentCollection || !subcollectionName) {
      throw new Error('Invalid collection path. Format should be: collection/{doc}/subcollection');
    }

    // Get all documents from parent collection
    const parentCollectionRef = collection(firestore, parentCollection);
    const querySnapshot = await getDocs(parentCollectionRef);

    // Delete subcollection for each document
    const batch = writeBatch(firestore);
    const deletePromises = querySnapshot.docs.map(async (parentDoc) => {
      const subcollectionRef = collection(firestore, parentCollection, parentDoc.id, subcollectionName);
      const subcollectionDocs = await getDocs(subcollectionRef);

      subcollectionDocs.forEach((doc) => {
        batch.delete(doc.ref);
      });
    });

    // Wait for all subcollection queries to complete
    await Promise.all(deletePromises);
    await batch.commit();

    return querySnapshot.size;
  };


  const removeCollectionField = async (docCollection: string, fieldToRemove: string): Promise<void> => {
    if (!isGod) {
      throw new Error('Permission denied: User is not God');
    }

    try {
      const segments = docCollection.split('/');
      const isSubcollection = segments.length > 1;

      if (isSubcollection) {
        const [parentCollection, wildcard, subcollection] = segments;
        if (wildcard !== '{*}') {
          throw new Error('Use {*} to remove field from all documents in subcollection');
        }

        // Get all parent documents
        const parentDocs = await getDocs(collection(firestore, parentCollection));
        if (parentDocs.empty) {
          throw new Error(`Parent collection ${parentCollection} is empty`);
        }

        // Process each parent's subcollection
        for (const parentDoc of parentDocs.docs) {
          const subRef = collection(firestore, parentCollection, parentDoc.id, subcollection);
          const subDocs = await getDocs(subRef);

          if (!subDocs.empty) {
            const batch = writeBatch(firestore);
            subDocs.forEach((document) => {
              batch.update(document.ref, {
                [fieldToRemove]: deleteField(),
              });
            });
            await batch.commit();
          }
        }
      } else {
        // Original code for top-level collections
        const querySnapshot = await getDocs(collection(firestore, docCollection));
        if (querySnapshot.empty) {
          throw new Error(`Collection ${docCollection} is empty`);
        }

        const batch = writeBatch(firestore);
        querySnapshot.forEach((document) => {
          batch.update(document.ref, {
            [fieldToRemove]: deleteField(),
          });
        });
        await batch.commit();
      }
    } catch (error) {
      console.error('Error removing field:', error);
      throw error;
    }
  };

  const updateCollectionField = async (docCollection: string, fieldToUpdate: string, value: unknown): Promise<void> => {
    if (!isGod) {
      throw new Error('Permission denied: User is not God');
    }

    try {
      const segments = docCollection.split('/');
      const isSubcollection = segments.length > 1;

      if (isSubcollection) {
        const [parentCollection, wildcard, subcollection] = segments;
        console.log("Updating subcollection: " + parentCollection + " " + wildcard + " " + subcollection);
        if (wildcard !== '{*}') {
          throw new Error('Use {*} to update all documents in subcollection');
        }

        // Get all parent documents
        const parentDocs = await getDocs(collection(firestore, parentCollection));
        if (parentDocs.empty) {
          throw new Error(`Parent collection ${parentCollection} is empty`);
        }

        // Process each parent's subcollection
        for (const parentDoc of parentDocs.docs) {
          const subRef = collection(firestore, parentCollection, parentDoc.id, subcollection);
          const subDocs = await getDocs(subRef);

          if (!subDocs.empty) {
            // Type validation using first document
            const sampleDoc = subDocs.docs[0].data();
            const existingValue = sampleDoc[fieldToUpdate];

            if (existingValue !== undefined) {
              const existingType = typeof existingValue;
              const newType = typeof value;

              if (existingType !== newType) {
                if (existingValue instanceof Timestamp && (value instanceof Date || value instanceof Timestamp)) {
                  value = value instanceof Date ? Timestamp.fromDate(value) : value;
                } else {
                  throw new Error(
                    `Type mismatch: Field "${fieldToUpdate}" expects ${existingType}, but got ${newType}`
                  );
                }
              }
            }

            // Create update object outside the loop
            const updateData = {
              [fieldToUpdate]: value as FieldValue | Partial<unknown> | undefined,
            };

            // Update all documents in this subcollection
            const batch = writeBatch(firestore);
            subDocs.forEach((document) => {
              batch.update(document.ref, updateData);
            });
            await batch.commit();
          }
        }
      } else {
        // Original code for top-level collections
        const querySnapshot = await getDocs(collection(firestore, docCollection));
        if (querySnapshot.empty) {
          throw new Error(`Collection ${docCollection} is empty`);
        }

        // ... rest of existing validation and update code
        const sampleDoc = querySnapshot.docs[0].data();
        const existingValue = sampleDoc[fieldToUpdate];

        if (existingValue !== undefined) {
          const existingType = typeof existingValue;
          const newType = typeof value;

          if (existingType !== newType) {
            if (existingValue instanceof Timestamp && (value instanceof Date || value instanceof Timestamp)) {
              value = value instanceof Date ? Timestamp.fromDate(value) : value;
            } else {
              throw new Error(`Type mismatch: Field "${fieldToUpdate}" expects ${existingType}, but got ${newType}`);
            }
          }
        }

        const batch = writeBatch(firestore);
        querySnapshot.forEach((document) => {
          const docRef = doc(firestore, docCollection, document.id);
          batch.update(docRef, {
            [fieldToUpdate]: value as FieldValue | Partial<unknown> | undefined,
          });
        });
        await batch.commit();
      }
    } catch (error) {
      console.error('Error updating field:', error);
      throw error;
    }
  };

  return {
    removeCollectionField,
    updateCollectionField,
    createSubcollectionForAll,
    removeSubcollectionForAll,
  };
};


  /*
  const copyEventToOrg = async (eventId: string, orgId: string) => {
    console.log("Copying event to org: " + eventId + " " + orgId);
    const eventRef = doc(eventsCol, eventId);
    const eventDoc = await getDoc(eventRef);
    try {
      if (eventDoc.exists()) {
        const event = eventDoc.data() as Event;
        const orgEventRef = doc(orgEventsCol, eventId);
        await setDoc(orgEventRef, event);
        const qReg = query(
          collection(firestore, 'event/' + event.itemId + '/reg'),
          orderBy('datetime')
        ) as Query<Registration>;
        const regSnapshot = await getDocs(qReg);
        regSnapshot.forEach(async (reg) => {
          const regRef = doc(firestore, "org/" + orgId + "/event/" + orgEventRef.id + "/reg/" + reg.id);
          const regData = reg.data() as Registration;
          regData.eventId = orgEventRef.id;
          await setDoc(regRef, regData);
        });
        return true;
      } else {
        console.log("No such document!");
          return false;
      }
    } catch (error) {
      console.log(error);
    }
    return false;
  };
  */