import { memo, ReactElement } from 'react';

import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import Hourglass from '../common/Hourglass';

export type OrgIdNamePair = {
  orgId: string;
  orgName: string;
}

export type OrgSelectorProps = {
  value: string | null;
  onChange: (orgPair: OrgIdNamePair) => void;
  orgPairs: OrgIdNamePair[];
  disabled?: boolean;
  helperText?: string;
  isLoading?: boolean;
}

const OrgSelector = memo(function OrgSelector(props: OrgSelectorProps): ReactElement {
  const { value, onChange, disabled, helperText, orgPairs, isLoading } = props;

  if (isLoading) {
    return <Hourglass />;
  }

  if (!orgPairs?.length) {
    return <Typography>No organizations available</Typography>;
  }

  return (
    <TextField
      id="org"
      label="Organization"
      required
      fullWidth
      disabled={disabled}
      helperText={helperText}
      select
      aria-label="Organization"
      aria-required="true"
      value={value}
      onChange={({ target }) => {
        const orgPair = orgPairs.find(orgPair => orgPair.orgId === target.value);
        if (orgPair) {
          onChange(orgPair);
        }
      }}
    >
      {orgPairs.map((orgPair) => (
        <MenuItem key={orgPair.orgId} value={orgPair.orgId}>
          {orgPair.orgName}
        </MenuItem>
      ))}
    </TextField>
  );
});

export default OrgSelector;
