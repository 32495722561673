import { memo, ReactElement, useEffect } from 'react';

import Alert from '@mui/material/Alert';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';

import { useData } from '../../contextProviders/DataProvider';
import useLeagues from '../../dataHooks/useLeagues';
import League from '../../types/League';
import Hourglass from '../common/Hourglass';

interface LeagueSelectorProps {
  value?: string | null;
  defaultValue?: string | null;
  onChange: (league: League | undefined) => void;
  showOrgNames?: boolean;
  disabled?: boolean;
  helperText?: string;
  required?: boolean;
}

export default memo(function LeagueSelector(props: LeagueSelectorProps): ReactElement {
  const { value, defaultValue, onChange, showOrgNames = false, disabled = false, helperText, required = false } = props;
  const { userProfile } = useData();

  const { leagues, isLeaguesLoading, leaguesError } = useLeagues({
    includeOrgAdmined: true,
    includeOrgPro: true,
    includeLeagueAdmined: true,
    includeCatchAll: true,
    person: userProfile,
  });

  // When value changes and leagues are loaded, ensure the onChange handler is called with the correct league
  useEffect(() => {
    if (leagues?.length && value) {
      const league = leagues.find((l) => l.itemId === value);
      if (league) {
        // This ensures the parent component has the full league object
        onChange(league);
      }
    }
  }, [value, leagues, onChange]);

  // Don't render the select until leagues are loaded
  if (isLeaguesLoading) {
    return <Hourglass />;
  }

  if (leaguesError) {
    return <Alert severity="error">Error loading leagues: {leaguesError.message}</Alert>;
  }

  // Don't try to use value/defaultValue until we have leagues to compare against
  const currentValue = leagues?.length
    ? value !== undefined
      ? value || (leagues[0]?.itemId ?? '') // Controlled
      : defaultValue || (leagues[0]?.itemId ?? '') // Uncontrolled
    : '';

  const getDisplayName = (league: League) => {
    return showOrgNames ? `${league.orgName}: ${league.name}` : league.name;
  };

  return (
    <TextField
      select
      required={required}
      fullWidth
      label="League"
      value={currentValue}
      error={required && !currentValue}
      disabled={disabled || isLeaguesLoading}
      helperText={helperText || (required && !currentValue ? 'League is required' : '')}
      onChange={(e) => {
        const newValue = e.target.value || null;
        const league = leagues?.find((l) => l.itemId === newValue);
        onChange(league);
      }}
    >
      {leagues?.map((league) =>
        league ? (
          <MenuItem key={league.itemId} value={league.itemId}>
            {getDisplayName(league)}
          </MenuItem>
        ) : null
      )}
    </TextField>
  );
});
