import { ReactElement, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import DeleteIcon from '@mui/icons-material/Delete';
import EmailIcon from '@mui/icons-material/Email';
import LinkIcon from '@mui/icons-material/Link';

import { useData } from '../../contextProviders/DataProvider';
import useInvitationsForOrg from '../../dataHooks/useInvitationsForOrg';
import { useInvitationMutators } from '../../dataMutators/useInvitationMutators';
import Invitation from '../../types/Invitation';
import Org from '../../types/Org';
import Hourglass from '../common/Hourglass';
import InvitationListItem from '../invitation/InvitationListItem';

interface InvitationListProps {
  org: Org;
  status: 'accepted' | 'pending' | 'rejected' | 'requested';
  showCopyEmailList?: boolean;
}

export default function InvitationList(props: InvitationListProps): ReactElement {
  const { org, status, showCopyEmailList = false } = props;
  const { isOrgAdmin, isOrgPro } = useData();
  const { deleteInvitation } = useInvitationMutators();
  const navigate = useNavigate();

  const [confirmingDeleteId, setConfirmingDeleteId] = useState<string | null>(null);
  const [snackbar, setSnackbar] = useState<{ open: boolean; message: string; severity: 'success' | 'error' }>({
    open: false,
    message: '',
    severity: 'success',
  });

  const {
    invitations,
    isInvitationsLoading: isInvitationsLoading,
    invitationsError: invitationsError,
  } = useInvitationsForOrg({
    orgId: org.itemId,
    status: status as 'pending' | 'accepted' | 'rejected' | 'requested',
  });

  const handleInvitationDelete = (invitation: Invitation) => {
    try {
      deleteInvitation(org.itemId, invitation.itemId);
      setConfirmingDeleteId(null);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCopyEmailList = () => {
    if (!invitations || invitations.length === 0) {
      setSnackbar({
        open: true,
        message: 'No invitations found',
        severity: 'error',
      });
      return;
    }

    const emails = invitations.map((invitation) => invitation.email).filter((email) => email && email.trim() !== '');

    if (emails.length === 0) {
      setSnackbar({
        open: true,
        message: 'No email addresses found',
        severity: 'error',
      });
      return;
    }

    const emailList = emails.join(', ');
    navigator.clipboard
      .writeText(emailList)
      .then(() => {
        setSnackbar({
          open: true,
          message: `Copied ${emails.length} email${emails.length > 1 ? 's' : ''} to clipboard`,
          severity: 'success',
        });
      })
      .catch(() => {
        setSnackbar({
          open: true,
          message: 'Failed to copy email list',
          severity: 'error',
        });
      });
  };

  if (isInvitationsLoading) {
    return <Hourglass />;
  }

  if (invitationsError) {
    return <Alert severity="error">Error loading invitations {invitationsError.message}</Alert>;
  }

  if (!invitations || invitations.length === 0) {
    return <Alert severity="error">No invitations</Alert>;
  }

  const renderActions = (invitation: Invitation) => {
    switch (status) {
      case 'pending':
        return (
          <Stack direction="column" spacing={1}>
            {confirmingDeleteId !== invitation.itemId && (
              <Button
                size="small"
                variant="text"
                color="secondary"
                startIcon={<DeleteIcon />}
                onClick={() => setConfirmingDeleteId(invitation.itemId)}
              >
                Delete
              </Button>
            )}
            {confirmingDeleteId === invitation.itemId && (
              <Stack direction="row" spacing={1}>
                <Button
                  size="small"
                  variant="contained"
                  color="secondary"
                  startIcon={<DeleteIcon />}
                  onClick={() => handleInvitationDelete(invitation)}
                >
                  Confirm
                </Button>
                <Button size="small" variant="text" color="secondary" onClick={() => setConfirmingDeleteId(null)}>
                  Cancel
                </Button>
              </Stack>
            )}
          </Stack>
        );
      case 'accepted':
        return (
          <Stack direction="column" spacing={1}>
            <Button
              size="small"
              variant="text"
              color="secondary"
              startIcon={<LinkIcon />}
              onClick={() => navigate(`/profile/${invitation.personId}`)}
            >
              View Profile
            </Button>
          </Stack>
        );
      default:
        return null;
    }
  };

  if (isOrgAdmin(org.itemId) || isOrgPro(org.itemId)) {
    if (!invitations || invitations.length === 0) {
      return <Box width="100vw" />;
    } else {
      return (
        <Container disableGutters className="BodyContainer">
          <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
            <Typography align="left" gutterBottom variant="headline">
              {props.status.charAt(0).toUpperCase() + props.status.slice(1)}
            </Typography>
            {showCopyEmailList && (
              <Button variant="outlined" startIcon={<EmailIcon />} onClick={handleCopyEmailList}>
                Copy {status.charAt(0).toUpperCase() + status.slice(1)} Email List
              </Button>
            )}
          </Stack>
          <Typography align="left" variant="body1" gutterBottom>
            {status === 'pending' && 'Invitations that the recipient has not responded to.'}
            {status === 'accepted' && 'Invitations that have been accepted by the recipient.'}
            {status === 'rejected' && 'Invitations that have been rejected by the recipient.'}
            {status === 'requested' && 'Invitations that have been requested by the recipient.'}
          </Typography>
          {invitations.flatMap((invitation: Invitation) => {
            return (
              <InvitationListItem
                key={invitation.itemId}
                invitation={invitation}
                checkAuthEmail={true}
                isClickable={false}
                isDividerDisplayed={true}
                renderActions={renderActions(invitation) ?? <></>}
              />
            );
          })}
          <Snackbar
            open={snackbar.open}
            autoHideDuration={6000}
            onClose={() => setSnackbar({ ...snackbar, open: false })}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
            <Alert
              onClose={() => setSnackbar({ ...snackbar, open: false })}
              severity={snackbar.severity}
              sx={{ width: '100%' }}
            >
              {snackbar.message}
            </Alert>
          </Snackbar>
        </Container>
      );
    }
  } else {
    return <Container />;
  }
}
