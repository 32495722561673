import { ReactElement, useState, useEffect } from 'react';

import { Timestamp } from 'firebase/firestore';

import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

import dayjs, { Dayjs } from 'dayjs';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import Course from '../../types/Course';
import { CoursePair } from '../../types/Course';
import EventDay from '../../types/EventDay';
import { DisplayDate, ToDayJS } from '../../utils/utils';
import CourseSelector from '../course/CourseSelector';

export interface EventTimeEditorProps {
  days: EventDay[];
  coursePairs: CoursePair[];
  defaultTime?: Dayjs;
  timezone?: string;
  isGolfEvent?: boolean;
  onChange: (days: EventDay[], coursePairs: CoursePair[]) => void;
}

// Helper function to convert duration (minutes) to a Dayjs object
function durationToDayjs(minutes: number): Dayjs {
  return dayjs('1970-01-01T00:00:00').add(minutes, 'minute');
}

// Helper function to convert a Dayjs object to duration (minutes)
function dayjsToDuration(date: Dayjs): number {
  return date.diff(dayjs('1970-01-01T00:00:00'), 'minute');
}

export default function EventTimeEditor(props: EventTimeEditorProps): ReactElement {
  const {
    days,
    coursePairs,
    defaultTime = dayjs('2000-01-01T09:00:00'),
    timezone = Intl.DateTimeFormat().resolvedOptions().timeZone,
    isGolfEvent = true,
    onChange,
  } = props;

  const [updatedDays, setUpdatedDays] = useState<EventDay[]>(days);

  useEffect(() => {
    setUpdatedDays(days);
  }, [days]);

  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.only('xs'));
  const isSm = useMediaQuery(theme.breakpoints.only('sm'));
  const isSmallScreen = isXS || isSm;
  const isLargeScreen = useMediaQuery(theme.breakpoints.only('lg'));

  const handleCourseChange = (index: number, course: Course) => {
    const newDays = [...updatedDays];
    newDays[index] = { ...newDays[index], courseId: course.itemId };

    // Correctly update coursePairs
    const newCoursePairs = [...coursePairs]; // Create a copy
    const existingPairIndex = newCoursePairs.findIndex((pair) => pair.id === course.itemId);

    if (existingPairIndex === -1) {
      newCoursePairs.push({
        id: course.itemId,
        name: course.name,
        shortName: course.shortName,
        timezone,
      });
    } else {
      newCoursePairs[existingPairIndex] = {
        ...newCoursePairs[existingPairIndex],
        name: course.name,
        shortName: course.shortName,
      };
    }

    setUpdatedDays(newDays);
    onChange(newDays, newCoursePairs);
  };

  if (days.length === 0) {
    return (
      <Stack>
        <Typography>Start Time</Typography>
        <TimePicker value={defaultTime} />
      </Stack>
    );
  } else {
    return (
      <Stack spacing={2} sx={{ pb: 4 }}>
        <Typography variant="title" color="primary.secondary">
          Daily Start Times
        </Typography>
        {days
          .sort((a, b) => (ToDayJS(a.datetime as Timestamp).isBefore(ToDayJS(b.datetime as Timestamp)) ? -1 : 1))
          .map((day, i) => {
            console.log('i: ', i, 'day: ', day);
            return (
              <Grid key={i} container spacing={2}>
                <Grid container size={{ xs: 12, lg: 9, xl: 8 }}>
                  <Stack direction="row" spacing={2} sx={{ display: 'flex', alignItems: 'center' }}>
                    <TimePicker
                      label={DisplayDate(
                        (days[i].datetime as Timestamp).toDate(),
                        timezone,
                        isSmallScreen ? 'M/D/YY' : 'MMM DD, YYYY'
                      )}
                      value={ToDayJS(days[i].datetime as Timestamp, timezone)}
                      onChange={(target) => {
                        if (target != null) {
                          const newDays = [...days];
                          newDays[i].datetime = Timestamp.fromDate(target.toDate());
                          onChange(newDays, coursePairs);
                        }
                      }}
                    />
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        label="Duration"
                        value={durationToDayjs(day.duration)}
                        onChange={(newValue) => {
                          if (newValue) {
                            const newDays = [...updatedDays];
                            newDays[i].duration = dayjsToDuration(newValue);
                            onChange(newDays, coursePairs);
                          }
                        }}
                        views={['hours', 'minutes']}
                        ampm={false}
                        format="HHh mmm"
                      />
                    </LocalizationProvider>
                    {(day.isGolf || isGolfEvent) && (
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={day.isShotgun ? day.isShotgun : false}
                              name="shotgun"
                              onChange={({ target }) => {
                                const newDays = [...updatedDays];
                                newDays[i].isShotgun = target.checked;
                                onChange(newDays, coursePairs);
                              }}
                            />
                          }
                          label='Shotgun'
                          labelPlacement={isSmallScreen ? 'top' : 'start'}
                        />
                      </FormGroup>
                    )}
                  </Stack>
                </Grid>
                <Grid key={i} size={{ xs: 12, lg: 3, xl: 4 }}>
                  {(day.isGolf || isGolfEvent) && (
                    <CourseSelector
                      value={day.courseId ?? ''}
                      shortName={isLargeScreen ? true : false}
                      handleChange={(course) => handleCourseChange(i, course)}
                    />
                  )}
                </Grid>
                {i < days.length - 1 && <Divider sx={{ width: '100%' }} />}
              </Grid>
            );
          })}
      </Stack>
    );
  }
}

/*
              {(day.isGolf || isGolfEvent) && courses && courses.length > 0 && (
                <TextField
                  id="location"
                  label="Location"
                  autoFocus={false}
                  select
                  aria-label="location"
                  aria-required="false"
                  value={days[i].courseId ? days[i].courseId : ""}
                  fullWidth
                  onChange={({ target }) => {
                    const newDays = [...days];
                    newDays[i].courseId = target.value;
                    onChange(newDays);
                  }}
                >
                  {CourseMenu()}
                </TextField>
              )}
*/
