import { memo, ReactElement, useMemo, useEffect } from 'react';

import Alert from '@mui/material/Alert';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListSubheader from '@mui/material/ListSubheader';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useData } from '../../contextProviders/DataProvider';
import useEvents from '../../dataHooks/useEvents';
import { EventQueryFilter } from '../../enums/EventQueryFilter';
import Event from '../../types/Event';
import Hourglass from '../common/Hourglass';

import EventListItem from './EventListItem';
import EventScheduleSection from './EventScheduleSection';

interface EventsBySeason {
  name: string;
  seasons: {
    [seasonId: string]: {
      name: string;
      events: Event[];
    };
  };
}

export interface EventScheduleProps {
  headline?: string;
  isSortedbyLeague?: boolean;
  filter: EventQueryFilter | null;
  selectedEventId?: string;
  onSelectedEventSet?: (event: Event) => void;
}

function EventSchedule(props: EventScheduleProps): ReactElement {
  const { headline, isSortedbyLeague = false, filter = null, selectedEventId, onSelectedEventSet } = props;
  const { userProfile } = useData();
  const { events, isEventsLoading, eventsError } = useEvents(userProfile ? { filter: filter} : {});

  // Call onEventFound when we find the selected event
  useEffect(() => {
    if (selectedEventId && events) {
      const foundEvent = events.find((event) => event.itemId === selectedEventId);
      if (foundEvent) {
        onSelectedEventSet?.(foundEvent);
      }
    }
  }, [selectedEventId, events, onSelectedEventSet]);

  // For league-sorted view
  const sortedEvents = useMemo(() => {
    if (!events) return {};

    const sorted: { [leagueId: string]: EventsBySeason } = {};

    for (const event of events) {
      // if (IsEventOver(event) === isUpcoming) continue;

      const { leagueId, leagueName, seasonId = '0', seasonName = '' } = event;

      if (!sorted[leagueId]) {
        sorted[leagueId] = { name: leagueName, seasons: {} };
      }

      if (!sorted[leagueId].seasons[seasonId]) {
        sorted[leagueId].seasons[seasonId] = { name: seasonName, events: [] };
      }

      sorted[leagueId].seasons[seasonId].events.push(event);
    }

    return sorted;
  }, [events]);

  if (isEventsLoading) return <Hourglass />;

  if (eventsError) {
    console.error('Error loading events', eventsError);
    return <Alert severity="error">Error loading events</Alert>;
  }

  const listContent = isSortedbyLeague
    ? Object.entries(sortedEvents).map(([leagueId, league]) => {
        // Check if this league contains the selected event
        const hasSelectedEvent =
          selectedEventId &&
          Object.values(league.seasons).some((season) =>
            season.events.some((event) => event.itemId === selectedEventId)
          )
            ? true
            : false;
        return (
          <ListItem disableGutters key={`section-${leagueId}`} sx={{ pl: 0, width: '100%' }}>
            <List
              subheader={
                <ListSubheader>
                  <Typography variant="h6" color="primary.main">
                    {league.name}
                  </Typography>
                </ListSubheader>
              }
              sx={{ pl: 0, width: '100%' }}
            >
              {Object.entries(league.seasons).map(([seasonId, season]) => (
                <EventScheduleSection
                  key={`section-${leagueId}-${seasonId}`}
                  title={season.name}
                  events={season.events}
                  isLeagueDisplayed={false}
                  sectionId={seasonId}
                  selectedEventId={selectedEventId}
                  defaultExpanded={hasSelectedEvent}
                />
              ))}
            </List>
          </ListItem>
        );
      })
    : events?.map((event) => (
        <EventListItem
          key={event.itemId}
          event={event}
          isLeagueDisplayed={true}
          isSelected={event.itemId === selectedEventId}
        />
      ));

  return (
    <Stack>
      {headline && events && (events.length > 0) && <Typography variant="h6">{headline}</Typography>}
      {(!events || (events.length === 0)) && <Alert severity="info">No events.</Alert>}
      <List
        sx={{
          width: '100%',
          height: '100%',
          position: 'relative',
          overflow: 'auto',
          p: 0,
          '& ul': { padding: 0 },
          '& li': { padding: 0 },
        }}
      >
        {listContent}
      </List>
    </Stack>
  );
}

export default memo(EventSchedule);
