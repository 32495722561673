import { ReactElement, useCallback, useEffect, useState } from 'react';

import { Link, useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PublishIcon from '@mui/icons-material/Publish';
import UnpublishedIcon from '@mui/icons-material/Unpublished';

import GratiPageWrapper from '../components/common/GratiPageWrapper';
import HandbookDirectory from '../components/handbook/HandbookDirectory';
import { useApp } from '../contextProviders/AppProvider';
import { useData } from '../contextProviders/DataProvider';
import useOrg from '../dataHooks/useOrg';
import { useHandbookMutators } from '../dataMutators/useHandbookMutators';
import Handbook from '../types/Handbook';

export default function HandbooksPage(): ReactElement {
  const { orgId } = useParams();
  const { setPageName } = useApp();
  const { canCreateHandbooks } = useData();
  const { org, isOrgLoading, orgError } = useOrg(orgId ?? '');
  const [openId, setOpenId] = useState<string | null>(null);
  const [confirmingId, setConfirmingId] = useState<string | null>(null);
  const { updateHandbook } = useHandbookMutators();
  const { isOrgAdmin, isOrgPro } = useData();

  useEffect(() => {
    if (!orgId) {
      setPageName('Docs');
    } else if (org) {
      setPageName(`${org.name} Docs`);
    }
  }, [setPageName, orgId, org]);

  const handbookCreatePath = orgId ? `/handbook-create/${orgId}` : `/handbook-create`;

  const renderActions = useCallback(
    (handbook: Handbook) => {
      const isOpen = openId === handbook.itemId;
      const isConfirming = confirmingId === handbook.itemId;

      const handlePublishHandbook = (handbook: Handbook) => {
        updateHandbook({ ...handbook, isPublished: true });
      };

      const handleUnpublishHandbook = (handbook: Handbook) => {
        updateHandbook({ ...handbook, isPublished: false });
      };

      if (!isOrgAdmin(handbook.orgId) && !isOrgPro(handbook.orgId)) {
        return null;
      }

      return {
        isOpen,
        actions: (
          <Stack direction="row" spacing={1}>
            {!isOpen && (
              <IconButton onClick={() => setOpenId(handbook.itemId)}>
                <MoreVertIcon />
              </IconButton>
            )}
            {isOpen && !isConfirming && handbook.isPublished && (
              <>
                <Button
                  size="small"
                  variant="contained"
                  color="secondary"
                  startIcon={<UnpublishedIcon />}
                  onClick={() => setConfirmingId(handbook.itemId)}
                >
                  Unpublish
                </Button>
                <Button size="small" variant="outlined" color="secondary" onClick={() => setOpenId(null)}>
                  Cancel
                </Button>
              </>
            )}
            {isOpen && !isConfirming && !handbook.isPublished && (
              <>
                <Button
                  size="small"
                  variant="contained"
                  color="secondary"
                  startIcon={<PublishIcon />}
                  onClick={() => {
                    handlePublishHandbook(handbook);
                    setOpenId(null);
                  }}
                >
                  Publish
                </Button>
                <Button size="small" variant="outlined" color="secondary" onClick={() => setOpenId(null)}>
                  Cancel
                </Button>
              </>
            )}
            {isConfirming && (
              <Stack direction="row" spacing={1}>
                <Button
                  size="small"
                  variant="contained"
                  color="secondary"
                  startIcon={<UnpublishedIcon />}
                  onClick={() => {
                    handleUnpublishHandbook(handbook);
                    setConfirmingId(null);
                    setOpenId(null);
                  }}
                >
                  Confirm Unpublish
                </Button>
                <Button
                  size="small"
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    setConfirmingId(null);
                    setOpenId(null);
                  }}
                >
                  Cancel
                </Button>
              </Stack>
            )}
          </Stack>
        ),
      };
    },
    [confirmingId, isOrgAdmin, isOrgPro, openId, updateHandbook]
  );

  return (
    <GratiPageWrapper isContentLoading={orgId && isOrgLoading ? true : false}>
      <HandbookDirectory orgId={orgId} renderActions={(handbook) => renderActions(handbook)?.actions ?? null} />
      {canCreateHandbooks() && handbookCreatePath && (
        <Fab variant="extended" color="primary" aria-label="add handbook" component={Link} to={handbookCreatePath}>
          <AddIcon />
          Add handbook
        </Fab>
      )}
      {orgError && (
        <Box>
          <Typography component="body">Error loading org {orgError.message}</Typography>
        </Box>
      )}
    </GratiPageWrapper>
  );
}
