import { ReactElement, useEffect, useState } from 'react';

import { getDownloadURL, ref } from 'firebase/storage';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useApp } from '../contextProviders/AppProvider';
import { useAuth } from '../contextProviders/AuthProvider';
import { imagesRef } from '../firebase';
//import NotVerifiedMsg from "../components/NotVerifiedMsg";

export default function WelcomePage(): ReactElement {
  const welcomeImageFileName = 'welcome.png';
  const [welcomeImageUrl, setWelcomeImageUrl] = useState('');

  const { isLoggedIn } = useAuth();
  const { setPageName } = useApp();

  useEffect(() => {
    setPageName('GratiGolf');
  }, [setPageName]);

  useEffect(() => {
    getDownloadURL(ref(imagesRef, welcomeImageFileName))
      .then((url) => {
        setWelcomeImageUrl(url);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <Stack spacing={2} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Card sx={{ maxWidth: 640 }}>
        <CardContent>
          <Typography gutterBottom variant="h3" component="h3" align="center">
            Less effort, more fun.
          </Typography>
          <Typography variant="h4" component="h4" align="center">
            Simpler, smarter tools for golf leagues, tournaments, and events.
          </Typography>
          <Typography variant="body2" color="surface.contrastText" align="center">
            GratiGolf is built for golfers and clubs alike -- creating better experiences for players, saving you time,
            and helping the next generation fall in love with the sport.
          </Typography>
        </CardContent>

        <CardActions>
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            spacing={2}
            sx={{
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button variant="contained" href="/Apply">
              Learn more and apply
            </Button>
            {!isLoggedIn && (
              <Button variant="text" href="/login">
                Already a member? Login
              </Button>
            )}
            {isLoggedIn && (
              <Button variant="text" href="/">
                Go to home page
              </Button>
            )}
          </Stack>
        </CardActions>
        <CardMedia
          component="img"
          image={welcomeImageUrl}
          alt="Welcome"
          sx={{
            mx: 2, // Horizontal margin of 2
            border: 1, // Add a border
            borderColor: 'divider', // Use theme divider color
            borderRadius: 1, // Slightly rounded corners
            width: 'calc(100% - 2rem)', // Adjust width to account for margins
            opacity: 0.7, // Make the image slightly faded (adjust value as needed)
            filter: 'brightness(1.05)', // Optional: slightly increase brightness to compensate
          }}
        />
      </Card>
    </Stack>
  );
}
