import { ReactElement } from 'react';

import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';

import { useAuth } from '../../contextProviders/AuthProvider';
import Event from '../../types/Event';

interface EventEditorGodActionsProps {
  event: Event;
  onChange: (updates: Partial<Event>) => void; // More generic update handler
}

export default function EventEditorGodActions({ event, onChange }: EventEditorGodActionsProps): ReactElement {
  const { isGod } = useAuth();

  if (!isGod || !event) {
    return <></>;
  }

  return (
    <FormGroup>
      <FormControlLabel
        id="isTest"
        label="Test?"
        control={
          <Switch
            checked={Boolean(event.isTest)}
            color="secondary"
            onChange={(e) => onChange({ isTest: e.target.checked })}
          />
        }
      />
    </FormGroup>
  );
}
