import { ReactElement } from 'react';

import { Link } from "react-router";

import Fab from '@mui/material/Fab';

import AddIcon from '@mui/icons-material/Add';

import GratiPageWrapper from '../components/common/GratiPageWrapper';
import { CourseDirectory } from '../components/course/CourseDirectory';
import { useAuth } from '../contextProviders/AuthProvider';

export default function CoursesPage(): ReactElement {
  const { isGod } = useAuth();
  
  return (
    <GratiPageWrapper>
      <CourseDirectory />
      { isGod &&
        <Fab variant="extended" color="primary" aria-label="add course" component={Link} to="/course-create">
          <AddIcon />
          Add course
        </Fab>}
    </GratiPageWrapper>
    );  
}