import { ReactElement, useState } from 'react';

import { verifyBeforeUpdateEmail } from 'firebase/auth';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import EmailIcon from '@mui/icons-material/Email';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { useAuth } from '../../contextProviders/AuthProvider';
import { validateEmail } from '../../utils/validators';

interface AccountEmailEditorProps {
  isEditing: boolean;
  onStartEditing: () => void;
  onCancelEditing: () => void;
}

export default function AccountEmailEditor({
  isEditing,
  onStartEditing,
  onCancelEditing,
}: AccountEmailEditorProps): ReactElement {
  const { user, authUrl, reauthenticateUser, sendVerifyEmailChange, signOutUser } = useAuth();
  const [newEmail, setNewEmail] = useState('');
  const [confirmEmail, setConfirmEmail] = useState('');
  const [emailError, setEmailError] = useState<string | null>(null);
  const [isChangingEmail, setIsChangingEmail] = useState(false);
  const [emailChangePassword, setEmailChangePassword] = useState('');
  const [isEmailChangeComplete, setIsEmailChangeComplete] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const email = e.target.value;
    setNewEmail(email);
    setEmailError(null);
    setEmailChangePassword(''); // Clear password when email changes

    // Basic format validation during typing
    if (email && email !== user?.email && !validateEmail(email)) {
      setEmailError('Please enter a valid email address');
    }
  };

  const handleConfirmEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const email = e.target.value;
    setConfirmEmail(email);
    if (email !== newEmail) {
      setEmailError('Email addresses do not match');
    } else {
      setEmailError(null);
    }
  };

  const handleCancel = () => {
    setNewEmail('');
    setConfirmEmail('');
    setEmailError(null);
    setEmailChangePassword('');
    setIsEmailChangeComplete(false);
    onCancelEditing();
  };

  const handleSubmit = async () => {
    const email = newEmail || user?.email || '';

    if (!user) {
      setEmailError('User is not logged in.');
      return;
    }

    if (!email || email === user?.email) {
      setEmailError('Please enter a new email address');
      return;
    }

    if (newEmail !== confirmEmail) {
      setEmailError('Email addresses do not match');
      return;
    }

    if (!emailChangePassword) {
      setEmailError('Please enter your password to confirm email change');
      return;
    }

    setIsChangingEmail(true);

    try {
      // Reauthenticate the user
      reauthenticateUser(emailChangePassword)
        .then(() => {
          // Send verification email with continuation URL that includes the new email
          verifyBeforeUpdateEmail(user, newEmail, {
            url: `${authUrl}/account-verify-email-change/${encodeURIComponent(
              newEmail
            )}?continueUrl=${encodeURIComponent(`${authUrl}/login`)}`,
          })
            .then(() => {
              signOutUser();
              console.log('verifyBeforeUpdateEmail success');
              // Log the verification URL for emulator testing
              const verificationUrl = `${authUrl}/account-verify-email-change/${encodeURIComponent(
                newEmail
              )}?continueUrl=${encodeURIComponent(`${authUrl}/login`)}`;
              console.log('Verification URL (for emulator testing):', verificationUrl);
            })
            .catch((error) => {
              console.error('verifyBeforeUpdateEmail error: ', error);
              if (error instanceof Error) {
                if (error.message.includes('auth/email-already-in-use')) {
                  setEmailError('This email address is already in use');
                } else {
                  setEmailError('Failed to send verification email. Please try again.');
                }
              } else {
                setEmailError('Failed to send verification email. Please try again.');
              }
            });
        })
        .catch((error) => {
          console.error('reauthenticateUser error: ', error);
          if (error instanceof Error) {
            if (error.message.includes('auth/wrong-password')) {
              setEmailError('Incorrect password. Please check your password and try again.');
            } else if (error.message.includes('auth/invalid-email')) {
              setEmailError('Invalid email format. Please check your email address.');
            } else if (error.message.includes('auth/user-disabled')) {
              setEmailError('This account has been disabled. Please contact support.');
            } else if (error.message.includes('auth/user-not-found')) {
              setEmailError('Account not found. Please check your email address.');
            } else {
              setEmailError('Authentication failed. Please check your email and password.');
            }
          } else {
            setEmailError('Authentication failed. Please try again.');
          }
          setIsChangingEmail(false);
        });

      setEmailError(null);
      setIsEmailChangeComplete(true);
    } catch (error) {
      console.log('error: ', error);
      if (error instanceof Error) {
        setEmailError(error.message);
      } else {
        setEmailError('An unknown error occurred');
      }
    } finally {
      setIsChangingEmail(false);
    }
  };

  const handleResendVerification = async () => {
    if (!user?.uid) return;
    try {
      await sendVerifyEmailChange(
        `${authUrl}/account-verify-email-change/${encodeURIComponent(newEmail)}?continueUrl=${encodeURIComponent(
          `${authUrl}/login`
        )}`
      );
      setEmailError(null);
    } catch (error) {
      setEmailError('Failed to send verification email. Please try again.');
    }
  };

  if (!isEditing) {
    return (
      <Container maxWidth="xs">
        <Stack spacing={2} maxWidth="xs">
          <Stack direction="row" spacing={2} alignItems="center">
            <TextField fullWidth label="Email Address" value={user?.email || ''} disabled />
            <Button variant="outlined" onClick={onStartEditing}>
              Change
            </Button>
          </Stack>
        </Stack>
      </Container>
    );
  }

  const ChangeEmailInstructions: ReactElement = (
    <Stack spacing={2}>
      <Typography variant="subtitle1" color="primary">
        Changing your email address
      </Typography>
      <Typography variant="body2" color="text.secondary">
        When you change your email address, you are changing the email you use to sign in.
      </Typography>
      <Typography variant="body2" color="text.secondary">
        To do this securely, we need you to do a few extra steps.
      </Typography>
      <Typography variant="body2" color="text.secondary" component="ol" sx={{ pl: 2 }}>
        <li>Enter and confirm your new email address.</li>
        <li>Enter your current password to verify the change.</li>
        <li>Tap Update Email.</li>
        <li>An email is sent to your current email to verify the change.</li>
        <li>Go to your email account, find the email from GratiGolf and Click the link to complete the change</li>
        <li>Sign in with your new email address</li>
      </Typography>
    </Stack>
  );

  if (isEmailChangeComplete) {
    return (
      <Container maxWidth="xs">
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '60vh' }}>
          <Paper
            elevation={3}
            sx={{
              p: 4,
              width: '100%',
              textAlign: 'center',
              backgroundColor: 'background.paper',
            }}
          >
            <Stack spacing={3} alignItems="center">
              <EmailIcon color="primary" sx={{ fontSize: 48 }} />
              <Typography variant="h5" color="primary" gutterBottom>
                Check Your Email
              </Typography>
              <Typography variant="body1" color="text.secondary">
                We&apos;ve sent a verification link to:
              </Typography>
              <Typography variant="body1" fontWeight="bold">
                {user?.email}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Click the link in the email to verify your email change. If you don&apos;t see the email, check your
                spam folder.
              </Typography>
              <Button variant="text" onClick={handleResendVerification} sx={{ textTransform: 'none' }}>
                Resend verification email
              </Button>
            </Stack>
          </Paper>
        </Box>
      </Container>
    );
  }

  return (
    <Container>
      <Stack spacing={2}>
        <Typography variant="title" gutterBottom>
          Email Settings
        </Typography>
        <Grid container spacing={4}>
          <Grid size={{ xs: 12, md: 7 }}>
            <Stack spacing={2}>
              <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                <Paper sx={{ p: 2 }}>{ChangeEmailInstructions}</Paper>
              </Box>
              <TextField
                fullWidth
                label="Current Email Address"
                value={user?.email || ''}
                disabled
                slotProps={{
                  input: {
                    autoComplete: 'username',
                  },
                }}
              />
              <Stack
                component="form"
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
                spacing={2}
              >
                <TextField
                  fullWidth
                  label="New Email Address"
                  value={newEmail}
                  onChange={handleEmailChange}
                  error={!!emailError}
                  helperText={emailError}
                  disabled={isChangingEmail}
                  slotProps={{
                    input: {
                      autoComplete: 'username',
                    },
                  }}
                />
                <TextField
                  fullWidth
                  label="Confirm New Email Address"
                  value={confirmEmail}
                  onChange={handleConfirmEmailChange}
                  error={!!emailError}
                  helperText={emailError}
                  disabled={isChangingEmail}
                  slotProps={{
                    input: {
                      autoComplete: 'username',
                    },
                  }}
                />
                <TextField
                  fullWidth
                  type="password"
                  label="Current Password"
                  value={emailChangePassword}
                  onChange={(e) => setEmailChangePassword(e.target.value)}
                  error={!!emailError}
                  helperText="Enter your password to confirm email change"
                  disabled={isChangingEmail}
                  slotProps={{
                    input: {
                      autoComplete: 'current-password',
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                            edge="end"
                            tabIndex={-1}
                          >
                            {showCurrentPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    },
                  }}
                />
                <Stack direction="row" spacing={2}>
                  <Button
                  type="submit"
                    variant="contained"
                    disabled={
                      !newEmail ||
                      !confirmEmail ||
                      newEmail !== confirmEmail ||
                      !validateEmail(newEmail) ||
                      !emailChangePassword ||
                      !!emailError ||
                      isChangingEmail
                    }
                  >
                    Update Email
                  </Button>
                  <Button variant="outlined" color="secondary" onClick={handleCancel} disabled={isChangingEmail}>
                    Cancel
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          </Grid>
          <Grid size={{ xs: 12, md: 5 }}>
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
              <Paper sx={{ p: 2 }}>{ChangeEmailInstructions}</Paper>
            </Box>
          </Grid>
        </Grid>
      </Stack>
    </Container>
  );
}
