import { ReactElement, useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { useCreateUserWithEmailAndPassword } from 'react-firebase-hooks/auth';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import GolfCourseIcon from '@mui/icons-material/GolfCourse';

import GratiPageWrapper from '../components/common/GratiPageWrapper';
import Hourglass from '../components/common/Hourglass';
import { useApp } from '../contextProviders/AppProvider';
import { useAuth } from '../contextProviders/AuthProvider';
import { useData } from '../contextProviders/DataProvider';
import { validateEmail } from '../utils/validators';

const inputProps = {
  minLength: 6,
};

interface FormState {
  values: {
    displayName: string;
    emailAddress: string;
    orgName: string;
    password: string;
    confirmPassword: string;
  };
  touched: {
    emailAddress: boolean;
    confirmPassword: boolean;
  };
  errors: {
    emailAddress?: string;
    confirmPassword?: string;
  };
}

export default function AcceptOrgInvitationPage(): ReactElement {
  const params = useParams<{
    appId?: string;
    orgName?: string;
    email?: string;
    name?: string;
  }>();

  const navigate = useNavigate();
  const { setPageName } = useApp();
  const { auth, isLoggedIn } = useAuth();
  const { userProfile } = useData();

  // Safely decode parameters with fallbacks
  // const decodedAppId = params.appId ? decodeURIComponent(params.appId) : '';
  const decodedOrgName = params.orgName ? decodeURIComponent(params.orgName) : '';
  const decodedEmail = params.email ? decodeURIComponent(params.email) : '';
  const decodedName = params.name ? decodeURIComponent(params.name) : '';

  const [formState, setFormState] = useState<FormState>({
    values: {
      displayName: decodedName,
      emailAddress: decodedEmail,
      orgName: decodedOrgName,
      password: '',
      confirmPassword: '',
    },
    touched: {
      emailAddress: false,
      confirmPassword: false,
    },
    errors: {},
  });

  const [createUserWithEmailAndPassword, newUser, isCreating, createError] = useCreateUserWithEmailAndPassword(auth);

  useEffect(() => {
    setPageName('Accept Invitation');
  }, [setPageName]);

  useEffect(() => {
    if (newUser && isLoggedIn && userProfile) {
      console.log('Navigation condition met - redirecting to home');
      navigate('/');
    }
    console.log('newUser:', newUser);
    console.log('isLoggedIn:', isLoggedIn);
    console.log('userProfile:', userProfile);
  }, [isLoggedIn, userProfile, newUser, navigate]);

  useEffect(() => {
    if (createError) {
      console.error('createError', createError);
      setFormState((prev) => ({
        ...prev,
        touched: {
          ...prev.touched,
          emailAddress: true,
        },
        errors: {
          ...prev.errors,
          emailAddress:
            createError.code === 'auth/email-already-in-use'
              ? 'Email already in use'
              : createError.code === 'auth/invalid-email'
              ? 'Invalid email address'
              : prev.errors.emailAddress,
        },
      }));
    }
  }, [createError]);

  const handleChange = (field: keyof FormState['values']) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setFormState((prev) => ({
      ...prev,
      values: {
        ...prev.values,
        [field]: value,
      },
    }));

    if (field === 'emailAddress') {
      const isValid = validateEmail(value);
      setFormState((prev) => ({
        ...prev,
        errors: {
          ...prev.errors,
          emailAddress: !isValid ? 'Invalid email address' : undefined,
        },
      }));
    }

    if (field === 'confirmPassword') {
      setFormState((prev) => ({
        ...prev,
        errors: {
          ...prev.errors,
          confirmPassword: value !== prev.values.password ? 'Passwords do not match' : undefined,
        },
      }));
    }

    // Also validate confirm password when password changes
    if (field === 'password') {
      setFormState((prev) => ({
        ...prev,
        errors: {
          ...prev.errors,
          confirmPassword:
            prev.values.confirmPassword && prev.values.confirmPassword !== value ? 'Passwords do not match' : undefined,
        },
      }));
    }
  };

  const handleBlur = (field: keyof FormState['touched']) => () => {
    setFormState((prev) => ({
      ...prev,
      touched: {
        ...prev.touched,
        [field]: true,
      },
    }));

    // Validate confirm password on blur
    if (field === 'confirmPassword') {
      setFormState((prev) => ({
        ...prev,
        errors: {
          ...prev.errors,
          confirmPassword: prev.values.password !== prev.values.confirmPassword ? 'Passwords do not match' : undefined,
        },
      }));
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault(); // Prevent default form submission

    console.log('Form submitted');

    // Validate passwords match
    if (formState.values.password !== formState.values.confirmPassword) {
      setFormState((prev) => ({
        ...prev,
        touched: {
          ...prev.touched,
          confirmPassword: true,
        },
        errors: {
          ...prev.errors,
          confirmPassword: 'Passwords do not match',
        },
      }));
      return;
    }

    if (!isCreating) {
      console.log('Creating user with email:', formState.values.emailAddress);

      try {
        const result = await createUserWithEmailAndPassword(formState.values.emailAddress, formState.values.password);

        console.log('User creation result:', result);

        if (result && result.user) {
          console.log('User created successfully:', result.user);
          // Navigate after successful creation
          navigate('/');
        }
      } catch (error) {
        console.error('Error creating user:', error);
      }
    }
  };

  return (
    <GratiPageWrapper isNoAuthRequired={true}>
      <Stack component="form" onSubmit={handleSubmit} spacing={2} sx={{ maxWidth: 'sm', margin: 'auto', padding: 2 }}>
        <Typography gutterBottom variant="h5" component="h5">
          You are approved to join {decodedOrgName ? 'the ' + decodedOrgName : 'your golf league'} on GratiGolf!
        </Typography>
        <Typography variant="body2" color="surface.contrastText">
          To accept your invitation, you just need to enter your name and email, then create a password.
        </Typography>
        {!isCreating && !newUser && (
          <Stack spacing={2} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <TextField
              fullWidth
              required
              autoFocus={!formState.values.displayName}
              id="displayName"
              label="Name"
              aria-label="name"
              helperText="First and last name"
              value={formState.values.displayName}
              onChange={handleChange('displayName')}
            />

            <TextField
              fullWidth
              required
              error={formState.touched.emailAddress && Boolean(formState.errors.emailAddress)}
              autoFocus={formState.values.displayName && !formState.values.emailAddress ? true : false}
              id="email"
              label="Email"
              onBlur={handleBlur('emailAddress')}
              aria-label="Email"
              helperText={
                formState.touched.emailAddress && formState.errors.emailAddress
                  ? formState.errors.emailAddress
                  : 'Valid email address'
              }
              value={formState.values.emailAddress}
              onChange={handleChange('emailAddress')}
            />

            <TextField
              fullWidth
              required
              autoFocus={formState.values.displayName && formState.values.emailAddress ? true : false}
              id="password"
              type="password"
              label="Password"
              aria-label="Password"
              helperText="At least 6 characters"
              value={formState.values.password}
              inputProps={inputProps}
              onChange={handleChange('password')}
            />

            <TextField
              fullWidth
              required
              id="confirmPassword"
              type="password"
              label="Confirm Password"
              aria-label="Confirm Password"
              error={formState.touched.confirmPassword && Boolean(formState.errors.confirmPassword)}
              helperText={
                formState.touched.confirmPassword && formState.errors.confirmPassword
                  ? formState.errors.confirmPassword
                  : 'Re-enter your password'
              }
              value={formState.values.confirmPassword}
              inputProps={inputProps}
              onChange={handleChange('confirmPassword')}
              onBlur={handleBlur('confirmPassword')}
            />

            <Button
              type="submit"
              variant="contained"
              startIcon={<GolfCourseIcon />}
              disabled={
                !formState.values.displayName ||
                !formState.values.emailAddress ||
                !formState.values.password ||
                !formState.values.confirmPassword ||
                Boolean(formState.errors.emailAddress) ||
                Boolean(formState.errors.confirmPassword)
              }
            >
              Accept
            </Button>
          </Stack>
        )}
        {isCreating && !newUser && (
          <Stack spacing={2} direction="column" alignItems="center" sx={{ py: 4 }}>
            <Hourglass />
            <Typography variant="h6">Creating your profile...</Typography>
          </Stack>
        )}
        {!isCreating && newUser && (
          <Stack spacing={2} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography variant="h6">Your profile has been created. Preparing your home.</Typography>
          </Stack>
        )}
      </Stack>
    </GratiPageWrapper>
  );
}
