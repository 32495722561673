import { ReactElement, ReactNode, useEffect, useState, Suspense, memo, useMemo } from 'react';

import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { useApp } from '../../contextProviders/AppProvider';
import { useData } from '../../contextProviders/DataProvider';
import Person from '../../types/Person';

import GratiGodActions from './GratiGodActions';
import Hourglass from './Hourglass';

interface GratiPageWrapperProps {
  children: ReactNode;
  isContentLoading?: boolean;
  isAuthRequired?: boolean;
  isNoAuthRequired?: boolean;
  validateUser?: (userProfile: Person | null | undefined) => boolean;
  godActions?: ReactElement | null;
  error?: Error | null;
}

const MemoizedGratiGodActions = memo(GratiGodActions);

export default function GratiPageWrapper(props: GratiPageWrapperProps) {
  const { children, validateUser, godActions, error } = props;
  const isNoAuthRequired = props.isNoAuthRequired ?? false;
  const isAuthRequired = props.isAuthRequired ?? !isNoAuthRequired;
  const isContentLoading = props.isContentLoading ?? false;

  const navigate = useNavigate();
  const { updateActions } = useApp();
  const { userProfile, isUserLoading } = useData();

  const memoizedUserProfile = useMemo(() => {
    return isUserLoading ? null : userProfile;
  }, [userProfile, isUserLoading]);

  const [isWrapperLoading, setIsWrapperLoading] = useState(true);
  const [isPageReady, setIsPageReady] = useState(false);

  useEffect(() => {
    return () => {
      updateActions([]);
    };
  }, [updateActions]);

  useEffect(() => {
    setIsWrapperLoading(true);
  }, []);

  useEffect(() => {
    if (!isUserLoading) {
      if (memoizedUserProfile) {
        if (validateUser && !validateUser(memoizedUserProfile)) {
          navigate('/');
        }
        if (isNoAuthRequired) {
          navigate('/');
        }
        setIsWrapperLoading(false);
      } else {
        if (isAuthRequired) {
          navigate('/login');
        }
        setIsWrapperLoading(false);
      }
    }
  }, [memoizedUserProfile, isUserLoading, validateUser, navigate, isAuthRequired, isNoAuthRequired]);

  useEffect(() => {
    if (!isWrapperLoading && !isContentLoading) {
      setIsPageReady(true);
    }
  }, [isWrapperLoading, isContentLoading]);

  if (error) {
    return (
      <Box>
        <Typography component="h6" color="error">
          {error.message}
        </Typography>
      </Box>
    );
  }

  if (!isPageReady) {
    return <Hourglass />;
  } else {
    return (
      <Suspense fallback={<Hourglass />}>
        <>
          {children}
          <MemoizedGratiGodActions pageGodActions={godActions} />
        </>
      </Suspense>
    );
  }
}