import { useEffect, useState } from 'react';

import { checkActionCode, applyActionCode } from 'firebase/auth';

import { useNavigate, useSearchParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import RestoreIcon from '@mui/icons-material/Restore';

import GratiPageWrapper from '../components/common/GratiPageWrapper';
import { useApp } from '../contextProviders/AppProvider';
import { auth } from '../firebase';

export default function AccountRecoverEmailPage() {
  const { setPageName } = useApp();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const oobCode = searchParams.get('oobCode');

  const [email, setEmail] = useState('');
  const [isEmailRestored, setIsEmailRestored] = useState(false);
  const [isCodeValid, setIsCodeValid] = useState<boolean | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    setPageName('Restore Email');
  }, [setPageName]);

  useEffect(() => {
    if (!oobCode) {
      setIsCodeValid(false);
      setError('Missing reset code. Please use the link from your email.');
      return;
    }

    const checkCode = async () => {
      checkActionCode(auth, oobCode)
        .then((info) => {
          if (info['data']['email']) {
            setEmail(info['data']['email']);
            setIsCodeValid(true);
          } else {
            setIsCodeValid(false);
            setError('Invalid or expired restore email link. Please request a new one.');
          }
        })
        .catch((error) => {
          setIsCodeValid(false);
          setError(error.message || 'Invalid or expired reset link. Please request a new one.');
        });
    };

    checkCode();
  }, [oobCode]);

  const handleSubmit = async () => {
    if (!oobCode) {
      setError('Missing reset code. Please use the link from your email.');
      return;
    }

    applyActionCode(auth, oobCode).then(() => {
      setIsEmailRestored(true);
    }).catch((error) => {
      setError(error.message || 'Failed to restore email. Please try again.');
    });
  };

  return (
    <GratiPageWrapper isNoAuthRequired={false} isAuthRequired={false}>
      <>
        {isEmailRestored ? (
          <Stack spacing={2} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography variant="h6" color="primary.text" align="center">
              Your email has been restored successfully!
            </Typography>
            <Typography variant="body1" align="center">
              You can now log in with your previous email address.
            </Typography>
          </Stack>
        ) : isCodeValid === false ? (
          <Stack spacing={2} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <ErrorOutlineIcon color="error" sx={{ fontSize: 60 }} />
            <Typography variant="h6" color="error" align="center">
              {error || 'Invalid restore email link'}
            </Typography>
            <Typography variant="h6" color="primary.text" align="center">
              We were unable to restore your email address. Please login with the new email address.
              Then tap the Profile icon in the upper right and select &quot;Account&quot; to update your email address.
            </Typography>
            <Button variant="contained" onClick={() => navigate('/login')}>
              Login
            </Button>
          </Stack>
        ) : isCodeValid === true ? (
          <Box component="form" onSubmit={handleSubmit} sx={{ maxWidth: 'sm', margin: 'auto' }}>
            <Stack spacing={3} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Typography variant="h6" align="center">
                Click to restore your login email to {email}
              </Typography>

              <Button
                type="submit"
                variant="contained"
                startIcon={<RestoreIcon />}
              >
                Restore Email
              </Button>
            </Stack>
          </Box>
        ) : (
          <Stack spacing={2} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography variant="body1" align="center">
              Verifying retore email link...
            </Typography>
          </Stack>
        )}
      </>
    </GratiPageWrapper>
  );
}
