import { useEffect, useState } from 'react';

import { getAuth, sendPasswordResetEmail } from 'firebase/auth';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import EmailIcon from '@mui/icons-material/Email';
import PasswordIcon from '@mui/icons-material/Password';

import GratiPageWrapper from '../components/common/GratiPageWrapper';
import { useApp } from '../contextProviders/AppProvider';
import { useAuth } from '../contextProviders/AuthProvider';

export default function AccountForgotPasswordPage() {
  const { authUrl } = useAuth();
  const { setPageName } = useApp();
  const [email, setEmail] = useState('');
  const [isEmailSent, setIsEmailSent] = useState(false);

  useEffect(() => {
    setPageName('Forgot Password');
  }, [setPageName]);

  const handleSubmit = async () => {
    console.log('handleSubmit');
    const auth = getAuth();
    const continueUrl = `${authUrl}/account-reset-password`;
    const url = `${authUrl}?continueUrl=${encodeURIComponent(continueUrl)}`;
    console.log('url: ', url);
    console.log('email: ', email);
    sendPasswordResetEmail(auth, email, {
      url,
      handleCodeInApp: true,
    })
      .then(() => {
        setIsEmailSent(true);
      })
      .catch((error) => {
        console.error('Full error:', error);
        if (error.code === 'auth/user-not-found') {
          alert('User not found, try again!');
        } else if (error.code === 'auth/too-many-requests') {
          alert('Too many attempts. Please try again later.');
        } else {
          alert(error.code + ': An error occurred, try again!');
        }
        setEmail('');
      });
  };

  return (
    <GratiPageWrapper isNoAuthRequired={true} isAuthRequired={false}>
      <>
        {isEmailSent ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '60vh' }}>
            <Paper
              elevation={3}
              sx={{
                p: 4,
                maxWidth: 400,
                width: '100%',
                textAlign: 'center',
                backgroundColor: 'background.paper',
              }}
            >
              <Stack spacing={3} alignItems="center">
                <EmailIcon color="primary" sx={{ fontSize: 48 }} />
                <Typography variant="h5" color="primary" gutterBottom>
                  Check Your Email
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  We&apos;ve sent a password reset link to:
                </Typography>
                <Typography variant="body1" fontWeight="bold">
                  {email}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Click the link in the email to reset your password. If you don&apos;t see the email, check your spam
                  folder.
                </Typography>
              </Stack>
            </Paper>
          </Box>
        ) : (
          <Box
            component="form"
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <Stack spacing={2} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <TextField
                fullWidth
                required
                id="email"
                label="email"
                aria-label="email"
                placeholder="name@email.com"
                helperText="Email address to reset password"
                onChange={({ target }) => setEmail(target.value)}
              />
              <Button type="submit" variant="contained" startIcon={<PasswordIcon />}>
                Reset Password
              </Button>
            </Stack>
          </Box>
        )}
      </>
    </GratiPageWrapper>
  );
}
