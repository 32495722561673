import { ReactElement, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import GratiPageWrapper from '../components/common/GratiPageWrapper';
import LeagueEditor from '../components/league/LeagueEditor';
import { useApp } from '../contextProviders/AppProvider';
import { useAuth } from '../contextProviders/AuthProvider';
import { useData } from '../contextProviders/DataProvider';
import { useCourseMutators } from '../dataMutators/useCourseMutators';
import Course from '../types/Course';
import DefaultObjects from '../types/DefaultObjects';
import CourseEditor from '../components/course/CourseEditor';

export default function CourseCreatePage(): ReactElement {
  const navigate = useNavigate();
  const { setPageName } = useApp();
  const { isGod } = useAuth();
  const { userProfile } = useData();
  const { addCourse } = useCourseMutators();

  useEffect(() => {
    setPageName('Create League');
  }, [setPageName]);

  const createNewCourse = (): Course => {
    return {
      ...DefaultObjects.Course,
      creatorId: userProfile?.itemId || '',
    };
  };

  const handleSubmit = (course: Course) => {
    if (course) {
      addCourse(course).then((addedCourse) => {
        if (addedCourse) {
          navigate('/course/' + addedCourse.itemId, { 
            replace: true,
          });
        } else {
          console.error("Course not added: ", course);
        }
      });
    } else {
      console.error("Course not added: ", course);
      navigate(-1);
    }
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const validateUser = () => {
    return isGod;
  };

  return (
    <GratiPageWrapper validateUser={validateUser}>
      <Box>
        <CourseEditor course={createNewCourse()} onCancel={handleCancel} onSubmit={handleSubmit} />
      </Box>
    </GratiPageWrapper>
  );
}
