import { ReactElement, useState } from "react";

import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";

import { useAuth } from "../../contextProviders/AuthProvider";
import Handbook from "../../types/Handbook";

interface HandbookEditorGodActionsProps {
  handbook: Handbook | null;
  onSetPermanentDelete: (isPermanentDelete: boolean) => void;
  onChange: (updates: Partial<Handbook>) => void; // More generic update handler
}

export default function HandbookEditorGodActions(props: HandbookEditorGodActionsProps): ReactElement {
  const { handbook, onSetPermanentDelete, onChange } = props;
  const [isPermanentDelete, setIsPermanentDelete] = useState(false);
  const { isGod } = useAuth();

  if (!isGod || !handbook) {
    return <></>;
  }

  return (
    <Stack
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={2}
    >
      <FormGroup>
        <FormControlLabel
          id="isTest"
          value="top"
          aria-label="Is a test league"
          aria-required="false"
          label="Test?"
          control={
            <Switch
              id={'isTest' + handbook.isTest}
              checked={handbook.isTest}
              color="secondary"
              onChange={(e) => onChange({ isTest: e.target.checked })}
            />
          }
        />
      </FormGroup>
      <FormGroup>
        <FormControlLabel
          id="isPermanentDelete"
          value="top"
          aria-label="Is the delete permanent?"
          aria-required="false"
          label="Is a Delete Permanent?"
          control={
            <Switch
              id={'isPermanentDelete' + isPermanentDelete}
              checked={isPermanentDelete}
              color="secondary"
              onChange={(e) => { 
                setIsPermanentDelete(e.target.checked);
                onSetPermanentDelete(e.target.checked);
              }}
            />
          }
        />
      </FormGroup>
    </Stack>
  );
}