import { memo, ReactElement } from 'react';

import Alert from '@mui/material/Alert';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';

import useSeasons from '../../dataHooks/useSeasons';
import Season from '../../types/Season';
import Hourglass from '../common/Hourglass';

interface SeasonSelectorProps {
  value?: string | null;
  defaultValue?: string | null;
  leagueId?: string | null;
  orgId?: string | null;
  onChange: (season: Season | undefined) => void;
  disabled?: boolean;
  helperText?: string;
  required?: boolean;
}

export default memo(function SeasonSelector(props: SeasonSelectorProps): ReactElement {
  const { value, onChange, disabled = false, helperText, required = false, leagueId, orgId } = props;

  console.log('leagueId', leagueId, 'orgId', orgId);
  const { seasons, isSeasonsLoading, seasonsError } = useSeasons({
    includeCatchAll: true,
    leagueId: leagueId,
    orgId: orgId,
  });

  // Don't render the select until seasons are loaded
  if (isSeasonsLoading) {
    return <Hourglass />;
  }

  if (seasonsError) {
    return <Alert severity="error">Error loading seasons: {seasonsError.message}</Alert>;
  }

  // Determine the current value for the controlled component
  const currentValue = value || '';

  return (
    <TextField
      select
      required={required}
      fullWidth
      label="Season"
      value={currentValue}
      error={required && !currentValue}
      disabled={disabled || isSeasonsLoading}
      helperText={helperText || (required && !currentValue ? 'Season is required' : '')}
      onChange={(e) => {
        const newValue = e.target.value || null;
        const season = seasons?.find((s) => s.itemId === newValue);
        onChange(season);
      }}
    >
      {seasons?.map((season) =>
        season ? (
          <MenuItem key={season.itemId} value={season.itemId}>
            {season.name}
          </MenuItem>
        ) : null
      )}
    </TextField>
  );
});
