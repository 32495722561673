import { ReactElement, SyntheticEvent, useEffect, useMemo, useState } from 'react';

import { UploadResult } from 'firebase/storage';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import SaveIcon from '@mui/icons-material/Save';

import { useAuth } from '../../contextProviders/AuthProvider';
import { useData } from '../../contextProviders/DataProvider';
import useMembershipsForOrg from '../../dataHooks/useMembershipsForOrg';
import useOrgsWithAdminOrProRights from '../../dataHooks/useOrgsWithAdminOrProRights';
import League from '../../types/League';
import Membership from '../../types/Membership';
import Org from '../../types/Org';
import Season from '../../types/Season';
import { CompetitiveLevels } from '../../utils/Compete';
import GratiImageUpload from '../common/GratiImageUpload';
import Hourglass from '../common/Hourglass';
import OrgSelector from '../org/OrgSelector';
import SeasonsEditor from '../season/SeasonsEditor';

export type LeagueEditorProps = {
  league: League;
  seasons?: Season[];
  isNew?: boolean;
  onCancel: () => void;
  onDelete?: (league: League) => void;
  onSubmit: (league: League, updatedMemberships: Partial<Membership>[]) => void;
  onSeasonAdd?: (season: Season) => void;
  onSeasonDelete?: (season: Season) => void;
  onSeasonChange?: (season: Partial<Season>) => void;
};

export default function LeagueEditor(props: LeagueEditorProps): ReactElement {
  const { isNew = false, onCancel, onDelete, onSubmit, onSeasonAdd, onSeasonDelete, onSeasonChange, seasons } = props;

  const { isGod } = useAuth();
  const { isLeagueAdmin, isOrgAdmin, isOrgPro } = useData();

  const [competitiveHover, setCompetitiveHover] = useState(-1);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [league, setLeague] = useState<League>(props.league);
  const [selectedMembers, setSelectedMembers] = useState<Set<string>>(new Set());

  const { orgs, isOrgsLoading, orgsError } = useOrgsWithAdminOrProRights({
    includeProRights: true,
    includeAdminRights: true,
  });

  const { memberships, isMembershipsLoading, isMembershipsError } = useMembershipsForOrg({
    orgId: league.orgId,
  });

  // Initialize selected members from existing league members
  useEffect(() => {
    if (memberships) {
      if (league.includeAllOrgMembers) {
        // When includeAllOrgMembers is true, select all members
        setSelectedMembers(new Set(memberships.map((m) => m.itemId)));
      } else {
        // When includeAllOrgMembers is false, only select existing league members
        const existingMembers = new Set(
          memberships.filter((m) => m.leagues?.includes(league.itemId)).map((m) => m.itemId)
        );
        setSelectedMembers(existingMembers);
      }
    }
  }, [memberships, league.itemId, league.includeAllOrgMembers]);

  // Get org pairs from orgs user can admin
  const orgPairs = useMemo(() => {
    if (!isNew || !orgs) return [];
    return orgs.map((org: Org) => ({
      orgId: org.itemId,
      orgName: org.name,
    }));
  }, [orgs, isNew]);

  const handleImageUpdated = (snapshot: UploadResult | null) => {
    if (league) {
      setLeague({ ...league, logo: snapshot ? snapshot.ref.name : '' });
    }
    console.log('Uploaded a blob or file!');
  };

  const handleMemberToggle = (memberId: string) => {
    const newSelected = new Set(selectedMembers);
    if (newSelected.has(memberId)) {
      newSelected.delete(memberId);
    } else {
      newSelected.add(memberId);
    }
    setSelectedMembers(newSelected);
  };

  const handleSubmit = async () => {
    try {
      if (!memberships) return;

      // Create array of membership updates
      const membershipUpdates: Partial<Membership>[] = [];

      if (!league.includeAllOrgMembers) {
        // Only handle membership updates when includeAllOrgMembers is false
        memberships.forEach((membership) => {
          const isSelected = selectedMembers.has(membership.itemId);
          const hasLeague = membership.leagues?.includes(league.itemId);

          if (isSelected !== hasLeague) {
            membershipUpdates.push({
              itemId: membership.itemId,
              leagues: isSelected ? [league.itemId] : [], // We'll handle the array operations on the server side
            });
          }
        });
      }

      onSubmit(league, membershipUpdates);
    } catch (error) {
      console.error('Error updating league:', error);
      // TODO: Show error to user
    }
  };

  const handleCancel = () => {
    onCancel();
  };

  const handleDelete = () => {
    setDeleteConfirm(false);
    if (onDelete) {
      onDelete(league);
    }
  };

  if (isNew && isOrgsLoading) {
    return <Hourglass />;
  }

  if (isNew && orgsError) {
    console.error('Error loading organizations:', orgsError);
  }

  if (league) {
    if (isOrgAdmin(league.orgId) || isOrgPro(league.orgId) || isLeagueAdmin(league.itemId, league.orgId)) {
      return (
        <Box
          component="form"
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <Stack spacing={2}>
            <Typography variant="headline">{isNew ? 'Create League' : 'Edit League'}</Typography>
            {isNew ? (
              <OrgSelector
                value={league.orgId}
                orgPairs={orgPairs}
                onChange={(orgPair) => setLeague({ ...league, orgId: orgPair.orgId, orgName: orgPair.orgName })}
              />
            ) : (
              <Stack spacing={1}>
                <Typography variant="label" component="span">
                  Organization
                </Typography>
                <Typography variant="body1" component="span" sx={{ pb: 1}}>
                  {league.orgName}
                </Typography>
              </Stack>
            )}
            <TextField
              id="name"
              label="League Name"
              aria-label="League Name"
              aria-required="true"
              value={league.name}
              onChange={({ target }) => setLeague({ ...league, name: target.value })}
            />
            <GratiImageUpload
              onImageUpdated={handleImageUpdated}
              storageImageFileName={league.itemId + '-logo'}
              label="Logo"
            />
            <TextField
              id="description"
              label="Description"
              aria-label="Description"
              aria-required="true"
              value={league.description}
              onChange={({ target }) => setLeague({ ...league, description: target.value })}
            />
            <Stack direction="row">
              <Typography variant="body1" sx={{ pr: 2 }}>
                Competition{' '}
              </Typography>
              <Box
                sx={{
                  width: 200,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Rating
                  name="hover-feedback"
                  aria-label="Competitive Level"
                  value={league.competitionLevel}
                  precision={1}
                  icon={<EmojiEventsIcon fontSize="inherit" />}
                  getLabelText={(value: number) => `$Competitive Level ${value}`}
                  onChange={(_event: SyntheticEvent, newValue: number | null) => {
                    setLeague({ ...league, competitionLevel: newValue ? newValue : 0 });
                  }}
                  onChangeActive={(_event: SyntheticEvent, competitiveHover: number) => {
                    setCompetitiveHover(competitiveHover);
                  }}
                  emptyIcon={<EmojiEventsIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                />
                {league.competitionLevel !== null && (
                  <Box sx={{ ml: '1rem' }}>
                    {CompetitiveLevels[competitiveHover !== -1 ? competitiveHover : league.competitionLevel]}
                  </Box>
                )}
              </Box>
            </Stack>

            <Stack spacing={1}>
              <Typography variant="subtitle1">League Members</Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={league.includeAllOrgMembers}
                    onChange={(e) => {
                      setLeague({ ...league, includeAllOrgMembers: e.target.checked });
                      if (e.target.checked && memberships) {
                        // When checked, select all members
                        setSelectedMembers(new Set(memberships.map((m) => m.itemId)));
                      } else {
                        // When unchecked, clear selection
                        setSelectedMembers(new Set());
                      }
                    }}
                  />
                }
                label="Include all org members in this league."
              />
                {isMembershipsLoading ? (
                  <Hourglass />
                ) : isMembershipsError ? (
                  <Typography color="error">{isMembershipsError.message}</Typography>
                ) : memberships ? (
                  <Grid container spacing={2}>
                    {memberships.map((membership) => (
                      <Grid key={membership.itemId} size={{ xs: 6, sm: 4, md: 3, lg: 2 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <Checkbox
                            checked={selectedMembers.has(membership.itemId)}
                            onChange={() => handleMemberToggle(membership.itemId)}
                            disabled={league.includeAllOrgMembers}
                          />
                          <Typography>{membership.name}</Typography>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                ) : null}
            </Stack>

            {(onSeasonAdd || onSeasonChange || onSeasonDelete) && (
              <SeasonsEditor
                league={league}
                seasons={seasons}
                onAdd={onSeasonAdd}
                onDelete={onSeasonDelete}
                onChange={onSeasonChange}
              />
            )}
          </Stack>

          <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2} mt={8}>
            <Button size="medium" variant="contained" color="primary" startIcon={<SaveIcon />} type="submit">
              Save
            </Button>
            <Button
              size="medium"
              variant="outlined"
              color="secondary"
              startIcon={<CancelIcon />}
              onClick={() => handleCancel()}
            >
              Cancel
            </Button>
            {(isOrgAdmin(league.orgId) || isOrgPro(league.orgId)) && (
              <>
                <Button
                  size="medium"
                  variant="text"
                  color="secondary"
                  startIcon={<DeleteIcon />}
                  onClick={() => setDeleteConfirm(true)}
                >
                  Delete
                </Button>
                {deleteConfirm && (
                  <span>
                    <Button size="large" onClick={() => handleDelete()}>
                      Confirm
                    </Button>
                    <Button size="large" onClick={() => setDeleteConfirm(false)}>
                      Cancel
                    </Button>
                  </span>
                )}
              </>
            )}
          </Stack>
          {isGod && league && (
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}
              sx={{ backgroundColor: 'tertiary.container', color: 'tertiary.onContainer', mt: 2, p: 1 }}
            >
              <FormGroup>
                <FormControlLabel
                  id="isTest"
                  value="top"
                  aria-label="Is a test league"
                  aria-required="false"
                  label="Test?"
                  control={
                    <Switch
                      id={'isTest' + league.isTest}
                      checked={league.isTest}
                      color="secondary"
                      onChange={(e) => setLeague({ ...league, isTest: e.target.checked })}
                    />
                  }
                />
              </FormGroup>
            </Stack>
          )}
        </Box>
      );
    } else {
      return <Typography variant="headline">Not authorized to edit this league</Typography>;
    }
  } else {
    return <></>;
  }
}
