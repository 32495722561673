import { ReactElement, useEffect, } from "react";

import { useNavigate } from "react-router";
import { useSearchParams } from "react-router";

import Container from '@mui/material/Container';

import { useAuth } from "../contextProviders/AuthProvider";

export default function AccountEmailVerifiedPage(): ReactElement {
  const { auth } = useAuth(); 
  const navigate = useNavigate();
  const [queryParameters] = useSearchParams()

  useEffect(() => {
    const isConfirmingEmail = queryParameters.get("confirm_email");
    if (auth.currentUser) {
      auth.currentUser.reload()
      .then (() => {
        auth.currentUser?.getIdToken(!!isConfirmingEmail).then(() => {
          // Ref
        })
      .then (() => {
        navigate("/");
      })
      })
    }
  });

  return (
    <Container>
    </Container>
  );
}
