import { ReactElement, useState, useEffect } from 'react';

import { getDownloadURL } from 'firebase/storage';
import { ref } from 'firebase/storage';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useAuth } from '../../contextProviders/AuthProvider';
import Person from '../../types/Person';

import { PersonDetailsView } from './PersonDetailsView';
import PersonLeagueList from './PersonLeagueList';

// Separate components for better organization
function GhinInfo({ person }: { person: Person }) {
  if (!person.ghin) return null;

  return (
    <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
      <Typography variant="label">GHIN:</Typography>
      <Typography variant="body1">{person.ghin}</Typography>
      <Typography variant="label">Index:</Typography>
      <Typography variant="body1">{person.index}</Typography>
    </Stack>
  );
}

function ContactInfo({ person }: { person: Person }) {
  return (
    <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
      <Typography variant="label">email:</Typography>
      <Typography variant="body1">{person.email}</Typography>
    </Stack>
  );
}

export interface PersonViewProps {
  person: Person;
}

export default function PersonView(props: PersonViewProps): ReactElement {
  const { person } = props;
  const { imagesRef } = useAuth();
  const [profilePic, setProfilePic] = useState('');
  console.log('person', person);

  useEffect(() => {
    if (person.picture) {
      getDownloadURL(ref(imagesRef, person.picture)).then(setProfilePic).catch(console.log);
    } else {
      setProfilePic('');
    }
  }, [person.picture, imagesRef]);

  return (
    <Grid container justifyContent="flex-start" alignItems="flex-start" spacing={2}>
      <Grid size={{ xs: 12, sm: 4, md: 2 }}>
        {profilePic && <Box component="img" src={profilePic} alt="profile picture" sx={{ width: '100%' }} />}
      </Grid>
      <Grid size={{ xs: 12, sm: 8, md: 10 }}>
        <Stack direction="column" spacing={2} className={person.isTest ? 'test' : ''}>
          <Typography variant="headline">{person.name}</Typography>

          <GhinInfo person={person} />
          <ContactInfo person={person} />

          <PersonDetailsView person={person} />

          <PersonLeagueList person={person} />
        </Stack>
      </Grid>
    </Grid>
  );
}