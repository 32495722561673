import { ReactElement } from 'react';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { SxProps, Theme } from '@mui/material/styles';

import usePersons from '../../dataHooks/usePersons';
import { OrgMemberType } from '../../enums/OrgMemberType';
import InvitationEntry from '../../types/InvitationEntry';
import { MembershipStatus } from '../../types/Membership';
import Person from '../../types/Person';
import PersonAutocomplete from '../person/PersonAutocomplete';

import OrgMembershipSelector from './OrgMembershipSelector';

interface OrgKnownInviteEditorProps {
  currentEntry: Omit<InvitationEntry, 'id'>;
  isFirstEntry: boolean;
  isEditing: boolean;
  onPersonChange: (person: Person | null) => void;
  onMemberChange: (isMember: boolean) => void;
  onProChange: (isPro: boolean) => void;
  onAdminChange: (isAdmin: boolean) => void;
  onAddOrUpdate: () => void;
  onCancel?: () => void;
  isDisabled: boolean;
  sx?: SxProps<Theme>;
}

export default function OrgKnownInviteEditor(props: OrgKnownInviteEditorProps): ReactElement {
  const {
    currentEntry,
    isEditing,
    onPersonChange,
    onMemberChange,
    onProChange,
    onAdminChange,
    onAddOrUpdate,
    onCancel,
    isDisabled,
    sx,
  } = props;

  // Create a hook that wraps usePersons to match PeopleHookResult
  const usePeopleHook = () => {
    const { persons, isPersonsLoading, personsError } = usePersons({
      isActiveUser: true,
      membershipStatus: 'active' as MembershipStatus,
      orgMemberType: OrgMemberType.MEMBER,
    });
    return {
      items: persons || undefined,
      isLoading: isPersonsLoading,
      error: personsError || undefined,
    };
  };

  return (
    <Grid container spacing={2} alignItems="center" sx={sx}>
      <Grid container size={{ xs: 9 }}>
        <Grid size={{ xs: 12, sm: 8, md: 9 }}>
          <PersonAutocomplete<Person>
            id="addPerson"
            size="small"
            label="Person to add"
            selectedPersonId={currentEntry.personId}
            handleOnChange={(event, value) => onPersonChange(value)}
            usePeopleHook={usePeopleHook}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 2, md: 1 }}>
          <OrgMembershipSelector
            isMember={currentEntry.isMember}
            isPro={currentEntry.isPro}
            isAdmin={currentEntry.isAdmin}
            onMemberChange={onMemberChange}
            onProChange={onProChange}
            onAdminChange={onAdminChange}
          />
        </Grid>
      </Grid>
      <Grid size={{ xs: 3, sm: 2, md: 1 }}>
        <Stack direction="row" spacing={1} justifyContent="flex-start">
          {isEditing && onCancel && (
            <Button
              id="cancel-invitation"
              aria-label="cancel invitation"
              variant="text"
              onClick={onCancel}
              sx={{ minWidth: 'auto' }}
            >
              Cancel
            </Button>
          )}
          <Button
            id="add-invitation"
            aria-label="add invitation"
            variant="outlined"
            onClick={onAddOrUpdate}
            disabled={isDisabled}
            sx={{ minWidth: 'auto' }}
          >
            {isEditing ? 'Update' : 'Add'}
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
}
