import { ReactElement } from "react";

import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { useData } from "../../contextProviders/DataProvider";
import useEvents from '../../dataHooks/useEvents';
import { EventQueryFilter } from "../../enums/EventQueryFilter";
import Event from '../../types/Event';
import Hourglass from "../common/Hourglass";

import EventCard from './EventCard';

interface EventCardSectionProps {
  filter: EventQueryFilter | null;
  headline: string;
}

export default function EventCardSection(props: EventCardSectionProps): ReactElement {
  const { filter, headline } = props;
  const { userProfile } = useData();
  const { events, isEventsLoading, eventsError } = useEvents(userProfile ? { filter: filter } : { });

  if (isEventsLoading) {
    return <Hourglass />;
  }

  if (events?.length > 0) {
    return (
      <Grid container direction="row" spacing={1}>
        <Grid size={{ xs: 12 }}>
          <Typography variant="h6">{headline}</Typography>
        </Grid>
        {events.flatMap((event: Event) => {
          return (
            <Grid key={'open' + event.itemId} minWidth={300} size={{ xs: 12, sm: 8, md: 6 }}>
              <EventCard  variant="outlined" isClickable={true} event={event} />
            </Grid>
          );
        })}
        <Divider variant="fullWidth" />
      </Grid>
    );
  } else if (eventsError) {
    return <Typography variant="body2">Error loading events: {eventsError.message}</Typography>;
  } else {
    return <> </>;
  }
}