import { deleteDoc, getDoc } from 'firebase/firestore';
import { doc, collection, setDoc, Timestamp } from 'firebase/firestore';

import { useData } from '../contextProviders/DataProvider';
import { firestore } from '../firebase';
import Invitation from '../types/Invitation';
import InvitationEntry from '../types/InvitationEntry';

export const useInvitationMutators = () => {
  const { userProfile } = useData();

  const addInvitation = async (orgId: string, entry: InvitationEntry): Promise<void> => {
    if (!userProfile?.itemId) {
      console.error('User profile not found');
      return;
    }

    try {
      // Get org name
      const orgRef = doc(firestore, 'org/' + orgId);
      const orgDoc = await getDoc(orgRef);
      const orgName = orgDoc.data()?.name || '';

      // Create invitation
      const invitationRef = doc(collection(firestore, `org/${orgId}/invitation`));
      const invitation: Invitation = {
        createTime: Timestamp.now(),
        creatorId: userProfile.itemId,
        itemId: invitationRef.id,
        email: entry.email,
        isTest: entry.isTest,
        name: entry.name,
        orgId,
        orgName,
        ghin: entry.ghin || '',
        isPro: entry.isPro || false,
        isAdmin: entry.isAdmin || false,
        isMember: entry.isMember || false,
        ...(entry.personId && { personId: entry.personId }),
      };

      await setDoc(invitationRef, invitation);
    } catch (error) {
      console.error('Error adding invitation:', error);
    }
  };

  const addInvitations = async (invitations: Invitation[]): Promise<void> => {
    if (!userProfile?.itemId) {
      console.error('User profile not found');
      return;
    }

    try {
      // Group invitations by orgId to minimize Firestore reads
      const invitationsByOrg = invitations.reduce((acc, invitation) => {
        if (!acc[invitation.orgId]) {
          acc[invitation.orgId] = [];
        }
        acc[invitation.orgId].push(invitation);
        return acc;
      }, {} as Record<string, Invitation[]>);

      // Process each org's invitations
      for (const [orgId, orgInvitations] of Object.entries(invitationsByOrg)) {
        // Get org name once per org
        const orgRef = doc(firestore, 'org/' + orgId);
        const orgDoc = await getDoc(orgRef);
        const orgName = orgDoc.data()?.name || '';

        // Create all invitations for this org
        const batch = orgInvitations.map((invitation) => {
          const invitationRef = doc(collection(firestore, `org/${orgId}/invitation`));
          return {
            ref: invitationRef,
            data: {
              ...invitation,
              createTime: Timestamp.now(),
              creatorId: userProfile.itemId,
              itemId: invitationRef.id,
              orgName,
            },
          };
        });

        // Set all invitations for this org
        await Promise.all(batch.map(({ ref, data }) => setDoc(ref, data)));
      }
    } catch (error) {
      console.error('Error adding invitations:', error);
    }
  };

  const deleteInvitation = async (orgId: string, invitationId: string): Promise<void> => {
    if (!orgId || !invitationId) {
      console.error('No application ID provided');
      return;
    }

    try {
      const invitationRef = doc(collection(firestore, `org/${orgId}/invitation`), invitationId);
      await deleteDoc(invitationRef);
    } catch (error) {
      console.error('Error deleting invitation:', error);
    }
  };

  const updateInvitation = async (orgId: string, invitationId: string, isApproved: boolean, isRejected: boolean) => {
    if (!orgId || !invitationId) {
      console.error('No invitation ID provided');
      return;
    }

    try {
      // reference to the document to update
      const invitationRef = doc(collection(firestore, `org/${orgId}/invitation`), invitationId);

      // Update the value of the application
      await setDoc(invitationRef, { isApproved: isApproved, isRejected: isRejected }, { merge: true });
    } catch (error) {
      alert(error);
    }
  };

  return {
    addInvitation,
    addInvitations,
    deleteInvitation,
    updateInvitation,
  };
};
