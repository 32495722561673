import { ReactElement } from 'react';

import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import AddIcon from '@mui/icons-material/Add';

import { useData } from '../../contextProviders/DataProvider';
import DefaultObjects from '../../types/DefaultObjects';
import League from '../../types/League';
import Season from '../../types/Season';
import {} from '../../utils/Compete';

import SeasonEditor from './SeasonEditor';

interface SeasonsEditorProps {
  league: League;
  seasons?: Season[];
  onAdd?: (season: Season) => void;
  onDelete?: (season: Season) => void;
  onChange?: (season: Partial<Season>) => void;
}

export default function SeasonsEditor(props: SeasonsEditorProps): ReactElement {
  const { league, seasons, onAdd, onDelete, onChange } = props;
  const { isLeagueAdmin, userProfile } = useData();

  const handleSeasonAdd = (parentLeague: League) => {
    if (onAdd) {
      const season = {
        ...DefaultObjects.Season,
        creatorId: userProfile?.itemId || '',
        isCatchAll: false,
        leagueId: parentLeague.itemId,
        leagueName: parentLeague.name,
        orgId: parentLeague.orgId,
        orgName: parentLeague.orgName,
      };
      onAdd(season);
    }
  };

  const handleSeasonChange = (season: Partial<Season>) => {
    if (onChange) {
      onChange(season);
    }
  };

  const handleSeasonDelete = (season: Season) => {
    if (onDelete) {
      onDelete(season);
    }
  };

  if (isLeagueAdmin(league.itemId, league.orgId)) {
    return (
      <Stack spacing={2}>
        <Typography variant="headline">Seasons</Typography>
        {seasons && 
          seasons.map((season) =>
            <SeasonEditor 
              key={season.itemId} 
              season={season} 
              onChange={handleSeasonChange} 
              onDelete={handleSeasonDelete} 
            />
          )
        }
        {onAdd && (
          <Button 
            color="secondary" 
            size="small" 
            variant="outlined" 
            startIcon={<AddIcon />} 
            onClick={() => handleSeasonAdd(league)}
          sx={{ width: 'fit-content' }}
          >
            Add season
          </Button>
        )}
      </Stack>
    );
  } else {
    return (
      <Alert severity="error">
        You are not authorized to edit this league
      </Alert>
    );
  }
}
