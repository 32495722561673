import { ReactElement } from 'react';

import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import DownloadIcon from '@mui/icons-material/Download';

export interface GratiTableColumn<T> {
  field: string;
  getValue: (row: T) => string | number | boolean;
  width?: number; // Optional column width
  align?: 'left' | 'center' | 'right'; // Optional alignment
}

export interface GratiTableRow<T> {
  field: string;
  dbname: keyof T;
  example: string;
  required: boolean;
}

interface GratiCsvTableProps<T> {
  description?: string;
  isEditable?: boolean;
  columns: GratiTableColumn<T>[];
  rows: T[];
  onRowChange?: (index: number, updatedRow: T) => void;
  hasCsvDownloadAction?: boolean;
}

export default function GratiCsvTable<T>({
  description = 'The CSV file must include the following columns:',
  columns,
  isEditable = false,
  rows,
  onRowChange,
  hasCsvDownloadAction = false,
}: GratiCsvTableProps<T>): ReactElement {
  const getTemplateData = () => {
    // Get headers (excluding first column)
    const headers = columns.slice(1).map((col) => col.field);

    // Get example row (where first column value is 'Example')
    const exampleRow = rows.find((row) => columns[0].getValue(row) === 'Example');
    const exampleData = exampleRow ? columns.slice(1).map((col) => col.getValue(exampleRow)) : [];

    return [headers, exampleData];
  };

  const handleCsvDownload = () => {
    const csvData = getTemplateData();
    const csvContent = csvData[0].join(',') + '\n' + csvData[1].join(',');

    // Create blob and download link
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', 'event_template.csv');

    // Trigger download dialog
    document.body.appendChild(link);
    link.click();

    // Cleanup
    document.body.removeChild(link);
    URL.revokeObjectURL(link.href);
  };

  return (
    <Stack direction="column" spacing={2} alignItems="flex-start" justifyContent="flex-start">
      {description && <Typography sx={{ mb: 2 }}>{description}</Typography>}

      <TableContainer component={Paper}>
        <Table
          size="small"
          sx={{
            tableLayout: 'fixed',
            minWidth: () => `${columns.length * 150}px`,
          }}
        >
          <TableHead>
            <TableRow>
              {columns.map((col) => (
                <TableCell
                  key={col.field}
                  sx={{
                    width: col.width,
                    textAlign: col.align || 'left',
                  }}
                >
                  {col.field}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, rowIndex) => {
              console.log(row);
              return (
                <TableRow key={rowIndex}>
                  {columns.map((col, colIndex) => (
                    <TableCell
                      key={`${rowIndex}-${col.field}`}
                      className={colIndex === 0 ? 'firstColumn' : undefined}
                      sx={{
                        width: col.width,
                        textAlign: col.align || 'left',
                      }}
                    >
                      {typeof col.getValue(row) === 'boolean' ? (col.getValue(row) ? 'Yes' : 'No') : col.getValue(row)}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {hasCsvDownloadAction && (
        <Button variant="outlined" startIcon={<DownloadIcon />} onClick={handleCsvDownload} size="small">
          Download CSV Template
        </Button>
      )}
    </Stack>
  );
}
