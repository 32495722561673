import { ReactElement } from "react";

import { Link } from "react-router";

import Stack from "@mui/material/Stack";

import { Alert, List, ListItem, Typography } from "@mui/material";

import { useAuth } from "../../contextProviders/AuthProvider";
import useLeagues from "../../dataHooks/useLeagues";
import Person from "../../types/Person";
import Hourglass from "../common/Hourglass";
interface PersonLeagueListProps {
  person: Person,
}

export default function PersonLeagueList(props: PersonLeagueListProps): ReactElement {
  const { person } = props; 
  const { isGod } = useAuth();
  const { leagues, isLeaguesLoading, leaguesError } = useLeagues({ 
    person: person, 
    includeCatchAll: isGod, 
    includeOrgAdmined: true, 
    includeOrgPro: true,
    includeLeagueAdmined: true,
    includeLeagueMember: true,
    includeOrgMember: true,
  });
  console.log('loading leagues for person ', person.itemId);

  if (isLeaguesLoading) {
    return <Hourglass />;
  }
  
if (leaguesError) {
  return <Alert severity="error">Error loading leagues {leaguesError.message}</Alert>;
}

  if (!person || !leagues || leagues.length === 0) {
    return <></>;
  }

  return (
    <Stack direction="column">
        <Typography variant="label">Leagues</Typography>
        <List
          sx={{
            width: '100%',
            height: '100%',
            position: 'relative',
            overflow: 'auto',
            p: 0,
            '& ul': { padding: 0 },
            '& li': { padding: 0 },
          }}
          subheader={<li />}
        >
          {leagues?.map((league) => {
              return (
                <ListItem 
                  key={league.itemId} 
                  component={Link} 
                  to={`/league/${league.orgId}/${league.itemId}`} 
                  sx={{ color: 'surface.contrastText' }}
                >
                  {league.name}
                </ListItem>
              );
            })}
        </List>
    </Stack>
  );
}