import { Timestamp } from 'firebase/firestore';

import Application from './Application';
import Course from './Course';
import Event from './Event';
import Handbook from './Handbook';
import Invitation from './Invitation';
import League from './League';
import Membership from './Membership';
import Org from './Org';
import PersonDetails from './PersonDetails';
import Registration from './Registration';
import Season from './Season';

export const DefaultObjects = {
  Application: {
    itemId: '',
    creatorId: '',
    createTime: Timestamp.now(),
    name: '',
    email: '',
    ghin: '',
    phone: '',
    isApproved: false,
    isTest: false,
    details: {
      itemId: '',
      mailingAddress: '',
      name: '',
    } as PersonDetails,
  } as Application,
  Course: {
    creatorId: '',
    createTime: Timestamp.now(),
    googlePlaceId: '',
    isTest: false,
    itemId: '',
    logo: '',
    membership: '',
    name: '',
    orgId: '',
    orgName: '',
    par: 0,
    shortName: '',
    teeTimeSpacing: 0,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    usgaCourseId: '',
  } as Course,
  PersonDetails: {
    itemId: '',
    creatorId: '',
    createTime: Timestamp.now(),
    name: '',
    birthday: Timestamp.fromDate(new Date('1900-01-01')),
    gender: '',
    localAddress: '',
    mailingAddress: '',
    phone: '',
  } as PersonDetails,
  Event: {
    name: '',
    coursePairs: [],
    areResultsPosted: false,
    competitions: [],
    creatorId: '',
    createTime: Timestamp.now(),
    days: [
      {
        courseId: '',
        datetime: Timestamp.now(),
        duration: 300,
        isGolf: true,
        isShotgun: false,
      },
    ],
    startDatetime: Timestamp.now(),
    endDatetime: Timestamp.now(),
    description: '',
    isGolf: true,
    isShotgun: false,
    isTest: false,
    itemId: '',
    leagueId: /* leagueId ?? */ '',
    leagueName: '',
    logo: '',
    maxPlayers: 0,
    numTeeTimes: 0,
    orgId: /* orgId ?? */ '',
    seasonId: /* seasonId ?? */ '',
    seasonName: '',
    signupOpensAt: Timestamp.now(),
    signupClosesAt: Timestamp.now(),
    socialLocation: '',
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  } as Event,
  Handbook: {
    category: '',
    creatorId: '',
    createTime: Timestamp.now(),
    description: '',
    history: [],
    isDeleted: false,
    isFeatured: false,
    isPublic: false,
    isPublished: false,
    isTest: false,
    itemId: '',
    logo: '',
    name: '',
    orgId: '',
    orgName: '',
    sections: [],
    tags: [],
  } as Handbook,
  Invitation: {
    createTime: Timestamp.now(),
    creatorId: 'system',
    email: '',
    ghin: '',
    isTest: false,
    isProcessed: false,
    isError: false,
    isPro: false,
    isAdmin: false,
    isMember: false,
    itemId: '',
    name: '',
    orgId: '',
    orgName: '',
    appDetails: {
      createTime: Timestamp.now(),
      creatorId: 'system',
      itemId: '',
      gender: '',
      localAddress: '',
      mailingAddress: '',
      name: '',
      phone: '',
      externalAppId: '',
      externalAppSource: '',
      clubId: '',
      clubSource: '',
    } as PersonDetails,
  } as Invitation,
  League: {
    name: '',
    competitionLevel: 0,
    creatorId: '',
    createTime: Timestamp.now(),
    description: '',
    includeAllOrgMembers: true,
    isCatchAll: false,
    isDeleted: false,
    isTest: false,
    itemId: '',
    logo: '',
    orgName: '',
    orgId: '',
  } as League,
  Membership: {
    creatorId: '',
    createTime: Timestamp.now(),
    isActive: true,
    isAdmin: false,
    isMember: true,
    isPending: false,
    isPro: false,
    itemId: '',
    name: '',
    orgId: '',
    status: 'active',
  } as Membership,
  Org: {
    catchAllLeagueId: '',
    catchAllSeasonId: '',
    createTime: Timestamp.now(),
    creatorId: '',
    description: '',
    homeUrl: '',
    isActive: true,
    isTest: false,
    itemId: '',
    logo: '',
    name: '',
    shortName: '',
  } as Org,
  Registration: {
    eventId: '',
    playerId: '',
    playerName: '',
    comment: '',
    datetime: Timestamp.now(),
    group: 0,
    isEighteen: true,
    isPlayingGame: true,
    isRiding: true,
    isRegistered: false,
    isWaitListed: false,
  } as Registration,
  Season: {
    creatorId: '',
    createTime: Timestamp.now(),
    endTime: Timestamp.now(),
    isCatchAll: false,
    isDeleted: false,
    isTest: false,
    itemId: '',
    leagueId: '',
    leagueName: '',
    name: '',
    orgId: '',
    orgName: '',
    startTime: Timestamp.now(),
  } as Season,
  // Add other types here
};

export type SupportedTypes = keyof typeof DefaultObjects;
export default DefaultObjects;
