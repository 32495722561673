import { Route, Routes } from "react-router-dom";

import AuthActionHandler from "../components/auth/AuthActionHandler";
import GodRoute from "../components/common/GodRoute";
import PrivateRoute from "../components/common/PrivateRoute";
import { useData } from "../contextProviders/DataProvider";
import AcceptOrgInvitationPage from "../pages/AcceptOrgInvitationPage";
import AccountEmailVerifiedPage from "../pages/AccountEmailVerifiedPage";
import AccountForgotPasswordPage from "../pages/AccountForgotPasswordPage";
import AccountPage from "../pages/AccountPage";
import AccountRecoverEmailPage from "../pages/AccountRecoverEmailPage";
import AccountResetPasswordPage from "../pages/AccountResetPasswordPage";
import AccountVerifyEmailChangePage from "../pages/AccountVerifyEmailChangePage";
import AccountVerifyEmailPage from "../pages/AccountVerifyEmailPage";
import ApplyPage from "../pages/ApplyPage";
import CourseCreatePage from "../pages/CourseCreatePage";
import CoursePage from "../pages/CoursePage";
import CoursesPage from "../pages/CoursesPage";
import EventCreatePage from "../pages/EventCreatePage";
import EventPage from "../pages/EventPage";
import EventsPage from "../pages/EventsPage";
import EventUploadPage from "../pages/EventUploadPage";
import FirestoreUtilityPage from "../pages/FirestoreUtilityPage";
import GuestsPage from "../pages/GuestsPage";
import HandbookCreatePage from "../pages/HandbookCreatePage";
import HandbookPage from "../pages/HandbookPage";
import HandbooksPage from "../pages/HandbooksPage";
import HomePage from "../pages/HomePage";
import InvitationUploadPage from "../pages/InvitationUploadPage";
import InviteMembersPage from "../pages/InviteMembersPage";
import LeagueCreatePage from "../pages/LeagueCreatePage";
import LeaguePage from "../pages/LeaguePage";
import LeaguesPage from "../pages/LeaguesPage";
import LoadingErrorPage from "../pages/LoadingErrorPage";
import LoginPage from "../pages/LoginPage";
import LogoutPage from "../pages/LogoutPage";
import MembersPage from "../pages/MembersPage";
import OrgCreatePage from "../pages/OrgCreatePage";
import OrgPage from "../pages/OrgPage";
import OrgsPage from "../pages/OrgsPage";
import PersonPage from "../pages/PersonPage";
import SeasonCreatePage from "../pages/SeasonCreatePage";
import SeasonPage from "../pages/SeasonPage";
import SeasonsPage from "../pages/SeasonsPage";
import WelcomePage from "../pages/WelcomePage";
export default function RouteTable() {

  const { getAllActiveOrgs } = useData();

    // Handle the case where a user is only in one org - by just routing to the
  // page that shows users for just their org.
  function isOnlyOneOrg() {
    const orgs = getAllActiveOrgs();
    return orgs.length === 1;
  }

  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/auth-action/*" element={<AuthActionHandler />} />
      <Route path="/accept-org-invitation/:appId?/:orgName?/:email?/:name?/*" element={<AcceptOrgInvitationPage />}/>
      <Route path="/account/:personId?" element={<PrivateRoute><AccountPage /></PrivateRoute>}/>
      <Route path="/account-email-verified" element={<AccountEmailVerifiedPage />} />
      <Route path="/account-forgot-password?/*" element={<AccountForgotPasswordPage />} />
      <Route path="/account-recover-email?/*" element={<AccountRecoverEmailPage />} />
      <Route path="/account-reset-password?/*" element={<AccountResetPasswordPage />} />
      <Route path="/account-verify-email?/*" element={<AccountVerifyEmailPage />} />
      <Route path="/account-verify-email-change/:newEmail?/*" element={<PrivateRoute><AccountVerifyEmailChangePage /></PrivateRoute>} />
      <Route path="/apply/:orgId?/:orgName?" element={<ApplyPage />} />
      <Route path="/course/:courseId" element={<PrivateRoute><CoursePage /></PrivateRoute>}/>
      <Route path="/course-create" element={<PrivateRoute><CourseCreatePage /></PrivateRoute>}/>
      <Route path="/courses" element={<PrivateRoute><CoursesPage /></PrivateRoute>}/>
      <Route path="/course-edit/:courseId" element={<PrivateRoute><CoursePage isEditMode={true} /></PrivateRoute>}/>
      <Route path="/now-events" element={<PrivateRoute><EventsPage initialTab={'3'} /></PrivateRoute>}/>
      <Route path="/upcoming-events" element={<PrivateRoute><EventsPage initialTab={'1'} /></PrivateRoute>}/>
      <Route path="/past-events" element={<PrivateRoute><EventsPage initialTab={'2'} /></PrivateRoute>}/>
      <Route path="/event/:orgId/:eventId" element={<PrivateRoute><EventPage /></PrivateRoute>}/>
      <Route path="/event-create/:orgId?/:leagueId?/:seasonId?" element={<PrivateRoute><EventCreatePage /></PrivateRoute>}/>
      <Route path="/event-edit/:orgId/:eventId" element={<PrivateRoute><EventPage isEditMode={true} /></PrivateRoute>}/>
      <Route path="/attendees-edit" element={<PrivateRoute><EventPage /></PrivateRoute>}/>
      <Route path="/events" element={<PrivateRoute><EventsPage /></PrivateRoute>}/>
      <Route path="/guests" element={<PrivateRoute><GuestsPage /></PrivateRoute>}/>
      <Route path="/handbook/:orgId/:handbookId" element={<PrivateRoute><HandbookPage /></PrivateRoute>}/>
      <Route path="/handbook-create/:orgId?" element={<PrivateRoute><HandbookCreatePage /></PrivateRoute>}/>
      <Route path="/handbook-edit/:orgId/:handbookId" element={<PrivateRoute><HandbookPage isEditMode={true} /></PrivateRoute>}/>
      <Route path="/handbooks/:orgId?" element={<PrivateRoute><HandbooksPage /></PrivateRoute>}/>
      <Route path="/home" element={<HomePage />} />
      <Route path="/loadingerror" element={<LoadingErrorPage />} />
      <Route path="/login/*" element={<LoginPage />} />
      <Route path="/logout" element={<LogoutPage />} />
      <Route path="/league/:orgId/:leagueId" element={<PrivateRoute><LeaguePage isEditMode={false} /></PrivateRoute>}/>
      <Route path="/league-create/:orgId" element={<PrivateRoute><LeagueCreatePage /></PrivateRoute>}/>
      <Route path="/league-edit/:orgId/:leagueId" element={<PrivateRoute><LeaguePage isEditMode={true} /></PrivateRoute>}/>
      <Route path="/leagues" element={<PrivateRoute><LeaguesPage /></PrivateRoute>}/>
      <Route path="/members" element={<PrivateRoute conditionForAltChildren={isOnlyOneOrg} altChildren={<OrgPage isEditMode={false} initialTab={'1'} orgId={getAllActiveOrgs()[0]} />}><MembersPage /></PrivateRoute>}/>
      <Route path="/orgs" element={<PrivateRoute><OrgsPage /></PrivateRoute>}/>
      <Route path="/org/:orgId" element={<PrivateRoute><OrgPage isEditMode={false} initialTab={'1'} /></PrivateRoute>}/>
      <Route path="/org-create" element={<PrivateRoute><OrgCreatePage /></PrivateRoute>}/>
      <Route path="/org-admins" element={<PrivateRoute><OrgPage isEditMode={false} initialTab={'2'} /></PrivateRoute>}/>
      <Route path="/org-edit/:orgId" element={<PrivateRoute><OrgPage isEditMode={true} initialTab={'1'} /></PrivateRoute>}/>
      <Route path="/org-invitations/:orgId" element={<PrivateRoute><OrgPage isEditMode={false} initialTab={'4'} /></PrivateRoute>}/>
      <Route path="/org-pros/:orgId" element={<PrivateRoute><OrgPage isEditMode={false} initialTab={'3'} /></PrivateRoute>}/>
      <Route path="/invite-members/:orgId" element={<PrivateRoute><InviteMembersPage /></PrivateRoute>}/>
      <Route path="/profile" element={<PrivateRoute><PersonPage isEditMode={false} /></PrivateRoute>}/>
      <Route path="/profile/:personId" element={<PrivateRoute><PersonPage isEditMode={false} /></PrivateRoute>}/>
      <Route path="/profile-edit/:personId" element={<PrivateRoute><PersonPage isEditMode={true} /></PrivateRoute>}/>
      <Route path="/season/:orgId/:seasonId" element={<PrivateRoute><SeasonPage /></PrivateRoute>}/>
      <Route path="/season-create/:orgId/:leagueId" element={<PrivateRoute><SeasonCreatePage /></PrivateRoute>}/>
      <Route path="/season-edit/:orgId/:seasonId" element={<PrivateRoute><SeasonPage isEditMode={true} /></PrivateRoute>}/>
      <Route path="/seasons" element={<PrivateRoute><SeasonsPage /></PrivateRoute>}/>
      <Route path="/welcome" element={<WelcomePage />} />
      {/* God only routes */}
      <Route path="/event-uploader/:leagueId?" element={<GodRoute><EventUploadPage /></GodRoute>}/>
      <Route path="/invitation-uploader/:orgId?" element={<GodRoute><InvitationUploadPage /></GodRoute>}/>
      <Route path="/org-create" element={<GodRoute><OrgPage isNew={true} isEditMode={true} /></GodRoute>}/>
      <Route path="/firestore-utility" element={<GodRoute><FirestoreUtilityPage /></GodRoute>}/>
    </Routes>
  )
}