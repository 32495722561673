import React, { useEffect, useState } from 'react';

import { getDownloadURL, ref } from 'firebase/storage';

import { Link } from 'react-router';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/material/styles';
import { useColorScheme } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { useAuth } from '../../contextProviders/AuthProvider';
import { useData } from '../../contextProviders/DataProvider';

interface SettingsMenuItem {
  name: string;
  to: string;
  passUserId: boolean;
  showLogin: boolean;
  showLogout: boolean;
  showOnlyAdmin: boolean;
}

const settings: SettingsMenuItem[] = [
  { name: 'Profile', to: '/profile', passUserId: true, showLogin: true, showLogout: false, showOnlyAdmin: false },
  { name: 'Account', to: '/account', passUserId: true, showLogin: true, showLogout: false, showOnlyAdmin: false },
  { name: 'Login', to: '/login', passUserId: false, showLogin: false, showLogout: true, showOnlyAdmin: false },
  { name: 'Logout', to: '/logout', passUserId: false, showLogin: true, showLogout: false, showOnlyAdmin: false },
  { name: 'Apply', to: '/apply', passUserId: false, showLogin: false, showLogout: true, showOnlyAdmin: false },
];

interface GratiSettingsMenuProps {
  sx?: SxProps<Theme>;
  label?: string;
}

export default function GratiSettingsMenu(props: GratiSettingsMenuProps) {
  const sx = props.sx;
  const label = props.label;

  const { imagesRef, user } = useAuth();
  const { getAdminOrgs, userProfile } = useData();

  const { mode, setMode } = useColorScheme();
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [letter, setLetter] = useState<string>('X');
  const [downloadPicUrl, setDownloadPicUrl] = useState('');

  useEffect(() => {
    if (user && userProfile) {
      if (userProfile.name?.length > 0) {
        setLetter(userProfile.name.charAt(0));
      } else {
        setLetter('X');
      }
      if (userProfile?.picture) {
        getDownloadURL(ref(imagesRef, userProfile.picture))
          .then((url) => {
            setDownloadPicUrl(url);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else {
      setLetter('X');
      setDownloadPicUrl('');
    }
  }, [imagesRef, user, userProfile]);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <Box sx={[...(Array.isArray(sx) ? sx : [sx])]}>
      <Tooltip title="Open settings">
        <IconButton onClick={handleOpenUserMenu} color="inherit" sx={{ flexDirection: 'column', pl: '1rem', pr: 0 }}>
          <Avatar alt={letter} src={downloadPicUrl} />
          {label && <Typography textAlign="center">{label}</Typography>}
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: '45px' }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {settings.map((setting) =>
          (setting.showLogin && user && (!setting.showOnlyAdmin || getAdminOrgs().length > 0)) ||
          (setting.showLogout && !user) ? (
            <MenuItem
              key={setting.name}
              component={Link}
              to={setting.passUserId ? `${setting.to}/${user?.uid}` : setting.to}
              onClick={handleCloseUserMenu}
            >
              <Typography textAlign="center">{setting.name}</Typography>
            </MenuItem>
          ) : null
        )}
        <Divider variant="middle" />
        <MenuItem>
          <ListItemText>Dark</ListItemText>
          <Switch
            checked={mode === 'dark'}
            id="modeSwitch"
            edge="end"
            onChange={(e) => setMode(e.target.checked ? 'dark' : 'light')}
          />
        </MenuItem>
      </Menu>
    </Box>
  );
}
