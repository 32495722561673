import { ReactElement, useState } from 'react';

import { Timestamp } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { UploadResult } from 'firebase/storage';

import { useNavigate, Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { useAuth } from '../../contextProviders/AuthProvider';
import { useData } from '../../contextProviders/DataProvider';
import { cloudFunctions } from '../../firebase';
import Person from '../../types/Person';
import PersonDetails from '../../types/PersonDetails';
import { FromDayJS, ToDayJS } from '../../utils/utils';
import GratiImageUpload from '../common/GratiImageUpload';

import PersonLeagueEditor from './PersonLeagueEditor';

interface PersonEditorProps {
  person: Person;
  personDetails?: PersonDetails;
  isNewPerson: boolean;
  onCancel?: () => void;
  onSubmit?: (person: Person, personDetails?: PersonDetails) => void;
}

export default function PersonEditor(props: PersonEditorProps): ReactElement {
  const { isNewPerson, onCancel, onSubmit } = props;
  const { isGod } = useAuth();
  const { hasAdminRightsOverPerson, isSelf } = useData();
  const navigate = useNavigate();
  const [person, setPerson] = useState<Person>(props.person);
  const [personDetails, setPersonDetails] = useState<PersonDetails | undefined>(props.personDetails);

  const handleImageUpdated = (snapshot: UploadResult | null) => {
    if (person) {
      setPerson({ ...person, picture: snapshot ? snapshot.ref.name : '' });
    }
    console.log('Image uploaded: ' + snapshot?.ref.name);
  };

  const handleActiveChange = () => {
    if (person) {
      setPerson({ ...person, isActive: !person.isActive });
    }
  };

  // This call will only work if the user is already a god.
  const handleDeify = () => {
    if (person) {
      const deifyUser = httpsCallable(cloudFunctions, 'makeUserAGod');
      deifyUser({ email: person?.email })
        .then((result) => {
          console.log(result);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      console.log('No active profile');
    }
  };

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    } else {
      navigate(-1);
    }
  };

  const handleSubmit = () => {
    if (onSubmit) {
      onSubmit(person, ...(personDetails ? [personDetails] : []));
    } else {
      console.log('No submit handler provided.');
    }
  };

  if (person) {
    // You can only edit your own profile unless you are an admin
    if (hasAdminRightsOverPerson(person)) {
      return (
        <>
          <Box
            component="form"
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <Grid container sx={{ display: 'flex' }}>
              <Grid size={{ xs: 12, sm: 6 }}>
                <Stack spacing={2} sx={{ display: 'flex', flexDirection: 'column', pb: 2, mr: 1 }}>
                  <Typography align="left" variant="title" gutterBottom>
                    Profile Information
                  </Typography>
                  <TextField
                    id="name"
                    autoFocus={true}
                    fullWidth
                    required
                    label="Name"
                    aria-label="Your name"
                    aria-required="true"
                    defaultValue={person.name}
                    onChange={(e) => setPerson({ ...person, name: e.target.value })}
                  />
                  <GratiImageUpload
                    onImageUpdated={handleImageUpdated}
                    storageImageFileName={person.itemId}
                    label="Profile Picture"
                  />
                  <TextField
                    id="ghin"
                    fullWidth
                    type="number"
                    label="GHIN"
                    aria-label="Golf Handicap Index Number"
                    aria-required="true"
                    inputProps={{ maxLength: 7 }}
                    defaultValue={person.ghin}
                    onChange={(e) => setPerson({ ...person, ghin: (+e.target.value).toString() })}
                  />
                  <TextField
                    id="index"
                    fullWidth
                    type="number"
                    label="index (from GHIN)"
                    disabled
                    InputProps={{ inputProps: { min: 0, max: 45, step: 0.1, readOnly: true } }}
                    aria-label="GHIN index"
                    aria-required="true"
                    defaultValue={person.index}
                    onChange={(e) => setPerson({ ...person, index: e.target.value })}
                  />
                  {personDetails && (
                    <DatePicker
                      label="Birthday"
                      aria-label="Birthday"
                      aria-required="true"
                      defaultValue={
                        personDetails.birthday instanceof Timestamp ? ToDayJS(personDetails.birthday) : null
                      }
                      onChange={(e) => {
                        e && setPersonDetails({ ...personDetails, birthday: FromDayJS(e) });
                      }}
                    />
                  )}
                  {personDetails && (
                    <FormControl>
                      <FormLabel id="gender-radio-buttons-group">Gender</FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="gender-radio-buttons-group"
                        name="gender-radio-buttons-group"
                        defaultValue={personDetails.gender}
                        onChange={(e) => setPersonDetails({ ...personDetails, gender: e.target.value })}
                      >
                        <FormControlLabel value="female" control={<Radio />} label="Female" />
                        <FormControlLabel value="male" control={<Radio />} label="Male" />
                        <FormControlLabel value="other" control={<Radio />} label="Other" />
                      </RadioGroup>
                    </FormControl>
                  )}
                </Stack>
              </Grid>
              <Grid size={{ xs: 12, sm: 6 }}>
                <Stack spacing={2}>
                  <Typography align="left" variant="title" gutterBottom>
                    Contact Information
                  </Typography>
                  <Stack>
                    <TextField
                      id="newEmail"
                      fullWidth
                      label="New Email"
                      value={person.email}
                      disabled={true}
                    />
                    {isSelf(person) && (
                      <Button 
                        component={Link} 
                        to="/account" 
                        size="small" 
                        color="secondary" 
                        startIcon={<EditIcon />} 
                        sx={{ alignSelf: 'flex-start' }}>
                        Change email
                      </Button>
                    )}
                  </Stack>
                  {personDetails && (
                    <TextField
                      id="phone"
                      fullWidth
                      label="Phone"
                      aria-label="Phone number"
                      aria-required="true"
                      defaultValue={personDetails?.phone}
                      onChange={(e) => setPersonDetails({ ...personDetails, phone: e.target.value })}
                    />
                  )}
                  {personDetails && (
                    <TextField
                      id="mailingAddress"
                      fullWidth
                      label="Mailing Address"
                      aria-label="Mailing address"
                      defaultValue={personDetails?.mailingAddress}
                      onChange={(e) => setPersonDetails({ ...personDetails, mailingAddress: e.target.value })}
                    />
                  )}
                  {personDetails && (
                    <TextField
                      id="localAddress"
                      fullWidth
                      label="Local Address"
                      aria-label="Local address"
                      defaultValue={personDetails?.localAddress}
                      onChange={(e) => setPersonDetails({ ...personDetails, localAddress: e.target.value })}
                    />
                  )}
                  <PersonLeagueEditor person={person} />
                </Stack>
              </Grid>
              <Grid size={{ xs: 12 }} sx={{ mt: 2 }}>
                <Stack direction="row" spacing={2}>
                  <Button size="medium" variant="contained" startIcon={<SaveIcon />} type="submit">
                    {isNewPerson ? 'Create' : 'Update'}
                  </Button>
                  <Button
                    size="medium"
                    variant="outlined"
                    color="secondary"
                    startIcon={<CancelIcon />}
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </Stack>
              </Grid>
              {isGod && (
                <Grid gap={2} size={{ xs: 12 }} sx={{ mt: 2 }}>
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                    sx={{ backgroundColor: 'tertiary.container', color: 'tertiary.onContainer' }}
                  >
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            id={'actions' + person.itemId}
                            checked={person.isActive}
                            color="secondary"
                            onChange={handleActiveChange}
                          />
                        }
                        label="Active"
                      />
                    </FormGroup>
                    <Stack
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={2}
                      sx={{ backgroundColor: 'tertiary.container', color: 'tertiary.onContainer', mt: 2, p: 1 }}
                    >
                      <FormGroup>
                        <FormControlLabel
                          id="isTest"
                          value="top"
                          aria-label="Is a test profile"
                          aria-required="false"
                          label="Test?"
                          control={
                            <Switch
                              id={'isTest' + person.isTest}
                              checked={person.isTest}
                              color="secondary"
                              onChange={(e) => setPerson({ ...person, isTest: e.target.checked })}
                            />
                          }
                        />
                      </FormGroup>
                      <Button type="button" size="medium" variant="outlined" onClick={handleDeify}>
                        Deify
                      </Button>
                    </Stack>
                  </Stack>
                </Grid>
              )}
            </Grid>
          </Box>
        </>
      );
    } else {
      // todo: Add a message that you can't edit this profile
      navigate(-1);
      return <></>;
    }
  } else {
    return <></>;
  }
}
