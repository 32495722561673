import { 
  ReactElement,
  useEffect,
  useState,
} from "react";

import { useNavigate } from "react-router";

import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";

import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import { useData } from "../../contextProviders/DataProvider";
import { OrgMemberType } from "../../enums/OrgMemberType";
import { MembershipStatus } from "../../types/Membership";
import Org from "../../types/Org";

import OrgCard from "./OrgCard";
import OrgInvitations from "./OrgInvitations";
import OrgMemberDirectory from "./OrgMemberDirectory";
type OrgViewProps = {
  org: Org | undefined;
  initialTab?: string;
};

export default function OrgView(props: OrgViewProps ): ReactElement {
  const { org, initialTab } = props;
  const navigate = useNavigate();
  const { isOrgAdmin, isOrgPro } = useData();

  const [tabSelected, setTabSelected] = useState(initialTab ? initialTab : "1");
  const [membershipStatus, setMembershipStatus] = useState('active' as MembershipStatus);

  useEffect(() => {
    if (org && (isOrgAdmin(org.itemId) || isOrgPro(org.itemId))) {
      setMembershipStatus('all' as MembershipStatus);
  } else {
      setMembershipStatus('active' as MembershipStatus);
    }
  }, [isOrgAdmin, isOrgPro, org]);

  const handleTabChange = (event: React.SyntheticEvent, newTabSelected: string) => {
    setTabSelected(newTabSelected);
    navigate(newTabSelected === '4' ? '/org-invitations/' + org?.itemId : '/org/' + org?.itemId, { 
      replace: true,
      state: { preventReload: true} 
    });
  };

  const OrgMemberManager = (): ReactElement | null => {
    if (!org) return null;
  
    return (
      <Box sx={{width: "100%"}}>
        <TabContext value={tabSelected}>
          <Box>
            <TabList 
              onChange={handleTabChange}
              variant="fullWidth"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            > 
              <Tab label="Members" value="1" />
              <Tab label="Pros" value="2" />
              <Tab label="Admins" value="3" />
              {(isOrgAdmin(org.itemId) || isOrgPro(org.itemId)) && <Tab label="Invites" value="4" />}
            </TabList>
          </Box>
          <TabPanel value="1">
            <OrgMemberDirectory 
              org={org} 
              orgMemberType={OrgMemberType.MEMBER}
              membershipStatus={membershipStatus}
            />
          </TabPanel>
          <TabPanel value="2">
            <OrgMemberDirectory 
              org={org} 
              orgMemberType={OrgMemberType.PRO}
              membershipStatus={membershipStatus}
            />
          </TabPanel>
          <TabPanel value="3">
            <OrgMemberDirectory 
              org={org} 
              orgMemberType={OrgMemberType.ADMIN}
              membershipStatus={membershipStatus}
            />
          </TabPanel>
          {(isOrgAdmin(org.itemId) || isOrgPro(org.itemId)) && (
            <TabPanel value="4">
              <OrgInvitations org={org} />
            </TabPanel>
          )}
        </TabContext>
      </Box>
    );
  };

  const OrgView = (): ReactElement => {
    if (org) {
      return (
        <Stack spacing={2}>
          <OrgCard org={org} variant="none" isClickable={false} />
          <Divider sx={{ display: { sm: 'none' } }} />
          <OrgMemberManager />
        </Stack>
      );
    } else {
      return <></>;
    }
  }; 

  return (
    <>
      <OrgView />
    </>
  );
}