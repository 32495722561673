import { ReactElement, useState, memo, useCallback, useEffect } from 'react';

import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';

import CancelIcon from '@mui/icons-material/Cancel';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';

import { useAuth } from '../../contextProviders/AuthProvider';
import { useData } from '../../contextProviders/DataProvider';
import { useMembershipMutators } from '../../dataMutators/useMembershipMutators';
import { OrgMemberType } from '../../enums/OrgMemberType';
import { MembershipStatus } from '../../types/Membership';
import Membership from '../../types/Membership';
import Org from '../../types/Org';
import Person from '../../types/Person';
import PersonDirectory from '../person/PersonDirectory';

export interface OrgMemberDirectoryProps {
  org: Org;
  membershipStatus?: MembershipStatus;
  orgMemberType: OrgMemberType;
}

export const OrgMemberDirectory = memo((props: OrgMemberDirectoryProps): ReactElement => {
  const { org, membershipStatus = 'all' as MembershipStatus, orgMemberType = OrgMemberType.MEMBER } = props;
  const { isGod } = useAuth();
  const { isOrgAdmin, isSelf, isOrgMember, isOrgActive, isOrgPro } = useData();
  const { deactivateOrgMembership, deleteOrgMembership, addOrgMembership, updateMembership } = useMembershipMutators();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [confirmingId, setConfirmingId] = useState<string | null>(null);
  const [currentPerson, setCurrentPerson] = useState<Person | null>(null);
  const [isUserOrgAdmin, setIsUserOrgAdmin] = useState(false);

  const [selfActionDialog, setSelfActionDialog] = useState<{
    open: boolean;
    action: string;
    callback: () => void;
  }>({ open: false, action: '', callback: () => {} });
  const [confirmText, setConfirmText] = useState('');

  useEffect(() => {
    if (org && isOrgAdmin(org.itemId)) {
      setIsUserOrgAdmin(true);
    }
  }, [isOrgAdmin, org]);

  const renderActions = useCallback(
    (person: Person) => {
      const isOpen = Boolean(anchorEl) && currentPerson?.itemId === person.itemId;
      const isConfirming = confirmingId === person.itemId;
      const isActive = isOrgActive(org.itemId, person);
      const isPro = isOrgPro(org.itemId, person);
      const isAdmin = isOrgAdmin(org.itemId, person);
      const isMember = isOrgMember(org.itemId, person);

      const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setCurrentPerson(person);
      };

      const handleCloseMenu = () => {
        setAnchorEl(null);
        setCurrentPerson(null);
        setConfirmingId(null);
      };

      const confirmSelfAction = (action: string, callback: () => void) => {
        if (isSelf(person)) {
          setSelfActionDialog({
            open: true,
            action,
            callback,
          });
        } else {
          callback();
        }
      };

      const handleDeactivateMembership = () => {
        if (org && person) {
          deactivateOrgMembership(org, person.itemId);
          console.log('handleDeactivateMembership', person.itemId);
        }
        handleCloseMenu();
      };

      const handleRemovePerson = () => {
        if (org && person) {
          deleteOrgMembership(org, person.itemId);
          console.log('handleRemovePerson', person.itemId);
        }
        handleCloseMenu();
      };

      const handleAdminChange = (isAdmin: boolean) => {
        if (org && person) {
          const partialMembership: Partial<Membership> = {
            orgId: org.itemId,
            itemId: person.itemId,
            isAdmin: isAdmin,
          };
          updateMembership(partialMembership);
        }
        handleCloseMenu();
      };

      const handleProChange = (isPro: boolean) => {
        if (org && person) {
          const partialMembership: Partial<Membership> = {
            orgId: org.itemId,
            itemId: person.itemId,
            isPro: isPro,
          };
          updateMembership(partialMembership);
        }
        handleCloseMenu();
      };

      const handleMembershipChange = (isMember: boolean) => {
        if (org && person) {
          const partialMembership: Partial<Membership> = {
            orgId: org.itemId,
            itemId: person.itemId,
            isMember: isMember,
          };
          updateMembership(partialMembership);
        }
        handleCloseMenu();
      };

      const handleActivateMembership = () => {
        if (org && person) {
          addOrgMembership(org, person.itemId);
          console.log('handleAddPerson', person.itemId);
        }
        handleCloseMenu();
      };

      const handleConfirmRemoval = () => {
        setConfirmingId(person.itemId);
      };

      return {
        isOpen,
        actions: (
          <>
            <IconButton onClick={handleOpenMenu}>
              <MoreVertIcon />
            </IconButton>

            <Menu
              anchorEl={anchorEl}
              open={isOpen}
              onClose={handleCloseMenu}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              {!isConfirming
                ? [
                    // Active status toggle
                    <MenuItem
                      key="active"
                      onClick={() => {
                        if ((isActive || isAdmin) && isSelf(person)) {
                          confirmSelfAction('deactivate your org membership', handleDeactivateMembership);
                        } else {
                          isActive ? handleDeactivateMembership() : handleActivateMembership();
                        }
                      }}
                      disabled={!isUserOrgAdmin}
                    >
                      <Checkbox checked={isActive} />
                      <ListItemText primary="Active" />
                    </MenuItem>,

                    isGod && (
                      <MenuItem
                        key="member"
                        onClick={() => {
                          handleMembershipChange(!isMember);
                        }}
                      >
                        <Checkbox checked={isMember} />
                        <ListItemText primary="Member" />
                      </MenuItem>
                    ),

                    // Admin status toggle - only for active members
                    <MenuItem
                      key="admin"
                      onClick={() => {
                        if (isAdmin && isSelf(person)) {
                          confirmSelfAction('revoke your admin privileges', () => handleAdminChange(false));
                        } else {
                          handleAdminChange(!isAdmin);
                        }
                      }}
                      disabled={!isUserOrgAdmin}
                    >
                      <Checkbox checked={isAdmin} />
                      <ListItemText primary="Admin" />
                    </MenuItem>,

                    // Pro status - placeholder for future implementation
                    isGod && (
                      <MenuItem
                        key="pro"
                        onClick={() => {
                          if (isPro && isSelf(person)) {
                            confirmSelfAction('revoke your Pro status', () => handleProChange(false));
                          } else {
                            handleProChange(!isPro);
                          }
                        }}
                        disabled={!isUserOrgAdmin}
                      >
                        <Checkbox checked={isPro} />
                        <ListItemText primary="Pro" />
                      </MenuItem>
                    ),

                    // Conditional remove option for inactive members
                    ...(!isActive
                      ? [
                          <Divider key="divider" />,
                          <MenuItem key="remove" onClick={handleConfirmRemoval}>
                            <ListItemIcon>
                              <PersonRemoveIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Remove" />
                          </MenuItem>,
                        ]
                      : []),
                  ]
                : [
                    <MenuItem key="confirm" onClick={handleRemovePerson}>
                      <ListItemIcon>
                        <PersonRemoveIcon fontSize="small" color="error" />
                      </ListItemIcon>
                      <ListItemText primary="Confirm removal" />
                    </MenuItem>,
                    <MenuItem key="cancel" onClick={handleCloseMenu}>
                      <ListItemIcon>
                        <CancelIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="Cancel" />
                    </MenuItem>,
                  ]}
            </Menu>
          </>
        ),
      };
    },
    [
      anchorEl,
      confirmingId,
      currentPerson,
      org,
      deactivateOrgMembership,
      deleteOrgMembership,
      addOrgMembership,
      updateMembership,
      isOrgAdmin,
      isSelf,
      isOrgPro,
      isOrgMember,
      isOrgActive,
      isGod,
      isUserOrgAdmin,
    ]
  );

  return (
    <>
      <PersonDirectory
        orgId={org.itemId}
        membershipStatus={membershipStatus}
        orgMemberType={orgMemberType}
        isAdminHighlighted={orgMemberType === OrgMemberType.MEMBER}
        renderActions={isOrgAdmin(org.itemId) || isOrgPro(org.itemId) ? renderActions : undefined}
        showCopyEmailList={isOrgAdmin(org.itemId) || isOrgPro(org.itemId)}
      />

      <Dialog
        open={selfActionDialog.open}
        onClose={() => {
          setSelfActionDialog({ ...selfActionDialog, open: false });
          setConfirmText('');
        }}
      >
        <DialogTitle>Irreversible Action</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You are about to {selfActionDialog.action}. This action cannot be undone by you, and you will need another
            admin to restore your privileges.
          </DialogContentText>
          <DialogContentText sx={{ mt: 2 }}>
            To confirm this action, please type &quot;confirm&quot; below:
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            fullWidth
            value={confirmText}
            onChange={(e) => setConfirmText(e.target.value)}
            placeholder={'Type confirm here'}
            variant="outlined"
            sx={{ mt: 1 }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setSelfActionDialog({ ...selfActionDialog, open: false });
              setConfirmText('');
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              selfActionDialog.callback();
              setSelfActionDialog({ ...selfActionDialog, open: false });
              setConfirmText('');
            }}
            color="error"
            disabled={confirmText.trim().toLowerCase() !== 'confirm'}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
});

OrgMemberDirectory.displayName = 'OrgMemberDirectory';

export default OrgMemberDirectory;
