import React, { ReactElement, useEffect, useRef, useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { Stack, useMediaQuery, useTheme } from '@mui/material';

import useMembershipsForLeague from '../../dataHooks/useMembershipsForLeague';
import { useMembershipMutators } from '../../dataMutators/useMembershipMutators';
import League from '../../types/League';
import Membership from '../../types/Membership';
import Person from '../../types/Person';
import PersonAutocomplete from '../person/PersonAutocomplete';

type LeagueMembersProps = {
  league: League;
  isEditable?: boolean;
};

export default function LeagueMembers(props: LeagueMembersProps): ReactElement {
  const { league, isEditable } = props;

  const { addLeagueMembership } = useMembershipMutators();
  const personSelected = useRef<Person | null>(null);
  const [addPersonFieldValue, setAddPersonFieldValue] = useState<Membership | null>(null);
  const theme = useTheme();
  const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const [numColumns, setNumColumns] = useState<number>(3);

  // Create a hook that wraps useMembershipsForLeague to match PeopleHookResult
  const useMembershipsHook = () => {
    const { memberships, isMembershipsLoading, isMembershipsError } = useMembershipsForLeague({
      orgId: league.orgId,
      leagueId: league.itemId,
    });
    return {
      items: memberships || undefined,
      isLoading: isMembershipsLoading,
      error: isMembershipsError || undefined,
    };
  };

  const { items: memberships, isLoading: isMembershipsLoading, error: isMembershipsError } = useMembershipsHook();

  useEffect(() => {
    if (isExtraSmallScreen) {
      setNumColumns(2);
    } else if (isSmallScreen) {
      setNumColumns(3);
    } else if (isMediumScreen) {
      setNumColumns(4);
    } else {
      setNumColumns(6);
    }
  }, [isExtraSmallScreen, isSmallScreen, isMediumScreen]);

  useEffect(() => {
    if (isMembershipsError) {
      console.error('Error loading league memberships', isMembershipsError);
    }
  }, [isMembershipsError]);

  const handleSelectionChange = (_event: React.SyntheticEvent, value: Membership | null, _reason: string) => {
    setAddPersonFieldValue(value);
  };

  const handleAddPerson = () => {
    if (personSelected.current !== null) {
      console.log('Adding person: ' + personSelected.current.name);
      addLeagueMembership(league, personSelected.current.itemId);
      setAddPersonFieldValue(null);
    }
  };

  function splitMembershipsIntoGroups(memberships: Membership[] | undefined): Membership[][] {
    const membershipGroups: Membership[][] = [];
    if (memberships && memberships.length > 0) {
      const chunkSize = Math.ceil(memberships.length / numColumns);

      for (let i = 0; i < memberships.length; i += chunkSize) {
        membershipGroups.push(memberships.slice(i, i + chunkSize));
      }
    }
    return membershipGroups;
  }

  // For MemberMember events, assumes that if a partnership has been established,
  // the attendee has the partner info and the partner may not have registered or confirmed the partnership.
  interface MembershipRowProps {
    membership: Membership;
  }

  const MembershipRow = (props: MembershipRowProps): ReactElement => {
    return (
      <Typography key={props.membership.itemId} variant="body1">
        {props.membership.name}
      </Typography>
    );
  };

  if (!isMembershipsLoading && memberships && memberships.length > 0) {
    const membershipGroups = splitMembershipsIntoGroups(memberships);
    return (
      <Stack spacing={2} sx={{ flexGrow: 1 }}>
        <Grid container size={{ xs: 12 }}>
          {memberships &&
            membershipGroups.map((membershipGroup: Membership[], groupIndex: number) => (
              <Grid key={groupIndex} size={{ xs: 6, sm: 4, md: 3, lg: 2 }}>
                {membershipGroup.map((membership: Membership) => (
                  <MembershipRow key={groupIndex + membership.itemId} membership={membership} />
                ))}
              </Grid>
            ))}
        </Grid>
        {isEditable && (
          <Stack direction="row" spacing={2}>
            <PersonAutocomplete<Membership>
              id="addPerson"
              size="small"
              label="Member to add"
              selectedPersonId={addPersonFieldValue ? addPersonFieldValue.itemId : ''}
              handleOnChange={handleSelectionChange}
              usePeopleHook={useMembershipsHook}
            />
            <Button
              type="button"
              variant="outlined"
              color="secondary"
              size="large"
              onClick={() => handleAddPerson()}
            >
              Add
            </Button>
          </Stack>
        )}
      </Stack>
    );
  } else {
    return <Box />;
  }
}
