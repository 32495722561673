import { doc, getDoc, setDoc, Timestamp, deleteDoc, collection, updateDoc } from 'firebase/firestore';

import { useAuth } from '../contextProviders/AuthProvider';
import { useData } from '../contextProviders/DataProvider';
import { firestore } from '../firebase';
import Result from '../types/Result';
import Season from '../types/Season';

export const useSeasonMutators = () => {
  const { isGod } = useAuth();
  const { isLeagueAdmin, userProfile } = useData();

  // TODO: Should we use Partial for other Adds? Since creatorId, createTime and itemId are all added at this layer...
  const addSeason = async (season: Partial<Season>): Promise<Season | null> => {
    if (!userProfile) {
      console.error('User profile not found');
      return null;
    }

    if (!season?.orgId) {
      console.error('Season orgId not found');
      return null;
    }

    try {
      const seasonRef = doc(collection(firestore, `org/${season.orgId}/season`));
      const newSeason = {
        ...season,
        creatorId: userProfile.itemId,
        createTime: Timestamp.now(),
        itemId: seasonRef.id,
      };

      await setDoc(seasonRef, newSeason);
      return newSeason as Season;
    } catch (error) {
      console.error('Error adding season:', error);
      return null;
    }
  };

  const addStandingToSeason = async (season: Season, standing: Result): Promise<void> => {
    if (!season.orgId) {
      console.error('Season orgId not found');
      return;
    }

    if (!isLeagueAdmin(season.leagueId, season.orgId)) {
      console.error('Permission denied. User:', userProfile?.itemId, 'Season:', season.itemId);
      return;
    }

    try {
      const seasonRef = doc(firestore, `org/${season.orgId}/season/${season.itemId}`);
      const seasonDoc = await getDoc(seasonRef);

      if (!seasonDoc.exists()) {
        console.error('Season not found:', season.itemId);
        return;
      }

      const currentSeason = seasonDoc.data() as Season;
      const standings = [...(currentSeason.standings || []), standing];

      await setDoc(seasonRef, { ...currentSeason, standings }, { merge: true });
    } catch (error) {
      console.error('Error updating season standings:', error);
    }
  };

  const deleteSeason = async (season: Season, isPermanent = false): Promise<void> => {
    if (!season.orgId || !season.itemId) {
      console.error('Season orgId or itemId not found');
      return;
    }

    if (season.isCatchAll) {
      console.error('Cannot delete catch-all season');
      return;
    }

    if (!isLeagueAdmin(season.leagueId, season.orgId)) {
      console.error('Permission denied. User:', userProfile?.itemId, 'Season:', season.itemId);
      return;
    }

    try {
      const seasonRef = doc(firestore, `org/${season.orgId}/season/${season.itemId}`);
      // TODO: All events in this season would need to be moved to the catch-all season or deleted (add parameter to decide)
      if (isPermanent) {
        console.log('Deleting season:', season.itemId);
        await deleteDoc(seasonRef);
      } else {
        console.log('Soft deleting season:', season.itemId);
        await updateDoc(seasonRef, { ...season, isDeleted: true });
      }
    } catch (error) {
      console.error('Error deleting season:', error);
    }
  };

  const updateSeason = async (season: Partial<Season>) => {
    try {
      const { orgId, itemId, leagueId, orgName, leagueName, isCatchAll, isTest, ...mutableUpdates } = season;

      if (!orgId || !itemId || !leagueId) {
        console.error('Missing required fields:', { orgId, itemId, leagueId });
        return;
      }

      if (!isLeagueAdmin(leagueId, orgId)) {
        console.error('Permission denied: must be admin of league');
        return;
      }

      const seasonUpdates = isGod ? { ...mutableUpdates, isCatchAll, isTest } : { ...mutableUpdates };

      const seasonRef = doc(firestore, `org/${orgId}/season/${itemId}`);
      await updateDoc(seasonRef, seasonUpdates);
    } catch (error) {
      console.error('Error updating season:', error);
    }
  };

  return {
    addSeason,
    addStandingToSeason,
    deleteSeason,
    updateSeason,
  };
};
