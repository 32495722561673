import { deleteDoc, Timestamp, updateDoc, writeBatch } from 'firebase/firestore';
import { arrayUnion, getDoc } from 'firebase/firestore';
import { doc, setDoc } from 'firebase/firestore';

import { Person } from '../../shared/dist/types/Person';
import { useData } from '../contextProviders/DataProvider';
import { firestore } from '../firebase';
import DefaultObjects from '../types/DefaultObjects';
import League from '../types/League';
import Membership from '../types/Membership';
import { MembershipStatus } from '../types/Membership';
import Org from '../types/Org';

export const useMembershipMutators = () => {
  const { userProfile, isOrgAdmin, isOrgPro } = useData();

  const updateMembership = async (membership: Partial<Membership>): Promise<void> => {
    if (!membership.orgId || !membership.itemId) {
      console.error('Missing required fields:', {
        orgId: membership.orgId,
        itemId: membership.itemId,
      });
      return;
    }

    try {
      const { orgId, itemId, ...membershipUpdates } = membership;
      const membershipRef = doc(firestore, `org/${orgId}/membership/${itemId}`);
      await setDoc(membershipRef, { ...membershipUpdates }, { merge: true });
    } catch (error) {
      console.error('Error updating membership:', error);
    }
  };

  const addMembership = async (membership: Partial<Membership>): Promise<Membership | null> => {
    if (!membership.orgId || !membership.itemId) {
      console.error('Missing required fields:', {
        orgId: membership.orgId,
        itemId: membership.itemId,
      });
      return null;
    }

    try {
      const membershipRef = doc(firestore, `org/${membership.orgId}/membership/${membership.itemId}`);
      const newMembership = {
        ...membership,
        createTime: Timestamp.now(),
        creatorId: userProfile?.itemId || '',
      };

      await setDoc(membershipRef, newMembership);
      return newMembership as Membership;
    } catch (error) {
      console.error('Error adding membership:', error);
      return null;
    }
  };

  const addOrgMembership = async (org: Org, personId: string): Promise<Membership | null> => {
    try {
      const personRef = doc(firestore, 'profile', personId);
      const personDoc = await getDoc(personRef);
      const person = personDoc.data() as Person;

      if (!person) {
        console.error('Member profile not found:', personId);
        return null;
      }

      let newMembership: Membership | null = null;

      // Handle membership document
      const membershipRef = doc(firestore, `org/${org.itemId}/membership/${personId}`);
      const membershipDoc = await getDoc(membershipRef);

      if (membershipDoc.exists()) {
        const membership = membershipDoc.data() as Membership;
        if (!membership.isMember || membership.status !== ('active' as MembershipStatus)) {
          newMembership = { ...membership, isMember: true, status: 'active' as MembershipStatus };
          await updateDoc(membershipRef, { ...newMembership });
        } else {
          console.log('Membership already exists:', person.itemId);
        }
      } else {
        newMembership = {
          ...DefaultObjects.Membership,
          creatorId: userProfile?.itemId || '',
          isMember: true,
          isAdmin: false,
          itemId: personId,
          name: person.name,
          orgId: org.itemId,
          status: 'active' as MembershipStatus,
        };
        await setDoc(membershipRef, newMembership);
      }

      return newMembership;
    } catch (error) {
      console.error('Error adding org membership:', error);
      return null;
    }
  };

  const acceptOrgMembership = async (org: Org, personId?: string): Promise<void> => {
    const acceptingPersonId = personId || userProfile?.itemId;

    if (!acceptingPersonId) {
      console.error('No person ID provided and no user profile found');
      return;
    }

    if (!org.itemId) {
      console.error('Org itemId not found');
      return;
    }

    try {
      const membershipRef = doc(firestore, `org/${org.itemId}/membership/${acceptingPersonId}`);
      await updateDoc(membershipRef, { isPending: false });
    } catch (error) {
      console.error('Error accepting org membership:', error);
    }
  };

  const deactivateOrgMembership = async (org: Org, personId: string): Promise<void> => {
    try {
      // Update membership status
      const membershipRef = doc(firestore, `org/${org.itemId}/membership/${personId}`);

      if (!membershipRef) {
        console.error('Membership not found:', personId);
        return;
      }

      await updateDoc(membershipRef, { isActive: false });
    } catch (error) {
      console.error('Error deactivating org membership:', error);
    }
  };

  const deleteOrgMembership = async (org: Org, personId: string, isPermanent: boolean = false) => {
    try {
      const personRef = doc(firestore, 'profile', personId);
      const personDoc = await getDoc(personRef);
      const person = personDoc.data() as Person;
      if (person) {
        const membershipRef = doc(firestore, 'org/' + org.itemId + '/membership/' + personId);
        if (isPermanent) {
          await deleteDoc(membershipRef);
        } else {
          console.log('Deleting org membership:', personId);
          await updateDoc(membershipRef, { isMember: false });
        }
      } else {
        throw new Error('Person not found: ' + personId);
      }
    } catch (error) {
      alert(error);
    }
  };

  const addLeagueMembership = async (league: League, memberId: string): Promise<void> => {
    try {
      const membershipRef = doc(firestore, `org/${league.orgId}/membership/${memberId}`);
      const membershipDoc = await getDoc(membershipRef);

      if (!membershipDoc.exists()) {
        console.error('Membership not found:', memberId);
        return;
      }

      const membership = membershipDoc.data() as Membership;

      if (membership.leagues?.includes(league.itemId)) {
        return;
      }

      const updateLeagues = {
        leagues: arrayUnion(membership.leagues, league.itemId),
      };

      // Update both membership and profile documents
      await updateDoc(membershipRef, updateLeagues);
    } catch (error) {
      console.error('Error adding league membership:', error);
    }
  };

  const updateMemberships = async (orgId: string, updates: Partial<Membership>[]): Promise<void> => {
    if (!orgId) {
      console.error('Missing required field: orgId');
      return;
    }

    if (!isOrgAdmin(orgId) && !isOrgPro(orgId)) {
      console.error('Permission denied: must be admin of organization');
      return;
    }

    try {
      const batch = writeBatch(firestore);

      updates.forEach((update) => {
        if (!update.itemId) {
          console.error('Missing required field: itemId in membership update');
          return;
        }
        const membershipRef = doc(firestore, `org/${orgId}/membership/${update.itemId}`);
        batch.update(membershipRef, update);
      });

      await batch.commit();
    } catch (error) {
      console.error('Error updating memberships:', error);
    }
  };

  return {
    updateMembership,
    addMembership,
    addOrgMembership,
    acceptOrgMembership,
    deactivateOrgMembership,
    deleteOrgMembership,
    addLeagueMembership,
    updateMemberships,
  };
};
