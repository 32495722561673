import { ReactElement } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import BadgeIcon from '@mui/icons-material/Badge';
import ShieldIcon from '@mui/icons-material/Shield';

import useOrgs from '../../dataHooks/useOrgs';
import { useMembershipMutators } from '../../dataMutators/useMembershipMutators';
import { MembershipStatus } from '../../types/Membership';
import Membership from '../../types/Membership';
import PgaIcon from '../common/PgaIcon';

interface OrgInvitationResponderProps {
  pendingMemberships: Membership[];
}

export default function OrgInvitationResponder({ pendingMemberships }: OrgInvitationResponderProps): ReactElement {
  const { updateMembership } = useMembershipMutators();
  const { orgs: pendingOrgs } = useOrgs({ membershipStatus: ['pending'] });

  const handleAccept = async (membership: Membership) => {
    await updateMembership({
      ...membership,
      status: 'active' as MembershipStatus,
    });
  };

  const handleReject = async (membership: Membership) => {
    await updateMembership({
      ...membership,
      status: 'inactive' as MembershipStatus,
    });
  };

  if (!pendingMemberships || pendingMemberships.length === 0) {
    return <></>;
  }

  return (
    <Container disableGutters className="SectionContainer">
      <Typography align="left" gutterBottom variant="headline">
        Organization Invitations
      </Typography>
      <Typography align="left" variant="body1" gutterBottom>
        You have been invited to join the following organizations. Accept or reject each invitation to manage your
        memberships.
      </Typography>
      <Box>
        <List
          sx={{
            width: '100%',
            height: '100%',
            position: 'relative',
            overflow: 'auto',
            p: 0,
            '& ul': { padding: 0 },
            '& li': { padding: 0 },
          }}
          subheader={<li />}
        >
          {pendingMemberships.map((membership) => {
            const org = pendingOrgs.find((org) => org.itemId === membership.orgId);
            return (
              <ListItem
                key={membership.itemId}
                divider
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  py: 1,
                }}
              >
                <ListItemAvatar sx={{ display: { xs: 'none', sm: 'flex' } }}>
                  {org?.logo && (
                    <Box
                      component="img"
                      src={org.logo}
                      alt={`${org.name} logo`}
                      sx={{
                        width: 40,
                        height: 40,
                        borderRadius: '50%',
                        objectFit: 'cover',
                        backgroundColor: 'action.hover',
                      }}
                    />
                  )}
                </ListItemAvatar>
                <ListItemAvatar sx={{ color: 'surface.contrastText' }}>
                  <Stack
                    direction="column"
                    spacing={0}
                    sx={{
                      alignItems: 'flex-start',
                      justifyContent: 'space-around',
                      width: { xs: '6rem', sm: '8rem', md: '12rem' },
                    }}
                  >
                    <Typography variant="h6" sx={{ display: { xs: 'block', md: 'none' } }}>
                      {org?.name || 'Loading...'}
                    </Typography>
                    <Typography variant="overline" sx={{ display: { xs: 'block', md: 'none' } }}>
                      {org?.name || 'Loading...'}
                    </Typography>
                    <Typography variant="h6" sx={{ display: { xs: 'none', md: 'block' } }}>
                      {org?.name || 'Loading...'}
                    </Typography>
                  </Stack>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Stack direction="column" spacing={0.5}>
                      {membership.isAdmin && (
                        <Stack direction="row" spacing={0.5} alignItems="center">
                          <ShieldIcon fontSize="small" color="primary" />
                          <Typography variant="body2">Admin</Typography>
                        </Stack>
                      )}
                      {membership.isPro && (
                        <Stack direction="row" spacing={0.5} alignItems="center">
                          <PgaIcon fontSize="small" color="primary" />
                          <Typography variant="body2">Pro</Typography>
                        </Stack>
                      )}
                      {membership.isMember && (
                        <Stack direction="row" spacing={0.5} alignItems="center">
                          <BadgeIcon fontSize="small" color="primary" />
                          <Typography variant="body2">Member</Typography>
                        </Stack>
                      )}
                    </Stack>
                  }
                  sx={{ pr: 2 }}
                />
                <Box sx={{ ml: 'auto', display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Stack direction="row" spacing={1}>
                    <Button size="small" color="primary" variant="contained" onClick={() => handleAccept(membership)}>
                      Accept
                    </Button>
                    <Button size="small" color="error" variant="outlined" onClick={() => handleReject(membership)}>
                      Reject
                    </Button>
                  </Stack>
                </Box>
              </ListItem>
            );
          })}
        </List>
      </Box>
    </Container>
  );
}
