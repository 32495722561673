import { doc, deleteDoc, setDoc, collection } from "firebase/firestore";

import { createCollection, firestore } from "../firebase";
import Application from "../types/Application";

export const useApplicationMutators = () => {
  const applicationsCol = createCollection<Application>('application');

  const addApplication = async (
    application: Application
  ): Promise<void> => {

    try {
      // Get org details
      const applicationRef = doc(collection(firestore, 'application'));
      await setDoc(applicationRef, application);
    } catch (error) {
      console.error('Error adding application:', error);
      // TODO: Consider proper error handling since this can be called by anyone
    }
  };

  const deleteApplication = async (appId: string): Promise<void> => {
    if (!appId) {
      console.error('No application ID provided');
      return;
    }

    try {
      const applicationRef = doc(applicationsCol, appId);
      await deleteDoc(applicationRef);
    } catch (error) {
      console.error('Error deleting application:', error);
    }
  };

  const updateApplication = async (appId: string, isApproved: boolean, isRejected: boolean) => {
    if (!appId) {
      console.error('No application ID provided');
      return;
    }

    try {
      // reference to the document to update
      const applicationRef = doc(applicationsCol, appId);

      // Update the value of the application
      await setDoc(applicationRef, { isApproved: isApproved, isRejected: isRejected }, { merge: true });
    } catch (error) {
      alert(error);
    }
  };

  return {
    addApplication,
    deleteApplication,
    updateApplication,
  };
};