import { ChangeEvent, FormEvent, useEffect, useState } from 'react';

import { confirmPasswordReset, verifyPasswordResetCode } from 'firebase/auth';

import { useNavigate, useSearchParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import PasswordIcon from '@mui/icons-material/Password';

import GratiPageWrapper from '../components/common/GratiPageWrapper';
import { useApp } from '../contextProviders/AppProvider';
import { auth } from '../firebase';

export default function AccountResetPasswordPage() {
  const { setPageName } = useApp();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const oobCode = searchParams.get('oobCode');

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [email, setEmail] = useState('');
  const [isCodeValid, setIsCodeValid] = useState<boolean | null>(null);
  const [isResetComplete, setIsResetComplete] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [passwordError, setPasswordError] = useState<string | null>(null);

  useEffect(() => {
    setPageName('Reset Password');
  }, [setPageName]);

  useEffect(() => {
    if (!oobCode) {
      setIsCodeValid(false);
      setError('Missing reset code. Please use the link from your email.');
      return;
    }

    const verifyCode = async () => {
      verifyPasswordResetCode(auth, oobCode)
        .then((email) => {
          setIsCodeValid(true);
          setEmail(email);
        })
        .catch((error) => {
          setIsCodeValid(false);
          setError(error.message || 'Invalid or expired reset link. Please request a new one.');
        });
    };

    verifyCode();
  }, [oobCode]);

  const validatePasswords = (): boolean => {
    if (password.length < 8) {
      setPasswordError('Password must be at least 8 characters long');
      return false;
    }

    if (password !== confirmPassword) {
      setPasswordError('Passwords do not match');
      return false;
    }

    setPasswordError(null);
    return true;
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!validatePasswords() || !oobCode) {
      return;
    }

    try {
      await confirmPasswordReset(auth, oobCode, password);
      setIsResetComplete(true);
      // Redirect after a short delay to show success message
      setTimeout(() => {
        navigate('/login');
      }, 2000);
    } catch (error) {
      console.error('Error resetting password:', error);
      setError(
        error instanceof Error && error.message.includes('expired-action-code')
          ? 'This reset link has expired. Please request a new one.'
          : 'Failed to reset password. Please try again.'
      );
    }
  };

  return (
    <GratiPageWrapper isNoAuthRequired={true} isAuthRequired={false}>
      <>
        {isResetComplete ? (
          <Stack spacing={2} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography variant="h6" color="primary.text" align="center">
              Your password has been reset successfully!
            </Typography>
            <Typography variant="body1" align="center">
              You can now log in with your new password.
            </Typography>
          </Stack>
        ) : isCodeValid === false ? (
          <Stack spacing={2} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <ErrorOutlineIcon color="error" sx={{ fontSize: 60 }} />
            <Typography variant="h6" color="error" align="center">
              {error || 'Invalid reset link'}
            </Typography>
            <Button variant="contained" onClick={() => navigate('/account-forgot-password')}>
              Request New Reset Link
            </Button>
          </Stack>
        ) : isCodeValid === true ? (
          <Box component="form" onSubmit={handleSubmit} sx={{ maxWidth: 'sm', margin: 'auto' }}>
            <Stack spacing={3} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Typography variant="h6" align="center">
                Reset Password for {email}
              </Typography>

              <TextField
                fullWidth
                required
                type="password"
                id="password"
                label="New Password"
                value={password}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                error={!!passwordError}
                helperText={passwordError || 'At least 8 characters recommended'}
                inputProps={{ minLength: 8 }}
              />

              <TextField
                fullWidth
                required
                type="password"
                id="confirmPassword"
                label="Confirm Password"
                value={confirmPassword}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setConfirmPassword(e.target.value)}
                error={!!passwordError}
              />

              <Button
                type="submit"
                variant="contained"
                startIcon={<PasswordIcon />}
                disabled={!password || !confirmPassword}
              >
                Reset Password
              </Button>
            </Stack>
          </Box>
        ) : (
          <Stack spacing={2} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography variant="body1" align="center">
              Verifying reset link...
            </Typography>
          </Stack>
        )}
      </>
    </GratiPageWrapper>
  );
}
