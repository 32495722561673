import { collection, doc, deleteDoc, setDoc, Timestamp, updateDoc } from 'firebase/firestore';

import { useAuth } from '../contextProviders/AuthProvider';
import { useData } from '../contextProviders/DataProvider';
import { firestore } from '../firebase';
import League from '../types/League';

export const useLeagueMutators = () => {
  const { isGod } = useAuth();
  const { isLeagueAdmin, isOrgAdmin, isOrgPro, userProfile } = useData();

  const addLeague = async (league: League, isTest = false): Promise<League | null> => {
    if (!userProfile) {
      console.error('User profile not found');
      return null;
    }

    if (!league.orgId) {
      console.error('League orgId not found');
      return null;
    }

    if (!isOrgAdmin(league.orgId) && !isOrgPro(league.orgId)) {
      console.error('Permission denied: must be admin of organization');
      return null;
    }

    try {
      const leagueRef = doc(collection(firestore, `org/${league.orgId}/league`));
      const newLeague: League = {
        ...league,
        creatorId: userProfile.itemId,
        createTime: Timestamp.now(),
        itemId: leagueRef.id,
        isTest,
      };

      await setDoc(leagueRef, newLeague);
      return newLeague;
    } catch (error) {
      console.error('Error adding league:', error);
      return null;
    }
  };

  const deleteLeague = async (league: League, isPermanent = false): Promise<void> => {
    if (!league.itemId || !league.orgId) {
      console.error('League itemId or orgId not found:', league.itemId, league.orgId);
      return;
    }

    if (league?.isCatchAll && !isGod) {
      console.error('Cannot modify catch-all league. user: ', userProfile?.itemId);
      return;
    }

    if (!isOrgAdmin(league.orgId) && !isOrgPro(league.orgId)) {
      console.error('Permission denied. User:', userProfile?.itemId, 'League:', league.itemId);
      return;
    }

    try {
      const leagueRef = doc(firestore, `org/${league.orgId}/league/${league.itemId}`);
      if (isPermanent) {
        await deleteDoc(leagueRef);
      } else {
        await updateDoc(leagueRef, { ...league, isDeleted: true });
      }
    } catch (error) {
      console.error('Error deleting league:', error);
    }
  };

  const updateLeague = async (league: Partial<League>): Promise<void> => {
    if (!league.orgId || !league.itemId) {
      console.error('Missing required fields:', { orgId: league.orgId, itemId: league.itemId });
      return;
    }

    if (league?.isCatchAll && !isGod) {
      console.error('Cannot modify catch-all league. user: ', userProfile?.itemId);
      return;
    }

    if (!isLeagueAdmin(league.itemId, league.orgId) && !isOrgAdmin(league.orgId) && !isOrgPro(league.orgId)) {
      console.error('Permission denied: must be admin of organization');
      return;
    }

    try {
      // extract the orgId and itemId from the league object as changing them is not allowed
      const { orgId, itemId, ...leagueUpdates } = league;
      const leagueRef = doc(firestore, `org/${orgId}/league/${itemId}`);
      await setDoc(leagueRef, { ...leagueUpdates }, { merge: true });
    } catch (error) {
      console.error('Error updating league:', error);
    }
  };

  return {
    addLeague,
    deleteLeague,
    updateLeague,
  };
};
