import { ReactElement, useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import Filter9Icon from '@mui/icons-material/Filter9';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

import useEventRegs from '../../dataHooks/useEventRegs';
import useMembershipsForLeague from '../../dataHooks/useMembershipsForLeague';
import { useEventRegistrationMutators } from '../../dataMutators/useEventRegistrationMutators';
import Event from '../../types/Event';
import Membership from '../../types/Membership';
import Registration from '../../types/Registration';
import Hourglass from '../common/Hourglass';
import PersonAutocomplete from '../person/PersonAutocomplete';

type EventAttendeesProps = {
  event: Event;
  isEditable?: boolean;
};

export default function EventAttendees(props: EventAttendeesProps): ReactElement {
  const event = props.event;
  const isEditable = props.isEditable;

  const { addEventRegistration, updateEventRegistration } = useEventRegistrationMutators();
  const [addPersonFieldValue, setAddPersonFieldValue] = useState<Membership | null>(null);
  const [regList, setRegList] = useState<Registration[]>([]);
  const [waitList, setWaitList] = useState<Registration[]>([]);
  const [deletingPlayerId, setDeletingPlayerId] = useState<string | null>(null);

  // Create a hook that wraps useMembershipsForLeague to match PeopleHookResult
  const useMembershipsHook = () => {
    const { memberships, isMembershipsLoading, isMembershipsError } = useMembershipsForLeague({
      orgId: event.orgId,
      leagueId: event.leagueId,
    });
    return {
      items: memberships || undefined,
      isLoading: isMembershipsLoading,
      error: isMembershipsError || undefined,
    };
  };

  const { regs, isRegsLoading, isRegsError } = useEventRegs({
    orgId: event.orgId,
    eventId: event.itemId,
    isRegistered: true,
  });

  useEffect(() => {
    if (isRegsLoading) {
      console.log('Loading registrations');
    } else if (regs) {
      if (event.participantOrder === 'ghinIndex') {
        setRegList(regs.sort((a, b) => (a.ghinIndex ?? 99) - (b.ghinIndex ?? 99)));
      }
      if (event.maxPlayers !== 0 && regs.length > event.maxPlayers) {
        setRegList(regs.slice(0, event.maxPlayers));
        setWaitList(regs.slice(event.maxPlayers, regs.length));
      } else {
        setRegList(regs);
        setWaitList([]);
      }
    } else if (isRegsError) {
      console.log('Error loading registrations: ' + isRegsError);
    }
  }, [regs, isRegsLoading, isRegsError, event.maxPlayers]);

  const handleSelectionChange = (_event: React.SyntheticEvent, value: Membership | null, _reason: string) => {
    setAddPersonFieldValue(value);
  };

  const handleAddPerson = () => {
    if (addPersonFieldValue !== null) {
      console.log('Adding person: ' + addPersonFieldValue.name);
      addEventRegistration(event, true, addPersonFieldValue.itemId, addPersonFieldValue.name);
      setAddPersonFieldValue(null);
    }
  };

  const handleDeleteClick = (playerId: string) => {
    setDeletingPlayerId(playerId);
  };

  const handleDeleteCancel = () => {
    setDeletingPlayerId(null);
  };

  const handleDeleteConfirm = async (attendee: Registration) => {
    await updateEventRegistration(event, attendee.playerId, { isRegistered: false });
    setDeletingPlayerId(null);
  };

  interface AttendeeRowProps {
    attendee: Registration;
    partner?: Registration | null;
    count: number;
  }

  // For MemberMember events, assumes that if a partnership has been established,
  // the attendee has the partner info and the partner may not have registered or confirmed the partnership.
  const AttendeeRow = (props: AttendeeRowProps): ReactElement => {
    const attendee = props.attendee;
    const partner = props.partner;
    const count = props.count;

    console.log('AttendeeRow: partner: ', partner);
    const isPartnerPending = event?.isMemberMember && partner && !partner.partnerId;
    const isSecondaryAction = !(attendee.isPlayingGame && attendee.isEighteen && attendee.isRiding);
    const isDeleting = deletingPlayerId === attendee.playerId;

    const secondaryAction = isSecondaryAction ? (
      <Stack
        direction="row"
        component={'span'}
        spacing={0.25}
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          flexDirection: 'row',
          alignItems: 'center',
          color: 'surface.tint',
        }}
      >
        {!attendee.isPlayingGame && <MoneyOffIcon fontSize="small" />}
        {!attendee.isEighteen && <Filter9Icon fontSize="small" />}
        {!attendee.isRiding && <DirectionsWalkIcon fontSize="small" />}
      </Stack>
    ) : null;

    return (
      <ListItem
        key={attendee.playerId}
        disableGutters
        component="li"
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          py: 0,
          backgroundColor: isDeleting ? 'action.hover' : 'transparent',
        }}
      >
        <Typography
          component="span"
          sx={{
            minWidth: '1rem',
            color: 'text.secondary',
          }}
        >
          {count}.
        </Typography>
        <ListItemText
          primary={attendee.playerName + (attendee.ghinIndex ? ` (${attendee.ghinIndex})` : '')}
          secondary={secondaryAction}
          slotProps={{
            primary: { sx: { color: 'surface.contrastText' } },
            secondary: { sx: { display: isSecondaryAction ? 'flex' : 'none' } },
          }}
          sx={{ pr: 1 }}
        />
        {attendee?.partnerName && (event?.isMemberMember || event?.isMemberGuest) && (
          <ListItemText
            primary={'(' + attendee.partnerName + ')'}
            sx={{
              fontStyle: isPartnerPending ? 'italic' : 'normal',
              color: isPartnerPending ? 'surface.light' : 'surface.onVariant',
            }}
          />
        )}
        {isEditable && (
          <Box sx={{ ml: 'auto', display: 'flex', alignItems: 'center', gap: 1 }}>
            {isDeleting ? (
              <>
                <Button
                  size="small"
                  color="error"
                  onClick={() => handleDeleteConfirm(attendee)}
                  sx={{ minWidth: 'auto' }}
                >
                  Confirm Delete
                </Button>
                <Button size="small" onClick={handleDeleteCancel} sx={{ minWidth: 'auto' }}>
                  Cancel
                </Button>
              </>
            ) : (
              <IconButton
                size="small"
                color="inherit"
                onClick={() => handleDeleteClick(attendee.playerId)}
                sx={{
                  color: 'text.secondary',
                  '&:hover': {
                    color: 'error.main',
                    backgroundColor: 'error.lighter',
                  },
                  padding: 0.5,
                  '& .MuiSvgIcon-root': {
                    fontSize: '1.1rem',
                  },
                }}
              >
                <RemoveCircleOutlineIcon />
              </IconButton>
            )}
          </Box>
        )}
      </ListItem>
    );
  };

  const AttendeesByPartner = (): [Registration, Registration | null][] => {
    const attendees: [Registration, Registration | null][] = [];
    for (let i = 0; i < regList.length; i++) {
      const attendee = regList[i];
      const partner =
        regList.find((reg) => reg.playerId === attendee?.partnerId || reg.partnerId === attendee?.playerId) || null;
      if (
        attendees.find(
          (a) =>
            a[0].playerId === attendee?.playerId ||
            a[0].partnerId === attendee?.playerId ||
            a[0].playerId === attendee?.partnerId
        )
      ) {
        continue;
      }
      if (partner && attendee.partnerId !== partner.playerId) {
        attendees.push([partner, attendee]);
      } else {
        attendees.push([attendee, partner]);
      }
    }
    return [...attendees].sort((a, b) => a[0].playerName.localeCompare(b[0].playerName));
  };

  if (!isRegsLoading && regList) {
    let count = 0;
    return (
      <Stack sx={{ width: 1 }}>
        <Typography variant="headline" gutterBottom>
          Attendees
        </Typography>
        <List
          component="ol"
          dense={true}
          sx={{
            pl: 2,
            pr: 1,
            listStyleType: 'decimal',
          }}
        >
          {AttendeesByPartner().map((attendee) => {
            count++;
            return <AttendeeRow key={count} attendee={attendee[0]} partner={attendee[1]} count={count} />;
          })}
        </List>
        {waitList.length > 0 && (
          <Stack>
            <Typography variant="h5" component="h5" gutterBottom>
              Wait List
            </Typography>
            <List
              component="ol"
              dense={true}
              sx={{
                pl: 2,
                pr: 1,
                listStyleType: 'decimal',
              }}
            >
              {[...waitList]
                .sort((a, b) => (a.datetime < b.datetime ? -1 : 1))
                .map((attendee) => {
                  return <AttendeeRow key={attendee.playerId} attendee={attendee} count={0} />;
                })}
            </List>
          </Stack>
        )}
        {event.isGolf && (
          <Stack
            direction="row"
            spacing={0.5}
            sx={{ py: 1, justifyContent: 'flex-start', alignItems: 'center', color: 'surface.tint' }}
          >
            <MoneyOffIcon />
            <Typography variant="body2" sx={{ pr: 1 }}>
              No Game
            </Typography>
            <Filter9Icon />
            <Typography variant="body2" sx={{ pr: 1 }}>
              9 Holes
            </Typography>
            <DirectionsWalkIcon />
            <Typography variant="body2" sx={{ pr: 1 }}>
              Walking
            </Typography>
          </Stack>
        )}
        {isEditable && (
          <Stack direction="row" spacing={2} sx={{ justifyContent: 'flex-start', alignItems: 'center' }}>
            <PersonAutocomplete<Membership>
              id="addPerson"
              size="small"
              label="Person to add"
              selectedPersonId={addPersonFieldValue ? addPersonFieldValue.itemId : ''}
              handleOnChange={handleSelectionChange}
              usePeopleHook={useMembershipsHook}
            />
            <Button type="button" variant="outlined" color="secondary" size="medium" onClick={() => handleAddPerson()}>
              Add
            </Button>
          </Stack>
        )}
      </Stack>
    );
  } else if (isRegsLoading) {
    return <Hourglass />;
  } else {
    if (isRegsError) {
      console.log('Error loading registrations: ' + isRegsError);
    }
    return <Box />;
  }
}
