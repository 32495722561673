export function validateAddress(address: string | null | undefined): boolean {
  if (!address) {
    return false;
  } else {
    const re = /[!@$%^&*(),?":{}|<>]/;
    return re.test(address.toLowerCase());
  }
}

export function validateEmail(email: string | null | undefined): boolean {
  if (!email) {
    return false;
  } else {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email.toLowerCase());
  }
}

export function validateGHIN(ghin: string | null | undefined): boolean {
  if (!ghin) {
    return false;
  } else {
    const re = /^(?=[0-9]{6,8}$)0*[1-9][0-9]{5,}$/;
    return re.test(ghin.toLowerCase());
  }
}

export function validatePhone(phone: string | null | undefined): boolean {
  if (!phone) {
    return false;
  } else {
    const re = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/;
    return re.test(phone.toLowerCase());
  }
}

export function validatePassword(password: string): boolean {
  return password.length >= 8;
}
