import { ReactElement, useEffect, useState } from "react";

import { collectionGroup, query, Query, where } from "firebase/firestore";

import { Link } from "react-router";

import { useCollectionData } from "react-firebase-hooks/firestore";

import Alert from "@mui/material/Alert";
import Checkbox from "@mui/material/Checkbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { useAuth } from "../../contextProviders/AuthProvider";
import useLeagues from "../../dataHooks/useLeagues";
import { firestore } from "../../firebase";
import Membership from "../../types/Membership";
import Person from "../../types/Person";
import Hourglass from "../common/Hourglass";

interface PersonLeagueEditorProps {
  person: Person,
}

export default function PersonLeagueEditor(props: PersonLeagueEditorProps): ReactElement {
  const { isGod } = useAuth();
  const { person } = props; 
  const { leagues, isLeaguesLoading, leaguesError } = useLeagues({ person, includeCatchAll: isGod });
  const [personLeagues, setPersonLeagues] = useState<string[]>([]);

  const qPersonMemberships = person && isGod ? query(
    collectionGroup(firestore, "membership") as Query<Membership>,
    where("itemId", "==", person.itemId),
  ) : null;

  const [personMemberships, isPersonMembershipsLoading, personMembershipsError] = useCollectionData<Membership>(
    qPersonMemberships
  );

  useEffect(() => {
    if (personMemberships && personMemberships.length > 0) {
      setPersonLeagues(personMemberships?.reduce((acc, membership) => {
        return acc.concat(membership.leagues || []);
      }, [] as string[]));
      console.log(personMemberships);
    } else if (personMembershipsError) {
      console.error(personMembershipsError);
    }
  }, [personMemberships, isPersonMembershipsLoading, personMembershipsError]);

  const handleLeagueClick = (leagueId: string) => {
    updateMembership(person.itemId, leagueId);
    console.log("league clicked: " + leagueId);
  };

  // TODO: The League Editor has to be contextual. If I am viewing my own profile, 
  // they would be read only except for the leagues I am an admin of. If I am viewing 
  // someone else's profile, I can edit only the leagues I am an admin of.
  if (isLeaguesLoading) { 
    return <Hourglass />;
  } else if (leaguesError) {
    return <Alert severity="error">Error loading leagues {leaguesError.message}</Alert>;
  } else if (leagues && leagues.length > 0 && personLeagues && personLeagues.length > 0) {
    return (
      <Stack direction="column">
        <Typography variant="label">Leagues</Typography>
        <List dense disablePadding>
          {leagues.map((league) => {
              return (
                <ListItem 
                  key={league.itemId} 
                  component={Link} 
                  to={`/league/${league.orgId}/${league.itemId}`} 
                  sx={{ color: 'surface.contrastText', py: 0 }}
                >
                  <ListItemButton onClick={() => handleLeagueClick(league.itemId)}>
                    <Checkbox
                      edge="start"
                      checked={personLeagues.includes(league.itemId)}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': league.itemId }}
                    />
                    <ListItemText>
                      {league.name}
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              );
            })}
        </List>
      </Stack>
    );
  } else {
    return <></>;
  }
}

function updateMembership(itemId: string, leagueId: string) {
  throw new Error("Function not implemented.");
}
