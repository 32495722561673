import { ReactElement, useState, useCallback } from 'react';

import { Timestamp } from 'firebase/firestore';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import DeleteIcon from '@mui/icons-material/Delete';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { useData } from '../../contextProviders/DataProvider';
import Season from '../../types/Season';
import { FromDayJS, ToDayJS } from '../../utils/utils';

interface SeasonEditorProps {
  season: Season;
  isNew?: boolean;
  onChange?: (value: Partial<Season>) => void;
  onDelete?: (value: Season) => void;
}

export default function SeasonEditor(props: SeasonEditorProps): ReactElement {
  const { season, isNew = false, onChange, onDelete } = props;
  const { isLeagueAdmin, isOrgAdmin, isOrgPro } = useData();
  const [deleteConfirm, setDeleteConfirm] = useState<string>('');

  const handleSeasonChange = useCallback(
    (changes: Partial<Season>) => {
      if (onChange) {
        onChange({ ...changes, itemId: season.itemId, orgId: season.orgId, leagueId: season.leagueId });
      }
    },
    [onChange, season]
  );

  const handleSeasonDelete = () => {
    if (onDelete) {
      onDelete(season);
      setDeleteConfirm('');
    }
  };

  if (season && (isOrgAdmin(season.orgId) || isOrgPro(season.orgId) || isLeagueAdmin(season.leagueId, season.orgId))) {
    return (
      <Stack direction="row" spacing={{ xs: 1, sm: 2, md: 3 }}>
        <TextField
          fullWidth
          label="Name"
          variant="outlined"
          value={season.name}
          sx={{ width: '34%', flexGrow: 1 }}
          onChange={(e) => handleSeasonChange({ name: e.target.value })}
        />
        <DatePicker
          label="Start date"
          aria-label="start date"
          aria-required="true"
          format="MM/DD/YY"
          value={season.startTime instanceof Timestamp ? ToDayJS(season.startTime) : null}
          sx={{ width: '33%', flexGrow: 0 }}
          onAccept={(dayJS) => {
            handleSeasonChange({
              startTime: FromDayJS(dayJS),
              endTime: FromDayJS(dayJS) > season.endTime ? FromDayJS(dayJS) : season.endTime,
            });
          }}
        />
        <DatePicker
          label="End date"
          aria-label="End date"
          aria-required="true"
          format="MM/DD/YY"
          value={season.endTime instanceof Timestamp ? ToDayJS(season.endTime) : null}
          minDate={season.startTime instanceof Timestamp ? ToDayJS(season.startTime) : null}
          sx={{ width: '33%', flexGrow: 0 }}
          onAccept={(dayJS) => {
            handleSeasonChange({ endTime: FromDayJS(dayJS) });
          }}
        />
        {!deleteConfirm && (
          <IconButton aria-label="delete" onClick={() => setDeleteConfirm(season.itemId)}>
            <DeleteIcon />
          </IconButton>
        )}

        {deleteConfirm === season.itemId && (
          <span>
            <Button variant="outlined" size="large" onClick={handleSeasonDelete}>
              Confirm Delete
            </Button>
            <Button size="large" color="secondary" onClick={() => setDeleteConfirm('')}>
              Cancel
            </Button>
          </span>
        )}
      </Stack>
    );
  } else {
    return <></>;
  }
}
