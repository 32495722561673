import { collection, deleteDoc, setDoc } from 'firebase/firestore';
import { doc, updateDoc } from 'firebase/firestore';
import { Timestamp } from 'firebase/firestore';

import { useData } from '../contextProviders/DataProvider';
import { firestore } from '../firebase';
import Event from '../types/Event';
import EventDay from '../types/EventDay';


function calculateEndDatetime(eventDays: EventDay[]): Timestamp {
  if (!eventDays || eventDays.length === 0) {
    return Timestamp.now();
  }

  let latestEndSeconds = 0;

  for (const day of eventDays) {
    console.log('day', day);
    if (!day.datetime) continue;

    // Get seconds from Timestamp
    if (day.datetime instanceof Timestamp) {
      console.log('day.datetime is a Timestamp');
    } else {
      console.log('day.datetime is not a Timestamp');
    }
    const startSeconds = day.datetime instanceof Timestamp ? day.datetime.seconds : (day.datetime as Timestamp).seconds;
    console.log('startSeconds', startSeconds);
    console.log('type of datetime', typeof day.datetime);

    // Add duration in seconds (duration is in minutes)
    const duration = day.duration || 60;
    console.log('duration', duration);
    const endSeconds = startSeconds + duration * 60;
    console.log('endSeconds', endSeconds);

    console.log('latestEndSeconds', latestEndSeconds);
    if (endSeconds > latestEndSeconds) {
      console.log('endSeconds is greater than latestEndSeconds');

      latestEndSeconds = endSeconds;
    }
  }

  console.log('latestEndSeconds', latestEndSeconds);
  return latestEndSeconds > 0 ? Timestamp.fromMillis(latestEndSeconds * 1000) : Timestamp.now();
}

export const useEventMutators = () => {
  const { isLeagueAdmin, userProfile } = useData();

  const addEvent = async (event: Event, isTest = false): Promise<Event | null> => {
    if (!userProfile) {
      console.error('User profile not found');
      return null;
    }

    if (!event.leagueId || !event.orgId) {
      console.error('Missing required event fields:', event.name);
      return null;
    }

    if (!isLeagueAdmin(event.leagueId, event.orgId)) {
      console.error('Permission denied. User:', userProfile.itemId, 'Event:', event.itemId);
      return null;
    }

    try {
      const eventRef = doc(collection(firestore, `org/${event.orgId}/event`));

      const startDatetime = event.days.reduce(
        (earliest, day) => (day.datetime < earliest ? day.datetime : earliest),
        event.days[0].datetime
      );
      console.log('event.days', event.days);
      const endDatetime = calculateEndDatetime(event.days);
      const newEvent = {
        ...event,
        createTime: Timestamp.now(),
        creatorId: userProfile.itemId,
        startDatetime: startDatetime,
        endDatetime: endDatetime,
        itemId: eventRef.id,
        isTest,
      };

      await setDoc(eventRef, newEvent);
      return newEvent;
    } catch (error) {
      console.error('Error adding event:', error);
      return null;
    }
  };

  const deleteEvent = async (event: Event): Promise<void> => {
    if (!event.itemId || !event.orgId) {
      console.error('Event itemId or orgId not found:', event.itemId, event.orgId);
      return;
    }

    if (!isLeagueAdmin(event.leagueId, event.orgId)) {
      console.error('Permission denied. User:', userProfile?.itemId, 'Event:', event.itemId);
      return;
    }

    try {
      const eventRef = doc(firestore, `org/${event.orgId}/event/${event.itemId}`);
      await deleteDoc(eventRef);
    } catch (error) {
      console.error('Error deleting event:', error);
    }
  };

  const updateEvent = async (event: Partial<Event>): Promise<void> => {
    if (!event.orgId || !event.itemId || !event.leagueId) {
      console.error('Missing required fields:', {
        orgId: event.orgId,
        itemId: event.itemId,
        leagueId: event.leagueId,
      });
      return;
    }

    if (!isLeagueAdmin(event.leagueId, event.orgId)) {
      console.error('Permission denied. User:', userProfile?.itemId, 'Event:', event.itemId);
      return;
    }

    try {
      const { orgId, itemId, leagueId, ...eventUpdates } = event;
      const eventRef = doc(firestore, `org/${orgId}/event/${itemId}`);
      const updates = {
        ...eventUpdates,
      };

      // TODO: This should be moved to the backend.
      if (event.days) {
        updates.startDatetime = event.days.reduce(
          (earliest, day) => (day.datetime < earliest ? day.datetime : earliest),
          event.days[0].datetime
        );
        updates.endDatetime = calculateEndDatetime(event.days);
      }

      await updateDoc(eventRef, updates);
    } catch (error) {
      console.error('Error updating event:', error);
    }
  };

  return {
    addEvent,
    deleteEvent,
    updateEvent,
  };
};

