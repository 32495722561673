import { ReactElement, useEffect } from 'react';

import Box from '@mui/material/Box';

import GratiPageWrapper from '../components/common/GratiPageWrapper';
import PersonDirectory from '../components/person/PersonDirectory';
import PersonGhinCsvUpdate from '../components/person/PersonGhinCsvUpdate';
import { useApp } from '../contextProviders/AppProvider';
import { useAuth } from '../contextProviders/AuthProvider';
import { OrgMemberType } from '../enums/OrgMemberType';
import { MembershipStatus } from '../types/Membership';
interface MembersProps {
  initialTab?: string;
}

export default function MembersPage(props: MembersProps): ReactElement {

  const { setPageName } = useApp();
  const { isGod } = useAuth();

  useEffect(() => {
    setPageName('Members');
  }, [setPageName]);

  return (
    <GratiPageWrapper>
      <Box sx={{ width: '100%' }}>
        <PersonDirectory
          membershipStatus={isGod ? 'all' as MembershipStatus : 'active' as MembershipStatus}
          orgMemberType={OrgMemberType.MEMBER}
          pageTitle="Directory"
        />
        {isGod && (
          <PersonGhinCsvUpdate />
        )}
      </Box>
    </GratiPageWrapper>
  );
}
