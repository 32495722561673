import { ReactElement, useState } from 'react';

import Stack from '@mui/material/Stack';

import AccountEmailEditor from './AccountEmailEditor';
import AccountPasswordEditor from './AccountPasswordEditor';

type EditingField = 'email' | 'password' | null;

export default function AccountEditor(): ReactElement {
  const [editingField, setEditingField] = useState<EditingField>(null);

  const handleStartEditing = (field: 'email' | 'password') => {
    setEditingField(field);
  };

  const handleCancelEditing = () => {
    setEditingField(null);
  };

  return (
    <Stack spacing={4}>
      <AccountEmailEditor
        isEditing={editingField === 'email'}
        onStartEditing={() => handleStartEditing('email')}
        onCancelEditing={handleCancelEditing}
      />
      <AccountPasswordEditor
        isEditing={editingField === 'password'}
        onStartEditing={() => handleStartEditing('password')}
        onCancelEditing={handleCancelEditing}
      />
    </Stack>
  );
}
