import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { responsiveFontSizes, styled } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';
import type {} from '@mui/material/themeCssVarsAugmentation';
import Typography from '@mui/material/Typography';

import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import MobiledataOffIcon from '@mui/icons-material/MobiledataOff';
import SignalWifi4BarIcon from '@mui/icons-material/SignalWifi4Bar';

import dayjs from 'dayjs';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import GratiAppBar from './components/common/GratiAppBar';
import GratiNavRail from './components/common/GratiNavRail';
import GratiNoticeBanner from './components/common/GratiNoticeBanner';
import Hourglass from './components/common/Hourglass';
import { useApp } from './contextProviders/AppProvider';
import { useAuth } from './contextProviders/AuthProvider';
import { useData } from './contextProviders/DataProvider';
import { MapsProvider } from './contextProviders/MapsProvider';
import RouteTable from './routes/RouteTable';
import { APP_BAR_HEIGHT } from './styles/mui6Theme';
import mui6Theme from './styles/mui6Theme';
import { useOnlineStatus } from './utils/useOnlineStatus';

function App() {
  // useMediaQuery('(prefers-color-scheme: dark)') ? 'dark' : 'light'
  const navRailWidth = '5rem';
  const { isEmulator, isOnline } = useOnlineStatus();
  const { isHomePage } = useApp();
  const { isLoggedIn, isLoggingIn } = useAuth();
  const { isUserLoading } = useData();
  dayjs.extend(utc);
  dayjs.extend(timezone);

  const responsiveMui6Theme = responsiveFontSizes(mui6Theme);
  const Offset = styled('div')(({ theme }) => theme.mixins.toolbar);

  return (
    <MapsProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ThemeProvider theme={responsiveMui6Theme} defaultMode="system">
          <Box sx={{ display: 'flex', flexGrow: 1 }}>
            <GratiAppBar
              isTopLevelPage={!isLoggedIn || isHomePage}
              sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
            />
            {isLoggedIn && <GratiNavRail sx={{ display: { xs: 'none', md: 'flex' } }} />}
            {isLoggingIn || isUserLoading ? (
              <Box
                sx={{
                  display: 'flex',
                  flexGrow: 1,
                  justifyContent: 'center',
                  alignItems: 'center',
                  mt: APP_BAR_HEIGHT,
                }}
              >
                <Hourglass />
              </Box>
            ) : (
              <Stack
                position="sticky"
                className="AppStack"
                sx={{
                  display: 'flex',
                  flexGrow: 1,
                  spacing: 1,
                  justifyContent: 'flex-start',
                  width: { xs: '100vw', md: `calc(100vw - ${navRailWidth})` },
                  backgroundColor: 'surface.main',
                }}
              >
                <Offset />
                <Box sx={{ pl: 1, pt: 1, mr: 1 }}>
                  {isLoggedIn && (
                    <GratiNoticeBanner
                      sx={{
                        height: '4rem',
                        display: 'flex',
                        alignItems: 'center',
                        backgroundColor: 'primary.container',
                      }}
                    />
                  )}
                </Box>
                <Box sx={{ p: 1 }}>
                  <RouteTable />
                  {(!isOnline || isEmulator) && (
                    <Stack
                      direction="row"
                      spacing={1}
                      divider={<Divider orientation="vertical" flexItem />}
                      sx={{
                        display: 'flex',
                        flexGrow: 1,
                        px: 2,
                        backgroundColor: 'surface.main',
                        color: 'surface.contrastText',
                      }}
                    >
                      {isOnline ? (
                        <SignalWifi4BarIcon sx={{ color: 'success.main' }} />
                      ) : (
                        <MobiledataOffIcon sx={{ color: 'error.main' }} />
                      )}
                      <Typography variant="body1" component="span">
                        {isOnline ? 'Online' : 'Offline'}
                      </Typography>
                      {isEmulator ? (
                        <DeveloperModeIcon sx={{ color: 'success.main' }} />
                      ) : (
                        <ImportExportIcon sx={{ color: 'warning.main' }} />
                      )}
                      <Typography variant="body1" component="span">
                        {isEmulator ? 'Emulator' : 'Production'}
                      </Typography>
                    </Stack>
                  )}
                </Box>
              </Stack>
            )}
          </Box>
        </ThemeProvider>
      </LocalizationProvider>
    </MapsProvider>
  );
}

export default App;
