import { useEffect, useMemo, useState } from 'react';

import { collection, collectionGroup, CollectionReference, Query, QueryConstraint } from 'firebase/firestore';
import { query, where } from 'firebase/firestore';

import { useCollectionData } from 'react-firebase-hooks/firestore';

import { useAuth } from '../contextProviders/AuthProvider';
import { useData } from '../contextProviders/DataProvider';
import { firestore } from '../firebase';
import Notice from '../types/Notice';

export interface NoticeContext {
  id: string;
  name: string;
}

interface UseNoticesProps {
  contextPairs?: NoticeContext[] | null; // The ids of the contexts that the notice is for.
  filter?: 'upcoming' | 'all' | null;
}

export default function useNotices(props: UseNoticesProps) {
  const { filter, contextPairs } = props;
  const { isGod } = useAuth();
  const { userProfile, isUserLoading, getAllActiveOrgs } = useData();

  // Add state to track the current time period
  const [timeCounter, setTimeCounter] = useState(0);

  // Add effect to update timeCounter every 24 hours
  useEffect(() => {
    const interval = setInterval(() => {
      setTimeCounter((prev) => prev + 1);
    }, 24 * 60 * 60 * 1000); // 24 hours in milliseconds

    return () => clearInterval(interval);
  }, []);

  // Create a single memoized time value that updates with timeCounter
  const currentDatetime = useMemo(() => new Date(Date.now() + timeCounter * 0), [timeCounter]);

  const noticesQuery = useMemo(() => {
    if (!userProfile || contextPairs === undefined || !filter) return null;

    const deletedConditions = [];
    const contextPairConditions: QueryConstraint[] = [];
    const filterConditions: QueryConstraint[] = [];
    const orgConditions = [];
    const testConditions = [];

    if (!isGod) {
      testConditions.push(where('isTest', '==', false));
      deletedConditions.push(where('isDeleted', '==', false));
    }

    if (contextPairs) {
      contextPairConditions.push(where('contextPairs', 'array-contains-any', contextPairs));
    }

    if (filter === 'upcoming') {
      filterConditions.push(where('displayStopsAt', '>', currentDatetime));
    }

    const allActiveOrgs = getAllActiveOrgs();

    if (allActiveOrgs.length === 0) {
      return null;
    }

    if (allActiveOrgs.length === 1) {
      orgConditions.push(where('orgId', '==', allActiveOrgs[0]));

      return query(
        collection(firestore, 'org', allActiveOrgs[0], 'notice') as CollectionReference<Notice>,
        ...contextPairConditions,
        ...orgConditions,
        ...deletedConditions,
        ...testConditions,
        ...filterConditions
      ) as Query<Notice>;
    } else {
      orgConditions.push(where('orgId', 'in', allActiveOrgs));

      return query(
        collectionGroup(firestore, 'notice') as CollectionReference<Notice>,
        ...contextPairConditions,
        ...orgConditions,
        ...deletedConditions,
        ...testConditions,
        ...filterConditions
      ) as Query<Notice>;
    }
  }, [currentDatetime, filter, getAllActiveOrgs, userProfile, contextPairs, isGod]);

  const [notices, isNoticesLoading, noticesError] = useCollectionData<Notice>(noticesQuery);

  if (!isNoticesLoading && !noticesError && getAllActiveOrgs().length === 0 && contextPairs !== undefined) {
    return {
      notices: [],
      isNoticesLoading: false,
      noticesError: new Error('User must be logged in to view notices'),
    };
  }

  return { notices: notices ?? [], isNoticesLoading: isNoticesLoading || isUserLoading, noticesError };
}
