import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';

import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';

import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import BadgeIcon from '@mui/icons-material/Badge';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ShieldIcon from '@mui/icons-material/Shield';

import { debounce } from '@mui/material';

import GratiPageWrapper from '../components/common/GratiPageWrapper';
import Hourglass from '../components/common/Hourglass';
import PgaIcon from '../components/common/PgaIcon';
import OrgKnownInviteEditor from '../components/org/OrgKnownInviteEditor';
import OrgSelector from '../components/org/OrgSelector';
import OrgUnknownInviteEditor from '../components/org/OrgUnknownInviteEditor';
import { useApp } from '../contextProviders/AppProvider';
import { useAuth } from '../contextProviders/AuthProvider';
import useOrgsWithAdminOrProRights from '../dataHooks/useOrgsWithAdminOrProRights';
import { useInvitationMutators } from '../dataMutators/useInvitationMutators';
import InvitationEntry from '../types/InvitationEntry';
import Person from '../types/Person';
import { validateEmail } from '../utils/validators';

type InvitationForm = {
  orgId: string;
  isTest: boolean;
  entries: InvitationEntry[];
};

export default function InviteMembersPage(): ReactElement {
  const { orgId } = useParams();
  const navigate = useNavigate();
  const { setPageName } = useApp();
  const { isGod } = useAuth();
  const { addInvitation } = useInvitationMutators();
  const { orgs, isOrgsLoading, orgsError } = useOrgsWithAdminOrProRights({
    includeProRights: true,
    includeAdminRights: true,
  });

  const [emailState, setEmailState] = useState({
    isChecking: false,
    isInvalid: false,
  });

  const [invitation, setInvitation] = useState<InvitationForm>({
    orgId: orgId || '',
    isTest: false,
    entries: [],
  });

  const [unknownEntry, setUnknownEntry] = useState<Omit<InvitationEntry, 'id'>>({
    name: '',
    email: '',
    ghin: '',
    isPro: false,
    isAdmin: false,
    isTest: false,
    isMember: true,
  });

  const [knownEntry, setKnownEntry] = useState<Omit<InvitationEntry, 'id'>>({
    name: '',
    email: '',
    ghin: '',
    isPro: false,
    isAdmin: false,
    isTest: false,
    isMember: true,
  });

  const [editingId, setEditingId] = useState<string | null>(null);

  const orgPairs = useMemo(() => {
    if (!orgs) return [];
    return orgs.map((org) => ({
      orgId: org.itemId,
      orgName: org.name,
    }));
  }, [orgs]);

  useEffect(() => {
    setPageName('Invite Members');
  }, [setPageName]);

  const handleSubmit = async () => {
    const promises = invitation.entries.map((entry) => addInvitation(invitation.orgId, entry));

    await Promise.all(promises);
    navigate(`/org-invitations/${invitation.orgId}`);
  };

  const handleOrgChange = useCallback((orgId: string) => {
    setInvitation((prev) => ({
      ...prev,
      orgId,
      entries: [],
    }));
  }, []);

  const handleAddOrUpdateEntry = () => {
    if (editingId) {
      const entryToUpdate = knownEntry.personId ? knownEntry : unknownEntry;
      setInvitation((prev) => ({
        ...prev,
        entries: prev.entries.map((entry) => (entry.id === editingId ? { ...entryToUpdate, id: entry.id } : entry)),
      }));
      setEditingId(null);
      setUnknownEntry({ name: '', email: '', ghin: '', isPro: false, isAdmin: false, isTest: false, isMember: true });
      setKnownEntry({ name: '', email: '', ghin: '', isPro: false, isAdmin: false, isTest: false, isMember: true });
    } else {
      const entryToAdd = knownEntry.personId ? knownEntry : unknownEntry;
      setInvitation((prev) => ({
        ...prev,
        entries: [...prev.entries, { ...entryToAdd, id: crypto.randomUUID() }],
      }));
      setUnknownEntry({ name: '', email: '', ghin: '', isPro: false, isAdmin: false, isTest: false, isMember: true });
      setKnownEntry({ name: '', email: '', ghin: '', isPro: false, isAdmin: false, isTest: false, isMember: true });
    }
  };

  const handlePersonChange = (person: Person | null) => {
    if (person) {
      setKnownEntry({
        name: person.name,
        email: person.email,
        ghin: person.ghin || '',
        isPro: false,
        isAdmin: false,
        isTest: false,
        isMember: true,
        personId: person.itemId,
      });
    } else {
      setKnownEntry({
        name: '',
        email: '',
        ghin: '',
        isPro: false,
        isAdmin: false,
        isTest: false,
        isMember: true,
      });
    }
  };

  const handleEditEntry = (entry: InvitationEntry) => {
    setEditingId(entry.id);
    if (entry.personId) {
      setKnownEntry(entry);
    } else {
      setUnknownEntry(entry);
    }
  };

  const handleCancelEdit = () => {
    setEditingId(null);
    setUnknownEntry({ name: '', email: '', ghin: '', isPro: false, isAdmin: false, isTest: false, isMember: true });
    setKnownEntry({ name: '', email: '', ghin: '', isPro: false, isAdmin: false, isTest: false, isMember: true });
  };

  const validateEmailField = debounce(async (email: string) => {
    // Don't check empty emails
    if (!email.trim()) {
      setEmailState((prev) => ({
        ...prev,
        isInvalid: false,
        isChecking: false,
      }));
      return;
    }

    setEmailState((prev) => ({ ...prev, isChecking: true }));

    // First check format
    const isInvalid = !validateEmail(email);
    if (isInvalid) {
      console.log('isInvalid: ', isInvalid);
      setEmailState((prev) => ({
        ...prev,
        isInvalid: true,
        isChecking: false,
      }));
      return;
    } else {
      setEmailState((prev) => ({
        ...prev,
        isInvalid: false,
        isChecking: false,
      }));
    }
  }, 500);

  const validateUser = (userProfile: Person | null | undefined) => {
    return isGod || (orgId && userProfile && orgs?.some((org) => org.itemId === orgId)) ? true : false;
  };

  if (isOrgsLoading) {
    return <Hourglass />;
  }

  if (orgsError) {
    console.error('Error loading organizations:', orgsError);
    return <Alert severity="error">Unable to load organizations. Please try again later.</Alert>;
  }

  return (
    <GratiPageWrapper validateUser={validateUser} isContentLoading={isOrgsLoading}>
      <Stack
        spacing={2}
        component="form"
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <OrgSelector
          value={invitation.orgId}
          orgPairs={orgPairs}
          onChange={(orgPair) => handleOrgChange(orgPair.orgId)}
        />
        {isGod && (
          <FormGroup sx={{ pl: 2 }}>
            <FormControlLabel
              control={
                <Switch
                  checked={invitation.isTest}
                  onChange={(e) => setInvitation((prev) => ({ ...prev, isTest: e.target.checked }))}
                />
              }
              label="Test Invitation"
            />
          </FormGroup>
        )}
        {invitation.entries.length > 0 && (
          <Stack spacing={1}>
            {invitation.entries.map((entry) => (
              <Paper key={entry.id} sx={{ pl: 2, display: editingId === entry.id ? 'none' : 'block' }}>
                <Grid container spacing={2} alignItems="center">
                  <Grid size={{ xs: 9 }}>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography>{entry.name}</Typography>
                      <Typography color="text.secondary">({entry.email})</Typography>
                      {entry.isMember && (
                        <Tooltip title="Member">
                          <BadgeIcon fontSize="small" color="primary" />
                        </Tooltip>
                      )}
                      {entry.isPro && (
                        <Tooltip title="Pro">
                          <PgaIcon fontSize="small" color="primary" />
                        </Tooltip>
                      )}
                      {entry.isAdmin && (
                        <Tooltip title="Admin">
                          <ShieldIcon fontSize="small" color="primary" />
                        </Tooltip>
                      )}
                    </Stack>
                  </Grid>
                  <Grid size={{ xs: 3 }}>
                    <Stack direction="row" spacing={1} justifyContent="flex-end">
                      <IconButton size="small" onClick={() => handleEditEntry(entry)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        size="small"
                        onClick={() => {
                          setInvitation((prev) => ({
                            ...prev,
                            entries: prev.entries.filter((e) => e.id !== entry.id),
                          }));
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Stack>
                  </Grid>
                </Grid>
              </Paper>
            ))}
          </Stack>
        )}
        <Stack spacing={2} sx={{ pl: 2 }}>
          {!editingId && (
            <>
              <Typography variant="subtitle1">Add existing person</Typography>
              <OrgKnownInviteEditor
                currentEntry={knownEntry}
                isFirstEntry={invitation.entries.length === 0}
                isEditing={false}
                onPersonChange={handlePersonChange}
                onMemberChange={(isMember) => setKnownEntry((prev) => ({ ...prev, isMember }))}
                onProChange={(isPro) => setKnownEntry((prev) => ({ ...prev, isPro }))}
                onAdminChange={(isAdmin) => setKnownEntry((prev) => ({ ...prev, isAdmin }))}
                onAddOrUpdate={handleAddOrUpdateEntry}
                isDisabled={!knownEntry.email || (!knownEntry.isMember && !knownEntry.isPro && !knownEntry.isAdmin)}
              />
              <Typography variant="subtitle1">Add new person</Typography>
              <OrgUnknownInviteEditor
                currentEntry={unknownEntry}
                isFirstEntry={invitation.entries.length === 0}
                isEditing={false}
                emailState={emailState}
                onNameChange={(name) => setUnknownEntry((prev) => ({ ...prev, name }))}
                onEmailChange={(email) => setUnknownEntry((prev) => ({ ...prev, email }))}
                onEmailValidation={validateEmailField}
                onMemberChange={(isMember) => setUnknownEntry((prev) => ({ ...prev, isMember }))}
                onProChange={(isPro) => setUnknownEntry((prev) => ({ ...prev, isPro }))}
                onAdminChange={(isAdmin) => setUnknownEntry((prev) => ({ ...prev, isAdmin }))}
                onAddOrUpdate={handleAddOrUpdateEntry}
                isDisabled={
                  !unknownEntry.name ||
                  !unknownEntry.email ||
                  emailState.isInvalid ||
                  (!unknownEntry.isMember && !unknownEntry.isPro && !unknownEntry.isAdmin)
                }
              />
            </>
          )}
          {editingId && (
            <>
              <Typography variant="subtitle1">Edit person</Typography>
              {knownEntry.personId ? (
                <OrgKnownInviteEditor
                  currentEntry={knownEntry}
                  isFirstEntry={false}
                  isEditing={true}
                  onPersonChange={handlePersonChange}
                  onMemberChange={(isMember) => setKnownEntry((prev) => ({ ...prev, isMember }))}
                  onProChange={(isPro) => setKnownEntry((prev) => ({ ...prev, isPro }))}
                  onAdminChange={(isAdmin) => setKnownEntry((prev) => ({ ...prev, isAdmin }))}
                  onAddOrUpdate={handleAddOrUpdateEntry}
                  onCancel={handleCancelEdit}
                  isDisabled={!knownEntry.email || (!knownEntry.isMember && !knownEntry.isPro && !knownEntry.isAdmin)}
                />
              ) : (
                <OrgUnknownInviteEditor
                  currentEntry={unknownEntry}
                  isFirstEntry={false}
                  isEditing={true}
                  emailState={emailState}
                  onNameChange={(name) => setUnknownEntry((prev) => ({ ...prev, name }))}
                  onEmailChange={(email) => setUnknownEntry((prev) => ({ ...prev, email }))}
                  onEmailValidation={validateEmailField}
                  onMemberChange={(isMember) => setUnknownEntry((prev) => ({ ...prev, isMember }))}
                  onProChange={(isPro) => setUnknownEntry((prev) => ({ ...prev, isPro }))}
                  onAdminChange={(isAdmin) => setUnknownEntry((prev) => ({ ...prev, isAdmin }))}
                  onAddOrUpdate={handleAddOrUpdateEntry}
                  onCancel={handleCancelEdit}
                  isDisabled={
                    !unknownEntry.name ||
                    !unknownEntry.email ||
                    emailState.isInvalid ||
                    (!unknownEntry.isMember && !unknownEntry.isPro && !unknownEntry.isAdmin)
                  }
                />
              )}
            </>
          )}
        </Stack>
        <Stack direction="row" justifyContent="center" spacing={2}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={invitation.entries.length === 0}
            sx={{ minWidth: 'auto', width: 'auto' }}
          >
            Invite {invitation.entries.length > 0 ? invitation.entries.length : ''} Member
            {invitation.entries.length !== 1 ? 's' : ''}
          </Button>
          {isGod && (
            <Button
              variant="text"
              color="primary"
              onClick={() => navigate(`/invitation-upload/${invitation.orgId}`)}
              sx={{ minWidth: 'auto', width: 'auto' }}
            >
              Bulk Invite
            </Button>
          )}
        </Stack>
      </Stack>
    </GratiPageWrapper>
  );
}
