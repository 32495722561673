import { ReactElement } from 'react';

import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import Stack from '@mui/material/Stack';

import AddIcon from '@mui/icons-material/Add';

import GratiPageWrapper from '../components/common/GratiPageWrapper';
import EventCardSection from '../components/event/EventCardSection';
import EventSchedule from '../components/event/EventSchedule';
import OrgInvitationResponder from '../components/org/OrgInvitationResponder';
import { useData } from '../contextProviders/DataProvider';
import useMembershipsOfPerson from '../dataHooks/useMembershipsOfPerson';
import { EventQueryFilter } from '../enums/EventQueryFilter';

export default function HomePage(): ReactElement {
  const { canCreateEvents, userProfile } = useData();
  const { 
    memberships: pendingMemberships,
  } = useMembershipsOfPerson(userProfile ? { userProfile, personId: userProfile.itemId, status: 'pending' } : {});

  return (
    <GratiPageWrapper>
      <Stack spacing={2} sx={{ display: 'flex', flexGrow: 1, p: 1 }}>
        {pendingMemberships && pendingMemberships.length > 0 && (
          <OrgInvitationResponder pendingMemberships={pendingMemberships} />
        )}
        <EventCardSection filter={EventQueryFilter.Now} headline="Happening Today" />
        <EventCardSection filter={EventQueryFilter.OpenForSignup} headline="Open for Sign Up" />
        <Box>
          <EventSchedule headline="Upcoming Events" filter={EventQueryFilter.Upcoming} isSortedbyLeague={false} />
        </Box>
        {canCreateEvents() && (
          <Fab variant="extended" color="primary" aria-label="add event" component={Link} to="/event-create">
            <AddIcon />
            Add event
          </Fab>
        )}
      </Stack>
    </GratiPageWrapper>
  );
}
