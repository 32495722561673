import { 
  ReactElement
} from 'react';

import { Link } from "react-router";

import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';

import AddIcon from '@mui/icons-material/Add';

import GratiPageWrapper from '../components/common/GratiPageWrapper';
import PersonDirectory from '../components/person/PersonDirectory';
import PersonGhinCsvUpdate from '../components/person/PersonGhinCsvUpdate';
import { useAuth } from "../contextProviders/AuthProvider";
import { OrgMemberType } from '../enums/OrgMemberType';

export default function GuestsPage(): ReactElement {
  const { isGod } = useAuth();
  
  const validateUser = (): boolean => {
    return isGod;
  }

  return (
    <GratiPageWrapper validateUser={validateUser}>
      <Box>
        <PersonDirectory isActiveUser={false} pageTitle="Inactive Users" orgMemberType={OrgMemberType.MEMBER}/>
        <PersonGhinCsvUpdate />
        <Fab variant="extended" color="primary" aria-label="invite guest" component={Link} to="/invite-guests">
          <AddIcon />
          Add guest
        </Fab>
      </Box>
    </GratiPageWrapper>
  );
}