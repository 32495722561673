import { useMemo } from 'react';

import { collection, orderBy, Query } from 'firebase/firestore';
import { query, where } from 'firebase/firestore';

import { useCollectionData } from 'react-firebase-hooks/firestore';

import { useAuth } from '../contextProviders/AuthProvider';
import { useData } from '../contextProviders/DataProvider';
import { firestore } from '../firebase';
import Invitation from '../types/Invitation';

interface UseInvitationsProps {
  orgId?: string;
  status: 'pending' | 'accepted' | 'rejected' | 'requested';
}

export default function useInvitationsForOrg(props: UseInvitationsProps) {
  const { orgId, status } = props;
  const { isGod } = useAuth();
  const { userProfile, isUserLoading, isOrgAdmin, isOrgPro } = useData();

  // Create the query reference
  const invitationsQuery = useMemo(() => {
    if (!userProfile) return null;
    if (!isGod && (!orgId || orgId.length === 0)) return null;

    // If orgIds specified and accessible, query for those orgs
    if (orgId) {
      const processedConditions = [];
      const acceptanceConditions = [];
      const testConditions = [];
      const memberConditions = [];
      if (!isGod) {
        testConditions.push(where('isTest', '==', false));
      }

      switch (status) {
        case 'pending':
          processedConditions.push(where('isPending', '==', true));
          break;
        case 'accepted':
          processedConditions.push(where('isPending', '==', false));
          acceptanceConditions.push(where('isAccepted', '==', true));
          memberConditions.push(where('personId', '!=', ''));
          break;
        case 'rejected':
          processedConditions.push(where('isPending', '==', false));
          acceptanceConditions.push(where('isAccepted', '==', false));
          memberConditions.push(where('personId', '!=', ''));
          break;
        case 'requested':
          processedConditions.push(where('isPending', '==', true));
          memberConditions.push(where('personId', '==', ''));
          memberConditions.push(where('applicationId', '!=', ''));
          break;
      }

      return query(
        collection(firestore, `org/${orgId}/invitation`),
        ...processedConditions,
        ...acceptanceConditions,
        ...memberConditions,
        ...testConditions,
        orderBy('name')
      ) as Query<Invitation>;
    }

    return null;
  }, [orgId, isGod, userProfile, status]);

  const [invitations, isInvitationsLoading, invitationsError] = useCollectionData<Invitation>(invitationsQuery);

  if (!userProfile && !isUserLoading && !isInvitationsLoading && !invitationsError && orgId) {
    return {
      invitations: null,
      isInvitationsLoading: false,
      invitationsError: new Error('User must be logged in')
    };
  }

  if (userProfile && 
      !isUserLoading && 
      !isInvitationsLoading && 
      !invitationsError && 
      orgId && 
      !(isOrgAdmin(orgId) || isOrgPro(orgId))) {
    return {
      invitations: null,
      isInvitationsLoading: false,
      invitationsError: new Error('Permission denied.')
    };
  }

  return {
    invitations: invitations || null,
    isInvitationsLoading: isInvitationsLoading || isUserLoading,
    invitationsError,
  };
}
