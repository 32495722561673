import { useMemo } from 'react';

import { collection, doc, DocumentReference } from 'firebase/firestore';

import { useDocumentData } from 'react-firebase-hooks/firestore';

import { useAuth } from '../contextProviders/AuthProvider';
import { useData } from '../contextProviders/DataProvider';
import { firestore } from '../firebase';
import League from '../types/League';

interface UseLeagueProps {
  orgId?: string | null;
  leagueId?: string | null;
}

export default function useLeague(props: UseLeagueProps) {
  const { orgId, leagueId } = props;
  const { isGod } = useAuth();
  const { userProfile, isUserLoading, isOrgActive } = useData();

  const leagueRef = useMemo(() => {
    if (!orgId || !leagueId) return null;

    return doc(collection(firestore, 'org', orgId, 'league'), leagueId) as DocumentReference<League>;
  }, [orgId, leagueId]);

  const [league, isLeagueLoading, leagueError] = useDocumentData<League>(leagueRef);

  if (!isUserLoading && !userProfile && !isLeagueLoading && !leagueError) {
    return {
      league: null,
      isLeagueLoading: false,
      leagueError: new Error('User must be logged in to view leagues'),
    };
  }

  if (!isUserLoading && !isLeagueLoading && !leagueError && !isGod && orgId && leagueId && !isOrgActive(orgId)) {
    return {
      league: null,
      isLeagueLoading: false,
      leagueError: new Error('Not authorized to view this league'),
    };
  }

  if (orgId === null && leagueId === null) {
    return {
      league: null,
      isLeagueLoading: false,
    };
  }

  return {
    league: league || null,
    isLeagueLoading: isLeagueLoading || isUserLoading,
    leagueError,
  };
}
