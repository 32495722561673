import { ReactNode, useEffect, useState } from 'react';

import { Autocomplete, AutocompleteProps, TextField } from '@mui/material';

// Minimal interface for items that can be selected
export interface SelectableItem {
  itemId: string;
  name: string;
}

type PeopleHookResult<T extends SelectableItem> = {
  items: T[] | undefined;
  isLoading: boolean;
  error: Error | undefined;
};

export interface PersonAutocompleteProps<
  T extends SelectableItem,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined = undefined
> extends Omit<AutocompleteProps<T | string, Multiple, DisableClearable, FreeSolo>, 'renderInput' | 'options'> {
  renderInput?: AutocompleteProps<T | string, Multiple, DisableClearable, FreeSolo>['renderInput'];
  options?: T[];
  emptyLabel?: string;
  label?: ReactNode;
  selectedPersonId?: string;
  usePeopleHook: () => PeopleHookResult<T>;
  handleOnChange?: (event: React.SyntheticEvent<Element, Event>, value: T | null, reason: string) => void;
  handleOnInputChange?: (event: React.SyntheticEvent<Element, Event>, value: T | null, reason: string) => void;
}

export default function PersonAutocomplete<
  T extends SelectableItem,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
>({ label, usePeopleHook, ...props }: PersonAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>) {
  const { emptyLabel, selectedPersonId, handleOnChange } = props;
  const [selectedPerson, setSelectedPerson] = useState<T | null | undefined>(undefined);

  const { items: people, isLoading, error: isError } = usePeopleHook();

  useEffect(() => {
    if (isError) {
      console.log('Error loading people: ' + isError);
    } else if (isLoading) {
      console.log('Loading people');
    } else {
      setSelectedPerson(
        people && selectedPersonId ? people.find((person: T) => person.itemId === selectedPersonId) || null : null
      );
      console.log('People loaded');
    }
  }, [people, isLoading, isError, selectedPersonId]);

  if (isLoading || !people || selectedPerson === undefined) {
    return <> </>;
  } else if (isError) {
    return <> </>;
  } else {
    console.log('SelectedPerson = ' + selectedPerson?.name);
    return (
      <Autocomplete
        limitTags={2}
        disablePortal
        options={people}
        autoSelect={true}
        clearOnBlur={false}
        value={selectedPerson}
        onChange={(event, value, reason) => {
          setSelectedPerson(value);
          if (handleOnChange) {
            handleOnChange(event, value, reason);
          }
        }}
        getOptionLabel={(option: T) => option.name}
        renderOption={(props, option: T) => {
          const { key, ...otherProps } = props;
          return (
            <li key={key} {...otherProps}>
              {option.name}
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField {...params} variant="filled" label={selectedPerson || !emptyLabel ? label : emptyLabel} />
        )}
      />
    );
  }
}
