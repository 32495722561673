import { ReactElement, useEffect, useState } from 'react';

import { doc, DocumentReference, Timestamp } from 'firebase/firestore';

import { Link } from 'react-router-dom';

import { useDocumentData } from 'react-firebase-hooks/firestore';

import Avatar from '@mui/material/Avatar';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import ConstructionIcon from '@mui/icons-material/Construction';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';

import { useAuth } from '../../contextProviders/AuthProvider';
import { useData } from '../../contextProviders/DataProvider';
import { firestore } from '../../firebase';
import Event from '../../types/Event';
import Registration from '../../types/Registration';
import { DisplayTime } from '../../utils/utils';
export interface EventListItemProps {
  event: Event;
  isLeagueDisplayed?: boolean;
  isDividerDisplayed?: boolean;
  isSelected?: boolean;
}

export default function EventListItem(props: EventListItemProps): ReactElement {
  const { event, isLeagueDisplayed = false, isDividerDisplayed = true, isSelected = false } = props;
  const { user } = useAuth();  
  const { getAllActiveOrgs } = useData();
  const [isAttending, setIsAttending] = useState(false);
  const orgCount = getAllActiveOrgs().length;

  const [registration, isRegistrationLoading, isRegistrationLoadingError] = useDocumentData<Registration>(
    doc(
      firestore,
      'org/' + event.orgId + '/event/' + event.itemId + '/reg/' + user?.uid
    ) as DocumentReference<Registration>
  );

  useEffect(() => {
    if (registration) {
      setIsAttending(registration.isRegistered);
    } else {
      if (isRegistrationLoadingError) {
        console.log('Error loading registration');
      } else if (isRegistrationLoading) {
        console.log('Loading registration');
      }
    }
  }, [registration, isRegistrationLoading, isRegistrationLoadingError]);

  const StyledDate = (): ReactElement => {
    let monthText = '';
    let dayText = '';

    const numEventDays = event.days.length;

    if (numEventDays > 1) {
      const eventStartDate = (event.days[0].datetime as Timestamp).toDate();
      const eventEndDate = (event.days[numEventDays - 1].datetime as Timestamp).toDate();

      monthText = eventStartDate.toLocaleString('default', { month: 'short' });
      if (eventStartDate.getMonth() !== eventEndDate.getMonth()) {
        monthText += ' - ' + eventEndDate.toLocaleString('default', { month: 'short' });
      }
      dayText = eventStartDate.getDate().toString() + ' - ' + eventEndDate.getDate().toString();
    } else {
      const eventDate = (event.days[0].datetime as Timestamp).toDate();
      monthText =
        eventDate.toLocaleString('default', { month: 'short' }) +
        ', ' +
        eventDate.toLocaleDateString('default', { weekday: 'short' });
      dayText = eventDate.getDate().toString();
    }

    return (
      <>
        <Typography variant="h6" sx={{ py: 0, my: 0, lineHeight: 1 }}>
          {dayText}
        </Typography>
        <Typography variant="overline" sx={{ pt: 1, my: 0, lineHeight: 1 }}>
          {monthText}
        </Typography>
      </>
    );
  };

  const StyledDateAvatar = (): ReactElement => {
    return (
      <Avatar
        variant="square"
        sx={{
          p: 0.5,
          height: 'fit-content',
          width: 'fit-content',
          backgroundColor: 'primary.container',
          color: 'primary.onContainer',
        }}
      >
        <Stack direction="column" alignItems="center">
          <StyledDate />
        </Stack>
      </Avatar>
    );
  };

  const StyledTime = (): ReactElement => {
    const tz = event.isGolf
      ? event.coursePairs?.find((coursePair) => coursePair.id === event.days[0].courseId)?.timezone
      : event.timezone;
    return (
      <Typography variant="caption" color="primary.main" sx={{ p: 0, m: 0, lineHeight: 2 }}>
        {DisplayTime(event.days[0].datetime as Timestamp, tz ?? Intl.DateTimeFormat().resolvedOptions().timeZone)}
      </Typography>
    );
  };

  const StyledTimeAvatar = (): ReactElement => {
    return (
      <Avatar
        variant="square"
        sx={{
          m: 0,
          p: 0,
          height: 'fit-content',
          width: 'fit-content',
          backgroundColor: 'primary.surface',
          color: 'primary.onSurface',
        }}
      >
        <Stack direction="column" alignItems="center">
          <StyledTime />
        </Stack>
      </Avatar>
    );
  };

  const LocationName = (): string => {
    // If the event is held at multiple locations...
    let locationName = '';

    if (event.isGolf) {
      const numEventDays = event.days.length;
      // Is the event played at multiple courses?
      if (numEventDays > 1) {
        if (!event.days.every((day, i, arr) => day.courseId === arr[0].courseId)) {
          locationName = 'Multiple Courses';
        } else {
          locationName = event?.coursePairs?.find((course) => course.id === event.days[0].courseId)?.name ?? '';
        }
      } else {
        locationName = event?.coursePairs?.find((course) => course.id === event.days[0].courseId)?.name ?? '';
      }
    } else {
      locationName = event.socialLocation;
    }

    return locationName;
  };

  const StyledAttendance = (): ReactElement => {
    return isAttending ? (
      <span>
        <EventAvailableIcon fontSize="small" color="primary" />
      </span>
    ) : (
      <></>
    );
  };

  const eventName = (): string => {
    if (event.name) {
      return event.name;
    } else if (isLeagueDisplayed && event.leagueName) {
      return event.leagueName;
    } else if (event.isGolf) {
      return 'Golf Event';
    } else {
      return 'Social Event';
    }
  };

  const leagueName = (): string => {
    if (event.leagueName && event.leagueName !== 'None') {
      if (event.seasonName) {
        return event.leagueName + ' - ' + event.seasonName;
      } else {
        return event.leagueName;
      }
    } else {
      if (event.seasonName) {
        return event.orgName + ' - ' + event.seasonName;
      } else {
        return '';
      }
    }
  };

  const orgName = (): string => {
    if (orgCount > 1 && event.orgName) {
      return event.orgName;
    } else {
      return '';
    }
  };

  return (
    <ListItem
      sx={{ pl: 0, backgroundColor: isSelected ? 'action.selected' : 'transparent' }}
      component={Link}
      to={`/event/${event.orgId}/${event.itemId}`}
      divider={isDividerDisplayed}
      className={event.isTest ? 'test' : ''}
    >
      <ListItemAvatar>
        <StyledDateAvatar />
      </ListItemAvatar>
      <ListItemAvatar sx={{ color: 'surface.onVariant' }}>
        <StyledTimeAvatar />
      </ListItemAvatar>
      <ListItemText>
        <Stack spacing={1}>
          {orgName() && (
            <Typography variant="overline" color="surface.onVariant" sx={{ py: 0, lineHeight: 1 }}>
              {orgName()}
            </Typography>
          )}  
          <Typography
            variant="overline"
            color={isLeagueDisplayed && event.leagueName ? 'surface.onVariant' : 'surface.contrastText'}
            sx={{ py: 0, lineHeight: 1 }}
          >
            {isLeagueDisplayed && event.leagueName ? leagueName() : eventName()}
          </Typography>
          <Typography variant="body1" color="surface.onVariant" sx={{ py: 0, lineHeight: 1 }}>
            {LocationName()}
          </Typography>
        </Stack>
      </ListItemText>
      {event.isTest && <ConstructionIcon fontSize="small" color="error" />}
      {isAttending && <StyledAttendance />}
    </ListItem>
  );
}
