import { forwardRef } from 'react';

import { OverridableComponent } from '@mui/material/OverridableComponent';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

import { SvgIconTypeMap, useTheme } from '@mui/material';
const PgaIcon = forwardRef<SVGSVGElement, SvgIconProps>(function PgaIcon(props, ref) {
  const { sx, ...otherProps } = props;
  const theme = useTheme();
  const foregroundColor = theme.palette.mode === 'dark' ? '#FFFFFF' : '#002059';

  return (
    <SvgIcon {...otherProps} ref={ref} viewBox="0 0 511 464" sx={{ color: foregroundColor, ...sx }}>
      <path
        fill={foregroundColor}
        opacity="1.000000"
        stroke="none"
        d="M1.000000,153.467941 
				C33.635815,153.000000 66.274666,152.737625 98.906113,153.115097 
				C114.302711,153.293198 129.134155,156.615707 141.904526,166.011673 
				C151.365936,172.973068 156.285278,182.412796 157.041214,194.184662 
				C157.810226,206.160172 154.355865,216.835587 146.555557,225.659088 
				C140.473785,232.538681 132.249344,237.155807 123.290405,238.793365 
				C111.454758,240.956711 99.373474,241.910309 87.358047,242.902832 
				C80.776886,243.446442 74.113945,243.000000 67.004478,243.000000 
				C67.004478,250.263535 66.750267,257.218628 67.186035,264.130249 
				C67.292877,265.824738 69.337425,268.148468 71.040108,268.849518 
				C76.074074,270.922150 81.442169,272.170258 86.544853,274.097534 
				C87.939972,274.624451 89.742096,276.338165 89.842972,277.625854 
				C90.229347,282.558411 89.999260,287.539307 89.999260,293.000000 
				C60.431740,293.000000 31.184526,293.000000 1.468657,293.000000 
				C1.000000,287.311951 1.000000,281.623932 1.373339,275.355896 
				C7.108641,273.178009 12.510917,271.701721 17.819422,269.942505 
				C21.843826,268.608795 24.156992,266.260254 24.111282,261.317200 
				C23.872740,235.521988 23.939432,209.723099 24.071220,183.926346 
				C24.089119,180.422836 22.784128,178.098923 19.750422,177.029129 
				C13.561231,174.846573 7.256899,172.990540 1.000000,171.000000 
				C1.000000,165.311966 1.000000,159.623917 1.000000,153.467941 
			M94.929733,173.421799 
				C85.707405,172.699127 76.485077,171.976440 67.317383,171.258026 
				C67.317383,189.388916 67.317383,206.100388 67.317383,223.436127 
				C75.540695,222.941727 83.526939,223.138916 91.264297,221.846313 
				C104.703224,219.601181 118.273636,210.967621 115.044067,192.259064 
				C113.497025,183.297211 105.039520,175.063248 94.929733,173.421799 
			z"
      />
      <path
        fill={foregroundColor}
        opacity="1.000000"
        stroke="none"
        d="
			M512.000000,292.532410 
				C482.860474,293.000000 453.720978,293.000000 424.291077,293.000000 
				C424.291077,287.012787 424.291077,281.266327 424.291077,275.700348 
				C430.622406,273.660461 436.936096,271.626282 443.310791,269.572418 
				C441.047607,265.077118 439.307800,261.621307 437.484680,258.000122 
				C419.699982,258.000122 401.875183,257.956116 384.051819,258.094452 
				C382.803650,258.104156 381.477997,259.352173 380.359802,260.240173 
				C379.781616,260.699341 379.572357,261.628998 379.209717,262.352753 
				C374.988953,270.775970 375.005646,270.810944 384.241730,272.979034 
				C387.381897,273.716156 390.523041,274.449402 393.805023,275.217499 
				C393.805023,281.029236 393.805023,286.795715 393.805023,292.780701 
				C370.543915,292.780701 347.477661,292.780701 324.001434,292.780701 
				C324.001434,287.804688 323.827637,283.003876 324.128693,278.233002 
				C324.200287,277.098389 325.657257,275.624359 326.834839,275.079071 
				C329.000061,274.076477 331.394989,273.454681 333.757599,273.014587 
				C342.016907,271.476135 347.208710,266.534180 351.064606,259.204865 
				C360.605682,241.069168 370.537811,223.138306 380.389526,205.167191 
				C389.467499,188.607498 398.601959,172.078140 407.866791,155.622849 
				C408.561127,154.389664 410.477112,153.165314 411.866547,153.116562 
				C419.018585,152.865646 426.184174,153.000229 433.434387,153.000229 
				C446.968781,179.934525 460.438171,206.803787 473.972382,233.640350 
				C479.137390,243.881927 484.532898,254.007629 489.661682,264.266937 
				C492.143738,269.231873 497.147827,270.024231 501.460388,271.962036 
				C504.663239,273.401215 508.003937,274.533661 511.641602,275.901367 
				C512.000000,281.354919 512.000000,286.709869 512.000000,292.532410 
			M420.466278,224.972778 
				C417.169281,217.902283 413.872284,210.831772 410.189575,202.934036 
				C402.809021,217.175034 395.952332,230.405228 388.906921,243.999603 
				C390.201599,243.999603 390.851257,243.999588 391.500885,243.999588 
				C404.140961,243.999588 416.781006,243.999603 429.857300,243.999603 
				C426.813904,237.706604 423.882019,231.644226 420.466278,224.972778 
			z"
      />
      <path
        fill={foregroundColor}
        opacity="1.000000"
        stroke="none"
        d="
			M263.533142,1.000000 
				C269.033447,2.856631 274.361786,4.162412 279.046692,6.670163 
				C294.963226,15.189959 303.002838,28.833374 304.020599,46.797512 
				C304.800720,60.567509 300.359161,72.606461 290.930267,82.302795 
				C283.760284,89.676132 274.747711,94.331284 264.243988,96.099129 
				C250.437088,98.422920 238.185135,95.316742 227.180771,86.992737 
				C217.190460,79.435799 211.114716,69.506393 208.931610,57.011940 
				C206.149338,41.088425 210.821579,27.269836 221.237656,15.723160 
				C228.088058,8.129199 236.939011,2.700426 247.859772,1.376873 
				C253.022095,1.000000 258.044189,1.000000 263.533142,1.000000 
			M230.015564,20.477085 
				C224.363937,25.433998 220.623215,31.470734 218.559845,38.787281 
				C215.745453,48.766891 216.309006,51.409561 223.558594,56.333050 
				C224.051041,55.072834 224.200500,53.105316 225.163193,52.551571 
				C227.269104,51.340237 230.534271,49.524593 231.979233,50.340576 
				C234.805908,51.936840 236.963287,54.989689 238.906876,57.781307 
				C240.165100,59.588512 240.552826,62.001766 241.065628,63.413799 
				C243.926865,59.802128 245.747513,55.934952 248.794830,53.974731 
				C255.749756,49.500870 267.118683,51.235672 269.099731,61.247559 
				C269.266785,62.091671 270.291443,62.766079 270.917694,63.519333 
				C271.611420,62.605427 272.499237,61.779312 272.960205,60.760124 
				C274.067902,58.311001 274.631409,55.556446 276.033569,53.311871 
				C279.183624,48.269379 285.050354,48.603470 287.984497,53.734234 
				C288.498871,54.633656 289.249237,55.398109 289.865387,56.192398 
				C293.869141,53.554001 295.496185,50.268368 295.015839,45.879242 
				C293.589600,32.847729 287.773407,22.749245 276.386505,15.689774 
				C261.775360,6.631364 242.338898,8.450798 230.015564,20.477085 
			M245.014587,69.125504 
				C248.275528,73.958771 252.662094,77.159195 258.615845,75.860832 
				C264.016815,74.683029 267.913452,71.267303 268.498230,65.003990 
				C261.020233,65.003990 253.414474,64.960037 245.814301,65.145912 
				C245.468185,65.154381 245.172760,67.235298 245.014587,69.125504 
			M276.059143,68.585518 
				C278.429443,68.715599 281.734467,69.878960 282.973297,68.758286 
				C285.467194,66.502220 286.835724,63.002140 288.668549,60.015327 
				C288.287964,59.876427 287.907349,59.737526 287.526764,59.598621 
				C282.981140,61.252724 278.435486,62.906822 273.801697,64.593002 
				C274.514954,66.007736 275.017761,67.005058 276.059143,68.585518 
			M228.006653,67.505623 
				C230.170258,68.272209 232.441940,69.828156 234.462692,69.551460 
				C236.032104,69.336555 237.288422,66.835007 239.276917,64.718674 
				C233.838730,62.846008 229.665833,61.409054 224.240128,59.540691 
				C225.610687,62.703987 226.531662,64.829620 228.006653,67.505623 
			z"
      />
      <path
        fill={foregroundColor}
        opacity="1.000000"
        stroke="none"
        d="
			M294.399994,465.000000 
				C289.188080,463.620636 284.689697,462.160217 282.805695,456.539703 
				C279.919556,447.929535 281.411499,434.126495 289.371796,429.996185 
				C295.336182,426.901520 303.250793,427.687042 308.140381,432.981354 
				C306.649078,434.998199 305.125732,437.058472 303.621704,439.092529 
				C296.035889,434.600586 294.182068,434.917969 290.898285,441.397583 
				C294.688965,441.397583 298.426239,440.799133 301.862366,441.560303 
				C305.541565,442.375336 308.489777,444.987366 308.970795,449.170502 
				C309.988892,458.024353 306.328156,463.084473 298.121124,464.638550 
				C296.933319,465.000000 295.866669,465.000000 294.399994,465.000000 
			M290.376495,451.110931 
				C289.508789,455.123474 291.859589,457.081970 295.213135,456.788666 
				C297.225067,456.612732 299.055511,454.361511 300.967529,453.043030 
				C299.265167,451.369415 297.805542,449.128723 295.761322,448.253632 
				C294.686829,447.793671 292.589539,449.722992 290.376495,451.110931 
			z"
      />
      <path
        fill={foregroundColor}
        opacity="1.000000"
        stroke="none"
        d="
			M237.399994,465.000000 
				C233.603302,463.601990 230.206589,462.203949 226.379013,460.628571 
				C228.366394,457.964203 229.893555,455.916809 231.374557,453.931335 
				C241.234192,458.239502 241.838928,458.140106 244.856857,452.000000 
				C242.253052,452.000000 239.600601,452.016479 236.948410,451.996490 
				C231.772141,451.957520 228.138260,449.179535 227.006287,444.422821 
				C225.548218,438.295868 227.357254,433.247345 231.686768,430.742889 
				C243.350113,423.996063 254.969833,431.365784 254.129440,443.786591 
				C253.663895,450.667175 254.140106,457.577881 247.470139,461.915436 
				C245.653198,463.096985 243.335175,463.508057 241.123566,464.636383 
				C239.933334,465.000000 238.866653,465.000000 237.399994,465.000000 
			M235.003433,441.325684 
				C236.186676,445.259949 239.381836,445.614014 242.402634,444.659393 
				C243.830078,444.208344 245.885620,441.124725 245.507416,440.463745 
				C244.458466,438.630707 242.481354,436.711151 240.503510,436.170624 
				C237.462494,435.339569 234.957840,436.669739 235.003433,441.325684 
			z"
      />
      <path
        fill={foregroundColor}
        opacity="1.000000"
        stroke="none"
        d="
			M182.776566,271.277832 
				C172.493332,262.055695 165.878159,250.803345 163.531952,237.787003 
				C160.045303,218.443756 162.796768,199.944031 174.870758,183.618546 
				C188.132339,165.687317 207.039001,157.242584 228.028137,153.206726 
				C239.224319,151.053894 250.851135,149.950714 262.235687,150.242447 
				C279.972687,150.696991 297.163483,154.718445 313.276184,162.632446 
				C316.685333,164.306900 318.279449,166.274078 318.123383,170.402313 
				C317.744171,180.432861 318.000000,190.487411 318.000000,200.998657 
				C311.532593,200.998657 304.414825,201.117554 297.309204,200.876816 
				C296.179352,200.838531 294.367310,199.351593 294.115356,198.249802 
				C290.759064,183.570740 280.926941,176.374146 266.739655,173.991989 
				C240.242584,169.542892 216.591797,188.167465 212.254807,213.156601 
				C209.578094,228.579544 211.480331,243.916885 220.825928,257.415192 
				C228.377625,268.322449 239.303085,273.436707 252.007645,274.880890 
				C258.086548,275.571899 264.309021,274.999817 270.997467,274.999817 
				C270.997467,265.902191 271.362610,256.924377 270.730042,248.017471 
				C270.579681,245.900360 267.431335,243.245911 265.061127,242.199371 
				C260.687744,240.268372 255.797943,239.540100 251.280243,237.882935 
				C249.920013,237.383972 248.254791,235.534485 248.148682,234.191498 
				C247.769577,229.393982 248.000320,224.548294 248.000320,219.360718 
				C277.497192,219.360718 306.893097,219.360718 336.988037,219.360718 
				C336.988037,224.509872 337.156830,229.939911 336.829651,235.339905 
				C336.772003,236.291687 334.765411,237.461777 333.444763,237.940750 
				C328.470490,239.744736 323.465271,241.518005 318.361389,242.893814 
				C313.647797,244.164413 313.070923,247.702530 313.034058,251.532547 
				C312.934692,261.861481 312.945404,272.192474 313.037659,282.521545 
				C313.061188,285.157227 312.601349,286.680450 309.521271,287.268066 
				C298.743103,289.324371 288.121552,292.468384 277.268921,293.792053 
				C266.636169,295.088928 255.732422,295.555328 245.056198,294.816772 
				C222.207657,293.236176 200.780045,287.071716 182.776566,271.277832 
			z"
      />
      <path
        fill={foregroundColor}
        opacity="1.000000"
        stroke="none"
        d="
			M356.723175,404.219421 
				C364.352234,414.174377 371.719421,423.873596 378.228363,432.442932 
				C388.979736,430.889496 398.945465,428.684906 408.989136,428.240051 
				C417.046021,427.883148 424.891418,430.678131 431.731689,435.354095 
				C433.893921,436.832184 435.548157,438.530731 434.076752,441.769104 
				C431.757629,446.873138 430.437195,452.480408 427.750275,457.354401 
				C424.872009,462.575592 418.776917,462.248077 413.799072,462.853394 
				C406.448730,463.747192 398.982208,463.811890 391.560333,463.932281 
				C388.329376,463.984741 384.851379,463.908508 381.915344,462.776306 
				C379.004547,461.653870 375.801300,459.668121 374.162262,457.132751 
				C363.560669,440.733337 353.346344,424.083435 343.018402,407.507355 
				C340.578461,403.591278 338.189453,399.643433 335.671448,395.538116 
				C344.881165,393.812164 352.573700,394.386230 356.723175,404.219421 
			z"
      />
      <path
        fill={foregroundColor}
        opacity="1.000000"
        stroke="none"
        d="
			M87.207031,432.015350 
				C101.730469,425.729218 115.815910,427.431854 129.805023,432.407349 
				C132.656860,433.421661 134.097153,433.785645 136.308823,430.596985 
				C144.078400,419.395294 152.444580,408.599121 160.844162,397.852112 
				C162.036972,396.325958 164.595200,395.401031 166.647858,395.119568 
				C169.912445,394.671936 173.283417,395.000031 177.448792,395.000031 
				C175.051071,399.176941 173.052094,402.928711 170.806046,406.526184 
				C160.118881,423.643707 149.325104,440.694702 138.656906,457.823944 
				C135.234192,463.319611 129.581223,463.922974 124.255646,463.847137 
				C113.750130,463.697479 103.230408,462.916199 92.778885,461.808289 
				C90.324585,461.548096 87.535561,459.395874 85.939232,457.316620 
				C83.737213,454.448425 82.179665,450.945465 80.945541,447.499695 
				C76.776520,435.859497 76.838303,438.682800 87.207031,432.015350 
			z"
      />
      <path
        fill={foregroundColor}
        opacity="1.000000"
        stroke="none"
        d="
			M147.737213,92.325485 
				C138.673660,77.965469 129.777145,63.941441 121.082214,49.793522 
				C120.358521,48.615974 120.339523,46.128658 121.112350,45.057064 
				C124.816124,39.921505 129.031738,35.326126 136.314514,35.973347 
				C138.291443,36.149036 140.297241,36.000000 142.413574,36.000000 
				C159.680084,67.136757 176.921066,98.227455 194.072296,129.156311 
				C189.304443,131.856201 184.798615,134.374634 180.327133,136.952591 
				C177.781128,138.420456 176.349045,138.266022 174.560989,135.302139 
				C165.903244,120.950943 156.862122,106.831024 147.737213,92.325485 
			z"
      />
      <path
        fill={foregroundColor}
        opacity="1.000000"
        stroke="none"
        d="
			M370.465942,37.043037 
				C380.836700,34.206921 386.495789,37.112537 391.678650,44.588364 
				C392.645996,45.983715 392.603638,49.131512 391.674316,50.633270 
				C374.539856,78.320572 357.201294,105.881561 339.889587,133.459045 
				C339.545135,134.007782 339.006439,134.434616 338.395599,135.094040 
				C332.624054,132.310303 326.875092,129.537460 320.556488,126.489883 
				C337.156433,96.635559 353.651306,66.970154 370.465942,37.043037 
			z"
      />
      <path
        fill={foregroundColor}
        opacity="1.000000"
        stroke="none"
        d="
			M236.225006,358.652283 
				C232.608063,353.693573 229.263184,348.972229 225.191986,343.225647 
				C225.191986,352.096588 225.191986,359.769897 225.191986,367.717194 
				C222.011398,367.717194 219.277588,367.717194 216.000000,367.717194 
				C216.000000,360.800751 216.000000,353.860352 216.000000,346.919952 
				C216.000000,340.119019 216.000000,333.318115 216.000000,326.260742 
				C219.723572,326.260742 223.153442,326.260742 226.479019,326.260742 
				C229.843582,331.739136 233.206329,337.214569 236.552963,342.663788 
				C239.014389,338.775055 241.899170,335.008209 243.903244,330.819977 
				C245.758057,326.943604 248.244232,325.405426 252.381607,325.963989 
				C254.009506,326.183746 255.691895,326.000000 257.673889,326.000000 
				C257.673889,339.872528 257.673889,353.611237 257.673889,367.675964 
				C254.759399,367.675964 251.691513,367.675964 248.219070,367.675964 
				C248.219070,359.753082 248.219070,351.681854 248.219070,342.719940 
				C243.938705,348.624390 240.217880,353.757019 236.225006,358.652283 
			z"
      />
      <path
        fill={foregroundColor}
        opacity="1.000000"
        stroke="none"
        d="
			M99.861496,352.343689 
				C94.319397,371.068970 73.171997,373.213196 62.121155,363.065277 
				C56.747421,358.130615 54.379360,348.766510 56.709354,340.718201 
				C59.197567,332.123444 64.798630,327.525818 73.478760,325.946259 
				C82.148766,324.368500 89.491676,326.484375 95.426376,332.957031 
				C100.400879,338.382446 100.273628,345.165619 99.861496,352.343689 
			M66.884827,343.594666 
				C64.366730,351.454895 71.133598,360.776062 78.626106,359.767883 
				C84.361984,358.996094 89.692101,353.436005 89.998070,347.905273 
				C90.262520,343.125275 85.448524,336.156067 81.133324,335.071838 
				C75.376503,333.625397 70.090744,336.512787 66.884827,343.594666 
			z"
      />
      <path
        fill={foregroundColor}
        opacity="1.000000"
        stroke="none"
        d="
			M341.369507,367.999756 
				C333.363861,370.080536 329.333160,365.441498 326.439484,359.718262 
				C324.438568,355.760773 321.978729,354.020081 317.271240,355.349945 
				C317.271240,359.148315 317.271240,363.220825 317.271240,367.646912 
				C313.823730,367.646912 310.756317,367.646912 307.341461,367.646912 
				C307.341461,354.172974 307.341461,340.454468 307.341461,325.556000 
				C316.233826,326.082489 325.462952,325.890045 334.396423,327.421814 
				C340.193359,328.415802 342.251862,334.237549 342.957458,339.808441 
				C343.612335,344.978424 339.998566,350.483582 334.207794,353.355652 
				C336.969604,357.242645 339.777527,361.042206 342.324402,365.009399 
				C342.585175,365.415649 345.776917,367.778168 341.369507,367.999756 
			M317.000641,343.351318 
				C317.790405,344.233398 318.488617,345.745636 319.387573,345.876221 
				C321.824524,346.230286 324.512299,346.535431 326.784668,345.835022 
				C328.741211,345.231903 331.385315,343.411591 331.668915,341.773407 
				C331.968140,340.044952 330.233948,336.692688 328.678070,336.172180 
				C325.135986,334.987183 321.159149,335.101562 317.000000,334.648834 
				C317.000000,337.812775 317.000000,340.106079 317.000641,343.351318 
			z"
      />
      <path
        fill={foregroundColor}
        opacity="1.000000"
        stroke="none"
        d="
			M286.012939,352.000000 
				C282.883942,352.000000 280.243164,352.000000 277.302368,352.000000 
				C277.302368,354.290161 277.302368,356.353943 277.302368,358.830688 
				C284.256714,358.830688 291.333282,358.830688 298.700989,358.830688 
				C298.700989,361.953918 298.700989,364.685455 298.700989,367.712341 
				C288.386230,367.712341 277.993469,367.712341 267.299133,367.712341 
				C267.299133,354.058777 267.299133,340.342499 267.299133,326.312225 
				C277.583435,326.312225 287.970856,326.312225 298.680420,326.312225 
				C298.680420,328.914795 298.680420,331.647125 298.680420,334.801147 
				C291.762421,334.801147 284.689850,334.801147 277.312195,334.801147 
				C277.312195,337.595825 277.312195,339.992950 277.312195,342.817749 
				C283.250366,342.817749 289.324371,342.817749 295.696503,342.817749 
				C295.696503,345.942108 295.696503,348.673767 295.696503,352.000000 
				C292.705597,352.000000 289.603394,352.000000 286.012939,352.000000 
			z"
      />
      <path
        fill={foregroundColor}
        opacity="1.000000"
        stroke="none"
        d="
			M451.759247,353.391541 
				C453.666443,358.219513 455.375061,362.705170 457.090546,367.208771 
				C448.927032,369.233673 447.759369,368.617462 444.230103,361.789093 
				C443.577484,360.526398 441.734161,359.271332 440.325500,359.125885 
				C436.697815,358.751373 432.993256,358.831207 429.346649,359.099640 
				C428.182281,359.185333 426.659912,360.170227 426.056763,361.190369 
				C421.856537,368.294250 421.920227,368.331909 412.830658,367.741272 
				C415.592438,361.132172 418.293762,354.703796 420.965729,348.263275 
				C423.678070,341.725372 426.236115,335.118378 429.167419,328.680847 
				C429.725861,327.454498 431.729431,326.123871 433.031067,326.158600 
				C435.655579,326.228638 439.046997,324.303650 440.746582,328.256836 
				C444.307739,336.539673 447.951080,344.787231 451.759247,353.391541 
			M438.943115,348.842316 
				C437.707825,345.846008 436.472565,342.849701 434.991089,339.256256 
				C433.977661,341.638214 433.246796,343.210663 432.637695,344.828979 
				C432.042938,346.409210 431.572601,348.036285 430.896637,350.104828 
				C433.867920,349.941376 436.325470,349.806152 438.943115,348.842316 
			z"
      />
      <path
        fill={foregroundColor}
        opacity="1.000000"
        stroke="none"
        d="
			M205.732986,359.332947 
				C206.928253,362.195007 207.898712,364.730682 209.149948,368.000000 
				C206.508270,368.000000 204.106979,367.830048 201.740494,368.044525 
				C198.997787,368.293091 197.487747,367.407532 196.964432,364.576294 
				C196.181961,360.342926 193.864929,357.961487 189.195511,358.995544 
				C189.036713,359.030701 188.862091,359.013550 188.696960,358.998077 
				C181.787277,358.350647 175.310455,358.396332 175.098358,367.875946 
				C171.305679,367.875946 167.987274,367.875946 164.362518,367.875946 
				C168.406372,358.232880 172.426300,348.667419 176.423782,339.092590 
				C177.041794,337.612305 177.545441,336.084656 178.121124,334.586273 
				C181.581131,325.580475 181.582993,325.581177 191.449203,326.227173 
				C196.142319,337.169495 200.825256,348.088043 205.732986,359.332947 
			M187.667313,341.149170 
				C187.117981,341.076721 186.147430,340.814056 186.081543,340.959961 
				C184.772171,343.858795 183.567413,346.804871 182.373047,349.665741 
				C185.504974,349.665741 188.187149,349.665741 190.993179,349.665741 
				C189.896896,346.833191 188.933365,344.343658 187.667313,341.149170 
			z"
      />
      <path
        fill={foregroundColor}
        opacity="1.000000"
        stroke="none"
        d="
			M405.177277,356.673889 
				C406.910645,357.997162 408.396301,359.044617 409.673462,359.945068 
				C401.765015,371.625458 385.189362,370.469940 378.041565,364.609772 
				C367.093109,355.633514 368.141907,340.097290 375.583038,331.955231 
				C383.294983,323.516876 401.323578,322.511871 408.817993,332.443390 
				C407.612183,333.798676 405.992981,335.023132 405.189514,336.650360 
				C403.695251,339.676636 402.440674,339.278778 399.925598,337.577423 
				C393.571472,333.279236 387.830841,334.031769 383.301849,339.181366 
				C379.730774,343.241882 380.321228,351.034332 383.801636,355.423218 
				C388.326141,361.128662 395.630646,360.194214 399.588989,356.797699 
				C401.468292,355.185120 402.948151,354.018250 405.177277,356.673889 
			z"
      />
      <path
        fill={foregroundColor}
        opacity="1.000000"
        stroke="none"
        d="
			M128.985748,326.000000 
				C133.120270,326.000000 136.758087,326.000000 140.696930,326.000000 
				C140.696930,328.945709 140.696930,331.676941 140.696930,334.827820 
				C133.730789,334.827820 126.659622,334.827820 119.298141,334.827820 
				C119.298141,337.622070 119.298141,340.021576 119.298141,342.839935 
				C125.277603,342.839935 131.349197,342.839935 137.708008,342.839935 
				C137.708008,345.966736 137.708008,348.697571 137.708008,351.846863 
				C131.704437,351.846863 125.634918,351.846863 119.151627,351.846863 
				C119.151627,357.306335 119.151627,362.378021 119.151627,367.719177 
				C115.681496,367.719177 112.615974,367.719177 109.272018,367.719177 
				C109.272018,354.019989 109.272018,340.303040 109.272018,326.000000 
				C115.650467,326.000000 122.069756,326.000000 128.985748,326.000000 
			z"
      />
      <path
        fill={foregroundColor}
        opacity="1.000000"
        stroke="none"
        d="
			M351.000000,342.002625 
				C351.000000,336.533173 351.000000,331.562988 351.000000,326.297394 
				C354.287781,326.297394 357.355103,326.297394 360.714630,326.297394 
				C360.714630,339.956940 360.714630,353.680115 360.714630,367.702576 
				C357.712311,367.702576 354.644958,367.702576 351.000000,367.702576 
				C351.000000,359.349731 351.000000,350.925812 351.000000,342.002625 
			z"
      />
      <path
        fill={foregroundColor}
        opacity="1.000000"
        stroke="none"
        d="
			M218.999725,460.804688 
				C218.313095,466.971924 213.790695,462.039215 211.210983,465.720093 
				C211.210983,455.413239 211.210983,446.885681 211.210983,438.069946 
				C209.751480,438.069946 208.614426,437.728333 207.895874,438.131866 
				C205.410049,439.527985 204.173431,438.863647 204.023209,436.126709 
				C203.913849,434.134247 201.387161,431.376129 206.067825,430.870026 
				C210.158432,430.427795 214.161545,429.176361 218.999985,428.106232 
				C218.999985,439.549988 218.999985,449.936615 218.999725,460.804688 
			z"
      />
      <path
        fill={foregroundColor}
        opacity="1.000000"
        stroke="none"
        d="
			M266.472565,429.014160 
				C269.073059,429.002228 271.229401,429.002228 273.692566,429.002228 
				C273.692566,440.555115 273.692566,451.967712 273.692566,463.691284 
				C271.393890,463.691284 268.990723,463.691284 266.172546,463.691284 
				C266.172546,455.380066 266.172546,446.952606 266.172546,437.898926 
				C264.043884,438.202972 262.393738,438.438690 260.398468,438.723694 
				C260.035431,437.917786 259.523163,436.775604 259.006714,435.635284 
				C257.778442,432.923340 257.803833,431.044159 261.563629,430.763885 
				C263.086609,430.650360 264.542175,429.632477 266.472565,429.014160 
			z"
      />
      <path
        fill={foregroundColor}
        opacity="1.000000"
        stroke="none"
        d="
			M474.042969,154.809296 
				C475.516571,153.807083 476.919739,153.207367 478.740723,152.429092 
				C478.740723,157.751007 478.740723,162.324448 478.740723,166.897873 
				C478.175171,167.025436 477.609619,167.153000 477.044067,167.280548 
				C476.540619,164.728317 476.037170,162.176071 475.475006,159.326157 
				C474.276062,160.768021 473.016724,162.282532 471.670593,163.901428 
				C470.602081,162.341171 469.647095,160.946716 467.999390,158.540771 
				C467.999390,161.052536 468.152100,162.718597 467.940063,164.336899 
				C467.819794,165.254974 467.101807,166.094727 466.653015,166.969757 
				C466.102112,166.038376 465.125519,165.129028 465.075989,164.171738 
				C464.888153,160.539627 465.000336,156.891998 465.000336,151.786072 
				C467.286102,153.358368 468.993774,154.053635 469.972534,155.314682 
				C471.581055,157.387054 472.689941,157.480576 474.042969,154.809296 
			z"
      />
      <path
        fill={foregroundColor}
        opacity="1.000000"
        stroke="none"
        d="
			M455.015076,156.053909 
				C453.593231,155.471680 452.625977,154.934219 451.658752,154.396759 
				C451.802673,154.029205 451.946594,153.661652 452.090546,153.294098 
				C455.627014,153.294098 459.163452,153.294098 462.699921,153.294098 
				C462.910614,153.640747 463.121307,153.987396 463.332001,154.334045 
				C462.099945,155.129974 460.867920,155.925903 459.000000,157.132614 
				C459.000000,158.275787 459.210419,160.258713 458.935944,162.172104 
				C458.700500,163.813400 457.910400,165.375137 457.365570,166.972061 
				C456.910370,165.450485 456.292999,163.955322 456.039917,162.400833 
				C455.701782,160.324219 455.646454,158.201569 455.015076,156.053909 
			z"
      />
      <path
        fill="#FFFFFF"
        opacity="1.000000"
        stroke="none"
        d="
			M230.273483,20.228226 
				C242.338898,8.450798 261.775360,6.631364 276.386505,15.689774 
				C287.773407,22.749245 293.589600,32.847729 295.015839,45.879242 
				C295.496185,50.268368 293.869141,53.554001 289.865387,56.192398 
				C289.249237,55.398109 288.498871,54.633656 287.984497,53.734234 
				C285.050354,48.603470 279.183624,48.269379 276.033569,53.311871 
				C274.631409,55.556446 274.067902,58.311001 272.960205,60.760124 
				C272.499237,61.779312 271.611420,62.605427 270.917694,63.519329 
				C270.291443,62.766079 269.266785,62.091671 269.099731,61.247559 
				C267.118683,51.235672 255.749756,49.500870 248.794830,53.974731 
				C245.747513,55.934952 243.926865,59.802128 241.065628,63.413799 
				C240.552826,62.001766 240.165100,59.588512 238.906876,57.781307 
				C236.963287,54.989689 234.805908,51.936840 231.979233,50.340576 
				C230.534271,49.524593 227.269104,51.340237 225.163193,52.551571 
				C224.200500,53.105316 224.051041,55.072834 223.558594,56.333050 
				C216.309006,51.409561 215.745453,48.766891 218.559845,38.787281 
				C220.623215,31.470734 224.363937,25.433998 230.273483,20.228226 
			M256.408508,29.000292 
				C247.798523,29.839329 244.519882,32.941963 243.144089,41.758503 
				C251.733032,41.758503 260.285156,41.758503 268.843323,41.758503 
				C268.645264,34.496822 266.395508,32.058693 256.408508,29.000292 
			M286.996887,36.761757 
				C285.906189,32.433029 283.287750,29.016827 278.801788,29.282698 
				C276.544922,29.416454 273.882568,32.101761 272.492950,34.316704 
				C271.494110,35.908794 272.298523,38.632221 272.298523,42.239021 
				C277.645020,40.546322 282.318298,39.066753 286.996887,36.761757 
			M239.314728,40.924755 
				C240.825897,34.465042 238.954880,30.039776 234.301132,29.066793 
				C230.742279,28.322723 226.190781,32.979717 225.034882,38.819004 
				C229.499466,39.544292 233.958176,40.268623 239.314728,40.924755 
			z"
      />
      <path
        fill="#FFFFFF"
        opacity="1.000000"
        stroke="none"
        d="
			M244.934143,68.738228 
				C245.172760,67.235298 245.468185,65.154381 245.814301,65.145912 
				C253.414474,64.960037 261.020233,65.003990 268.498230,65.003990 
				C267.913452,71.267303 264.016815,74.683029 258.615845,75.860832 
				C252.662094,77.159195 248.275528,73.958771 244.934143,68.738228 
			z"
      />
      <path
        fill="#FFFFFF"
        opacity="1.000000"
        stroke="none"
        d="
			M275.789856,68.293945 
				C275.017761,67.005058 274.514954,66.007736 273.801697,64.593002 
				C278.435486,62.906822 282.981140,61.252724 287.526764,59.598621 
				C287.907349,59.737526 288.287964,59.876427 288.668549,60.015327 
				C286.835724,63.002140 285.467194,66.502220 282.973297,68.758286 
				C281.734467,69.878960 278.429443,68.715599 275.789856,68.293945 
			z"
      />
      <path
        fill="#FFFFFF"
        opacity="1.000000"
        stroke="none"
        d="
			M227.729645,67.230438 
				C226.531662,64.829620 225.610687,62.703987 224.240128,59.540691 
				C229.665833,61.409054 233.838730,62.846008 239.276917,64.718674 
				C237.288422,66.835007 236.032104,69.336555 234.462692,69.551460 
				C232.441940,69.828156 230.170258,68.272209 227.729645,67.230438 
			z"
      />
      <path
        fill="#000000"
        opacity="1.000000"
        stroke="none"
        d="
			M187.818573,341.501648 
				C188.933365,344.343658 189.896896,346.833191 190.993179,349.665741 
				C188.187149,349.665741 185.504974,349.665741 182.373047,349.665741 
				C183.567413,346.804871 184.772171,343.858795 186.081543,340.959961 
				C186.147430,340.814056 187.117981,341.076721 187.818573,341.501648 
			z"
      />
      <path
        fill={foregroundColor}
        opacity="1.000000"
        stroke="none"
        d="
			M256.827698,29.001595 
				C266.395508,32.058693 268.645264,34.496822 268.843323,41.758503 
				C260.285156,41.758503 251.733032,41.758503 243.144089,41.758503 
				C244.519882,32.941963 247.798523,29.839329 256.827698,29.001595 
			z"
      />
      <path
        fill={foregroundColor}
        opacity="1.000000"
        stroke="none"
        d="
			M286.994263,37.174473 
				C282.318298,39.066753 277.645020,40.546322 272.298523,42.239021 
				C272.298523,38.632221 271.494110,35.908794 272.492950,34.316704 
				C273.882568,32.101761 276.544922,29.416454 278.801788,29.282698 
				C283.287750,29.016827 285.906189,32.433029 286.994263,37.174473 
			z"
      />
      <path
        fill={foregroundColor}
        opacity="1.000000"
        stroke="none"
        d="
			M238.865814,40.958855 
				C233.958176,40.268623 229.499466,39.544292 225.034882,38.819004 
				C226.190781,32.979717 230.742279,28.322723 234.301132,29.066793 
				C238.954880,30.039776 240.825897,34.465042 238.865814,40.958855 
			z"
      />
    </SvgIcon>
  );
}) as unknown as OverridableComponent<SvgIconTypeMap<object, 'svg'>> & { muiName: string; displayName: string };

PgaIcon.displayName = 'GratiIcon'; // Add display name
PgaIcon.muiName = 'SvgIcon';

export default PgaIcon;
