import { ReactElement } from 'react';

import Alert from '@mui/material/Alert';
import List from '@mui/material/List';

import { useData } from '../../contextProviders/DataProvider';
import useOrgs from '../../dataHooks/useOrgs';
import { useMembershipMutators } from '../../dataMutators/useMembershipMutators';
import { MembershipStatus } from '../../types/Membership';
import Org from '../../types/Org';
import Hourglass from '../common/Hourglass';

import { OrgListItem } from './OrgListItem';

export const OrgDirectory = (): ReactElement => {
  const { userProfile } = useData();
  const { acceptOrgMembership } = useMembershipMutators();
  const { orgs, isOrgsLoading, orgsError } = useOrgs({ membershipStatus: ['all' as MembershipStatus] });

  console.log('orgs:', orgs); // Add this to debug

  if (isOrgsLoading) {
    return <Hourglass />;
  } else if (orgsError) {
    return <Alert severity="error">Error loading orgs {orgsError.message}</Alert>;
  } else if (!orgs || orgs.length === 0) {
    return <Alert severity="error">No orgs</Alert>;
  } else {
    return (
      <List
        sx={{
          width: '100%',
          height: '100%',
          position: 'relative',
          overflow: 'auto',
          p: 0,
          '& ul': { padding: 0 },
          '& li': { padding: 0 },
        }}
        subheader={<li />}
      >
        { orgs.flatMap((org: Org) => {
            return (
              <OrgListItem 
                key={org.itemId} 
                org={org} 
                isDividerDisplayed={true} 
                isPending={userProfile?.pendingOrgIds?.includes(org.itemId)}
                onAcceptInvitation={() => {
                  acceptOrgMembership(org);
                }}
              />
            );
          })
        }
      </List>
    );
  }
};
