import { ReactElement } from 'react';

import { Link } from 'react-router-dom';

import Fab from '@mui/material/Fab';

import AddIcon from '@mui/icons-material/Add';

import GratiPageWrapper from '../components/common/GratiPageWrapper';
import LeagueDirectory from '../components/league/LeagueDirectory';
import { useData } from '../contextProviders/DataProvider';

export default function LeaguesPage(): ReactElement {
  const { getAdminOrgs} = useData();

  return (
    <GratiPageWrapper>
      <LeagueDirectory />
      { /* TODO: Should this really just default to the first org that is admined? */}
      { (getAdminOrgs().length > 0) &&
        <Fab 
          variant="extended" 
          color="primary" 
          aria-label="add league" 
          component={Link} 
          to={"/league-create/" + getAdminOrgs()[0]}
        >
          <AddIcon />
          Add league
        </Fab>}
    </GratiPageWrapper>
  );  
}
