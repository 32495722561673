import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';

import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import EditIcon from '@mui/icons-material/Edit';

import GratiPageWrapper from '../components/common/GratiPageWrapper';
import HandbookEditor from '../components/handbook/HandbookEditor';
import HandbookEditorGodActions from '../components/handbook/HandbookEditorGodActions';
import HandbookView from '../components/handbook/HandbookView';
import { useApp } from '../contextProviders/AppProvider';
import { useAuth } from '../contextProviders/AuthProvider';
import { useData } from '../contextProviders/DataProvider';
import useHandbook from '../dataHooks/useHandbook';
import { useHandbookMutators } from '../dataMutators/useHandbookMutators';
import Handbook from '../types/Handbook';
interface HandbookPageProps {
  isEditMode?: boolean;
}

export default function HandbookPage(props: HandbookPageProps): ReactElement {
  const isEditMode = props.isEditMode ?? false;
  const { orgId, handbookId } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { setPageName, updateActions } = useApp();
  const { isGod } = useAuth();
  const { isOrgActive, isOrgAdmin, isOrgPro } = useData();
  const [selectedSection, setSelectedSection] = useState(searchParams.get('section') || '0');
  const { updateHandbook, deleteHandbook } = useHandbookMutators();
  const [isPermanentDelete, setIsPermanentDelete] = useState(false);
  const { handbook, isHandbookLoading, handbookError } = useHandbook({ orgId: orgId, handbookId: handbookId });

  useEffect(() => {
    const handleEdit = () => {
      if (handbook) {
        const contentIndex = parseInt(selectedSection.toString());
        navigate(`/handbook-edit/${handbook.orgId}/${handbook.itemId}?section=${contentIndex}`, { replace: true });
      }
    };

    if (handbook) {
      setPageName(handbook.name);
      if (isOrgAdmin(handbook.orgId) || isOrgPro(handbook.orgId)) {
        updateActions([
          {
            name: 'Edit',
            handleClick: handleEdit,
            icon: EditIcon,
          },
        ]);
      }
    }
  }, [handbook, selectedSection, setPageName, updateActions, isOrgAdmin, isOrgPro, navigate]);

  const handleSectionChange = (_event: React.SyntheticEvent, newValue: string) => {
    setSelectedSection(newValue);
  };

  const handleSetPermanentDelete = useCallback((isPermanentDelete: boolean) => {
    setIsPermanentDelete(isPermanentDelete);
  }, []);

  const handleSubmit = (handbook: Handbook, currentSection: string) => {
    if (handbook) {
      updateHandbook(handbook);
    }
    navigate('/handbook/' + orgId + '/' + handbookId + '?section=' + currentSection, { replace: true });
  };

  const handleCancel = (currentSection: string) => {
    navigate('/handbook/' + orgId + '/' + handbookId + '?section=' + currentSection, { replace: true });
  };


  const handleChange = useCallback(
    (updates: Partial<Handbook>) => {
      if (handbook) {
        updateHandbook({
          itemId: handbook.itemId,
          orgId: handbook.orgId,
          ...updates,
        });
      }
    },
    [handbook, updateHandbook]
  );

  const handleDelete = (handbook: Handbook) => {
      console.log(`Deleting handbook ${handbook.itemId}`);
      if (isGod && isPermanentDelete) {
        deleteHandbook(handbook, true);
      } else {
        deleteHandbook(handbook);
      }
      navigate(-1);
  };


  const validateUser = () => {
    if (!orgId || !handbookId) {
      return false;
    }
    return isOrgActive(orgId) ?? false;
  };

  const godActionsElement = useMemo(() => {
    if (!handbook || !isEditMode) return null;
    return <HandbookEditorGodActions handbook={handbook} onSetPermanentDelete={handleSetPermanentDelete} onChange={handleChange} />;
  }, [handbook, isEditMode, handleSetPermanentDelete, handleChange]);

  if (handbookError) {
    return <div>Error: {handbookError.message}</div>;
  }

  return (
    <GratiPageWrapper 
      isContentLoading={isHandbookLoading && !handbook} 
      validateUser={validateUser} 
      godActions={godActionsElement}
    >
      {handbook && (
        <>
          {(isOrgAdmin(handbook.orgId) || isOrgPro(handbook.orgId)) ? (
            <>
              {isEditMode ? (
                <HandbookEditor
                  handbook={handbook}
                  isNew={false}
                  initialSection={selectedSection}
                  onCancel={handleCancel}
                  onDelete={handleDelete}
                  onSubmit={handleSubmit}
                />
              ) : (
                <HandbookView 
                  handbook={handbook} 
                  initialSection={selectedSection} 
                  onSectionChange={handleSectionChange} 
                  showTitle={false}
                />
              )}
            </>
          ) : (
            <HandbookView 
              handbook={handbook} 
              initialSection={selectedSection}
              showTitle={false}
              onSectionChange={handleSectionChange} 
            />
          )}
        </>
      )}
    </GratiPageWrapper>
  );
}
