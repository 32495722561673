import { ReactElement, useState } from 'react';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import List from '@mui/material/List';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import EmailIcon from '@mui/icons-material/Email';

import { useData } from '../../contextProviders/DataProvider';
import usePersons from '../../dataHooks/usePersons';
import { OrgMemberType } from '../../enums/OrgMemberType';
import { MembershipStatus } from '../../types/Membership';
import Person from '../../types/Person';
import Hourglass from '../common/Hourglass';

import PersonListItem from './PersonListItem';

interface PersonDirectoryProps {
  orgId?: string;
  membershipStatus?: MembershipStatus;
  orgMemberType: OrgMemberType;
  isActiveUser?: boolean;
  isAdminHighlighted?: boolean;
  renderActions?: (person: Person) => {
    actions: ReactElement;
    isOpen: boolean;
  };
  pageTitle?: string;
  showCopyEmailList?: boolean;
}

export default function PersonDirectory(props: PersonDirectoryProps): ReactElement {
  const {
    orgId,
    membershipStatus,
    orgMemberType,
    isActiveUser,
    isAdminHighlighted = true,
    pageTitle,
    renderActions,
    showCopyEmailList = false,
  } = props;

  const { getAllActiveOrgs } = useData();
  const [snackbar, setSnackbar] = useState<{ open: boolean; message: string; severity: 'success' | 'error' }>({
    open: false,
    message: '',
    severity: 'success',
  });

  const { persons, isPersonsLoading, personsError } = usePersons({
    isActiveUser,
    membershipStatus,
    orgMemberType,
    orgId,
  });

  const handleCopyEmailList = () => {
    if (!persons || persons.length === 0) {
      setSnackbar({
        open: true,
        message: 'No members found',
        severity: 'error',
      });
      return;
    }

    const emails = persons.map((person) => person.email).filter((email) => email && email.trim() !== '');

    if (emails.length === 0) {
      setSnackbar({
        open: true,
        message: 'No email addresses found',
        severity: 'error',
      });
      return;
    }

    const emailList = emails.join(', ');
    navigator.clipboard
      .writeText(emailList)
      .then(() => {
        setSnackbar({
          open: true,
          message: `Copied distribution list (${emails.length} ${emails.length > 1 ? 'contactss' : 'contact'}) to clipboard`,
          severity: 'success',
        });
      })
      .catch(() => {
        setSnackbar({
          open: true,
          message: 'Failed to copy email list',
          severity: 'error',
        });
      });
  };

  const getPersonStatus = (person: Person): boolean => {
    if (orgId) {
      switch (orgMemberType) {
        case OrgMemberType.MEMBER:
          return person.orgIds?.includes(orgId) ?? false;
        case OrgMemberType.PRO:
          return person.proOrgIds?.includes(orgId) ?? false;
        case OrgMemberType.ADMIN:
          return person.adminOrgIds?.includes(orgId) ?? false;
        default:
          return person.orgIds?.includes(orgId) ?? false;
      }
    } else {
      const allUserOrgIds = getAllActiveOrgs();
      switch (orgMemberType) {
        case OrgMemberType.MEMBER:
          return person.orgIds?.some((id) => allUserOrgIds.includes(id)) ?? false;
        case OrgMemberType.PRO:
          return person.proOrgIds?.some((id) => allUserOrgIds.includes(id)) ?? false;
        case OrgMemberType.ADMIN:
          return person.adminOrgIds?.some((id) => allUserOrgIds.includes(id)) ?? false;
        default: {
          const allPersonOrgIds = [
            ...(person.orgIds || []),
            ...(person.adminOrgIds || []),
            ...(person.proOrgIds || []),
          ];
          return allPersonOrgIds.some((id) => allUserOrgIds.includes(id)) ?? false;
        }
      }
    }
  };

  if (isPersonsLoading) {
    return <Hourglass />;
  }

  if (personsError) {
    console.log('personsError:', personsError);
    return <Alert severity="error">Error loading members {personsError.message}</Alert>;
  }

  if (!persons || persons.length === 0) {
    return <Alert severity="error">{`There are no ${orgMemberType}s in this org.`}</Alert>;
  }

  return (
    <Container disableGutters className="SectionContainer">
      {pageTitle && (
        <Typography align="left" gutterBottom variant="headline">
          {pageTitle}
        </Typography>
      )}
      {showCopyEmailList && (
        <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
          <Button variant="outlined" startIcon={<EmailIcon />} onClick={handleCopyEmailList}>
            Copy Email List
          </Button>
        </Stack>
      )}
      <Box>
        <List
          sx={{
            width: '100%',
            height: '100%',
            position: 'relative',
            overflow: 'auto',
            p: 0,
            '& ul': { padding: 0 },
            '& li': { padding: 0 },
          }}
          subheader={<li />}
        >
          {persons?.flatMap((person: Person) => {
            const actionData = renderActions?.(person);
            return (
              <PersonListItem
                key={person.itemId}
                person={person}
                isActive={getPersonStatus(person)}
                isAdminHighlighted={isAdminHighlighted}
                isClickable={true}
                isDividerDisplayed={true}
                isOrgAdmin={orgId ? person.adminOrgIds?.includes(orgId) : false}
                isOrgPro={orgId ? person.proOrgIds?.includes(orgId) : false}
                renderActions={actionData?.actions}
                isActionsOpen={actionData?.isOpen}
              />
            );
          })}
        </List>
      </Box>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}
