import { ReactElement, useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { useData } from '../../contextProviders/DataProvider';
import useEventPartnerRegs from '../../dataHooks/useEventPartnerRegs';
import useMembershipsForLeague from '../../dataHooks/useMembershipsForLeague';
import { useEventRegistrationMutators } from '../../dataMutators/useEventRegistrationMutators';
import Event from '../../types/Event';
import Membership from '../../types/Membership';
import Registration from '../../types/Registration';
import { GetFirstName } from '../../utils/utils';
import PersonAutocomplete from '../person/PersonAutocomplete';

type PartnerState = {
  partnerId: string | undefined;
  partnerName?: string;
  isPartnerMismatch: boolean;
  isBeingInvited: boolean;
  isInviting: boolean;
  isPartnerConfirmed: boolean;
};

interface PartnerSelectorProps {
  event: Event;
  registration: Registration;
  isMemberMember?: boolean;
  isMemberGuest?: boolean;
}

export default function PartnerSelector(props: PartnerSelectorProps): ReactElement {
  const { event, registration } = props;
  const isMemberMember = props.isMemberMember ?? false;
  // const isMemberGuest = props.isMemberGuest ?? false;

  const { userProfile } = useData();
  const { updateEventRegistration } = useEventRegistrationMutators();
  const [partnerState, setPartnerState] = useState<PartnerState | null>(null);
  const [changeConfirm, setChangeConfirm] = useState(false);

  // Create a hook that wraps useMembershipsForLeague to match PeopleHookResult
  const useMembershipsHook = () => {
    const { memberships, isMembershipsLoading, isMembershipsError } = useMembershipsForLeague({
      orgId: event.orgId,
      leagueId: event.leagueId,
    });
    return {
      items: memberships || undefined,
      isLoading: isMembershipsLoading,
      error: isMembershipsError || undefined,
    };
  };

  const {
    regs: partnerRegs,
    isRegsLoading: isPartnerRegLoading,
    isRegsError: isPartnerRegError,
  } = useEventPartnerRegs({
    orgId: event.orgId,
    eventId: event.itemId,
    partnerId: registration.partnerId,
  });

  useEffect(() => {
    if (registration) {
      console.log('Registration loaded. Setting partners: partnerReg count: ', partnerRegs?.length);
      switch (partnerRegs?.length) {
        case 0:
          setPartnerState({
            partnerId: registration.partnerId,
            partnerName: registration.partnerName,
            isPartnerMismatch: false,
            isBeingInvited: false,
            isInviting: registration?.partnerId !== '',
            isPartnerConfirmed: false,
          });
          break;
        case 1:
          setPartnerState({
            partnerId: partnerRegs[0].playerId,
            partnerName: partnerRegs[0].playerName,
            isPartnerMismatch: registration.partnerId !== '' && registration.partnerId !== partnerRegs[0].playerId,
            isBeingInvited: !registration.partnerId,
            isInviting: false,
            isPartnerConfirmed: registration.partnerId === partnerRegs[0].playerId,
          });
          break;
        default:
          {
            const possiblePartnerId =
              partnerRegs?.find((reg) => reg.playerId === registration.partnerId)?.playerId || '';
            setPartnerState({
              partnerId: possiblePartnerId,
              isPartnerMismatch: false,
              isBeingInvited: possiblePartnerId !== '' && possiblePartnerId !== registration.partnerId,
              isInviting: possiblePartnerId !== '' && possiblePartnerId !== registration.partnerId,
              isPartnerConfirmed: possiblePartnerId === registration.partnerId,
            });
            console.log('Multiple partner registrations found. Verify partner is an active reg.');
          }
          break;
      }
    } else {
      console.log("Registration not loaded. Can''t set partners.");
    }
  }, [registration, partnerRegs]);

  useEffect(() => {
    if (isPartnerRegError) {
      console.log('Error loading registration');
    } else if (isPartnerRegLoading) {
      console.log('Loading partner registration');
    }
  }, [isPartnerRegLoading, isPartnerRegError]);

  const handleRegChange = (updatedRegistration: Partial<Registration>) => {
    if (userProfile) {
      try {
        console.log('Updating registration info: ' + event.itemId, userProfile.itemId, { ...updatedRegistration });
        updateEventRegistration(event, userProfile.itemId, { ...updatedRegistration });
      } catch {
        console.log('Error updating registration info');
      }
    } else {
      console.log('User not logged in');
    }
  };

  const handlePartnerSelectionChange = (_event: React.SyntheticEvent, value: Membership | null, _reason: string) => {
    setPartnerState((prev) => ({
      ...prev,
      partnerId: value?.itemId,
      partnerName: value?.name,
      isPartnerMismatch: false,
      isBeingInvited: false,
      isInviting: false,
      isPartnerConfirmed: false,
    }));
  };

  const handleEndPartnership = () => {
    if (userProfile) {
      try {
        updateEventRegistration(event, userProfile.itemId, { partnerId: '', partnerName: '' });
        setChangeConfirm(false);
      } catch {
        console.log('Error updating registration info');
      }
    } else {
      console.log('User not logged in');
    }
  };

  if (!registration || isPartnerRegLoading) {
    return <> </>;
  } else {
    if (isMemberMember) {
      if (!partnerState) {
        return <> </>;
      } else {
        return (
          <>
            {partnerState.partnerId && partnerState.isPartnerConfirmed && (
              <Box>
                <Typography variant="body1">Your fabulous partner is</Typography>
                <Typography variant="h6">{partnerState.partnerName}</Typography>
                <Stack direction="row" spacing={2}>
                  {!changeConfirm && (
                    <Button variant="outlined" color="secondary" size="small" onClick={() => setChangeConfirm(true)}>
                      Change
                    </Button>
                  )}
                  {changeConfirm && (
                    <Stack direction="row" spacing={1}>
                      <Button variant="text" size="small" color="secondary" onClick={() => handleEndPartnership()}>
                        Confirm Ending Partnership
                      </Button>
                      <Button variant="text" size="small" color="secondary" onClick={() => setChangeConfirm(false)}>
                        Don&apos;t change
                      </Button>
                    </Stack>
                  )}
                </Stack>
              </Box>
            )}
            {!partnerState.isPartnerMismatch && !partnerState.isBeingInvited && !partnerState.isPartnerConfirmed && (
              <Box>
                <PersonAutocomplete<Membership>
                  id="partner"
                  size="small"
                  label="Partner"
                  selectedPersonId={partnerState.partnerId}
                  handleOnChange={handlePartnerSelectionChange}
                  usePeopleHook={useMembershipsHook}
                />
                {partnerState.isInviting && !partnerState.isPartnerConfirmed && (
                  <Typography variant="caption">
                    {partnerState.partnerName
                      ? GetFirstName(partnerState.partnerName) + ' has not yet confirmed.'
                      : 'Waiting for confirmation.'}
                  </Typography>
                )}
              </Box>
            )}
            {partnerState.partnerId && partnerState.isBeingInvited && !partnerState.isPartnerConfirmed && (
              <Box>
                <Typography variant="body1">Confirm your partnership with:</Typography>
                <Typography variant="h6">{partnerState.partnerName}</Typography>
                <Stack direction="row" spacing={2}>
                  <Button
                    variant="contained"
                    onClick={() =>
                      handleRegChange({ partnerId: partnerState.partnerId, partnerName: partnerState.partnerName })
                    }
                  >
                    Confirm
                  </Button>
                  <Button>Reject</Button>
                </Stack>
              </Box>
            )}
          </>
        );
      }
    } else if (props.isMemberGuest) {
      return (
        <TextField
          id="guest name"
          label="Guest"
          aria-label="guest name"
          aria-required="false"
          fullWidth
          defaultValue={registration.partnerName ? registration.partnerName : ''}
          onChange={(e) => {
            if (e != null) {
              handleRegChange({ partnerName: e.target.value });
            }
          }}
        />
      );
    } else {
      return <> </>;
    }
  }
}
